<template>
  <div class="editEnergy">
    <el-button @click="emit('isShowFun', true);">取消</el-button>
    <el-button type="primary" @click="btnClick">确认编辑</el-button>
    <vue3-tree-org
        :clone-node-drag="false"
        :collapsable="true"
        :data="treeData"
        :default-expand-level="10"
        :horizontal="true"
        :label-style="style"
        :only-one-node="false"
        center
        disabled
    >
      <template v-slot="{node}">
        <div class="treeItemCont">
          <p class="name">{{ node.label }}</p>
          <el-popover
              content=""
              placement="bottom"
              popper-class="editEnergyPopver"
              title=""
              trigger="hover"
              width="72"
          >
            <template #reference>
              <p class="more">...</p>
            </template>
            <ul class="operator">
              <li @click="addClick(node)">新增</li>
              <li @click="warnClick(node)">告警设置</li>
              <li @click="delClick(node)">删除</li>
            </ul>
          </el-popover>
        </div>
      </template>
    </vue3-tree-org>


    <!--新增设备弹窗-->
    <!--新增指标弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" class="addDialog addDialogCopy" title='新增'
               width='480px'>
      <div class="borderClass">
        <el-input v-model="deviceName"
                  style="position:sticky;top:0;padding:  30px 10px 10px;z-index: 39;background: #fff"></el-input>
        <div style="padding: 10px;">
          <el-tree
              ref="treeRef"
              :data="allTree"
              :props="prop"
              :render-after-expand="false"
              :render-content="renderContent"
              :show-checkbox="true"
              node-key="id"
              @check-change="nodeClick"
          />
          <!--          @node-click="nodeClick"-->
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="addSave">确定</el-button>
      </span>
      </template>
    </el-dialog>


    <!--告警设置弹窗-->
    <el-dialog v-model="warnDialog" :close-on-click-modal="false" class="addDialog" title='告警设置' width='560px'>
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <div v-for="(item,index) in formData.targetJsonStr" :key="index" class="rowItem">
          <el-form-item name="item.level">
            <el-select v-model="item.level" class="item" clearable placeholder="请选择告警级别">
              <el-option :value="1" label="紧急告警"></el-option>
              <el-option :value="2" label="主要告警"></el-option>
              <el-option :value="3" label="次要告警"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item class="data" name="item.value">
            <el-input v-model="item.value" class="item" placeholder="请输入告警值" type="number">
              <template #prepend>损耗 ></template>
            </el-input>
          </el-form-item>

          <el-icon v-if="formData.targetJsonStr.length>1" class="delIcon" @click="delItemClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===formData.targetJsonStr.length-1" class="addIcon" @click="addItemClick">
            <CirclePlus/>
          </el-icon>
        </div>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
        <el-button @click="warnDialog = false">取消</el-button>
        <el-button type="primary" @click="warnSave">确定</el-button>
      </span>
      </template>
    </el-dialog>


  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, nextTick, onMounted, reactive, ref, watch} from "vue"
import {ElMessage} from "element-plus";
import {getAllTreeApi2} from "@/api/modules/strategy";
import {saveEnergyFlowApi, getEnergyFlowApi} from "@/api/modules/energyFlowChart";
import {CircleClose, CirclePlus} from '@element-plus/icons-vue'
import qs from "qs";

const emit = defineEmits(['isShowFun']);
const props = defineProps(['custerObj', 'type']);

let custerObj = ref({})
let treeData = ref({
  id: 0,
  label: custerObj.value.name,
})
let style = ref({
  background: "#F3F6FF",
  border: '1px solid #E2E7F3',
  boxShadow: '0 0 0 0'
})

// 获取详情-----------------------------------------------------------------------------------
const getEnergyFlowFun = function () {
  getEnergyFlowApi(qs.stringify({
    projectId: custerObj.value.id,
    type: props.type,
  })).then(res => {
    if (res.data.code === 0) {
      if (res.data.result.nodeJsonStr !== '') {
        treeData.value = JSON.parse(res.data.result.nodeJsonStr)
      } else {
        treeData.value = {
          id: 0,
          label: custerObj.value.name
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增-----------------------------------------------------------------------------------
let dialogFormVisible = ref(false)
let addNode = ref()
const addClick = function (node) {
  addNode.value = node
  dialogFormVisible.value = true
  nextTick(() => {
    treeRef.value.setCheckedNodes([])
  })
}
let treeRef = ref()
const addSave = function () {
  // let node = treeRef.value.getCurrentNode()
  let node = treeRef.value.getCheckedNodes()
  if (node) {
    // 把项目删除
    for (let i = 0; i < node.length; i++) {
      if (node[i].isProject) {
        node.splice(i, 1)
      }
    }
    // 把设备添加进去
    for (let i = 0; i < node.length; i++) {
      // 判断是否在第一层
      if (treeData.value.id === addNode.value.id) {
        if (!treeData.value.children) {
          treeData.value.children = []
        }
        treeData.value.children.push({
          id: node[i].id,
          // label: deviceName.value,
          label: node.length === 1 ? deviceName.value : node[i].name,
          pid: addNode.value.id
        })
        dialogFormVisible.value = false
      } else {
        if (!treeData.value.children) {
          treeData.value.children = []
        }
        addFun(treeData.value.children, {
          id: node[i].id,
          // label: deviceName.value,
          label: node.length === 1 ? deviceName.value : node[i].name,
          pid: addNode.value.id
        })
      }
    }
  } else {
    ElMessage({
      message: '请选择需要添加的设备',
      type: 'warning'
    })
  }
}
// 判断在哪新增
const addFun = function (data, obj) {
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === obj.pid) {
        if (!data[i].children) {
          data[i].children = []
        }
        data[i].children.push(obj)
        dialogFormVisible.value = false
        return
      } else {
        if (!data[i].children) {
          data[i].children = []
        }
        addFun(data[i].children, obj)
      }
    }
  }
}
// 处理设备名称
let deviceName = ref()
const nodeClick = function (e, node) {
  // console.log(e, node, treeRef.value.getCheckedNodes())
  let nodeArr = treeRef.value.getCheckedNodes()
  // 把项目删除
  for (let i = 0; i < nodeArr.length; i++) {
    if (nodeArr[i].isProject) {
      nodeArr.splice(i, 1)
    }
  }
  if (nodeArr.length === 1) {
    deviceName.value = nodeArr[0].name
    document.querySelector('.borderClass>.el-input').style.display = 'flex'
  } else {
    deviceName.value = ''
    document.querySelector('.borderClass>.el-input').style.display = 'none'
  }

  // if (!e.isProject) {
  //   deviceName.value = e.name
  // }
}
// 确认编辑
const btnClick = function () {
  saveEnergyFlowApi({
    projectId: custerObj.value.id,
    type: props.type,
    nodeJsonStr: JSON.stringify(treeData.value)
  }).then(res => {
    if (res.data.code === 0) {
      emit('isShowFun', true);
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 报警设置-----------------------------------------------------------------------------------
let warnDialog = ref(false)
// 新增编辑表单数据
let formData = ref({
  targetJsonStr: [
    {
      level: '',
      value: ''
    }
  ]
})
// 点击增加报警
const addItemClick = function () {
  formData.value.targetJsonStr.push({
    level: '',
    value: ''
  })
}
// 点击删除报警
const delItemClick = function (index) {
  formData.value.targetJsonStr.splice(index, 1)
}
let warnSelectId = ref()
const warnClick = function (node) {
  warnSelectId.value = node.id
  // 判断点击的node有没有报警
  // 判断是否在第一层
  if (treeData.value.id === warnSelectId.value) {
    if (treeData.value.warnList && treeData.value.warnList.length > 0) {
      formData.value.targetJsonStr = treeData.value.warnList
    } else {
      formData.value = {
        targetJsonStr: [
          {
            level: '',
            value: ''
          }
        ]
      }
    }
  } else {
    isWarnFun(treeData.value.children)
  }
  warnDialog.value = true
}
// 判断点击的node有没有报警
const isWarnFun = function (data) {
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === warnSelectId.value) {
        if (data[i].warnList && data[i].warnList.length > 0) {
          formData.value.targetJsonStr = data[i].warnList
        } else {
          formData.value = {
            targetJsonStr: [
              {
                level: '',
                value: ''
              }
            ]
          }
        }
        return
      } else {
        isWarnFun(data[i].children)
      }
    }
  }

}
// 保存告警
const warnSave = function () {
  // 判断是否在第一层
  if (treeData.value.id === warnSelectId.value) {
    treeData.value = {
      ...treeData.value,
      warnList: formData.value.targetJsonStr
    }
    warnDialog.value = false
  } else {
    warnFun(treeData.value.children)
  }
}
// 判断在哪设置告警
const warnFun = function (data) {
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === warnSelectId.value) {
        data[i] = {
          ...data[i],
          warnList: formData.value.targetJsonStr
        }
        warnDialog.value = false
        return
      } else {
        warnFun(data[i].children)
      }
    }
  }

}


// 删除-----------------------------------------------------------------------------------
const delClick = function (node) {
  // 判断是否在第一层
  if (treeData.value.id === node.id) {
    ElMessage({
      message: '不能删除项目',
      type: 'warning'
    })
  } else {
    delFun(treeData.value.children, node)
  }
}
// 判断在哪删除
const delFun = function (data, node) {
  if (data && data.length > 0) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === node.id) {
        data.splice(i, 1)
        return
      } else {
        delFun(data[i].children, node)
      }
    }
  }

}


// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let prop = {
  label: 'name',
  value: 'id',
  children: 'subList',
  // disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 自定义树形结构的前面图标
const renderContent = (h, {node, data}) => {
  return h('div', [
    h('img', {
      src: data.isProject ? 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon5.png' : 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon4.png',
      style: {
        width: '16px',
        height: '16px',
        transform: 'translateY(3px)',
        marginRight: '5px'
      }
    }, ''),
    h('span', null, node.label)
  ])


}
// 树形结构数据
let allTree = ref()
const getAllTreeFun = function () {
  // 获取所有设备树
  getAllTreeApi2(qs.stringify({
    type: props.type
  })).then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      // await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
onMounted(() => {
  getAllTreeFun()
  getEnergyFlowFun()
})

// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => props.custerObj, async (newValues, oldValues) => {
  custerObj.value = newValues
  getEnergyFlowFun()
}, {immediate: true, deep: true});
watch(() => props.type, async (newValues, oldValues) => {
  getAllTreeFun()
  getEnergyFlowFun()
}, {immediate: true, deep: true});

</script>

<style lang="less">
.editEnergy {
  width: calc(100% - 40px);
  height: calc(100% - 100px);
  padding: 16px 20px;

  > .el-button:first-child {
    margin-left: calc(100% - 160px);
  }

  .zm-tree-org {
    height: calc(100% - 32px);

    .treeItemCont {
      padding: 7px 10px;
      display: flex;
      align-items: center;

      .name {
        color: #165DFF;
        font-weight: 400;
        font-size: 16px;
        white-space: nowrap;
        margin: 0;
      }

      .more {
        font-size: 20px;
        letter-spacing: 2px;
        margin-left: 50px;
        transform: translateY(-4px);
        cursor: pointer;
        margin-top: 0;
      }

    }
  }

  .addDialog {
    max-height: 73%;
    overflow-y: auto;
    overflow-x: hidden;

    .el-dialog__body {
      height: calc(100% - 180px);
      overflow: auto;
    }


    .el-tree-node__content {
      height: 40px;
      border-radius: 3px;
      border: 1px solid #DCDCDC;
    }

    .is-current {
      > .el-tree-node__content {
        background: #e6eafa;

        span {
          //color: #2A6EF3;
        }
      }
    }

    .rowItem {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 18px;

      .el-form-item {
        margin-bottom: 0 !important;
        margin-right: 16px;

        .el-select.item {
          width: 200px;
        }
      }

      .addIcon, .delIcon {
        font-size: 18px;
        margin-right: 5px;
        cursor: pointer;
      }
    }
  }


  .addDialogCopy {
    height: 73%;

    .el-dialog__body {
      padding-top: 0 !important;
    }
  }
}

.editEnergyPopver {
  width: 90px;
  min-width: 76px !important;
  height: 102px;
  padding-top: 10px !important;

  .operator {
    margin-top: 8px;

    li {
      width: 74px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      margin: 0;
      cursor: pointer;
    }

    li:hover {
      background: #F3F6FF;
      color: #165DFF;
    }
  }


}
</style>
