<template>
  <!--单选指标选择-->
  <div class="deviceSelectModule">
    <el-dialog v-model="deviceVisible" :close-on-click-modal="false" class="bindDialog" title="选择设备" width="70%">
      <div class="dialogCont">
        <div class="leftBox">
          <div class="head">选择指标:</div>
          <div class="leftBoxCont">
            <div class="transferContentLeft">
              <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                       :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                       :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
              </el-tree>
            </div>
            <div class="transferContRight">
              <p v-for="(item,index) in equipmentSelectList" :key="index" :class="activeDevice===item.id?'active':''"
                 @click="addEquipmentFun(item)">
                {{ item.name }}</p>
            </div>
            <div class="transferContRight" style="border-left: 1px solid #efefef">
              <p v-for="(item,index) in dictList" :key="index" :class="activeDict===item.id?'active':''"
                 @click="addDictFun(item)">
                {{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceVisible = false">取消</el-button>
		    		<el-button type="primary" @click="addDeviceFun">添加</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, nextTick, onMounted, reactive, ref, watch} from "vue"
import qs from "qs";
import {ElMessage} from "element-plus";
import {getTreePrefixWithPowerStationApi2} from "@/api/modules/powerStation";
import router from "@/router";
import {listSelectByPrefixEquipmentIdApi} from "@/api/modules/alarm";

const props = defineProps(['deviceVisible', 'checkDeviceList'])
const emit = defineEmits(['deviceVisibleFun', 'checkDeviceListFun']);
const deviceVisible = ref()


// 绑定设备操作-------------------------------------------------------------------
// 点击新增设备
let checkList = ref({})   //选中的设备
let dialogVisible = ref(false)  //时间段弹窗
let activeDevice = ref()
let dictList = ref([])
// 根据设备获取指标
const addEquipmentFun = function (item) {
  activeDevice.value = item.id
  checkList.value.deviceId = item.id
  checkList.value.deviceName = item.name
  // 根据设备获取指标
  listSelectByPrefixEquipmentIdApi(qs.stringify({equipmentIdList: activeDevice.value})).then(res => {
    if (res.data.code === 0) {
      dictList.value = res.data.result
      activeDict.value = ''
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 选择指标
let activeDict = ref()
const addDictFun = function (item) {
  activeDict.value = item.id
  checkList.value.dictId = item.id
  checkList.value.dictName = item.name
  checkList.value.unit = item.unitName
  checkList.value.dictKey = item.dictKey
}
// 点击将设备添加到选择框内
const addDeviceFun = function () {
  dialogVisible.value = false
  emit('deviceVisibleFun', false)
  emit('checkDeviceListFun', checkList.value)
}
// 树级列表
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let defaultExpandKeys = ref() //默认选中树级列表第一级
let treeRef = ref()
let customerList = ref([])
//获取项目树级列表
const getProjectListFun = async function () {
  await getTreePrefixWithPowerStationApi2(qs.stringify({
    id: 0,
    powerStationId: router.currentRoute.value.query.id
  })).then(res => {
    if (res.data.code === 0) {
      customerList.value = res.data.result
      defaultExpandKeys.value = [customerList.value[0].id]
      //有无路由参数
      currentNodeKey.value = customerList.value[0].id
      custerObj.value = customerList.value[0]
      nextTick(() => {
        if (treeRef.value) {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        }
      })
      getEquipmentSelectFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  defaultExpandKeys.value.push(e.parentId)
  getEquipmentSelectFun()
}
// 根据项目获取设备选项
let equipmentSelectList = ref([])
const getEquipmentSelectFun = function () {
  getTreePrefixWithPowerStationApi2(qs.stringify({
    id: custerObj.value.id,
    powerStationId: router.currentRoute.value.query.id
  })).then(res => {
    if (res.data.code === 0) {
      equipmentSelectList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


watch(() => props.deviceVisible, (newVal) => {
  deviceVisible.value = newVal
}, {immediate: true, deep: true})
watch(() => deviceVisible.value, (newVal) => {
  deviceVisible.value = newVal
  emit('deviceVisibleFun', newVal)
})
onMounted(() => {
  getProjectListFun()
})
</script>

<style lang="less">
.deviceSelectModule {
  .el-dialog {
    .dialogCont {
      display: flex;
      justify-content: space-between;
      min-height: 300px;

      .leftBox, .rightBox {
        flex: 1;
        border-radius: 3px;
        border: 1px solid #DDDDDD;
      }

      .leftBox {
        margin-right: 10px;

        .head {
          width: 100%;
          height: 48px;
          background: #F5F7FA;
          border-radius: 3px 3px 0px 0px;
          border: 1px solid #EEEEEE;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 48px;
          text-indent: 16px;
        }

        .leftBoxCont {
          width: 100%;
          height: calc(100% - 52px);
          display: flex;
          justify-content: space-between;


          .transferContentLeft {
            flex: 1;
            min-width: 200px;
            padding: 10px;
            overflow: auto;
            border-right: 1px solid #eee;

            .el-tree-node__content {
              height: 40px;

              .opacityIcon {
                display: none;
              }
            }

            .el-tree-node__content:hover {
              background-color: #F6F7F7;

              .opacityIcon {
                display: inline-flex;
              }
            }

            /*选中颜色*/


            .el-tree-node.is-current > .el-tree-node__content {
              background-color: rgba(22, 93, 255, 0.1);
              color: #165DFF;

              .el-icon {
                color: #165DFF;
              }
            }

            .el-tree {
              --el-tree-expand-icon-color: #000;
              color: #000;
            }

          }

          .transferContRight {
            flex: 1;
            padding: 10px;
            overflow: auto;
            min-width: 200px;

            p {
              width: 100%;
              height: 32px;
              line-height: 32px;
              text-indent: 10px;
              cursor: pointer;
            }

            p:hover {
              background-color: rgba(22, 93, 255, 0.1);
            }

            .active {
              background-color: rgba(22, 93, 255, 0.1);
              color: #165DFF;
            }
          }
        }
      }


      .rightBox {
        flex: 1;
        overflow-y: auto;
        overflow-x: hidden;

        .head {
          width: 100%;
          height: 48px;
          background: #F5F7FA;
          border-radius: 3px 3px 0px 0px;
          border: 1px solid #EEEEEE;
          font-weight: 400;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.9);
          line-height: 48px;
          text-indent: 16px;
        }

        .deviceBox {
          padding: 16px;

          p {
            display: inline-block;
            padding: 1px 8px;
            background: #EBEDF0;
            border-radius: 3px;
            margin-right: 10px;
            margin-bottom: 10px;

            .el-icon {
              margin-left: 5px;
              transform: translateY(2px);
              color: #999999;
              cursor: pointer;
            }
          }
        }
      }

      > .deviceBox {

        p {
          display: inline-block;
          padding: 1px 8px;
          background: #EBEDF0;
          border-radius: 3px;
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }

    }
  }


  .bindDialog {
    margin-top: 5vh;
    max-height: 90vh;

    .leftBox, .rightBox {
      max-height: calc(90vh - 200px);
    }
  }
}
</style>
