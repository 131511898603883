<template>
  <div class="compoundQueryPlan">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="router.push('/admin/compoundQuery')">复合查询</span>
        <span> / </span>
        <span>复合查询管理</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        复合查询管理
      </p>
    </div>
    <!--列表-->
    <div class="content">
      <div class="contain">
        <!--新增查询-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增查询
        </el-button>
        <!--工单列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增复合查询方案</p>
            </el-empty>
          </template>
          <el-table-column label="查询名称" prop="queryName"></el-table-column>
          <el-table-column label="创建时间" prop="ct" sortable></el-table-column>
          <el-table-column label="创建人" prop="creator"></el-table-column>
          <el-table-column fixed="right" label="操作" width="280px">
            <template #default="scope">
              <div class="opacity">
                <span class="editClass btn" @click.prevent="openClick(scope.row.id,scope.row)">打开</span>
                <span v-if="scope.row.canCopyFlag" class="editClass btn"
                      @click.prevent="editClick(scope.row.id,scope.row,'copy')">复制</span>
                <span class="editClass btn"
                      @click.prevent="applicationPlanClick(scope.row.id,scope.row)">应用</span>
                <span class="editClass btn" @click.prevent="chartPlanClick(scope.row.id,scope.row)">图表</span>
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass btn delClass" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑查询':'新建查询'"
               width="60%">
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <el-form-item label="查询名称" prop="queryName" style="width: 33%;">
          <el-input v-model="formData.queryName" placeholder="请输入查询名称"></el-input>
        </el-form-item>
        <el-form-item label="数据间隔" prop="frequency" style="width: 33%;">
          <el-select v-model="formData.timeUnit" placeholder="请选择数据间隔">
            <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value" :value="item.key"/>
          </el-select>
        </el-form-item>
        <el-form-item label="展示方式" prop="chatType" style="width: 33%;">
          <el-select v-model="formData.chatType" placeholder="请选择展示方式">
            <el-option :value="1" label="折线图"></el-option>
            <el-option :value="0" label="柱状图"></el-option>
            <el-option :value="4" label="饼图"></el-option>
            <el-option :disabled="true" :value="2" label="面积图"></el-option>
            <el-option :disabled="true" :value="3" label="点图"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期期限" prop="timeFlag" style="width: 34%;">
          <el-select v-model="formData.timeFlag" clearable @change="timeFlagSelect">
            <el-option label="自定义时间段" value="-1"></el-option>
            <el-option :disabled="formData.chatType==4?false:true" label="瞬时" value="now"></el-option>
            <el-option label="近1小时" value="1H"></el-option>
            <el-option label="近12小时" value="12H"></el-option>
            <el-option label="近一天" value="1D"></el-option>
            <el-option label="近3天" value="3D"></el-option>
            <el-option label="近1周" value="1W"></el-option>
            <el-option label="本月" value="1M"></el-option>
            <el-option label="本季" value="1Q"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="查询日期" prop="date" style="width: 50%">
          <el-date-picker v-model="formData.date" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→"
                          start-placeholder="开始时间" type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="yom" style="width: 16%">
          <el-checkbox-group v-model="formData.yom" :max="1" :min="0"
                             style="display: inline-block;margin-left: 20px">
            <el-checkbox :label="1">同比</el-checkbox>
            <el-checkbox :label="2">环比</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <div v-for="(item,index) in formData.compositeIndexList" :key="index"
             class="rowItem">
          <div v-if="formData.canEditEquipmentFlag==1||isCopy==='copy'"
               :style="{visibility:formData.compositeIndexList.length!==1?'visible': 'hidden'}"
               style="display: flex;align-items: center">
            <el-icon class="el-icon-delete delFilter"
                     style="color: #FE4761;font-size: 16px;cursor: pointer;"
                     @click="delCompound(index)">
              <Delete/>
            </el-icon>
          </div>
          <div class="liItemBox">
            <div>
              <el-input v-model="item.name" :disabled="formData.canEditEquipmentFlag!=1&&isCopy!=='copy'"
                        placeholder="请输入复合指标名称" style="width: 160px"></el-input>
              <el-checkbox-group v-model="item.valueType" :disabled="formData.canEditEquipmentFlag!=1&&isCopy!=='copy'"
                                 style="margin-top: 20px;">
                <el-checkbox :label="0">最大值</el-checkbox>
                <el-checkbox :label="1">最小值</el-checkbox>
              </el-checkbox-group>
            </div>
            <p style="margin-left: 70px">=</p>
          </div>
          <div v-for="(item_child,index_child) in item.data" :key="index_child"
               class="contItemBox">
            <div v-if="index_child!==0" class="symbol">
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='+'?'activeSymbol':''"
                  @click="formData.canEditEquipmentFlag==1||isCopy==='copy'?symbolClick(index,index_child,'+'):null">
                +</p>
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='-'?'activeSymbol':''"
                  @click="formData.canEditEquipmentFlag==1||isCopy==='copy'?symbolClick(index,index_child,'-'):null">
                -</p>
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='×'||item.formula.substring(2*index_child-1,2*index_child)==='*'?'activeSymbol':''"
                  @click="formData.canEditEquipmentFlag==1||isCopy==='copy'?symbolClick(index,index_child,'*'):null">
                ×</p>
              <p
                  :class="item.formula.substring(2*index_child-1,2*index_child)==='÷'||item.formula.substring(2*index_child-1,2*index_child)==='/'?'activeSymbol':''"
                  @click="formData.canEditEquipmentFlag==1||isCopy==='copy'?symbolClick(index,index_child,'/'):null">
                ÷</p>
            </div>
            <div class="contItem">
              <el-select v-model="item_child.type" :disabled="formData.canEditEquipmentFlag!=1&&isCopy!=='copy'"
                         clearable
                         placeholder="请选择参数类型">
                <el-option :value="1" label="指标"/>
                <el-option :value="0" label="数值"/>
              </el-select>
              <el-icon v-if="item.data.length>1&&(formData.canEditEquipmentFlag==1||isCopy==='copy')" class="delIcon"
                       @click="delControl(index,index_child)">
                <CircleClose/>
              </el-icon>
              <div v-if="item_child.type!==''" class="contBox">
                <el-select v-show="item_child.type==1" ref="elSelectTable"
                           v-model="item_child.typeName" :disabled="formData.canEditEquipmentFlag!=1&&isCopy!=='copy'"
                           :filter-method="filterMethod" :popper-append-to-body="false"
                           class="select-unitName select-option-father" clearable filterable
                           popper-class="select_report_and_type">
                  <el-option :value="dataItemList">
                    <el-tree
                        ref="treeRef"
                        :expand-on-click-node=true
                        :filter-node-method="filterNode"
                        :load="treeList"
                        :props="props"
                        highlight-current
                        lazy
                        @node-click="clickUnitTreeNodeChild($event,index,index_child)"
                    />
                  </el-option>
                </el-select>
                <div class="center">
                  <el-input v-if="item_child.type==0" v-model="item_child.val"
                            :disabled="formData.canEditEquipmentFlag!=1&&isCopy!=='copy'" class="val" placeholder="请输入"
                            type="number"
                  />
                  <span v-if="item_child.type==1&& item_child.deviceName">{{ item_child.deviceName }}</span>
                  <!--                  <p v-if="item_child.type==1">{{ item_child.typeName }}</p>-->
                </div>
                <p v-if="item_child.type==1" class="unit">{{ item_child.unit }}</p>
              </div>
              <div v-if="item_child.type==1" class="contTime">
                <el-checkbox v-model="item_child.difference"
                             :disabled="formData.canEditEquipmentFlag!=1&&isCopy!=='copy'" label="差值" size="large"/>
              </div>
            </div>
          </div>
          <div v-if="formData.canEditEquipmentFlag==1||isCopy==='copy'">
            <el-icon class="addIcon" @click="addControl(index)">
              <CirclePlus/>
            </el-icon>
          </div>
        </div>
        <el-button v-if="formData.canEditEquipmentFlag==1||isCopy==='copy'" style="margin-bottom: 0;width: 160px"
                   type="primary" @click="addCompound">添加复合指标
        </el-button>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle resetButton" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <!--创建图表弹窗-->
    <el-drawer v-model="drawerChart" :show-close="false" direction="rtl" size="580">
      <template #header>
        <span class="tltleClass">创建图表</span>
        <span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerChart=false">
						<CloseBold/>
					</el-icon>
				</span>
      </template>
      <template #footer>
        <div class="operBox">
          <el-button class="resetButton" @click="drawerChart=false">取消</el-button>
          <el-button type="primary" @click="saveProjectSubmit">确认</el-button>
        </div>
      </template>
      <template #default>
        <div style="padding: 20px;height: calc(100% - 40px)">
          <el-form ref="chartFormRef" :model="chartFormData" :rules="chartFormRules" class="demo-ruleForm"
                   status-icon>
            <el-form-item label="资产目录:" prop="projectId">
              <el-tree-select
                  ref="treeRef2"
                  v-model="chartFormData.projectId"
                  :check-strictly="true"
                  :data="customerList"
                  :expand-on-click-node="false"
                  :props="{label:'name',children: 'subList'}"
                  filterable
                  node-key="id"
                  style="height: auto!important;"
                  @change="getQueryGroupListFun"
              />
            </el-form-item>
            <el-form-item label="分组:" name="name" prop="name">
              <el-select v-model="queryGroup" :teleported="false" allow-create class="planBox"
                         clearable filterable placeholder="请选择已有分组或新增分组">
                <el-option v-for="(item,index) in queryGroupList" :key="index" :label="item"
                           :value="item"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="transferRightBox">
            <div class="head">
              <p>已应用的设备</p>
            </div>
            <!--多设备操作-->
            <div class="transferContain">
              <div class="transferContainHead">
                <p>
                  <el-checkbox v-model="checkAll" @change="checkAllFun"></el-checkbox>
                </p>
                <p>序号</p>
                <p v-for="(item,index) in checkList[0].arr" :key="index">设备{{ index + 1 }}</p>
              </div>
              <div class="transferContainCont">
                <div v-for="(item,index) in checkList" :key="index" class="transferContainItem">
                  <p>
                    <el-checkbox v-model="item.check"></el-checkbox>
                  </p>
                  <p>
                    <span>{{ index + 1 }}</span>
                  </p>
                  <p v-for="(item_t,index_t) in item.arr" :key="index_t">
                    <el-tooltip :content="item_t.value">
                      <span style="color: #165DFF">{{ item_t.value }}</span>
                    </el-tooltip>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </el-drawer>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref, watch} from "vue"
import './compoundQueryPlan.less'

import {Delete, ZoomIn, Plus, Back, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {
  getQueryListApi,
  getQueryDetailApi,
  saveQueryListApi,
  editQueryListApi,
  delQueryListApi
} from '@/api/modules/compoundQuery'

import moment from "moment";
import {getTreeListApi} from "@/api/modules/control";
import letterList from "@/assets/js/letterList";
import {
  getIntervalListApi,
  getSearchDetailApi,
  getSearchListApiPag,
  getUseEquipmentApi
} from "@/api/modules/dataSearch";
import {addQueryGroupListApi, getProjectListApi, getQueryGroupListApi} from "@/api/modules/project";
import {v4 as uuidv4} from "uuid";


let router = useRouter()
let route = useRouter()

// 返回
const goBack = function () {
  // router.push('/admin/compoundQuery')
  router.go(-1)
}


// 工单列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getSearchListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getSearchListFun()
}
// 表格数据
let tableData = ref([])
// 获取表格数据
let loading = ref(false)
const getSearchListFun = function () {
  loading.value = true
  getSearchListApiPag(qs.stringify({
    type: 1,
    pageSize: pageSize.value,
    pageNumber: pageNumber.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)

// 新增编辑表单数据
let formData = ref({
  queryName: '',
  queryType: 1,
  timeFlag: '',
  yom: [],
  date: [],
  startTime: '',
  endTime: '',
  frequency: 1,
  chatType: 1,
  timeUnit: '1h',
  canEditEquipmentFlag: 1,
  creator: window.localStorage.getItem('memberId'),
  compositeIndexList: [
    {
      formula: 'A',
      name: '',
      valueType: [],
      data: [{
        val: "",
        type: '',
        unit: "",
        typeId: '',
        typeName: "",
        replaceStr: "A",
        difference: '',
        deviceId: '',
        deviceName: '',
      }]
    },
  ]
})
// 表单规则
let formRules = ref({
  queryName: [
    {required: true, message: '请输入查询方案名称', trigger: 'blur'},
  ],
  timeFlag: [
    {required: true, message: '请选择日期期限', trigger: 'blur'},
  ]
})
// 日期快捷选择事件
const timeFlagSelect = function () {
  if (formData.value.timeFlag === '-1') {
    formData.value.date = []
  } else if (formData.value.timeFlag === '1H') {
    formData.value.date = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '12H') {
    formData.value.date = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1D') {
    formData.value.date = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '3D') {
    formData.value.date = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1W') {
    formData.value.date = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (formData.value.timeFlag === '1M') {
    formData.value.date = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')]
  } else if (formData.value.timeFlag === '1Q') {
    formData.value.date = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value = {
    queryName: '',
    queryType: 1,
    timeFlag: '',
    yom: [],
    date: '',
    startTime: '',
    endTime: '',
    frequency: 1,
    chatType: 1,
    timeUnit: '1h',
    creator: window.localStorage.getItem('memberId'),
    canEditEquipmentFlag: 1,
    compositeIndexList: [
      {
        formula: 'A',
        name: '',
        valueType: [],
        data: [{
          val: "",
          type: '',
          unit: "",
          typeId: '',
          typeName: "",
          replaceStr: "A",
          difference: '',
          deviceId: '',
          deviceName: '',
        }]
      },
    ]
  }
}
// 点击编辑
let isCopy = ref()
const editClick = function (id, row, copyFlag) {
  dialogFormVisible.value = true
  isCopy.value = copyFlag
  getQueryDetailApi(id).then(res => {
    if (res.data.code === 200) {
      formData.value = {
        id: id,
        queryName: row.queryName,
        queryType: 1,
        timeFlag: res.data.data.timeFlag,
        yom: res.data.data.yom ? [res.data.data.yom] : [],
        date: [res.data.data.startTime, res.data.data.endTime],
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        frequency: res.data.data.frequency,
        chatType: res.data.data.chatType,
        timeUnit: res.data.data.timeUnit,
        canEditEquipmentFlag: res.data.data.canEditEquipmentFlag,
        creator: window.localStorage.getItem('memberId'),
        compositeIndexList: res.data.data.compositeIndexList
      }
      for (let i = 0; i < formData.value.compositeIndexList.length; i++) {
        // 处理最大值最小值
        if (formData.value.compositeIndexList[i].valueType === '') {
          formData.value.compositeIndexList[i].valueType = []
        } else {
          formData.value.compositeIndexList[i].valueType = formData.value.compositeIndexList[i].valueType.split(',')
          formData.value.compositeIndexList[i].valueType = formData.value.compositeIndexList[i].valueType.map(Number)
        }
        // 处理差值
        for (let j = 0; j < formData.value.compositeIndexList[i].data.length; j++) {
          formData.value.compositeIndexList[i].data[j].difference = formData.value.compositeIndexList[i].data[j].difference === 1 ? true : false
        }
      }
      if (copyFlag) {
        formData.value.id = 0
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 提交
let fromRef = ref()
const save = function () {
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let flag = formData.value.compositeIndexList.length > 0 ? true : false
        for (let i = 0; i < formData.value.compositeIndexList.length; i++) {
          if (!formData.value.compositeIndexList[i].name) {
            flag = false
          } else {
            for (let j = 0; j < formData.value.compositeIndexList[i].data.length; j++) {
              if (formData.value.compositeIndexList[i].data[j].type == 1) {
                if (!formData.value.compositeIndexList[i].data[j].typeId) {
                  flag = false
                }
              } else if (formData.value.compositeIndexList[i].data[j].type == 0) {
                if (!formData.value.compositeIndexList[i].data[j].val && formData.value.compositeIndexList[i].data[j].val !== 0) {
                  flag = false
                }
              }
            }
          }
        }
        if (flag) {
          formData.value.startTime = formData.value.date[0]
          formData.value.endTime = formData.value.date[1]
          // 处理同比和环比
          if (formData.value.yom.length === 0) {
            formData.value.yom = 0
          } else {
            formData.value.yom = formData.value.yom[0]
          }
          // 处理差值
          for (let i = 0; i < formData.value.compositeIndexList.length; i++) {
            // 处理最大最小值
            formData.value.compositeIndexList[i].valueType = formData.value.compositeIndexList[i].valueType.join()
            for (let j = 0; j < formData.value.compositeIndexList[i].data.length; j++) {
              formData.value.compositeIndexList[i].data[j].difference = formData.value.compositeIndexList[i].data[j].difference ? 1 : 0
            }
          }
          // 处理符号和字母
          for (let i = 0; i < formData.value.compositeIndexList.length; i++) {
            for (let j = 0; j < formData.value.compositeIndexList[i].data.length; j++) {
              formData.value.compositeIndexList[i].data[j].replaceStr = letterList()[j].name
            }
            formData.value.compositeIndexList[i].formula = letterList()[0].name + formData.value.compositeIndexList[i].formula.substr(1);
            for (let j = 0; j < formData.value.compositeIndexList[i].formula.length; j++) {
              if (j % 2 == 0 && j !== 0) {
                formData.value.compositeIndexList[i].formula = formData.value.compositeIndexList[i].formula.substr(0, j) + letterList()[j / 2].name + formData.value.compositeIndexList[i].formula.substr(j + 1);
              }
            }
          }
          delete formData.value.date
          if (formData.value.chatType === 4) {
            formData.value.yom = 0
          }
          if (formData.value.id) {
            editQueryListApi(formData.value).then(res => {
              if (res.data.code === 200) {
                getSearchListFun()
                dialogFormVisible.value = false
              } else {
                ElMessage({
                  type: 'error',
                  message: res.data.message
                })
              }
            }).catch(err => {
              console.log(err);
            })
          } else {
            saveQueryListApi(formData.value).then(res => {
              if (res.data.code === 200) {
                getSearchListFun()
                dialogFormVisible.value = false
              } else {
                ElMessage({
                  type: 'error',
                  message: res.data.message
                })
              }
            }).catch(err => {
              console.log(err);
            })
          }
        } else {
          ElMessage({
            type: 'info',
            message: '请输入必填项'
          })
        }
      }
    })
  }
}


// 点击删除
const delClick = function (id, row) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除查询方案',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此查询方案，删除后数据不可恢复'),
    ])
  }).then(() => {
    delQueryListApi(id).then(res => {
      if (res.data.code === 200) {
        getSearchListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 弹窗的参数操作------------------------------------
// 符号点击
const symbolClick = function (index, index_child, e) {
  let str = formData.value.compositeIndexList[index].formula
  formData.value.compositeIndexList[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加行
const addCompound = function () {
  formData.value.compositeIndexList.push({
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      difference: '',
      deviceId: '',
      deviceName: '',
    }]
  })
}
// 删除行
const delCompound = function (index) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除复合指标',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此复合指标，删除后数据不可恢复'),
    ])
  }).then(() => {
    formData.value.compositeIndexList.splice(index, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
// 添加参数值
const addControl = function (index) {
  formData.value.compositeIndexList[index].formula = formData.value.compositeIndexList[index].formula.concat('+D')
  formData.value.compositeIndexList[index].data.push({
    val: "",
    type: '',
    unit: "",
    typeId: '',
    typeName: "",
    replaceStr: "A",
    difference: '',
    deviceId: '',
    deviceName: '',
  })
  console.log(formData.value.compositeIndexList)
}
// 删除参数值
const delControl = function (index, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    let str = formData.value.compositeIndexList[index].formula
    let strA
    let strB
    if (index_child !== 0) {
      strA = str.slice(0, 2 * index_child - 1)
      strB = str.slice(2 * index_child + 1, str.length)
    } else {
      strA = ''
      strB = str.slice(2, str.length)
    }
    formData.value.compositeIndexList[index].formula = strA.concat(strB)
    formData.value.compositeIndexList[index].data.splice(index_child, 1)
    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index, index_child) {
  if (data.type == 3) {
    formData.value.compositeIndexList[index].data[index_child].typeName = data.name
    formData.value.compositeIndexList[index].data[index_child].typeId = data.id
    formData.value.compositeIndexList[index].data[index_child].deviceName = data.equipmentName
    formData.value.compositeIndexList[index].data[index_child].unit = data.unitName
    formData.value.compositeIndexList[index].data[index_child].deviceId = data.equipmentId
    formData.value.compositeIndexList[index].data[index_child].dictKey = data.dictKey
    elSelectTable.value[index_child].blur();
  } else {
    // ElMessage({
    //   type: 'info',
    //   message: '当前选择不是指标选项'
    // });
  }
}


// 日期快捷选择事件
const timeFlagSelect2 = function (timeFlag) {
  if (timeFlag === '-1') {
    return []
  } else if (timeFlag === '1H') {
    return [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '12H') {
    return [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1D') {
    return [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '3D') {
    return [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1W') {
    return [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === 'THIS_WEEK') {
    return [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1M') {
    return [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag === '1Q') {
    return [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // return [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}

// 点击打开
const openClick = async function (id, row) {
  // 获取方案详情-------------------------
  await getQueryDetailApi(id).then(res => {
    if (res.data.code === 200) {
      // 处理图表查询条件数据---复杂查询处理
      let data = {
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        frequency: res.data.data.frequency,
        timeUnit: res.data.data.timeUnit,
        timeFlag: res.data.data.timeFlag,
        chatType: res.data.data.chatType,
        compositeIndexList: res.data.data.compositeIndexList,
        yom: res.data.data.yom
      }
      if (res.data.data.timeFlag !== '-1' && res.data.data.timeFlag !== 'now') {
        data.startTime = timeFlagSelect2(res.data.data.timeFlag)[0]
        data.endTime = timeFlagSelect2(res.data.data.timeFlag)[1]
      }
      // 处理差值
      for (let i = 0; i < data.compositeIndexList.length; i++) {
        // 处理最大最小值
        // data.compositeIndexList[i].valueType = data.compositeIndexList[i].valueType.join()
        for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
          data.compositeIndexList[i].data[j].difference = data.compositeIndexList[i].data[j].difference ? 1 : 0
          data.compositeIndexList[i].data[j].val = Number(data.compositeIndexList[i].data[j].val)
        }
      }
      // 计算同环比时间
      if (data.yom == 1) {
        data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      } else if (data.yom == 2) {
        if (data.timeFlag === '1M') {
          data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
        } else if (data.timeFlag === '1Q') {
          data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
          data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        } else {
          let timeLength = moment(data.endTime).diff(data.startTime, 'second')
          data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let flag = false
      for (let i = 0; i < data.compositeIndexList.length; i++) {
        if (!data.compositeIndexList[i].name || !data.compositeIndexList[i].formula || (data.compositeIndexList[i].data.length === 0)) {
          flag = true
        } else {
          for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
            if (data.compositeIndexList[i].data[j].type === '') {
              flag = true
            }
          }
        }
      }
      // 处理符号和字母
      for (let i = 0; i < data.compositeIndexList.length; i++) {
        for (let j = 0; j < data.compositeIndexList[i].data.length; j++) {
          data.compositeIndexList[i].data[j].replaceStr = letterList()[j].name
        }
        data.compositeIndexList[i].formula = letterList()[0].name + data.compositeIndexList[i].formula.substr(1);
        for (let j = 0; j < data.compositeIndexList[i].formula.length; j++) {
          if (j % 2 == 0 && j !== 0) {
            data.compositeIndexList[i].formula = data.compositeIndexList[i].formula.substr(0, j) + letterList()[j / 2].name + data.compositeIndexList[i].formula.substr(j + 1);
          }
        }
      }
      if (data.chatType === 4) {
        data.yom = 0
      }

      // 点击跳转并传递查询条件
      let obj = {
        echartSearchData: data
      }
      let uid = uuidv4()
      window.localStorage.setItem(uid, JSON.stringify(obj));
      window.open(`#/homeSearchScreen?queryType=2&uid=${uid}`)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })

}


// 方案应用操作-----------------------------------------------------------------
const applicationPlanClick = function (id, row) {
  router.push({
    path: '/admin/applicationPlan',
    query: {
      id: id,
      name: row.queryName,
      type: 1
    }
  })
}


// 创建图表-------------------------------------------------------------------------
let drawerChart = ref(false)  //创建图表弹窗
let chartFormData = ref({
  projectId: ''
})  //图表弹窗的数据
let chartFormRules = ref({
  projectId: [{
    required: true,
    message: '请选择需要绑定的资产',
    trigger: 'blur'
  },]
})
// 点击创建图表
let chartIdx = ref()
const chartPlanClick = function (id, row) {
  getUseEquipmentFun(id)
  chartIdx.value = id
  drawerChart.value = true
}
// 获取分组列表
let queryGroup = ref('')
let queryGroupList = ref([])
const getQueryGroupListFun = function () {
  getQueryGroupListApi(qs.stringify({
    type: 2,
    projectId: chartFormData.value.projectId
  })).then(res => {
    if (res.data.code === 0) {
      queryGroupList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let checkList = ref([{}])  //设备方案已选设备
// 获取应用设备初始化列表
const getUseEquipmentFun = function (id) {
  getUseEquipmentApi(qs.stringify({
    id: id,
  })).then(res => {
    if (res.data.code === 0) {
      checkList.value = []
      let list = JSON.parse(JSON.stringify(res.data.result))
      for (let i = 0; i < list.length; i++) {
        checkList.value.push({
          check: false,
          arr: []
        })
        for (let j = 0; j < list[i].length; j++) {
          checkList.value[i].arr.push(list[i][j])
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 全选
let checkAll = ref(false)
let checkAllFun = function () {
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkAll.value) {
      checkList.value[i].check = true
    } else {
      checkList.value[i].check = false
    }
  }
}
// 创建图表提交
let chartFormRef = ref()
const saveProjectSubmit = function () {
  let list = []
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkList.value[i].check) {
      list[i] = []
      for (let j = 0; j < checkList.value[i].arr.length; j++) {
        list[i].push(checkList.value[i].arr[j].key)
      }
    }
  }
  let list1 = list.filter(element => element != null && element !== '' && element !== undefined)
  chartFormRef.value.validate((valid) => {
    //提交成功
    if (valid) {
      if (list1.length > 0 && chartFormData.value.projectId !== '' && chartIdx.value !== '') {
        addQueryGroupListApi(qs.stringify({
          type: 2,
          projectId: chartFormData.value.projectId,
          groupName: queryGroup.value,
          queryId: chartIdx.value,
          equipmentIdJson: JSON.stringify(list1)
        })).then(res => {
          if (res.data.code === 0) {
            drawerChart.value = false
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            })
          }
        }).catch(err => {
          console.log(err);
        })
      } else {
        ElMessage({
          type: 'info',
          message: '请输入必填项'
        })
      }
    }
  })

}

// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let treeRef2 = ref(null)
//获取项目树级列表
const getProjectListFun = async function () {
  await getProjectListApi().then(res => {
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}

let isAddBtn = ref(false) //判断是否是新增按钮进来
let intervalList = ref([])  //数据间隔列表
onMounted(() => {
  getProjectListFun()  //获取项目树
  getSearchListFun()
  getQueryGroupListFun()  //获取分组列表
  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })


  if (router.currentRoute.value.query.flag) {
    isAddBtn.value = true
    dialogFormVisible.value = true
  }
  if (router.currentRoute.value.query.id) {
    getUseEquipmentFun(router.currentRoute.value.query.id)
    chartIdx.value = router.currentRoute.value.query.id
    drawerChart.value = true
  }
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});
})


watch(dialogFormVisible, (newVal) => {
  if (!newVal) {
    isAddBtn.value = false
  }
})

</script>

<style lang="less" scoped>
</style>
