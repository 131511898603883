<template>
  <div class="ljsScreen">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="true" hight="1080" width="1920">
      <div class="mainCont">
        <div class="head">罗 杰 斯 科 技 光 伏 数 据</div>
        <div class="timeBox">
          <p class="time">{{ timeVal }}</p>
          <p class="date">{{ dateVal }}</p>
          <p class="week">{{ weekVal }}</p>
        </div>
        <div class="leftBox">
          <div class="leftBoxItem">
            <div class="titleBox">
              <p class="name">光伏发电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUpGenerateElectricityToday" :end-val="data.generateElectricityToday"
                          :start-val="countStart1" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUpGenerateElectricityYesterday" :end-val="data.generateElectricityYesterday"
                          :start-val="countStart2" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUpGenerateElectricityMonth" :end-val="data.generateElectricityMonth"
                          :start-val="countStart3" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">历史累计</p>
                <count-up ref="countUpGenerateElectricityTotal" :end-val="data.generateElectricityTotal"
                          :start-val="countStart4" class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">近七日发电趋势</p>
              <div class="chart1 chart"></div>
            </div>
          </div>
          <div class="leftBoxItem">
            <div class="titleBox">
              <p class="name">光伏上网电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">今日</p>
                <count-up ref="countUpUpToday" :end-val="data.upToday" :start-val="countStart5" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">昨日</p>
                <count-up ref="countUpUpYesterday" :end-val="data.upYesterday" :start-val="countStart6"
                          class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当月累计</p>
                <count-up ref="countUpUpMonth" :end-val="data.upMonth" :start-val="countStart7" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">历史累计</p>
                <count-up ref="countUpUpTotal" :end-val="data.upTotal" :start-val="countStart8" class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">近七日上网电量趋势</p>
              <div class="chart2 chart"></div>
            </div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightBoxItem weatherBox">
            <div class="titleBox">
              <span>{{ currentWeatherObj.city }}</span>
              <span>天气</span>
              <span>{{ currentWeatherObj.reporttime }}更新</span>
            </div>
            <div class="currentWeatherBox">
              <div>{{ currentWeatherObj.temperature }}°</div>
              <div>
                <span>{{ currentWeatherObj.winddirection }}风{{ currentWeatherObj.windpower }}级</span>
                <span>{{
                    allWeatherData[0] ? allWeatherData[0].nighttemp : ''
                  }}~{{ allWeatherData[0] ? allWeatherData[0].daytemp : '' }}C°</span>
                <!--<span>{{currentWeatherObj.humidity}}</span>-->
              </div>
              <div>
                <span>{{ currentWeatherObj.weather }}</span>
                <!--<img alt="" src="../../../assets/img/icon5.svg" />-->
              </div>
            </div>
            <div class="contentBox">
              <p>近期天气预报</p>
              <div class="ulBox">
                <div v-for="item in allWeatherData" class="liBox">
                  <span>{{ item.dateWeek }}</span>
                  <span>{{ item.dateNew }}</span>
                  <span style="color: #71CFD7;">{{ item.dayweather }}</span>
                  <span>{{ item.nighttemp }}</span>
                  <span>{{ item.daytemp }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="rightBoxItem">
            <div class="titleBox">
              <p class="name">光伏发电功率</p>
              <p class="unit">kwh</p>
            </div>
            <div class="dataBox">
              <div class="dataItem">
                <p class="name">最高 <span>{{ data.generateRateMaxTime }}</span></p>
                <count-up ref="countUpGenerateRateMaxValue" :end-val="data.generateRateMaxValue"
                          :start-val="countStart9" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">最低 <span>{{ data.generateRateMinTime }}</span></p>
                <count-up ref="countUpGenerateRateMinValue" :end-val="data.generateRateMinValue"
                          :start-val="countStart10" class="val"></count-up>
              </div>
              <div class="dataItem">
                <p class="name">当前 <span>{{ data.generateRateNowTime }}</span></p>
                <count-up ref="countUpGenerateRateNowValue" :end-val="data.generateRateNowValue"
                          :start-val="countStart11" class="val"></count-up>
              </div>
            </div>
            <div class="chartBox">
              <p class="chartTitle">当日光伏发电功率</p>
              <div class="chart3 chart"></div>
            </div>
          </div>
        </div>
        <div class="topBox">
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon.svg">
            <div class="valCont">
              <count-up ref="countUpInTimeRate" :end-val="data.inTimeRate" :start-val="countStart12"
                        class="val"></count-up>
              <span> kw</span>
              <p class="name">即时功率</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon2.svg">
            <div class="valCont">
              <count-up ref="countUpThatDayIncome" :end-val="data.thatDayIncome" :start-val="countStart13"
                        class="val"></count-up>
              <span> 元</span>
              <p class="name">当天收益</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon3.svg">
            <div class="valCont">
              <count-up ref="countUpStandardCoal" :end-val="data.standardCoal" :start-val="countStart14"
                        class="val"></count-up>
              <span> 吨</span>
              <p class="name">总折合标准煤</p>
            </div>
          </div>
          <div class="topBoxItem">
            <img alt="" src="../../../assets/img/icon4.svg">
            <div class="valCont">
              <count-up ref="countUpTotalIncome" :end-val="data.totalIncome" :start-val="countStart15"
                        class="val"></count-up>
              <span> 元</span>
              <p class="name">总收益</p>
            </div>
          </div>
        </div>
        <div class="bottomBox">
          <div class="bottomBoxItem">
            <div class="titleBox">
              <p class="name">当天发电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="chart chart4"></div>
          </div>
          <div class="bottomBoxItem">
            <div class="titleBox">
              <p class="name">当月发电量</p>
              <p class="unit">kwh</p>
            </div>
            <div class="chart chart5"></div>
          </div>
        </div>
        <div class="mainCircuit">
          <div class="leftCont">
            <div class="topCont">
              <div class="itemCont">
                <div class="img">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/gf2.svg">
                  <img alt="" class="jiantou" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/jiantou.svg">
                </div>
                <div class="list">
                  <div v-for="(item,index) in equipmentData.aList" :key="index" class="listItem">
                    <p class="name">{{ item.key }}</p>
                    <count-up :decimalPlaces="2" :end-val=" item.value" :start-val="0" class="val"></count-up>
                    kwh
                  </div>
                </div>
              </div>
              <div class="itemCont">
                <div class="img">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/gf2.svg">
                  <img alt="" class="jiantou" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/jiantou.svg">
                </div>
                <div class="list">
                  <div v-for="(item,index) in equipmentData.dList" :key="index" class="listItem">
                    <p class="name">{{ item.key }}</p>
                    <count-up :decimalPlaces="2" :end-val=" item.value" :start-val="0" class="val"></count-up>
                    kwh
                  </div>
                </div>
              </div>
            </div>
            <div class="centerLine"></div>
            <div class="bottomCont">
              <div class="itemCont">
                <div class="img">
                  <img alt="" class="jiantou" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/jiantou.svg">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/gf2.svg">
                </div>
                <div class="list">
                  <div v-for="(item,index) in equipmentData.eList" :key="index" class="listItem">
                    <p class="name">{{ item.key }}</p>
                    <count-up :decimalPlaces="2" :end-val=" item.value" :start-val="0" class="val"></count-up>
                    kwh
                  </div>
                </div>
              </div>
              <div class="itemCont">
                <div class="img">
                  <img alt="" class="jiantou" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/jiantou.svg">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/gf2.svg">
                </div>
                <div class="list">
                  <div v-for="(item,index) in equipmentData.gList" :key="index" class="listItem">
                    <p class="name">{{ item.key }}</p>
                    <count-up :decimalPlaces="2" :end-val=" item.value" :start-val="0" class="val"></count-up>
                    kwh
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightCont">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/dw2.svg">
            <div style="display: flex;justify-content: center;">
              <count-up :decimalPlaces="2" :end-val="equipmentData.total" :start-val="0" class="totalVal"></count-up>
              <span class="totalVal">kwh</span></div>
          </div>
        </div>
      </div>
      <!--      <img alt="" src="http://zkep2.1942it.com/storage/upload/202309/a8f1ad30-4d4f-11ee-93be-a50bae242d53.png"-->
      <!--           style="width: 100%">-->
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import VScaleScreen from 'v-scale-screen'
import './ljsScreen.less'
import * as echarts from "echarts";
import {ljsScreenApi, getWeatherApi2, getLJSEquipmentDataApi} from '@/api/modules/bigScreen'
import {ElMessage} from "element-plus";
import CountUp from 'vue-countup-v3'
/*天气************************************************/
let currentWeatherObj = ref({}) //实时天气
//实时天气
const currentWeatherFun = function () {
  getWeatherApi2('base').then(res => {
    if (res.data.status === '1') {
      currentWeatherObj.value = res.data.lives[0]
      //console.log(currentWeatherObj.value, '实时')
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let allWeatherData = ref([]) //所有天气
//所有天气
const allWeatherFun = function () {
  getWeatherApi2('all').then(res => {
    if (res.data.status === '1') {
      allWeatherData.value = res.data.forecasts[0].casts
      allWeatherData.value.forEach((item, index) => {
        item.dateNew = item.date.split('-')[1] + '/' + item.date.split('-')[2]
        if (index == 0) {
          item.dateWeek = '今日'
        } else if (index == 1) {
          item.dateWeek = '明日'
        } else {
          if (item.week == '1') {
            item.dateWeek = '周一'
          } else if (item.week == '2') {
            item.dateWeek = '周二'
          } else if (item.week == '3') {
            item.dateWeek = '周三'
          } else if (item.week == '4') {
            item.dateWeek = '周四'
          } else if (item.week == '5') {
            item.dateWeek = '周五'
          } else if (item.week == '6') {
            item.dateWeek = '周六'
          } else if (item.week == '7') {
            item.dateWeek = '周日'
          }
        }
      })
      //console.log(allWeatherData.value, '所有')
    } else {
      ElMessage({
        message: res.data.code,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 进入全屏
const enterFullScreen = () => {
  // 获取layout的dom元素
  const parentNode = document.querySelector('.hegfScreen'); // 获取需要全屏的元素;
  // 进入全屏
  if (parentNode.requestFullscreen) {
    parentNode.requestFullscreen();
  } else if (parentNode.webkitRequestFullScreen) {
    parentNode.webkitRequestFullScreen();
  } else if (parentNode.mozRequestFullScreen) {
    parentNode.mozRequestFullScreen();
  } else if (parentNode.msRequestFullscreen) {
    // IE11
    parentNode.msRequestFullscreen();
  }
};
// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};
const check = function () {
  enterFullScreen()
}
// 当前时间
let timeVal = ref()
let dateVal = ref()
let weekVal = ref()
const updateTime = function () {
  const year = String(new Date().getFullYear()).length === 1 ? '0' + String(new Date().getFullYear()) : new Date().getFullYear()
  const month = String(Number(new Date().getMonth()) + 1).length === 1 ? '0' + String(Number(new Date().getMonth()) + 1) : Number(new Date().getMonth()) + 1; // 月份从0开始，因此需要加1
  const day = String(new Date().getDate()).length === 1 ? '0' + String(new Date().getDate()) : new Date().getDate();
  const hours = String(new Date().getHours()).length === 1 ? '0' + String(new Date().getHours()) : new Date().getHours();
  const minutes = String(new Date().getMinutes()).length === 1 ? '0' + String(new Date().getMinutes()) : new Date().getMinutes();
  const seconds = String(new Date().getSeconds()).length === 1 ? '0' + String(new Date().getSeconds()) : new Date().getSeconds();
  timeVal.value = hours + ':' + minutes + ':' + seconds
  dateVal.value = year + '-' + month + '-' + day
  weekVal.value = new Intl.DateTimeFormat('zh-CN', {
    weekday: 'long'
  }).format(new Date());
}

// 加载左边第一个
const chartLoad1 = function () {
  var chartDom = document.querySelector('.chart1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.generateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.generateElectricityListY,
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: '#FF639A'
        },
          {
            offset: 1,
            color: '#F5335C'
          },
        ]),
        borderRadius: [10, 10, 10, 10]
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载左边第二个
const chartLoad2 = function () {
  var chartDom = document.querySelector('.chart2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.upMonthListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.upMonthListY,
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        color: '#1F2D3E',
        borderRadius: [10, 10, 10, 10]
      },
      barWidth: 16,
      itemStyle: {
        color: '#43F2AE',
        borderRadius: [10, 10, 10, 10]
      },
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载右边第二个
const chartLoad3 = function () {
  var chartDom = document.querySelector('.chart3');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 20,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.generateRateListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.generateRateListY,
      type: 'line',
      itemStyle: {
        color: '#43F2AE',
        //					opacity: 0
      },

      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(67,242,174, 0.60)'
        },
          {
            offset: 1,
            color: 'rgba(67,242,174, 0)'
          },
        ])
      },
      lineStyle: {
        width: 4
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载下面第一个
const chartLoad4 = function () {
  var chartDom = document.querySelector('.chart4');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.todayGenerateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.todayGenerateElectricityListY,
      type: 'line',
      showSymbol: true,
      //				symbol:'circle',
      itemStyle: {
        color: '#18BEF3',
        //					opacity: 0
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(54, 161, 255, 0.60)'
        },
          {
            offset: 1,
            color: 'rgba(25, 104, 255, 0)'
          },
        ])
      },
      lineStyle: {
        width: 4
      },
      markPoint: {
        data: [{
          type: 'max',
          name: 'Max',
          symbolSize: 40
        }]
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载下边第二个
const chartLoad5 = function () {
  var chartDom = document.querySelector('.chart5');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: 50,
      top: 30,
      bottom: 30,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      data: data.value.monthGenerateElectricityListX
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(24,190,243,0.22)'],
        }
      },
    },
    series: [{
      data: data.value.monthGenerateElectricityListY,
      type: 'line',
      itemStyle: {
        color: '#DA5C1F',
        //					opacity: 0
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
          offset: 0,
          color: 'rgba(218,92,31, 0.60)'
        },
          {
            offset: 1,
            color: 'rgba(218,92,31, 0)'
          },
        ])
      },
      lineStyle: {
        width: 4

      },
      markPoint: {
        data: [{
          type: 'max',
          name: 'Max',
          symbolSize: 40
        }]
      }
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    }
  });
  window.onresize = () => {
    myChart.resize();
  }
}

let data = ref({
  "generateElectricityMonth": "",
  "generateElectricityMonthList": [],
  "generateElectricityToday": "",
  "generateElectricityTotal": "",
  "generateElectricityYesterday": "",
  "generateRateList": [],
  "generateRateMaxTime": "",
  "generateRateMaxValue": "",
  "generateRateMinTime": "",
  "generateRateMinValue": "",
  "generateRateNowTime": "",
  "generateRateNowValue": "",
  "inTimeRate": "",
  "monthGenerateElectricityList": [],
  "photovoltaicEquipmentList": [],
  "standardCoal": "",
  "storeEquipment": {
    "key": "",
    "value": ""
  },
  "thatDayIncome": "",
  "todayGenerateElectricityList": [],
  "totalIncome": "",
  "upMonth": "",
  "upMonthList": [],
  "upToday": "",
  "upTotal": "",
  "upYesterday": ""
})
//数字轮播数据
let countUpGenerateElectricityToday = ref()
let countUpGenerateElectricityYesterday = ref()
let countUpGenerateElectricityMonth = ref()
let countUpGenerateElectricityTotal = ref()
let countUpUpToday = ref()
let countUpUpYesterday = ref()
let countUpUpMonth = ref()
let countUpUpTotal = ref()
let countUpGenerateRateMaxValue = ref()
let countUpGenerateRateMinValue = ref()
let countUpGenerateRateNowValue = ref()
let countUpInTimeRate = ref()
let countUpThatDayIncome = ref()
let countUpStandardCoal = ref()
let countUpTotalIncome = ref()
//数字轮播初始值
let countStart1 = ref(0)
let countStart2 = ref(0)
let countStart3 = ref(0)
let countStart4 = ref(0)
let countStart5 = ref(0)
let countStart6 = ref(0)
let countStart7 = ref(0)
let countStart8 = ref(0)
let countStart9 = ref(0)
let countStart10 = ref(0)
let countStart11 = ref(0)
let countStart12 = ref(0)
let countStart13 = ref(0)
let countStart14 = ref(0)
let countStart15 = ref(0)
const getDataFun = async function () {
  await ljsScreenApi().then(res => {
    if (res.data.code === 0) {
      if (data.value.generateElectricityToday != res.data.result.generateElectricityToday) {
        countStart1.value = data.value.generateElectricityToday
      }
      if (data.value.generateElectricityYesterday != res.data.result.generateElectricityYesterday) {
        countStart2.value = data.value.generateElectricityYesterday
      }
      if (data.value.generateElectricityMonth !== res.data.result.generateElectricityMonth) {
        countStart3.value = data.value.generateElectricityMonth
      }
      if (data.value.generateElectricityTotal != res.data.result.generateElectricityTotal) {
        countStart4.value = data.value.generateElectricityTotal
      }
      if (data.value.upToday != res.data.result.upToday) {
        countStart5.value = data.value.upToday
      }
      if (data.value.upYesterday != res.data.result.upYesterday) {
        countStart6.value = data.value.upYesterday
      }
      if (data.value.upMonth != res.data.result.upMonth) {
        countStart7.value = data.value.upMonth
      }
      if (data.value.upTotal != res.data.result.upTotal) {
        countStart8.value = data.value.upTotal
      }
      if (data.value.generateRateMaxValue != res.data.result.generateRateMaxValue) {
        countStart9.value = data.value.generateRateMaxValue
      }
      if (data.value.generateRateMinValue != res.data.result.generateRateMinValue) {
        countStart10.value = data.value.generateRateMinValue
      }
      if (data.value.generateRateNowValue != res.data.result.generateRateNowValue) {
        countStart11.value = data.value.generateRateNowValue
      }
      if (data.value.inTimeRate != res.data.result.inTimeRate) {
        countStart12.value = data.value.inTimeRate
      }
      if (data.value.thatDayIncome != res.data.result.thatDayIncome) {
        countStart13.value = data.value.thatDayIncome
      }
      if (data.value.standardCoal != res.data.result.standardCoal) {
        countStart14.value = data.value.standardCoal
      }
      if (data.value.totalIncome != res.data.result.totalIncome) {
        countStart15.value = data.value.totalIncome
      }
      if (data.value.generateElectricityList && data.value.generateElectricityList.length > 0) {
        if (JSON.stringify(data.value.generateElectricityList) != JSON.stringify(res.data.result.generateElectricityList)) {
          chartLoad1()
        }
      }
      if (data.value.upList && data.value.upList.length > 0) {
        if (JSON.stringify(data.value.upList) != JSON.stringify(res.data.result.upList)) {
          chartLoad2()
        }
      }
      if (data.value.generateRateList && data.value.generateRateList.length > 0) {
        if (JSON.stringify(data.value.generateRateList) != JSON.stringify(res.data.result.generateRateList)) {
          chartLoad3()
        }
      }
      if (data.value.todayGenerateElectricityList && data.value.todayGenerateElectricityList.length > 0) {
        if (JSON.stringify(data.value.todayGenerateElectricityList) != JSON.stringify(res.data.result.todayGenerateElectricityList)) {
          chartLoad4()
        }
      }
      if (data.value.monthGenerateElectricityList && data.value.monthGenerateElectricityList.length > 0) {
        if (JSON.stringify(data.value.monthGenerateElectricityList) != JSON.stringify(res.data.result.monthGenerateElectricityList)) {
          chartLoad5()
        }
      }
      data.value = res.data.result
      //console.log(data.value)
      // 光伏发电量
      data.value.generateElectricityListX = []
      data.value.generateElectricityListY = []
      for (let i = 0; i < data.value.generateElectricityList.length; i++) {
        data.value.generateElectricityListX.push(data.value.generateElectricityList[i].key)
        data.value.generateElectricityListY.push(Number(data.value.generateElectricityList[i].value))
      }
      // 光伏上网电量
      data.value.upMonthListX = []
      data.value.upMonthListY = []
      for (let i = 0; i < data.value.upList.length; i++) {
        data.value.upMonthListX.push(data.value.upList[i].key)
        data.value.upMonthListY.push(Number(data.value.upList[i].value))
      }
      // 光伏发电功率
      data.value.generateRateListX = []
      data.value.generateRateListY = []
      for (let i = 0; i < data.value.generateRateList.length; i++) {
        data.value.generateRateListX.push(data.value.generateRateList[i].key)
        data.value.generateRateListY.push(Number(data.value.generateRateList[i].value))
      }
      // 当天发电量
      data.value.todayGenerateElectricityListX = []
      data.value.todayGenerateElectricityListY = []
      for (let i = 0; i < data.value.todayGenerateElectricityList.length; i++) {
        data.value.todayGenerateElectricityListX.push(data.value.todayGenerateElectricityList[i].key)
        data.value.todayGenerateElectricityListY.push(Number(data.value.todayGenerateElectricityList[i].value))
      }
      // 当月发电量
      data.value.monthGenerateElectricityListX = []
      data.value.monthGenerateElectricityListY = []
      for (let i = 0; i < data.value.monthGenerateElectricityList.length; i++) {
        data.value.monthGenerateElectricityListX.push(data.value.monthGenerateElectricityList[i].key)
        data.value.monthGenerateElectricityListY.push(Number(data.value.monthGenerateElectricityList[i].value))
      }

    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

let equipmentData = ref({
  aList: [],
  dList: [],
  eList: [],
  gList: [],
  total: ''
})
const getLJSEquipmentFun = function () {
  getLJSEquipmentDataApi().then(res => {
    if (res.data.code === 0) {
      equipmentData.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

onMounted(async () => {
  getLJSEquipmentFun()
  await getDataFun()

  updateTime()
  //天气
  currentWeatherFun()
  allWeatherFun()

  nextTick(() => {
    chartLoad1()
    chartLoad2()
    chartLoad3()
    chartLoad4()
    chartLoad5()
  })

  // window.onresize = () => {
  //   let timer = setTimeout(() => {
  //     if (timer) {
  //       clearTimeout(timer)
  //     }
  //     let dom = document.querySelector('.hngfScreen')
  //     console.log(dom.clientWidth / 1920, dom.clientHeight / 1080)
  //     let dom2 = document.querySelector('.screen-wrapper')
  //     dom2.style.transform = `scale(${dom.clientWidth / 1920},${dom.clientHeight / 1080})`
  //     console.log(dom2.style)
  //   }, 500)
  // }
})

// 定时器
const intervalId2 = setInterval(getDataFun, 10000)
const intervalId5 = setInterval(getLJSEquipmentFun, 60000)
const intervalId = setInterval(updateTime, 1000)
const intervalId3 = setInterval(currentWeatherFun, 1800000)
const intervalId4 = setInterval(allWeatherFun, 1800000)
onUnmounted(() => {
  clearInterval(intervalId)
  clearInterval(intervalId2)
  clearInterval(intervalId3)
  clearInterval(intervalId4)
  clearInterval(intervalId5)
})
</script>

<style lang="less" scoped>

</style>
