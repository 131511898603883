<template>
  <div class="projectWarning">
    <div class="title">
      <p>
        <span @click="goBack">资产管理</span>
        <span> / </span>
        <span @click="goBack">设备</span>
        <span> / </span>
        <span>设备报警</span>
      </p>
    </div>
    <div class="content">
      <Alarm :value="router.currentRoute.value.query.id"/>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {reactive, ref} from "vue"
import './projectWarning.less'
import Alarm from "@/components/comAlarm";
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()


const goBack = function () {
  router.push({
    path: '/admin/project',
    query: {
      type: 1
    }
  })
}
</script>

<style lang="less" scoped>
</style>
