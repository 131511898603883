<template>
  <div class="tenant">
    <!-- <div class="title">
      <p>
        <span>租户信息管理</span>
      </p>
    </div> -->
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">租户</label>
          <el-input
            v-model="queryParams.tenantName"
            prefix-icon="Search"
            clearable
            placeholder="请输入租户"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">手机号</label>
          <el-input
            v-model="queryParams.mobile"
            prefix-icon="Search"
            clearable
            placeholder="请输入手机号"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">房东</label>
          <el-select
            v-model="queryParams.landlordId"
            placeholder="请选择房东"
            clearable
            style="width: 200px"
          >
            <el-option
              v-for="item in queryLists.landlordList"
              :key="item.landlordId"
              :label="item.landlordName"
              :value="item.landlordId"
            />
          </el-select>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建租户信息
        </el-button>
        <el-table v-loading="loading" :data="tenantList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建租户信息</p>
            </el-empty>
          </template>
          <el-table-column label="租户姓名" align="center" prop="tenantName" />
          <el-table-column label="余额" align="center" prop="balance">
            <template #default="scope">
              <span>{{ scope.row.balance }} 元</span>
            </template>
          </el-table-column>
          <el-table-column label="手机号码" align="center" prop="mobile" />
          <el-table-column
            label="房东姓名"
            align="center"
            prop="landlordName"
          />
          <el-table-column label="设备数量" align="center" prop="equipmentNum">
            <template #default="scope">
              <el-button
                v-if="scope.row.equipmentNum > 0"
                type="primary"
                @click="handleEquipment(scope.row, '2')"
                >{{ scope.row.equipmentNum }}</el-button
              >
              <span v-else>{{ scope.row.equipmentNum }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="计费模版"
            align="center"
            prop="templateName"
          />
          <el-table-column label="租户交割" align="center" width="115px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn delClass" @click="handleDeliveryMeter(scope.row)">
                  租户交割
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="350px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleConfigurationSettings(scope.row)">
                  设置异动阈值
                </p>
                <!-- <p class="btn" @click="handleSetting(scope.row)">设置电表服务费</p> -->
                <p class="btn" @click="handleEquipment(scope.row, '1')">
                  绑定设备
                </p>
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <el-dialog
      title="租户交割"
      v-model="deliveryMeterOpen"
      width="500px"
      append-to-body
    >
      <el-form
        ref="deliveryMeterRef"
        :model="deliveryMeterForm"
        :rules="deliveryMeterRules"
        label-width="auto"
      >
        <el-form-item label="电表" prop="equipmentId">
          <el-select
            v-model="deliveryMeterForm.equipmentId"
            placeholder="请选择电表"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in meterList"
              :key="item.equipmentId"
              :label="item.equipmentName"
              :value="item.equipmentId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租户" prop="oldTenantName">
          <el-input
            v-model="deliveryMeterForm.oldTenantName"
            disabled
            clearable
          />
        </el-form-item>
        <el-form-item label="交割租户" prop="newTenantId">
          <el-select
            v-model="deliveryMeterForm.newTenantId"
            placeholder="请选择交割的租户"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in deliveryTenantList"
              :key="item.tenantId"
              :label="item.tenantName"
              :value="item.tenantId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="交割时间" prop="deliveryTime">
          <el-date-picker
            v-model="deliveryMeterForm.deliveryTime"
            value-format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="deliveryMeterSubmitForm"
            >确 定</el-button
          >
          <el-button @click="deliveryMeterCancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      title="设置异动阈值"
      v-model="settingsConfigurationOpen"
      width="500px"
      append-to-body
    >
      <el-form
        ref="settingConfigRef"
        :model="settingConfigForm"
        :rules="settingConfigRules"
        label-width="auto"
      >
        <el-form-item label="对比类型" prop="comparisonType">
          <el-radio-group v-model="settingConfigForm.comparisonType">
            <el-radio
              v-for="dict in comparison_type"
              :key="dict.value"
              :label="dict.value"
              >{{ dict.label }}</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item label="浮动超过" prop="fluctuationValue">
          <el-input-number
            v-model="settingConfigForm.fluctuationValue"
            clearable
            :controls="false"
          >
          </el-input-number>
          <span style="font-weight: 700; color: #606266"
            >&nbsp; 时，进行提醒</span
          >
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="settingConfigSubmitForm"
            >确 定</el-button
          >
          <el-button @click="settingConfigCancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      title="设置电表服务费"
      v-model="settingOpen"
      width="500px"
      append-to-body
    >
      <el-form
        ref="settingRef"
        :model="settingForm"
        :rules="settingRules"
        label-width="auto"
      >
        <el-form-item label="交费金额" prop="serviceCharge">
          <el-input
            v-model="settingForm.serviceCharge"
            placeholder="请输入交费金额"
            clearable
          />
        </el-form-item>
        <el-form-item label="交至年月" prop="serviceChargeToDate">
          <el-date-picker
            v-model="settingForm.serviceChargeToDate"
            value-format="YYYY-MM"
            type="month"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="settingSubmitForm">确 定</el-button>
          <el-button @click="settingCancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
      :title="equipmentForm.type === '1' ? '绑定设备' : '解绑设备'"
      v-model="equipmentOpen"
      :close-on-click-modal="false"
      width="1000px"
      append-to-body
    >
      <el-input
        v-model="equipmentQueryParams.equipmentName"
        clearable
        placeholder="请输入设备名称"
        style="width: 200px; margin: 0 10px 10px 0"
        @input="getAllDeviceListFun"
      ></el-input>

      <el-table
        :data="equipmentList"
        @selection-change="handleEquipmentSelectionChange"
      >
        <template #empty>
          <el-empty
            class="emptyClass"
            description="暂无数据信息"
            image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
          >
            <p>暂无设备信息</p>
          </el-empty>
        </template>
        <el-table-column type="selection" align="center" width="55" />
        <el-table-column label="设备名称" align="center" prop="equipmentName" />
        <el-table-column
          label="设备地址"
          align="center"
          prop="equipmentAddress"
        />
        <el-table-column label="产品型号" align="center" prop="productName" />
        <el-table-column label="创建人" align="center" prop="createName" />
        <el-table-column label="创建时间" align="center" prop="createTime" />
      </el-table>

      <div style="display: flex; justify-content: flex-end; margin-top: 20px">
        <el-pagination
          v-model:page-size="equipmentQueryParams.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :total="equipmentTotal"
          background
          layout="total,sizes,prev, pager, next"
          small
          @size-change="handleEquipmentSizeChange"
          @current-change="pageEquipmentChange"
        />
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="equipmentCancel"
            >取消</el-button
          >
          <el-button
            class="confrim"
            type="primary"
            @click="submitEquipmentOpenForm"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="1000px"
      append-to-body
    >
      <el-form ref="tenantRef" :model="form" :rules="rules" label-width="auto">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="租户姓名" prop="tenantName">
              <el-input
                v-model="form.tenantName"
                placeholder="请输入租户姓名"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码" prop="mobile">
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号码"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="租户类型" prop="tenantType">
              <el-select
                v-model="form.tenantType"
                placeholder="请选择租户类型"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="dict in tenant_tenant_type"
                  :key="dict.value"
                  :label="dict.label"
                  :value="dict.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="余额" prop="balance">
              <el-input
                v-model="form.balance"
                placeholder="请输入余额"
                clearable
              >
                <template #append>元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="form.address"
            placeholder="请输入详细地址"
            clearable
          />
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属房东" prop="landlordId">
              <el-select
                v-model="form.landlordId"
                placeholder="请选择所属房东"
                clearable
                filterable
                style="width: 100%"
              >
                <el-option
                  v-for="item in formLists.landlordList"
                  :key="item.landlordId"
                  :label="item.landlordName"
                  :value="item.landlordId"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item
              label="是否关联子电表用户"
              prop="isSubTableUserLinked"
            >
              <el-radio-group v-model="form.isSubTableUserLinked">
                <el-radio
                  v-for="dict in yes_or_no"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col v-if="form.isSubTableUserLinked === 1" :span="12">
            <el-form-item label="选择子电表租户" prop="tenantIds">
              <el-select
                multiple
                v-model="form.tenantIds"
                placeholder="请选择子电表租户"
                clearable
                style="width: 100%"
              >
                <el-option
                  v-for="item in formLists.subMeterTenantList"
                  :key="item.id"
                  :label="item.tenantName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row> -->

        <el-card style="margin-bottom: 8px" header="租户配置" shadow="never">
          <el-form-item label="自定义电费单价" prop="customElectricCharge">
            <el-radio-group v-model="form.customElectricCharge">
              <el-radio
                v-for="dict in yes_or_no"
                :key="dict.value"
                :label="dict.value"
                >{{ dict.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-row v-if="form.customElectricCharge === 1" :gutter="20">
            <el-col :span="12">
              <el-form-item label="自定义电费模板" prop="meterPriceDetailId">
                <el-select
                  v-model="form.meterPriceDetailId"
                  placeholder="请选择自定义电费模板"
                  clearable
                  style="width: 100%"
                  @change="handleFormMeterPriceDetailChange"
                >
                  <el-option
                    v-for="item in formLists.meterPriceDetailList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="form.customElectricCharge === 1" :gutter="20">
            <el-col :span="12">
              <el-form-item label="尖峰电费单价" prop="electricCharge">
                <el-input
                  v-model="form.electricCharge"
                  placeholder="请输入尖峰电费单价"
                  clearable
                >
                  <template #append>
                    <span>元</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="峰-电费单价" prop="peakPrice">
                <el-input
                  v-model="form.peakPrice"
                  placeholder="请输入峰-电费单价"
                  clearable
                >
                  <template #append>
                    <span>元</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row v-if="form.customElectricCharge === 1" :gutter="20">
            <el-col :span="12">
              <el-form-item label="平-电费单价" prop="flatPrice">
                <el-input
                  v-model="form.flatPrice"
                  placeholder="请输入平-电费单价"
                  clearable
                >
                  <template #append>
                    <span>元</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="谷-电费单价" prop="valleyPrice">
                <el-input
                  v-model="form.valleyPrice"
                  placeholder="请输入谷-电费单价"
                  clearable
                >
                  <template #append>
                    <span>元</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="电表是否合并计算" prop="isMeterConsolidated">
            <el-radio-group v-model="form.isMeterConsolidated">
              <el-radio
                v-for="dict in yes_or_no"
                :key="dict.value"
                :label="dict.value"
                >{{ dict.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="计费模板" prop="templateId">
                <el-select
                  v-model="form.templateId"
                  placeholder="请选择计费模板"
                  clearable
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in formLists.templateList"
                    :key="item.id"
                    :label="item.templateName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="自定义结算日" prop="isDailyCutoff">
                <el-radio-group v-model="form.isDailyCutoff">
                  <el-radio
                    v-for="dict in yes_or_no"
                    :key="dict.value"
                    :label="dict.value"
                    >{{ dict.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <el-form-item
                v-if="form.isDailyCutoff === 1"
                label="自定义结算日"
                prop="cutoffDate"
              >
                <el-input
                  v-model="form.cutoffDate"
                  placeholder="请输入自定义结算日"
                  clearable
                  type="number"
                >
                  <template #append>
                    <span>日</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="主要设备数量" prop="loomNum">
                <el-input
                  v-model="form.loomNum"
                  placeholder="请输入主要设备数量"
                  clearable
                >
                  <template #append>
                    <span>台</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>

        <el-card
          style="margin-bottom: 8px"
          header="自定义结算项"
          shadow="never"
        >
          <el-row
            v-for="(item, index) in form.tenantChargeItemDTOList"
            :key="item.key"
            :gutter="10"
            style="width: 100%"
          >
            <el-col :span="8">
              <el-form-item>
                <el-select
                  v-model="item.key"
                  placeholder="请选择"
                  clearable
                  filterable
                  @change="(val) => handleFormChargeItemChange(val, item)"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in disabledFixedList"
                    :key="item.chargeItemValue"
                    :label="item.chargeItemName"
                    :value="item.chargeItemValue"
                    :disabled="item.disabled"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item>
                <el-input v-model="item.value" placeholder="请输入" clearable>
                  <template v-if="item.unit" #append>
                    <span>{{ item.unit }}</span>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4" align="center">
              <el-button type="primary" @click="addTenantChargeItem(index)"
                >新增</el-button
              >
              <el-button
                :disabled="form.tenantChargeItemDTOList.length === 1"
                type="danger"
                @click="removeTenantChargeItem(index)"
                >删除</el-button
              >
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h, computed } from 'vue'
import './tenant.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listTenant,
  getTenant,
  delTenant,
  addTenant,
  updateTenant,
  ListTenantEquipment,
  bindEquipment,
  unbindEquipment,
  getTenantEquipmentList,
  getTenantList,
  transferTenantMeter,
  setTenant,
} from '@/api/modules/tenant'
import { getLandlordList } from '@/api/modules/landlord'
import { getTemplateList } from '@/api/modules/template'
import { getPriceList } from '@/api/modules/price'
import { getFixedList } from '@/api/modules/fixed'

const props = defineProps({
  landlordId: String,
})

const tenantList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const tenantRef = ref(null)

const tenant_tenant_type = ref([
  { label: '房东', value: 0 },
  { label: '租户', value: 1 },
  { label: '基建', value: 2 },
])
const yes_or_no = ref([
  { label: '否', value: 0 },
  { label: '是', value: 1 },
])

const equipmentOpen = ref(false)
const equipmentList = ref([])
const equipmentQueryParams = ref({})
const equipmentTotal = ref(0)
const equipmentForm = ref({})

const deliveryMeterOpen = ref(false)
const deliveryMeterRef = ref(null)
const deliveryMeterForm = ref({})
const deliveryMeterRules = ref({
  equipmentId: [{ required: true, message: '请选择电表', trigger: 'change' }],
  newTenantId: [
    { required: true, message: '请选择交割的租户', trigger: 'change' },
  ],
  deliveryTime: [
    { required: true, message: '请选择交割时间', trigger: 'change' },
  ],
})
const meterList = ref([])
const deliveryTenantList = ref([])

const settingsConfigurationOpen = ref(false)
const settingConfigRef = ref(null)
const settingConfigForm = ref({})
const settingConfigRules = ref({
  comparisonType: [
    { required: true, message: '请选择对比类型', trigger: 'change' },
  ],
  fluctuationValue: [
    {
      required: true,
      message: '请输入浮动超过时，进行提醒',
      trigger: 'change',
    },
  ],
})
const comparison_type = ref([
  { label: '电费', value: '0' },
  { label: '电费比例', value: '1' },
])

const settingOpen = ref(false)
const settingRef = ref(null)
const settingForm = ref({})
const settingRules = ref({
  serviceCharge: [
    { required: true, message: '请输入交费金额', trigger: 'blur' },
  ],
  serviceChargeToDate: [
    { required: true, message: '请选择交至年月', trigger: 'change' },
  ],
})

const queryLists = reactive({
  landlordList: [],
})

const formLists = reactive({
  landlordList: [],
  templateList: [],
  meterPriceDetailList: [],
  subMeterTenantList: [],
  fixedList: [],
})

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    tenantName: null,
    mobile: null,
    landlordId: props.landlordId,
  },
  rules: {
    tenantName: [
      { required: true, message: '租户姓名不能为空', trigger: 'blur' },
    ],
    mobile: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }],
    tenantType: [
      { required: true, message: '租户类型不能为空', trigger: 'change' },
    ],
    balance: [{ required: true, message: '余额不能为空', trigger: 'blur' }],
    landlordId: [
      { required: true, message: '所属房东不能为空', trigger: 'change' },
    ],
    customElectricCharge: [
      { required: true, message: '自定义电费单价不能为空', trigger: 'change' },
    ],
    isMeterConsolidated: [
      { required: true, message: '请选择电表是否合并计算', trigger: 'change' },
    ],
    peakPrice: [
      { required: true, message: '峰-电费单价不能为空', trigger: 'blur' },
    ],
    flatPrice: [
      { required: true, message: '平-电费单价不能为空', trigger: 'blur' },
    ],
    valleyPrice: [
      { required: true, message: '谷-电费单价不能为空', trigger: 'blur' },
    ],
    electricCharge: [
      { required: true, message: '尖峰电费单价不能为空', trigger: 'blur' },
    ],
    chargeDifference: [
      { required: true, message: '电费差价-电价不能为空', trigger: 'blur' },
    ],
    templateId: [
      { required: true, message: '计费模板不能为空', trigger: 'change' },
    ],
    isDailyCutoff: [
      { required: true, message: '请选择自定义结算日', trigger: 'change' },
    ],
    cutoffDate: [
      { required: true, message: '请填写自定义结算日', trigger: 'blur' },
    ],
    isSubTableUserLinked: [
      {
        required: true,
        message: '请选择是否关联子电表用户',
        trigger: 'change',
      },
    ],
  },
})

const { queryParams, form, rules } = toRefs(data)

getLandlordList().then((response) => {
  queryLists.landlordList = response.data.result
})

/** 查询租户信息列表 */
function getList() {
  loading.value = true
  listTenant(queryParams.value).then((response) => {
    tenantList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    tenantName: null,
    mobile: null,
    tenantType: null,
    balance: null,
    address: null,
    landlordId: null,
    isSubTableUserLinked: null,
    tenantIds: [],
    customElectricCharge: null,
    meterPriceDetailId: null,
    electricCharge: null,
    peakPrice: null,
    flatPrice: null,
    valleyPrice: null,
    isMeterConsolidated: null,
    templateId: null,
    isDailyCutoff: null,
    cutoffDate: null,
    loomNum: null,
    tenantChargeItemDTOList: [
      {
        key: '',
        value: '',
      },
    ],
  }
  tenantRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    tenantName: null,
    mobile: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  getLandlordList().then((response) => {
    formLists.landlordList = response.data.result
    getPriceList({ type: 0 }).then((response) => {
      formLists.meterPriceDetailList = response.data.result
      getTemplateList().then((response) => {
        formLists.templateList = response.data.result
        getFixedList().then((response) => {
          formLists.fixedList = response.data.result
          open.value = true
          title.value = '新建租户信息'
        })
      })
    })
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  getLandlordList().then((response) => {
    formLists.landlordList = response.data.result
    getPriceList().then((response) => {
      formLists.meterPriceDetailList = response.data.result
      getTemplateList().then((response) => {
        formLists.templateList = response.data.result
        getFixedList().then((response) => {
          formLists.fixedList = response.data.result
          getTenant(row.id).then((response) => {
            form.value = response.data.result
            if (response.data.result.tenantChargeItemDTOList.length === 0) {
              form.value.tenantChargeItemDTOList = [
                {
                  key: '',
                  value: '',
                },
              ]
            }
            open.value = true
            title.value = '修改租户信息'
          })
        })
      })
    })
  })
}

/** 提交按钮 */
function submitForm() {
  tenantRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updateTenant(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addTenant(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除租户信息',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此租户信息，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delTenant(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}

let timer = null

function getAllDeviceListFun() {
  clearTimeout(timer)
  timer = setTimeout(() => {
    equipmentQueryParams.value.pageNumber = 1
    ListTenantEquipment(equipmentQueryParams.value).then((response) => {
      equipmentList.value = response.data.result.list
      equipmentTotal.value = Number(response.data.result.total)
    })
  }, 300)
}

function handleEquipment(row, type) {
  equipmentReset()
  equipmentForm.value.tenantId = row.id
  equipmentForm.value.type = type
  equipmentQueryParams.value.tenantId = row.id
  equipmentQueryParams.value.type = type
  ListTenantEquipment(equipmentQueryParams.value).then((response) => {
    equipmentList.value = response.data.result.list
    equipmentTotal.value = Number(response.data.result.total)
    equipmentOpen.value = true
  })
}

function equipmentReset() {
  equipmentQueryParams.value = {
    type: null,
    tenantId: null,
    pageNumber: 1,
    pageSize: 10,
    equipmentName: null,
  }
  equipmentList.value = []
  equipmentForm.value = {
    type: null,
    tenantId: null,
    equipmentIds: [],
  }
}

function equipmentCancel() {
  equipmentOpen.value = false
  equipmentReset()
}

function submitEquipmentOpenForm() {
  if (equipmentForm.value.equipmentIds.length === 0) {
    equipmentOpen.value = false
    return
  }

  if (equipmentForm.value.type === '1') {
    bindEquipment(equipmentForm.value).then((response) => {
      if (response.data.code === 0) {
        ElMessage.success('绑定成功')
        equipmentOpen.value = false
        getList()
      } else {
        ElMessage.error(response.data.message)
      }
    })
  }

  if (equipmentForm.value.type === '2') {
    unbindEquipment(equipmentForm.value).then((response) => {
      if (response.data.code === 0) {
        ElMessage.success('解绑成功')
        equipmentOpen.value = false
        getList()
      } else {
        ElMessage.error(response.data.message)
      }
    })
  }
}

function handleEquipmentSizeChange(e) {
  equipmentQueryParams.value.pageSize = e
  ListTenantEquipment(equipmentQueryParams.value).then((response) => {
    equipmentList.value = response.data.result.list
    equipmentTotal.value = Number(response.data.result.total)
  })
}

function pageEquipmentChange(e) {
  equipmentQueryParams.value.pageNumber = e
  ListTenantEquipment(equipmentQueryParams.value).then((response) => {
    equipmentList.value = response.data.result.list
    equipmentTotal.value = Number(response.data.result.total)
  })
}

function handleEquipmentSelectionChange(selection) {
  equipmentForm.value.equipmentIds = selection.map((item) => item.equipmentId)
}

function handleDeliveryMeter(row) {
  deliveryMeterReset()
  getTenantEquipmentList({
    tenantId: row.id,
    pageNumber: 1,
    pageSize: 1000,
    type: 2,
  }).then((response) => {
    meterList.value = response.data.result.list
    getTenantList(row.landlordId).then((response) => {
      deliveryTenantList.value = response.data.result
      deliveryMeterForm.value.oldTenantId = row.id
      deliveryMeterForm.value.oldTenantName = row.tenantName
      deliveryMeterOpen.value = true
    })
  })
}

function deliveryMeterReset() {
  deliveryMeterForm.value = {
    oldTenantId: null,
    equipmentId: null,
    newTenantId: null,
    deliveryTime: null,
  }
  meterList.value = []
  deliveryTenantList.value = []
  deliveryMeterRef.value?.resetFields()
}

function deliveryMeterCancel() {
  deliveryMeterOpen.value = false
  deliveryMeterReset()
}

function deliveryMeterSubmitForm() {
  deliveryMeterRef.value.validate((valid) => {
    if (valid) {
      transferTenantMeter(deliveryMeterForm.value).then((response) => {
        if (response.data.code === 0) {
          ElMessage.success('交割成功')
          deliveryMeterOpen.value = false
          getList()
        } else {
          ElMessage.error(response.data.message)
        }
      })
    }
  })
}

function handleConfigurationSettings(row) {
  settingsConfigurationReset()
  settingConfigForm.value.tenantId = row.id
  settingsConfigurationOpen.value = true
}

function settingsConfigurationReset() {
  settingConfigForm.value = {
    tenantId: null,
    comparisonType: null,
    fluctuationValue: null,
  }
  settingConfigRef.value?.resetFields()
}

function settingConfigCancel() {
  settingsConfigurationOpen.value = false
  settingsConfigurationReset()
}

function settingConfigSubmitForm() {
  settingConfigRef.value.validate((valid) => {
    if (valid) {
      setTenant(settingConfigForm.value).then((response) => {
        if (response.data.code === 0) {
          ElMessage.success('设置成功')
          settingsConfigurationOpen.value = false
          getList()
        } else {
          ElMessage.error(response.data.message)
        }
      })
    }
  })
}

function handleSetting(row) {
  settingReset()
  settingOpen.value = true
}

function settingReset() {
  settingForm.value = {
    serviceCharge: null,
    serviceChargeToDate: null,
  }
  settingRef.value?.resetFields()
}

function settingCancel() {
  settingOpen.value = false
  settingReset()
}

function settingSubmitForm() {
  settingRef.value.validate((valid) => {
    if (valid) {
      console.log(settingForm.value)
    }
  })
}

function handleFormMeterPriceDetailChange(val) {
  if (val) {
    const item = formLists.meterPriceDetailList.find((item) => item.id === val)

    delete item.id

    form.value = {
      ...form.value,
      ...item,
    }

    tenantRef.value.clearValidate()
  }
}

function removeTenantChargeItem(index) {
  form.value.tenantChargeItemDTOList.splice(index, 1)
}

function addTenantChargeItem(index) {
  form.value.tenantChargeItemDTOList.splice(index + 1, 0, {
    key: '',
    value: '',
  })
}

const disabledFixedList = computed(() => {
  return formLists.fixedList.map((item) => {
    return {
      ...item,
      disabled: form.value.tenantChargeItemDTOList.some(
        (chargeItem) => chargeItem.key === item.chargeItemValue
      ),
    }
  })
})

function handleFormChargeItemChange(val, item) {
  item.value = ''
  item.unit = ''
  const chargeItem = formLists.fixedList.find(
    (chargeItem) => chargeItem.chargeItemValue === val
  )
  if (chargeItem) {
    item.unit = chargeItem.unit
  }
}
</script>

<style scoped>
table,
td,
th {
  border: 1px solid #595959;
  border-collapse: collapse;
}
td,
th {
  padding: 3px;
  width: 30px;
  height: 25px;
}
</style>
