import { api } from '@/api'

// 获取oss-sts-token
export function getStsToken(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/aliyun/get-sts-token',
    method: 'get',
    params: query,
  })
}
