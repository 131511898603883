<template>
  <div class="applicationPlan">
    <!--标题-->
    <div class="title">
      <p>
        <span v-if="router.currentRoute.value.query.type==0" @click="router.push('/admin/dataSearch')">数据查询</span>
        <span v-if="router.currentRoute.value.query.type==1" @click="router.push('/admin/compoundQuery')">复合查询</span>
        <span> / </span>
        <span v-if="router.currentRoute.value.query.type==0" @click="router.push('/admin/searchPlan')">数据查询管理</span>
        <span v-if="router.currentRoute.value.query.type==1"
              @click="router.push('/admin/compoundQueryPlan')">复合查询管理</span>
        <span> / </span>
        <span>应用</span>
      </p>
    </div>
    <div class="content">
      <p class="tit">方案应用</p>
      <div class="planName">
        <span>查询方案: </span>
        <el-input v-model="router.currentRoute.value.query.name" disabled="true"></el-input>
      </div>
      <div class="transferCont">
        <div class="transferBox">
          <div class="transferLeftBox">
            <div class="head">
              <p>待选项</p>
              <el-checkbox v-model="subFlag" label="显示下级" @change="getEquipmentSelectFun"/>
            </div>
            <div class="transferContain">
              <el-input v-model="searchEquipmentName" placeholder="请输入" prefix-icon="Search"
                        @input="searchEquipmentNameSelect"></el-input>
              <div class="transferContent">
                <div class="transferContentLeft">
                  <el-tree ref="treeRef" :current-node-key="currentNodeKey" :data="customerList"
                           :default-expanded-keys="defaultExpandKeys" :expand-on-click-node="false"
                           :props="{label: 'name',children: 'subList',}" node-key="id" @node-click="handleNodeClick">
                  </el-tree>
                </div>
                <div class="transferContRight">
                  <el-popover :visible="checkAllVisible" :width="224" placement="top">
                    <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                      <div style="display:flex;align-items: center;">确定要将设备全部添加到应用中吗？</div>
                      <div style="display:flex;justify-content: end;height: 32px;">
                        <el-button size="small" @click="checkAllVisible = false">取消</el-button>
                        <el-button size="small" type="primary" @click="equipmentSelectAllClick">确定</el-button>
                      </div>
                    </div>
                    <template #reference>
                      <p v-if="checkList[0]&&checkList[0].length===1" @click="checkAllVisible=true">全选</p>
                    </template>
                  </el-popover>
                  <p v-for="(item,index) in equipmentSelectList" :key="index" @click="equipmentSelectClick(item)">
                    {{ item.name }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="transferRightBox">
            <div class="head">
              <p>已选项</p>
              <el-popover :visible="delAllVisible" :width="224" placement="top">
                <div style="width: 200px;height: 86px;padding: 12px;box-sizing: border-box;">
                  <div style="display:flex;align-items: center;">确定要将应用设备设备清空吗？</div>
                  <div style="display:flex;justify-content: end;height: 32px;">
                    <el-button size="small" @click="delAllVisible = false">取消</el-button>
                    <el-button size="small" type="primary" @click="delAllClick">确定</el-button>
                  </div>
                </div>
                <template #reference>
                  <div v-if="checkList[0]&&checkList[0].length===1" @click="delAllVisible=true">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/clear.png">
                    <span>清空</span>
                  </div>
                </template>
              </el-popover>
            </div>
            <!--多设备操作-->
            <div class="transferContain">
              <div class="transferContainHead">
                <p>序号</p>
                <p v-for="(item,index) in checkList[0]" :key="index">设备{{ index + 1 }}</p>
              </div>
              <div class="transferContainCont">
                <div v-for="(item,index) in checkList" :key="index" class="transferContainItem">
                  <p style="width: 60px">
                    <span>{{ index + 1 }}</span>
                    <el-icon v-if="index!==0" @click="delClick(index)">
                      <Delete/>
                    </el-icon>
                  </p>
                  <p v-for="(item_t,index_t) in item" :key="index_t" :style="{width: checkListWidth[index_t+1]+'px'}">
                    <el-tooltip :content="item_t.value">
                      <span :style="{color:index===0?'#999999':'#165DFF'}">{{ item_t.value }}</span>
                    </el-tooltip>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="operaBox">
          <el-button style="margin-left: calc(100% - 160px);border: 1px solid #165DFF;color: #165DFF;"
                     @click="saveUseEquipmentFun('chart')">创建图表
          </el-button>
          <el-button type="primary" @click="saveUseEquipmentFun">确定</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, nextTick, onMounted, reactive, ref} from "vue"
import './applicationPlan.less'
import {Back} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {getProjectListApi, getQueryGroupListApi} from "@/api/modules/project";
import {
  delSearchListApi,
  getEquipmentSelectApi,
  getSearchDetailApi,
  getUseEquipmentApi,
  saveUseEquipmentApi
} from "@/api/modules/dataSearch";
import {ElMessage, ElMessageBox} from "element-plus";
import {Delete, CloseBold} from '@element-plus/icons-vue'
import qs from "qs";


let router = useRouter()


// 根据项目获取设备选项操作-----------------------------------------------------------------------
let equipmentSelectList = ref([])
let subFlag = ref(true)
let searchEquipmentName = ref()
// 根据项目获取设备选项
const getEquipmentSelectFun = function () {
  getEquipmentSelectApi(qs.stringify({
    id: custerObj.value.id,
    getSubFlag: subFlag.value ? 1 : 0,
    equipmentName: searchEquipmentName.value
  })).then(res => {
    if (res.data.code === 0) {
      equipmentSelectList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 设备选项搜索
let timeout = null;
const searchEquipmentNameSelect = function () {
  if (timeout) clearTimeout(timeout);
  timeout = setTimeout(() => {
    getEquipmentSelectFun()
  }, 200);
}


// 选择方案的操作-----------------------------------------------------------------------
let checkList = ref([])  //设备方案已选设备
let checkListWidth = ref([])
// 获取应用设备初始化列表
const getUseEquipmentFun = function () {
  getUseEquipmentApi(qs.stringify({
    id: router.currentRoute.value.query.id,
  })).then(res => {
    if (res.data.code === 0) {
      checkList.value = res.data.result
      nextTick(() => {
        let arr1 = document.querySelectorAll('.transferContainHead p')
        checkListWidth.value = []
        for (let i = 0; i < arr1.length; i++) {
          checkListWidth.value.push(Number(arr1[i].offsetWidth) - 2)
        }
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击选择应用设备
const equipmentSelectClick = function (item) {
  // 判断是单设备应用还是多设备应用
  if (checkList.value[0].length === 1) {
    let flag = false
    for (let i = 0; i < checkList.value.length; i++) {
      for (let j = 0; j < checkList.value[i].length; j++) {
        if (checkList.value[i][j].key == item.id) {
          flag = true
        }
      }
    }
    if (!flag) {
      checkList.value.push([{
        key: item.id,
        value: item.name
      }])
    } else {
      ElMessage({
        type: 'info',
        message: '该设备已选中'
      })
    }
  } else {
    // 判断是否应该添加下一行
    if (checkList.value[checkList.value.length - 1].length === checkList.value[0].length) {
      checkList.value.push([
        {
          key: item.id,
          value: item.name
        }
      ])
    } else {
      checkList.value[checkList.value.length - 1].push({
        key: item.id,
        value: item.name
      })
    }
  }
}
// 全选应用设备
let checkAllVisible = ref(false)
const equipmentSelectAllClick = function () {
  for (let i = 0; i < equipmentSelectList.value.length; i++) {
    equipmentSelectClick(equipmentSelectList.value[i])
  }
  checkAllVisible.value = false
}
// 删除应用设备
const delClick = function (idx1, idx2) {
  checkList.value.splice(idx1, 1)
}
// 清空应用设备
let delAllVisible = ref(false)
const delAllClick = function () {
  checkList.value = [checkList.value[0]]
  delAllVisible.value = false
}
// 保存应用设备
const saveUseEquipmentFun = function (chart) {
  let arr = []
  let flag = true
  for (let i = 0; i < checkList.value.length; i++) {
    arr.push([])
    if (checkList.value[i].length < checkList.value[0].length) {
      flag = false
    }
    for (let j = 0; j < checkList.value[i].length; j++) {
      arr[i].push(checkList.value[i][j].key)
    }
  }
  arr.shift()
  if (flag) {
    saveUseEquipmentApi(qs.stringify({
      id: router.currentRoute.value.query.id,
      equipmentIdJson: JSON.stringify(arr)
    })).then(res => {
      if (res.data.code === 0) {
        if (router.currentRoute.value.query.type == 0) {
          if (chart === 'chart') {
            router.push({
              path: '/admin/searchPlan',
              query: {
                id: router.currentRoute.value.query.id
              }
            })
          } else {
            router.push('/admin/searchPlan')
          }
        } else {
          if (chart === 'chart') {
            router.push({
              path: '/admin/compoundQueryPlan',
              query: {
                id: router.currentRoute.value.query.id
              }
            })
          } else {
            router.push('/admin/compoundQueryPlan')
          }
        }
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请将每一组的设备选完！'
    })
  }
}


// 项目树----------------------------------------------------------------------------
let customerList = ref([]) //组织树级列表
let searchVal = ref() //树级列表字段
let defaultExpandKeys = ref([])
//树级列表搜索
const searchClick = function (data, name) {
  defaultExpandKeys.value = []
  let res = tree(data, name)
  if (res) {
    treeRef.value.setCurrentKey(res.id)
    defaultExpandKeys.value.push(res.parentId)
    custerObj.value = res

  }
}
const tree = function (data, name) {
  for (let i = 0; i < data.length; i++) {
    let item = data[i];
    if (item.name === name) {
      return item;
    } else {
      if (item.subList && item.subList.length > 0) {
        let res = tree(item.subList, name);
        if (res) return res;
      }
    }
  }
}
let custerObj = ref({}) //当前项目信息
let currentNodeKey = ref() //默认选中树级列表第一级
let treeRef = ref(null)
//获取项目树级列表
const getProjectListFun = async function () {
  await getProjectListApi().then(res => {
    if (res.data.ok) {
      customerList.value = []
      customerList.value.push(res.data.result)
      defaultExpandKeys.value = [customerList.value[0].id]
      if (currentNodeKey.value === undefined) { //第一次加默认选中
        //有无路由参数
        currentNodeKey.value = customerList.value[0].id
        custerObj.value = customerList.value[0]
        nextTick(() => {
          treeRef.value.setCurrentKey(currentNodeKey.value)
        })
      } else {
        nextTick(() => {
          treeRef.value.setCurrentKey(custerObj.value.id)
        })
      }
      nextTick(() => {
        treeRef.value.setCurrentKey(currentNodeKey.value)
      })
      getEquipmentSelectFun()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树点击
const handleNodeClick = function (e) {
  custerObj.value = e
  currentNodeKey.value = e.id
  defaultExpandKeys.value = []
  defaultExpandKeys.value.push(e.parentId)
  getEquipmentSelectFun()
}


onMounted(() => {
  getProjectListFun()  //获取项目树
  getUseEquipmentFun()  //获取初始化应用设备
})


</script>

<style lang="less">
.copySearchPlan {

}
</style>
