<template>
  <!--网关设备页面-->
  <div class="gatewayDevice">
    <div class="content">
      <div class="tableBox">
        <div class="operationBox">
          <!--<el-button @click="addDevice()" type="primary" :icon="Plus">新增设备</el-button>-->
          <el-button v-if="props.type!=1" type="primary" @click="readDeviceFun()">读取档案</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="emptyDeviceFun()">清空档案</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="sendFileClick()">下发档案</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="batchNow()">批量实时抄读设置</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="batchDelete()">批量删除</el-button>
          <el-button v-if="props.type!=1" type="primary" @click="batchEdit()">批量扩展录入</el-button>
          <el-button v-if="props.type==1" type="primary" @click="setDevice()">设置档案</el-button>
          <el-button v-if="props.type==1" type="primary" @click="meterReadingFun()">立即抄表</el-button>
        </div>
        <el-table v-loading="DeviceLoading" :data="DeviceData" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="选取" style="height:20px" type="selection" width="55">
          </el-table-column>
          <el-table-column label="通讯地址" min-width="3" prop="address"/>
          <el-table-column label="端口" min-width="4" prop="portStr"/>
          <el-table-column label="波特率" min-width="3" prop="rateTypeStr"/>
          <el-table-column label="规约类型" min-width="4" prop="rulerTypeStr"/>
          <el-table-column label="电压变比" min-width="2" prop="rateV"/>
          <el-table-column label="电流变比" min-width="2" prop="rateA"/>
          <el-table-column label="操作" min-width="4" prop="">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="nowsetClick(scope.row,'实时抄读设置')">实时抄读设置</li>
                <li class="editClass" @click="editDevice(scope.row.id)">扩展录入</li>
                <li class="editClass" @click="delDevice(scope.row.id)"><img alt=""
                                                                            src="../../../assets/img/163@2x.png"/></li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--新增设备弹窗-->
    <el-dialog v-model="deviceAlert" :close-on-click-modal="false" :title="title" :width="dialogWidth"
               class="deviceeviceAlert">
      <!--<el-form :model="deviceForm" ref="deviceFormRef" :rulesPage="deviceFormRules">
        <el-form-item label="端口" prop="port">
          <el-select v-model="deviceForm.port" placeholder="请选择端口" style="width:100%">
            <el-option v-for="item in portList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电压变比" prop="rateV">
          <el-input v-model="deviceForm.rateV" placeholder="电流" />
        </el-form-item>
        <el-form-item label="电流变比" prop="rateA">
          <el-input v-model="deviceForm.rateA" placeholder="电压" />
        </el-form-item>
      </el-form>-->
      <div class="tableBox" style="padding: 0;">
        <el-table ref="multipleTableRef2" v-loading='unBindDeviceLoading' :data="unBindDeviceList" row-key="id"
                  style="width: 100%" table-layout="auto" @select-all="checkAll2"
                  @selection-change="handleSelectionChange2">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column :reserve-selection="true" type="selection" width="55"/>
          <el-table-column label="设备名称" prop="name"/>
          <el-table-column label="通讯地址" prop="address"/>
          <el-table-column label="产品型号" prop="productModel"/>
          <el-table-column label="创建人" prop="creatorName"/>
          <el-table-column label="创建时间" prop="createTime"/>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="bPageSize" :page-sizes="[20,100, 200, 300, 400]"
                         :small="small" :total="bTotal" background layout="total, prev, pager, next, sizes" small
                         @size-change="bHandleSizeChange" @current-change="bHandleCurrentChange"/>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="deviceAlert=false">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">加入网关</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--编辑设备弹窗（扩展录入）-->
    <el-dialog v-model="editDeviceAlert" :close-on-click-modal="false" :title="title" :width="dialogWidth"
               class="deviceeviceAlert">
      <el-form ref="editDeviceFormRef" :model="editDeviceForm" :rules="editDeviceFormRules" label-width="auto">
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.categoryIdFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="产品类型" prop="categoryId" style="width:95%">
            <el-select v-model="editDeviceForm.categoryId" clearable placeholder="请选择产品类型" style="width:100%"
                       @change="categoryChange">
              <el-option v-for="item in allCategoryList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.productIdFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="产品型号" prop="productId" style="width:95%">
            <el-select v-model="editDeviceForm.productId" clearable placeholder="请选择产品型号" style="width:100%">
              <el-option v-for="item in productList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.nameFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="设备名称" prop="name" style="width:95%">
            <el-input v-model="editDeviceForm.name" placeholder="请输入设备名称"/>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.addressFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="通讯地址" prop="address" style="width:95%">
            <el-input v-model="editDeviceForm.address" placeholder="请输入通讯地址"/>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.rateVFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="电压变比" prop="rateV" style="width:95%">
            <el-input v-model="editDeviceForm.rateV" placeholder="请输入电压变比" type="number"/>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.rateAFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="电流变比" prop="rateA" style="width:95%">
            <el-input v-model="editDeviceForm.rateA" placeholder="请输入电流变比" type="number"/>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.rateTypeFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="波特率" prop="rateType" style="width:95%">
            <el-select v-model="editDeviceForm.rateType" clearable placeholder="请选择波特率" style="width:100%">
              <el-option v-for="item in rateTypeList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.rulerTypeFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="规约类型" prop="rulerType" style="width:95%">
            <el-select v-model="editDeviceForm.rulerType" clearable placeholder="请选择规约类型" style="width:100%">
              <el-option v-for="item in rulerTypeList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.portFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="端口" prop="port" style="width:95%">
            <el-select v-model="editDeviceForm.port" placeholder="请选择端口" style="width:100%">
              <el-option v-for="item in portList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="formBox">
          <el-checkbox v-if="title=='批量扩展录入'" v-model="editDeviceForm.userTypeFlag" class='largeCheckClass'
                       @change=""></el-checkbox>
          <el-form-item label="用户类型" prop="userType" style="width:95%">
            <el-input v-model="editDeviceForm.userType" placeholder="请输入用户类型"/>
          </el-form-item>
        </div>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="editDeviceAlert=false">取消</el-button>
		    		<el-button type="primary" @click="deviceSave">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--读取弹窗-->
    <el-dialog v-model="readDialog" :close-on-click-modal="false" title="档案读取列表" width="80%">
      <div class="tableBox" style="padding: 0;">
        <el-table v-loading="readLoading" :data="readData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="上报时间" min-width="1%" prop="createTime">
          </el-table-column>
          <el-table-column label="上报内容" min-width="3%" prop="content">
          </el-table-column>
          <el-table-column label="操作" min-width="1%" prop="">
            <template #default="scope">
              <a class="editClass" @click.prevent="analysisClick(scope.row.id)">解析</a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="confrim" style='background-color: #2477F2;color:#fff;'
                   @click="readDialog=false">确认</el-button>
      </span>
      </template>
    </el-dialog>
    <!--解析弹窗-->
    <el-dialog v-model="analysisDialog" :close-on-click-modal="false" title="档案解析" width="95%">
      <!--档案解析-->
      <div class="tableBox" style="padding: 0;">
        <el-table v-loading="analysisLoading" :data="analysisList" style="width: 100%" table-layout="auto">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="设备编号" prop="archiveNo" width="130">
          </el-table-column>
          <el-table-column label="通讯地址" prop="address" width="130">
          </el-table-column>
          <el-table-column label="用户类型" prop="memberType" width="130">
          </el-table-column>
          <el-table-column label="端口" prop="port" width="130">
          </el-table-column>
          <el-table-column label="波特率" prop="rateType" width="130">
          </el-table-column>
          <el-table-column label="规约类型" prop="rulerType" width="130">
          </el-table-column>
          <el-table-column label="电压变比" prop="rateV" width="130">
          </el-table-column>
          <el-table-column label="电流变比" prop="rateA" width="130">
          </el-table-column>
          <el-table-column label="通讯密码" prop="password" width="130">
          </el-table-column>
          <el-table-column label="费率个数" prop="feeNum" width="130">
          </el-table-column>
          <el-table-column label="接线方式" prop="joinTypeStr" width="130">
          </el-table-column>
          <el-table-column label="额定电压" prop="ratedV" width="130">
          </el-table-column>
          <el-table-column label="额定电流" prop="ratedA" width="130">
          </el-table-column>
          <el-table-column label="资产号" prop="pcode" width="130">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
				<span class="dialog-footer">
       			<el-button class="confrim" type="primary" @click="analysisDialog=false">确认</el-button>
      </span>
      </template>
    </el-dialog>
    <div v-if="progressShow" class="progressPop">
      <Progress></Progress>
    </div>
    <!--批量实时抄读设置弹窗-->
    <el-dialog v-model="batchParserDialog" :close-on-click-modal="false" :width="dialogWidth" title="产品抄读列表">
      <div class="tableBox" style="padding: 0;">
        <el-table v-loading="gatewayProductLoading" :data="gatewayProductList" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="产品型号" min-width="1%" prop="productModel">
          </el-table-column>
          <el-table-column label="抄读合集" min-width="1%" prop="parserName">
          </el-table-column>
          <el-table-column label="操作" min-width="1%" prop="">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="nowsetClick(scope.row,'批量实时抄读设置')">设置实时抄读</li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button @click="batchParserDialog=false">关闭</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--实时抄读设置弹窗-->
    <el-dialog v-model="nowParserDialog" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <div class="headerTop">
        <span>抄读方式：{{ parserName }}</span>
        <div class="checkBox">
          <el-checkbox v-model="checkedAll" @change="checkedAllChange(parserItemList,checkedAll)">全选/全不选</el-checkbox>
        </div>
      </div>
      <div class="tabBox" style="margin-bottom: 8px;">
        <el-form label-width="auto">
          <div v-for="item in parserItemList" :class="[item.selected?'parserClass2':'parserClass3']" class="tab_li"
               style="position: relative;">
            <div class="tab_li22">
              <el-checkbox v-model="item.checked" @change="checkedRadioChange()">{{ item.name }}</el-checkbox>
              <div v-if="item.checkFlag" :class="[item.selected?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
              <div v-if="!item.checkFlag" :class="[item.checked?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
            </div>
          </div>
        </el-form>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="nowParserDialog=false">取消</el-button>
		    		<el-button type="primary" @click="nowParserSave">确认</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--立即抄表弹窗-->
    <el-dialog v-model="meterReadingShow" :close-on-click-modal="false" class="organizationTaskAlert" title="立即抄表"
               width="40%">
      <el-form ref="meterReadingRef" :model="meterReadingData" :rules="meterReadingRules" label-width="auto">
        <el-form-item label="方法码" prop="readInterval">
          <el-input v-model="meterReadingData.readInterval" placeholder="请输入方法码"></el-input>
        </el-form-item>
        <el-form-item label="读表间隔" prop="readFn">
          <el-input v-model="meterReadingData.readFn" placeholder="请输入读表间隔" type="number"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="meterReadingShow=false">取消</el-button>
		    		<el-button type="primary" @click="meterReadingSubmit">确认</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, nextTick, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus, Minus} from '@element-plus/icons-vue'
import {
  getList,
  getSelectList,
  getAllCategory,
  getProductList,
  bindList,
  saveList,
  batchUpdate,
  getDetail,
  deleteList,
  downList,
  cleanList,
  readList,
  readResultList,
  readParseList,
  getAllDeviceListApi,
  single,
  updateParser,
  batch,
  batchUpdateParser,
  listProductParser,
  setGatewayApi,
  meterReadingApi,
} from '../../../api/modules/gatewayDevice'
import './gatewayDevice.less'
import Progress from '../../../components/progress.vue'
import qs from 'qs';
import router from "@/router";
// 定义传值类型
const props = defineProps({
  value: String, //这里就接收到父组件传递的value
  type: String, //这里就接收到父组件传递的value
})

// 分页 **********************************************************
// 当前页数
let pageIndex = ref(1)
// 每页条数
let pageSize = ref(20)
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageIndex.value = val;
  getListFun();
}
// 获取总条数
const total = ref(0)
// 获取列表
let DeviceLoading = ref(false)
let DeviceData = ref([])
const getListFun = function () {
  DeviceLoading.value = true
  getList(qs.stringify({
    pageSize: pageSize.value,
    pageNumber: pageIndex.value,
    gatewayId: props.value
  })).then(res => {
    //console.log(res);
    DeviceLoading.value = false
    if (res.data.ok) {
      DeviceData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let portList = ref([]) //端口列表
let rateTypeList = ref([]) //波特率列表
let rulerTypeList = ref([]) //规约类型列表
//获取所有select
const getSelectListFun = function () {
  getSelectList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      portList.value = res.data.result.portList
      rateTypeList.value = res.data.result.rateTypeList
      rulerTypeList.value = res.data.result.rulerTypeList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let allCategoryList = ref([]) //所有分类列表
//所有分类列表
const getAllCategoryFun = function () {
  getAllCategory(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      allCategoryList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let productList = ref([]) //产品列表
//产品列表
const getProductListFun = function () {
  getProductList(qs.stringify({
    categoryId: editDeviceForm.value.categoryId
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      productList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//分类改变
const categoryChange = function () {
  if (editDeviceForm.value.categoryId) {
    getProductListFun()
  }
  editDeviceForm.value.productId = ''
}

// 设备***********************************************************
// 设备弹窗
let deviceAlert = ref(false)
// 设备表单
let deviceForm = ref({
  port: '',
  rateV: 1,
  rateA: 1
})
let title = ref() //弹窗标题
let dialogWidth = ref('50%') //弹窗宽度
let deviceFormRef = ref() //设备
//设备验证规则
let deviceFormRules = ref({
  port: [{
    required: true,
    message: '请选择端口',
    trigger: 'blur'
  }],
  rateA: [{
    required: true,
    message: '请输入电压变比',
    trigger: 'blur'
  }],
  rateV: [{
    required: true,
    message: '请输入电流变比',
    trigger: 'blur'
  }],
})
//新增设备的点击
const addDevice = function () {
  title.value = "新增设备"
  deviceForm.value = {
    port: '',
    rateV: 1,
    rateA: 1
  }
  editId.value = ''
  deviceAlert.value = true
  getAllDeviceListFun()
  nextTick(() => {
    multipleTableRef2.value.clearSelection()
  })
}
/*扩展录入****************************/
let editDeviceAlert = ref(false)
// 扩展录入表单
let editDeviceForm = ref({
  categoryIdFlag: '',
  productIdFlag: '',
  nameFlag: '',
  addressFlag: '',
  rulerTypeFlag: '',
  userTypeFlag: '',
  rateTypeFlag: '',
  portFlag: '',
  rateVFlag: '',
  rateAFlag: '',
  productId: '',
  name: '',
  address: '',
  rateV: '',
  rateA: '',
  rulerType: '',
  userType: '',
  rateType: '',
  port: ''
})
let editDeviceFormRef = ref() //扩展录入
//扩展录入验证规则
let editDeviceFormRules = ref({
  categoryId: [{
    required: true,
    message: '请选择产品类型',
    trigger: 'blur'
  }],
  productId: [{
    required: true,
    message: '请选择产品型号',
    trigger: 'blur'
  }],
  name: [{
    required: true,
    message: '请输入设备名称',
    trigger: 'blur'
  }],
  address: [{
    required: true,
    message: '请输入通讯地址',
    trigger: 'blur'
  }],
  rateV: [{
    required: true,
    message: '请输入电压变比',
    trigger: 'blur'
  }],
  rateA: [{
    required: true,
    message: '请输入电流变比',
    trigger: 'blur'
  }],
  port: [{
    required: true,
    message: '请选择端口',
    trigger: 'blur'
  }],

})
// 编辑设备的点击
let editId = ref()
const editDevice = function (id) {
  title.value = "扩展录入"
  editId.value = id
  editDeviceAlert.value = true
  getDetail(qs.stringify({
    id: id
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      editDeviceForm.value.categoryId = res.data.result.categoryId
      categoryChange()
      editDeviceForm.value.productId = res.data.result.productId
      editDeviceForm.value.name = res.data.result.name
      editDeviceForm.value.address = res.data.result.address
      editDeviceForm.value.rateV = res.data.result.rateV
      editDeviceForm.value.rateA = res.data.result.rateA
      editDeviceForm.value.rulerType = String(res.data.result.rulerType)
      editDeviceForm.value.userType = res.data.result.userType
      editDeviceForm.value.rateType = String(res.data.result.rateType)
      editDeviceForm.value.port = String(res.data.result.port)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 保存设备(添加、编辑、批量扩展录入)
const deviceSave = function () {
  if (title.value == '扩展录入') { //编辑
    var params = {
      gatewayId: props.value,
      id: editId.value,
      productId: editDeviceForm.value.productId,
      name: editDeviceForm.value.name,
      address: editDeviceForm.value.address,
      rulerType: editDeviceForm.value.rulerType,
      userType: editDeviceForm.value.userType,
      rateType: editDeviceForm.value.rateType,
      port: editDeviceForm.value.port,
      rateV: editDeviceForm.value.rateV,
      rateA: editDeviceForm.value.rateA
    }
  } else if (title.value == '新增设备') { //新增
    var params = {
      type: 2,
      id: props.value,
      idList: checkIdArr2.value.join(',')
    }
  } else if (title.value == '批量扩展录入') { //批量扩展录入
    let idList = [];
    multipleSelection.value.forEach(item => {
      idList.push(item.id)
    })
    var params = {
      idList: idList.join(','),
      productIdFlag: editDeviceForm.value.productIdFlag ? 1 : 0,
      nameFlag: editDeviceForm.value.nameFlag ? 1 : 0,
      addressFlag: editDeviceForm.value.addressFlag ? 1 : 0,
      rulerTypeFlag: editDeviceForm.value.rulerTypeFlag ? 1 : 0,
      userTypeFlag: editDeviceForm.value.userTypeFlag ? 1 : 0,
      rateTypeFlag: editDeviceForm.value.rateTypeFlag ? 1 : 0,
      portFlag: editDeviceForm.value.portFlag ? 1 : 0,
      rateVFlag: editDeviceForm.value.rateVFlag ? 1 : 0,
      rateAFlag: editDeviceForm.value.rateAFlag ? 1 : 0,
      productId: editDeviceForm.value.productId,
      name: editDeviceForm.value.name,
      address: editDeviceForm.value.address,
      rulerType: editDeviceForm.value.rulerType,
      userType: editDeviceForm.value.userType,
      rateType: editDeviceForm.value.rateType,
      port: editDeviceForm.value.port,
      rateV: editDeviceForm.value.rateV,
      rateA: editDeviceForm.value.rateA
    }
  }
  //console.log(params)
  if (title.value == '扩展录入') {
    if (editDeviceFormRef) {
      editDeviceFormRef.value.validate((valid) => {
        //提交成功
        if (valid) {
          saveList(qs.stringify(params)).then(res => {
            //console.log(res);
            if (res.data.ok) {
              getListFun()
              editDeviceAlert.value = false
            } else {
              ElMessage({
                message: res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        }
      })
    }
  } else if (title.value == '新增设备') {
    if (checkIdArr2.value && checkIdArr2.value.length > 0) {
      bindList(qs.stringify(params)).then(res => {
        //console.log(res);
        if (res.data.ok) {
          getListFun()
          deviceAlert.value = false
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      })
    } else {
      ElMessage({
        message: '请至少选择一个设备',
        type: 'info'
      })
    }
  } else if (title.value == '批量扩展录入') {
    batchUpdate(qs.stringify(params)).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
        editDeviceAlert.value = false
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
//设备的删除
const delDevice = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除设备',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此设备，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}

// 新增设备（绑定）-----------------------------------------------------------------
let bTotal = ref()
let bPageNumber = ref(1)
let bPageSize = ref(20)
let unBindDeviceLoading = ref(false)
// 未绑定的设备列表
let unBindDeviceList = ref([])
const bHandleSizeChange = function (e) {
  bPageSize.value = e
  getAllDeviceListFun()
}
const bHandleCurrentChange = function (e) {
  bPageNumber.value = e
  getAllDeviceListFun()
}
// 获取未绑定设备列表
const getAllDeviceListFun = async function () {
  await getAllDeviceListApi(qs.stringify({
    pageNumber: bPageNumber.value,
    pageSize: bPageSize.value,
    type: 2
  })).then(res => {
    unBindDeviceLoading.value = false
    if (res.data.code === 0) {
      unBindDeviceList.value = res.data.result.list
      bTotal.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击绑定
const bindClick = async function () {
  bindDeviceDialog.value = true
  unBindDeviceLoading.value = true
  await getAllDeviceListFun()
  multipleTableRef2.value.clearSelection()
}
// 设备表格多选事件
let checkIdArr2 = ref([])
const handleSelectionChange2 = function (e) {
  checkIdArr2.value = []
  for (let i = 0; i < e.length; i++) {
    checkIdArr2.value.push(Number(e[i].id))
  }
  checkIdArr2.value = [...new Set(checkIdArr2.value)]
}
// 全选
let multipleTableRef2 = ref()
const checkAll2 = async function (e) {
  let arr = []
  await getAllDeviceListApi(qs.stringify({
    pageNumber: 1,
    pageSize: bTotal.value,
    type: 2
  })).then(res => {
    if (res.data.code === 0) {
      arr = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })

  // 判断全选或全不选
  if (!multipleTableRef2.value.store.states.isAllSelected.value) {
    multipleTableRef2.value.clearSelection()
  } else {
    for (let i = 0; i < arr.length; i++) {
      multipleTableRef2.value.toggleRowSelection(arr[i], true)
    }
  }
}
// 读取档案相关事件及数据***********************************************************
let readLoading = ref(false)
let readDialog = ref(false)
let analysisDialog = ref(false)
// 读取完整档案相关事件及数据
const readDeviceFun = function (val) {
  analysisList.value = []
  readData.value = []
  progressShow.value = true
  readDialog.value = true
  readList(qs.stringify({
    gatewayId: props.value,
  })).then(async res => {
    //console.log(res);
    if (res.data.ok) {
      if (res.data.result) {
        await readResultListFun(res.data.result);
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message + '!'
      });
      progressShow.value = false
    }
  }).catch(err => {
    console.log(err);
  })
}
//档案读取结果
const readResultListFun = function (id) {
  let num = 0
  let timer = setInterval(() => {
    num++
    readResultList(qs.stringify({
      commandLogId: id
    })).then(res => {
      //console.log(num)
      //console.log(res);
      if (res.data.ok) {
        if (res.data.result.length > 0) {
          progressShow.value = false
          readData.value = res.data.result
          ElMessage({
            type: 'success',
            message: '读取完整档案成功!'
          });
        }
        if (num > 9 || res.data.result.length > 0) {
          clearInterval(timer)
        }
        if (num > 9) {
          progressShow.value = false
          ElMessage({
            type: 'error',
            message: '读取完整档案失败!'
          });
        }
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }, 1000)
}
let readData = ref([])
const readParseListFun = function (id) {
  analysisLoading.value = true
  readParseList(qs.stringify({
    commandLogId: id
  })).then(res => {
    //console.log(res);
    analysisLoading.value = false
    if (res.data.ok) {
      analysisList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//解析
const analysisClick = function (id) {
  analysisDialog.value = true
  readParseListFun(id)
}
let analysisLoading = ref(false)
let analysisList = ref([])
// 清空档案*********************************************************************
const emptyDeviceFun = function () {
  ElMessageBox.confirm("确认清空此网关的档案吗？", '确认要清空档案吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'success',
  }).then(() => {
    //openFullScreen()
    progressShow.value = true
    cleanList(qs.stringify({
      gatewayId: props.value
    })).then(res => {
      //console.log(res);
      //loadingVal.value.close()
      progressShow.value = false
      if (res.data.ok) {
        ElMessage({
          message: '档案清空成功！',
          type: 'success',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message + '!'
        });
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消清空档案！',
    })
  })
}
// 下发档案******************************************************************
const sendFileClick = function () {
  ElMessageBox.confirm("确认下发此网关的档案吗？", '确认要下发档案吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'success',
  }).then(() => {
    //openFullScreen()
    progressShow.value = true
    downList(qs.stringify({
      gatewayId: props.value
    })).then(res => {
      //loadingVal.value.close()
      progressShow.value = false
      //console.log(res);
      if (res.data.ok) {
        ElMessage({
          message: '档案下发成功！',
          type: 'success',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message + '!'
        });
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消档案下发！',
    })
  })
}
// 设置档案
const setDevice = function () {
  ElMessageBox.confirm("确认设置此网关的档案吗？", '确认要设置档案吗？', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'success',
  }).then(() => {
    //openFullScreen()
    progressShow.value = true
    setGatewayApi(qs.stringify({
      gatewayId: props.value
    })).then(res => {
      //loadingVal.value.close()
      progressShow.value = false
      //console.log(res);
      if (res.data.ok) {
        ElMessage({
          message: '档案设置成功！',
          type: 'success',
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message + '!'
        });
      }
    }).catch(err => {
      progressShow.value = false
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消设置档案！',
    })
  })
}

// 立即抄表----------------------------------------------------------------------------------------
// 立即抄表----------------------------------------------------------------------------------------
let meterReadingShow = ref(false)
let meterReadingRef = ref()
// 立即抄表表单数据
let meterReadingData = ref({
  gatewayId: props.value,
  readFn: '',
  readInterval: '',
  pns: 3,
})
let meterReadingRules = ref({
  readFn: [{
    required: true,
    message: '请输入方法码',
    trigger: 'blur'
  }],
  readInterval: [{
    required: true,
    message: '请输入读表间隔',
    trigger: 'blur'
  }],
})
// 立即抄表
const meterReadingFun = function () {
  meterReadingShow.value = true
}
// 立即抄表提交
const meterReadingSubmit = function () {
  meterReadingApi(meterReadingData.value).then(res => {
    meterReadingShow.value = false
    if (res.data.code === 0) {
      ElMessage({
        message: '立即抄表成功！',
        type: 'success',
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message + '!'
      });
    }
  }).catch(err => {
    console.log(err);
  })
}

/*加载loading*/
const loadingVal = ref()
const progressShow = ref(false)
const openFullScreen = () => {
  loadingVal.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.7)',
  })
}
//复选框触发
let multipleSelection = ref([])
const handleSelectionChange = function (val) {
  multipleSelection.value = val;
}
//批量删除
const batchDelete = function () {
  let idList = []
  if (multipleSelection.value.length > 0) {
    multipleSelection.value.forEach(item => {
      idList.push(item.id)
    })
    ElMessageBox.confirm("确认要删除吗？", '注意:', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      title: '删除设备',
      message: h('p', null, [
        h('img', {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
        }, ''),
        h('span', {
          style: 'color:#595959;'
        }, '请确认是否要删除多条设备，删除后数据不可恢复'),
      ])
    }).then(() => {
      deleteList(qs.stringify({
        type: 2,
        id: props.value,
        idList: idList.join(',')
      })).then(res => {
        //console.log(res);
        if (res.data.ok) {
          getListFun()
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      })
      ElMessage({
        type: 'success',
        message: '删除成功!'
      });
    }).catch(() => {
      ElMessage({
        type: 'info',
        message: '已取消删除!'
      });
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条批量删除的数据!'
    })
  }
}
//批量扩展录入
const batchEdit = function () {
  if (multipleSelection.value.length > 0) {
    title.value = '批量扩展录入'
    editDeviceAlert.value = true
  } else {
    ElMessage({
      type: 'info',
      message: '请至少选择一条批量扩展录入的数据!'
    })
  }
}
/*实时抄读、批量实时抄读***********************************/
let batchParserDialog = ref(false) //批量实时抄读设置弹窗
let gatewayProductList = ref([]) //批量实时抄读设置列表
let gatewayProductLoading = ref(false) //批量实时抄读设置加载
let nowParserDialog = ref(false) //实时抄读弹窗
let parserName = ref() //抄读名称
let checkedAll = ref(false) //全选
let parserItemList = ref([]) //实时抄读列表
let signVal = ref()
let productId = ref() //产品id
let deviceId = ref() //设备id

//批量实时抄读设置的点击
const batchNow = function () {
  checkedAll.value = false
  gatewayProductLoading.value = true
  batchParserDialog.value = true
  listProductParser(qs.stringify({
    gatewayId: props.value
  })).then(res => {
    gatewayProductLoading.value = false
    //console.log(res)
    if (res.data.ok) {
      gatewayProductList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//设置实时抄读的点击
const nowsetClick = function (obj, val) {
  signVal.value = val
  nowParserDialog.value = true
  title.value = '实时抄读设置'
  if (val == '批量实时抄读设置') {
    parserName.value = obj.parserName
    productId.value = obj.productId
    deviceId.value = ''
    batch(qs.stringify({
      parserId: obj.parserId
    })).then(res => {
      //console.log(res)
      if (res.data.ok) {
        parserItemList.value = res.data.result
        parserItemList.value.forEach(item => {
          if (item.checkFlag) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else if (val == '实时抄读设置') {
    deviceId.value = obj.id
    parserName.value = obj.parserName
    productId.value = ''
    single(qs.stringify({
      id: obj.id
    })).then(res => {
      //console.log(res)
      if (res.data.ok) {
        parserItemList.value = res.data.result
        parserItemList.value.forEach(item => {
          if (item.checkFlag) {
            item.checked = true
          } else {
            item.checked = false
          }
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
//实时抄读的保存
const nowParserSave = function () {
  var arr = []
  if (parserItemList.value && parserItemList.value.length > 0) {
    parserItemList.value.forEach(item => {
      if (item.checked) {
        arr.push(item.id)
      }
    })
  }
  if (signVal.value == '批量实时抄读设置') {
    batchUpdateParser(qs.stringify({
      gatewayId: props.value,
      productId: productId.value,
      parserItemIdList: arr.toString()
    })).then(res => {
      //console.log(res)
      if (res.data.ok) {
        getListFun()
        nowParserDialog.value = false
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else if (signVal.value == '实时抄读设置') {
    updateParser(qs.stringify({
      id: deviceId.value,
      parserItemIdList: arr.toString()
    })).then(res => {
      //console.log(res)
      if (res.data.ok) {
        getListFun()
        nowParserDialog.value = false
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
//全选、全不选
const checkedAllChange = function (val, checkedVal) {
  if (checkedVal == true) {
    val.forEach(item => {
      item.checked = true
    })
  } else {
    val.forEach(item => {
      item.checked = false
    })
  }
}
//单选
const checkedRadioChange = function () {
  var checkedArr = []
  parserItemList.value.forEach(item => {
    if (item.checked == true) {
      checkedArr.push(item)
    }
  })
  if (checkedArr.length == parserItemList.value.length) {
    checkedAll.value = true
  } else {
    checkedAll.value = false
  }
}

watch(() => props.value, (newValue, oldValue) => {
  getListFun()
  getSelectListFun()
  getAllCategoryFun()
}, {
  immediate: true
})
</script>

<style>

</style>
