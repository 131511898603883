<template>
  <div class="user">
    <div class="title">
      <p>
        <span>用户管理</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">用户名称</label>
          <el-input
            v-model="queryParams.userName"
            prefix-icon="Search"
            clearable
            placeholder="请输入用户名称"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">手机号码</label>
          <el-input
            v-model="queryParams.phone"
            prefix-icon="Search"
            clearable
            placeholder="请输入手机号码"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建用户
        </el-button>
        <el-table v-loading="loading" :data="userList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建用户</p>
            </el-empty>
          </template>
          <el-table-column label="用户名称" align="center" prop="userName" />
          <el-table-column label="用户角色" align="center" prop="roleName" />
          <el-table-column label="用户手机号码" align="center" prop="phone" />
          <el-table-column label="微信号码" align="center" prop="wxOpenid" />
          <el-table-column label="状态" align="center" prop="enable">
            <template #default="scope">
              <el-tag
                :type="
                  sys_status.find((item) => item.value === scope.row.enable)
                    ?.type
                "
                size="small"
              >
                {{
                  sys_status.find((item) => item.value === scope.row.enable)
                    ?.label
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form ref="userRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="用户名称" prop="userName">
          <el-input
            v-model="form.userName"
            placeholder="请输入用户名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="用户角色" prop="roleId">
          <el-select
            v-model="form.roleId"
            placeholder="请选择"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in formLists.roleList"
              :key="item.id"
              :label="item.roleName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="手机号码" prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号码"
            clearable
          />
        </el-form-item>
        <el-form-item label="是否启用" prop="enable">
          <el-select
            v-model="form.enable"
            placeholder="请选择"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="dict in sys_status"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './user.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listUser,
  getUser,
  delUser,
  addUser,
  updateUser,
} from '@/api/modules/user'
import { listBoxRole } from '@/api/modules/boxRole'

const userList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const userRef = ref(null)

const sys_status = ref([
  { label: '启用', value: 0, type: 'success' },
  { label: '禁用', value: 1, type: 'danger' },
])

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    userName: null,
    phone: null,
  },
  rules: {
    userName: [{ required: true, message: '请输入用户名称', trigger: 'blur' }],
    roleId: [{ required: true, message: '请选择用户角色', trigger: 'change' }],
    phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }],
    enable: [{ required: true, message: '请选择是否启用', trigger: 'change' }],
  },
})

const { queryParams, form, rules } = toRefs(data)

const formLists = reactive({
  roleList: [],
})

/** 查询用户列表 */
function getList() {
  loading.value = true
  listUser(queryParams.value).then((response) => {
    userList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    userName: null,
    roleId: null,
    phone: null,
    enable: null,
  }
  userRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    userName: null,
    phone: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listBoxRole({
    pageNumber: 1,
    pageSize: 1000,
  }).then((response) => {
    formLists.roleList = response.data.result.list
    open.value = true
    title.value = '新建用户'
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listBoxRole({
    pageNumber: 1,
    pageSize: 1000,
  }).then((response) => {
    formLists.roleList = response.data.result.list
    getUser(row.id).then((response) => {
      form.value = response.data.result
      open.value = true
      title.value = '修改用户'
    })
  })
}

/** 提交按钮 */
function submitForm() {
  userRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updateUser(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addUser(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除用户',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此用户，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delUser(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}
</script>
