import {createApp} from 'vue'
import App from './App.vue'

import router from './router/index'
import axios from './api/index'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store'
import './assets/font/iconfont.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import vue3TreeOrg from 'vue3-tree-org';
import "vue3-tree-org/lib/vue3-tree-org.css";

const app = createApp(App)
app.use(vue3TreeOrg).use(router).use(ElementPlus, {
    locale: zhCn,
}).use(store).mount('#app')


// main.js
import * as ElIcon from '@element-plus/icons-vue'

for (let iconName in ElIcon) {
    app.component(iconName, ElIcon[iconName])
}

app.config.globalProperties.$axios = axios;


//登录拦截
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {//requireAuth若为true则该路由需要判断是否登录
        if (window.localStorage.getItem('memberId')) {
            next()
        } else {
            next({//返回登录页面
                path: '/login',
                query: {redirect: to.fullPath}
            })
        }
    } else {
        next();
    }
})
