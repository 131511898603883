<template>
  <div class="smartFactory">
    <v-scale-screen :autoScale="{x:false,y:false}" :fullScreen="false" hight="1080" width="1920">
      <div class="mainCont">
        <div class="leftBox">
          <div class="leftItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftTitle1.png">
            </div>
            <div class="contBox">
              <div class="contItemBox">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem1.png">
                <div class="contPox">
                  <div class="topBox">
                    <span class="name">电</span>
                    <span class="unit">kwh</span>
                  </div>
                  <p class="val">9911.05</p>
                </div>
              </div>
              <div class="contItemBox">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem2.png">
                <div class="contPox">
                  <div class="topBox">
                    <span class="name">水</span>
                    <span class="unit">t</span>
                  </div>
                  <p class="val">361.13</p>
                </div>
              </div>
              <div class="contItemBox">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem3.png">
                <div class="contPox">
                  <div class="topBox">
                    <span class="name">气</span>
                    <span class="unit">km³</span>
                  </div>
                  <p class="val">588.05</p>
                </div>
              </div>
              <div class="contItemBox">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftItem4.png">
                <div class="contPox">
                  <div class="topBox">
                    <span class="name">空压机</span>
                    <span class="unit">kwh</span>
                  </div>
                  <p class="val">511.55</p>
                </div>
              </div>
            </div>
          </div>
          <div class="leftItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftTitle2.png">
            </div>
            <div class="tabBox">
              <p class="active">电</p>
              <p>水</p>
              <p>气</p>
              <p>综合能耗</p>
            </div>
            <div class="chart1"></div>
          </div>
          <div class="leftItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/leftTitle3.png">
            </div>
            <div class="circleBgc"></div>
            <div class="chart2"></div>
          </div>
        </div>
        <div class="rightBox">
          <div class="rightItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightTitle1.png">
            </div>
            <div class="contBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightItem1.png">
              <div class="contPox">
                <div class="name">实时耗电量(kwh)</div>
                <p class="val">80,9999,6767</p>
                <div class="listCont">
                  <div class="listItem">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightIcon1.png">
                    <p class="name">总耗电量</p>
                    <p class="val">20.57</p>
                    <p class="unit">kwh</p>
                  </div>
                  <div class="listItem">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightIcon2.png">
                    <p class="name">设备数量</p>
                    <p class="val">20</p>
                    <p class="unit">台</p>
                  </div>
                  <div class="listItem">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightIcon3.png">
                    <p class="name">电力损耗</p>
                    <p class="val">3%</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightTitle2.png">
            </div>
            <div class="contBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightItem2.png">
              <div class="contPox">
                <div class="name">实时用水量(t)</div>
                <p class="val">80,9999,6767</p>
                <div class="listCont">
                  <div class="listItem">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightIcon4.png">
                    <p class="name">总用水量</p>
                    <p class="val">20.57</p>
                    <p class="unit">t</p>
                  </div>
                  <div class="listItem">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightIcon2.png">
                    <p class="name">设备数量</p>
                    <p class="val">20</p>
                    <p class="unit">台</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="rightItem">
            <div class="titCont">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightTitle3.png">
            </div>
            <div class="contBox">
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightItem3.png">
              <div class="contPox">
                <div class="name">实时消耗量(m³)</div>
                <p class="val">80,9999,6767</p>
                <div class="listCont">
                  <div class="listItem">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightIcon5.png">
                    <p class="name">总消耗量</p>
                    <p class="val">20.57</p>
                    <p class="unit">m³</p>
                  </div>
                  <div class="listItem">
                    <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/smartFactory/rightIcon2.png">
                    <p class="name">设备数量</p>
                    <p class="val">20</p>
                    <p class="unit">台</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="toolitBox toolitBox1">
          <p class="name">C1楼耗能数据</p>
          <p>
            <span>耗电量: </span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用水量: </span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用气量: </span>
            <span>3434m³</span>
          </p>
        </div>
        <div class="toolitBox toolitBox2">
          <p class="name">C2楼耗能数据</p>
          <p>
            <span>耗电量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用水量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用气量:</span>
            <span>3434m³</span>
          </p>
        </div>
        <div class="toolitBox toolitBox3">
          <p class="name">B1楼耗能数据</p>
          <p>
            <span>耗电量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用水量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用气量:</span>
            <span>3434m³</span>
          </p>
        </div>
        <div class="toolitBox toolitBox4">
          <p class="name">B2楼耗能数据</p>
          <p>
            <span>耗电量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用水量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用气量:</span>
            <span>3434m³</span>
          </p>
        </div>
        <div class="toolitBox toolitBox5">
          <p class="name">1号宿舍耗能数据</p>
          <p>
            <span>耗电量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用水量:</span>
            <span>3434kwh</span>
          </p>
        </div>
        <div class="toolitBox toolitBox6">
          <p class="name">2号宿舍耗能数据</p>
          <p>
            <span>耗电量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用水量:</span>
            <span>3434kwh</span>
          </p>
        </div>
        <div class="toolitBox toolitBox7">
          <p class="name">3号宿舍耗能数据</p>
          <p>
            <span>耗电量:</span>
            <span>3434kwh</span>
          </p>
          <p>
            <span>用水量:</span>
            <span>3434kwh</span>
          </p>
        </div>
      </div>
      <div class="bgcGif"></div>
      <div class="headGif"></div>
      <div class="bottomGif"></div>
    </v-scale-screen>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './smartFactory.less'
import VScaleScreen from 'v-scale-screen'
import * as echarts from "echarts";


// 加载折线图
const chartFun1 = function () {
  var chartDom = document.querySelector('.chart1');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    title: {
      text: 'kwh',
      textStyle: {
        fontSize: 12,
        fontWeight: 300,
        color: '#fff',
      }
    },
    grid: {
      left: 40,
      top: 30,
      bottom: 20,
      right: 20,
    },
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      axisLabel: {
        textStyle: {
          fontWeight: 400,
          color: '#fff'
        }
      },
      data: [1, 2, 3, 4, 5, 6]
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(62, 122, 248, 0.12)'],
        }
      },
      axisLabel: {
        textStyle: {
          color: '#fff'
        }
      },
    },
    series: [
      {
        type: 'line',
        smooth: true,
        data: [41, 54, 23, 45, 33, 29],
        itemStyle: {
          color: '#00EBEB',
          opacity: 0
        },
        lineStyle: {
          width: 3
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(0, 235, 235, 0.20)'
          },
            {
              offset: 1,
              color: 'rgba(0, 235, 235, 0.00)'
            },
          ])
        }
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}
// 加载饼图
const chartFun2 = function () {
  var chartDom = document.querySelector('.chart2');
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    grid: {
      left: -70,
      top: 10,
      bottom: 10,
      right: 10,
    },
    tooltip: {
      trigger: 'item', // axis   item   none三个值
      show: true,
      position: function (point, params, dom, rect, size) {
        // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
        var x = 0; // x坐标位置
        var y = 0; // y坐标位置
        var pointX = point[0];
        var pointY = point[1];
        // 提示框大小
        var boxWidth = size.contentSize[0];
        var boxHeight = size.contentSize[1];
        // boxWidth > pointX 说明鼠标左边放不下提示框
        if (boxWidth > pointX) {
          x = 100;
        } else { // 左边放的下
          x = pointX - boxWidth;
        }
        // boxHeight > pointY 说明鼠标上边放不下提示框
        if (boxHeight > pointY) {
          y = 5;
        } else { // 上边放得下
          y = pointY - boxHeight;
        }
        return [x, y];
      },
    },
    legend: {
      show: false
    },
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['50%', '65%'],
        avoidLabelOverlap: false,
        label: {
          formatter: '{b}: {c}'
          // formatter: function (data) {
          //   let ele = data.name === '运行中' ? `
          //   <p style="width: 10px;height: 10px;border-radius: 50%;border: 4px solid #05D1F4;"></p>
          //   ` : `
          //   <p style="width: 10px;height: 10px;border-radius: 50%;border: 4px solid #DBDBDB;"></p>
          //   `
          //   return `${data.name} ${data.value}`;
          // }
        },
        labelLine: {
          show: true
        },
        color: ['#05D1F4', '#DBDBDB'],
        data: [
          {value: 1048, name: '运行中'},
          {value: 735, name: '空闲中'},
        ]
      }
    ]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize({
    animation: {
      duration: 2800,
      easing: 'cubicInOut',
      // delay: 500,
    },
  });
  window.onresize = () => {
    myChart.resize();
  }
}


onMounted(() => {
  chartFun1()
  chartFun2()
})
</script>

<style lang="less" scoped>
</style>
