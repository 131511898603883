<template>
  <div class="comAlarm">
    <!--搜索-->
    <div v-if="!props.value" class="searchBox">
      <div class="liBox" style="display: flex;justify-content: space-between;align-items: center;">
        <div style="margin-right: 8px">发生位置</div>
        <el-select ref="elSelectTable" v-model="equipmentName" :popper-append-to-body="false"
                   class="select-unitName select-option-father" clearable popper-class="select_report_and_type"
                   @clear="clearFun">
          <el-option :value="dataItemList">
            <el-tree
                ref="treeRef"
                :expand-on-click-node='false'
                :load="treeList"
                :props="props2"
                highlight-current
                lazy
                @node-click="clickUnitTreeNodeChild($event)"
            />
          </el-option>
        </el-select>
      </div>
      <div class="liBox">
        <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
        <el-button class="searchButton" @click="clearFun">重置</el-button>
      </div>
    </div>
    <div class="contain">
      <!--报警列表-->
      <!--      <el-table v-loading="loading" :data="tableData" style="width: 100%" table-layout="fixed">-->
      <!--        <template #empty>-->
      <!--          <el-empty class="emptyClass" description="暂无数据信息"-->
      <!--                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">-->
      <!--            &lt;!&ndash;            <p>您可以在此页面新建设备信息</p>&ndash;&gt;-->
      <!--          </el-empty>-->
      <!--        </template>-->
      <!--        <el-table-column fixed label="发生时间" min-width="160px" prop="sendTime"/>-->
      <!--        <el-table-column label="发生位置" min-width="260px" prop="sendLocation"/>-->
      <!--        <el-table-column label="报警数量汇总" min-width="280px" prop="numberStr"/>-->
      <!--        <el-table-column label="上报通讯地址" min-width="180px" prop="upAddress">-->
      <!--          <template #default="scope">-->
      <!--            <span>{{ scope.row.upAddress }}</span>-->
      <!--            <img alt="" class="adressClass" src="@/assets/img/编组@2x.png" style="width: 18px;height: 18px;"/>-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column label="通讯地址" min-width="180px" prop="address">-->
      <!--          <template #default="scope">-->
      <!--            <span>{{ scope.row.address }}</span>-->
      <!--            <img alt="" class="adressClass" src="@/assets/img/编组@2x.png" style="width: 18px;height: 18px;"/>-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--        <el-table-column fixed="right" label="操作" min-width="146px">-->
      <!--          <template #default="scope">-->
      <!--            <span class="btn" @click="lookClick(scope.row.id)">查看详情</span>-->
      <!--          </template>-->
      <!--        </el-table-column>-->
      <!--      </el-table>-->
      <el-table v-loading="loading" :data="tableData" stripe style="width: 100%" table-layout="auto">
        <template #empty>
          <el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/noData.png">
          </el-empty>
        </template>
        <el-table-column label="告警设备" prop="equipmentName"></el-table-column>
        <el-table-column label="告警等级" prop="levelStr">
          <template #default="scope">
            <p v-if="scope.row.level===1" class="level level1">{{ scope.row.levelStr }}</p>
            <p v-if="scope.row.level===2" class="level level2">{{ scope.row.levelStr }}</p>
            <p v-if="scope.row.level===3" class="level level3">{{ scope.row.levelStr }}</p>
            <p v-if="scope.row.level===4" class="level level4">{{ scope.row.levelStr }}</p>
            <p v-if="scope.row.level===5" class="level level5">{{ scope.row.levelStr }}</p>
          </template>
        </el-table-column>
        <el-table-column label="发生时间" prop="time"></el-table-column>
        <el-table-column label="触发规则" prop="triggerRule"></el-table-column>
        <el-table-column label="触发值" prop="nowValue"></el-table-column>
        <el-table-column label="通知人" prop="memberList">
          <template #default="scope">
            <p>{{ scope.row.memberList && scope.row.memberList.join(',') }}</p>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="220px">
          <template #default="scope">
            <span class="editClass btn" @click.prevent="workOrderClick(scope.row.id,scope.row)">转工单</span>
            <span class="editClass btn" @click.prevent="closeWarnClick(scope.row.id,scope.row)">结束警告</span>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="tableData.length > 0" class="pag">
        <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                       layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                       @current-change="pageChange"/>
      </div>
    </div>
    <!--报警详情弹窗-->
    <el-dialog v-model="alarmDialog" :close-on-click-modal="false" title="查看详细" width="70%">
      <div class="tableBox">
        <el-table v-loading="dLoading" :data="detailTableData" style="width: 100%" table-layout="auto">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="级别" prop="levStr"/>
          <el-table-column label="发生时间" prop="sendTime"/>
          <el-table-column label="结束时间" prop="endTime"/>
          <el-table-column label="类别" prop="type"/>
          <el-table-column label="操作" width="120">
            <template #default="scope">
              <ul class="operationUl">
                <li style="color: #307FE2;cursor: pointer" @click.prevent="lookValue(scope.row.id)">查看当时值</li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <!--查看当时值弹窗-->
    <el-dialog v-model="valueDialog" :close-on-click-modal="false" title="报警时数据" width="40%">
      <el-table v-loading="valueLoading" :data="lookValueData" stripe style="width: 100%" table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="数据项" prop="dictName"/>
        <el-table-column label="值" prop="value"/>
      </el-table>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {endAlarmAPi, getAlarmListAPi2} from "@/api/modules/alarm";
import {getAlarmDetailListApi, getValueListApi} from '@/api/modules/warn'
import qs from "qs";
import {getTreeListApi} from "@/api/modules/control";


const props = defineProps({
  value: String
})

let router = useRouter()


// 报警-----------------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 报警表格数据
let tableData = ref([])
// 获取报警列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getAlarmListAPi2(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    equipmentIdStr: props.value,
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 转工单-----------------------------------------------------------------
const workOrderClick = function (id, scope) {
  router.push({
    path: '/admin/workOrder',
    query: {
      equipmentName: scope.equipmentName,
      levelStr: scope.levelStr,
      triggerRule: scope.triggerRule,
      nowValue: scope.nowValue
    }
  })
}


// 结束警告-----------------------------------------------------------------
const closeWarnClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要结束此告警', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    endAlarmAPi(qs.stringify({id: id})).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '结束告警成功'
        });
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消结束告警'
    });
  });
}


// 查看详细-----------------------------------------------------------------------
// 详细弹窗
let alarmDialog = ref(false)
// 详情数据
let detailTableData = ref([])
let dLoading = ref(false)
// 点击查看详细
const lookClick = function (id) {
  alarmDialog.value = true
  dLoading.value = true
  getAlarmDetailListApi(qs.stringify({id: id})).then(res => {
    dLoading.value = false
    if (res.data.code === 0) {
      detailTableData.value = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 查看当时值-----------------------------------------------------------------------
// 查看当时值弹窗
let valueDialog = ref(false)
// 查看当时值数据
let lookValueData = ref([])
let valueLoading = ref(false)
// 点击查看当时值
const lookValue = function (id) {
  valueDialog.value = true
  valueLoading.value = true
  getValueListApi(qs.stringify({eventId: id})).then(res => {
    valueLoading.value = false
    if (res.data.code === 0) {
      lookValueData.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 查询
let equipmentId = ref()
let equipmentName = ref()
let type = ref()

// 树形结构数据
let dataItemList = ref([])

// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props2 = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}

//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data) {
  console.log(data)
  elSelectTable.value.blur();
  equipmentId.value = data.id
  equipmentName.value = data.name
  type.value = data.type
}
// 下拉框清空
const clearFun = function () {
  equipmentId.value = ''
  equipmentName.value = ''
  type.value = ''
}

const searchFun = function () {
  getListFun()
}

onMounted(() => {
  getListFun()
})


</script>

<style lang="less" scoped>
.comAlarm {
  width: 100%;
  //搜索
  .searchBox {
    margin-bottom: 16px;
    padding: 24px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .liBox {
      //margin-bottom: 16px;
      //margin-right: 16px;

      > p {
        margin-bottom: 8px;
      }

      .el-select, .el-input {
        width: 220px;
      }

      //.el-button {
      //  margin-top: -1px;
      //}
    }
  }

  //列表内容
  .contain {
    width: calc(100% - 48px);
    padding: 24px 24px 24px 24px;
    background-color: #fff;

    //新增
    .addBtn {
      font-weight: 400;
      margin-bottom: 16px;

      .el-icon {
        margin-right: 5px;
      }
    }

    //表格
    .el-table {
      color: rgba(3, 10, 26, 0.85);

      .el-table__body-header, .el-table__header, .el-table__body {
        th {
          background-color: rgb(250, 250, 250);
          font-weight: 500;
        }

        //th:nth-child(2)::before, th:nth-child(3)::before, th:nth-child(4)::before, th:nth-child(5)::before, th:nth-child(6)::before, th:nth-child(7)::before {
        //  content: '';
        //  width: 1px;
        //  height: 1.6em;
        //  position: absolute;
        //  left: 0;
        //  top: 50%;
        //  transform: translateY(-50%);
        //  background-color: rgba(0, 0, 0, 0.06);
        //}
      }

      th, td {
        padding: 0;
      }

      .cell {
        padding: 14px 16px;

        .btn {
          display: inline-block;
          color: #307FE2;
          margin-right: 14px;
          cursor: pointer;
        }

        .confBox {
          display: flex;
          align-items: center;
          flex-wrap: wrap;

          .contBox {
            display: flex;
            align-items: center;
          }

          .contItem {
            /*width: 100px;*/
            max-width: 150px;
            height: 60px;
            padding: 0 5px;
            background-color: #f6f6f6;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin: 5px 10px;
            //color: #ccc;

            .name {
              font-size: 13px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }


            .time, .valUnit {
              font-size: 12px;
            }

            .val {
              font-size: 16px;
            }

            .valcopy {
              font-size: 12px;
              text-align: left !important;
              line-height: 14px;
            }
          }
        }


        .level {
          width: 72px;
          height: 22px;
          text-align: center;
          line-height: 22px;
          font-size: 12px;
        }

        .level1 {
          background-color: rgb(255, 242, 240);
          border: 1px solid rgb(243, 66, 66);
          color: rgb(243, 66, 66);
        }

        .level2 {
          background-color: rgb(255, 242, 240);
          border: 1px solid rgb(246, 163, 0);
          color: rgb(246, 163, 0);
        }

        .level3 {
          background-color: rgba(255, 242, 240, 0.5);
          border: 1px solid rgba(255, 220, 0, 1);
          color: rgba(255, 220, 0, 1);
        }

        .level4 {
          background-color: rgb(255, 242, 240);
          border: 1px solid rgb(147, 140, 255);
          color: rgb(147, 140, 255);
        }

        .level5 {
          background-color: rgb(255, 242, 240);
          border: 1px solid rgb(103, 194, 58);
          color: rgb(103, 194, 58);
        }

      }
    }

    //分页
    .pag {
      width: 100%;
      height: 32px;
      padding: 16px 0 0 0;

      .el-pagination {
        float: right;
        margin-right: 12px;
      }
    }
  }

  .el-dialog {
    .el-table {
      .el-table__header, .el-table__body {
        th {
          background-color: rgb(250, 250, 250);
          font-weight: 500;
        }

        //th:nth-child(2)::before, th:nth-child(3)::before, th:nth-child(4)::before, th:nth-child(5)::before, th:nth-child(6)::before, th:nth-child(7)::before {
        //  content: '';
        //  width: 1px;
        //  height: 1.6em;
        //  position: absolute;
        //  left: 0;
        //  top: 50%;
        //  transform: translateY(-50%);
        //  background-color: rgba(0, 0, 0, 0.06);
        //}
      }
    }
  }
}

.adressClass {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-left: 4px;
}
</style>
