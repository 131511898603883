import { api } from '@/api'

// 查询用电箱管理系统-用电箱型号管理列表
export function listModel(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/model/page',
    method: 'get',
    params: query,
  })
}

// 查询用电箱管理系统-用电箱型号管理详细
export function getModel(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/model/detail/' + id,
    method: 'get',
  })
}

// 新增用电箱管理系统-用电箱型号管理
export function addModel(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/model/add',
    method: 'post',
    data: data,
  })
}

// 修改用电箱管理系统-用电箱型号管理
export function updateModel(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/model/update',
    method: 'post',
    data: data,
  })
}

// 删除用电箱管理系统-用电箱型号管理
export function delModel(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/model/remove/' + id,
    method: 'post',
  })
}
