import { api } from '@/api'

// 查询用电箱管理系统-用户管理列表
export function listUser(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/user/page',
    method: 'get',
    params: query,
  })
}

// 查询用电箱管理系统-用户管理详细
export function getUser(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/user/detail/' + id,
    method: 'get',
  })
}

// 新增用电箱管理系统-用户管理
export function addUser(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/user/add',
    method: 'post',
    data: data,
  })
}

// 修改用电箱管理系统-用户管理
export function updateUser(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/user/update',
    method: 'post',
    data: data,
  })
}

// 删除用电箱管理系统-用户管理
export function delUser(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/user/remove/' + id,
    method: 'post',
  })
}
