<template>
  <div class="powerStationVideoScreen">
    <el-carousel :autoplay="false" :initial-index="1">
      <el-carousel-item v-for="(item,index) in videoList" :key="index">
        <div class="videoBox">
          <video :src="item.url" autoplay="autoplay" loop muted></video>
          <p>{{ item.name }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-icon class="close" @click="backClick">
      <CloseBold/>
    </el-icon>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {reactive, ref} from "vue"
import {ArrowLeftBold, ArrowRightBold, CloseBold} from '@element-plus/icons-vue'
import router from "@/router";

const backClick = function () {
  router.push('/admin/energyStationVideo')
}

let videoList = ref([
  {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/mov_bbb.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  }, {
    name: 'xxx视频监控',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  },
])


let idx = ref(0)


const leftClick = function () {
  if (idx.value !== 0) {
    idx.value = idx.value - 1
  }
}

const rightClick = function () {
  if (idx.value !== (videoList.value.length - 1)) {
    idx.value = idx.value + 1
  }
}

</script>

<style lang="less">
.powerStationVideoScreen {
  width: calc(100vw - 240px);
  height: calc(100vh - 160px);
  background: #000;
  padding: 80px 120px;
  position: relative;

  .el-carousel {
    width: 100%;
    height: 100%;


    .el-carousel__arrow {
      display: block !important;
      width: 48px;
      height: 48px;
      background: #2F2F2F;
      color: #FFFFFF;
      line-height: 48px;
      border-radius: 6px;
      position: absolute;

      .el-icon {
        font-size: 20px;
        font-weight: 800;
        transform: translateY(6px);
      }
    }

    .el-carousel__arrow--left {
      left: 0;
    }

    .el-carousel__arrow--right {
      right: 0;
    }

    .el-carousel__indicators.el-carousel__indicators--horizontal {
      display: none;
    }

    .el-carousel__container {
      height: 100%;
    }

    .videoBox {
      width: calc(100% - 200px);
      height: 100%;
      margin-left: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      video {
        width: 100%;
        max-width: 100%;
        max-height: calc(100% - 30px);
      }

      p {
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }

  .close {
    position: absolute;
    font-size: 18px;
    color: #fff;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
}
</style>
