<template>
  <div class="homeSearchScreen">
    <div class="largeScreenContent">
      <!--单个查询-->
      <div v-if="indexDataObj.queryGroupName === ''" class="largeScreenContent2">
        <div class="fullScreenHead">
          <p>{{
              indexDataObj.title ? indexDataObj.title : '--'
            }}</p>
          <!--          <el-button @click="exitFullscreen">-->
          <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/back.png"-->
          <!--                 style="width: 16px;height: 16px;margin-right: 7px">返回-->
          <!--          </el-button>-->
        </div>
        <!--方案查询-->
        <div v-show="router.currentRoute.value.query.queryType!=1"
             style="height: calc(100% - 56px);width: 100%;overflow: hidden">
          <div class="searchScreen">
            <div class="leftSearch">
              <div class="timeUnitBox">
                <span style="margin-left: 20px">数据间隔： </span>
                <el-radio-group v-model="indexDataObj.timeUnit" @change="timeUnitChange">
                  <el-radio value="5m">5分钟</el-radio>
                  <el-radio value="15m">15分钟</el-radio>
                  <el-radio value="1d">日</el-radio>
                </el-radio-group>
                <div class="timeUnitSelectBox" style="width: 70px;">
                  <el-select v-model="indexDataObj.timeUnit" :teleported="false" placeholder="请选择数据间隔"
                             @change="timeUnitChange()">
                    <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value" :value="item.key"/>
                  </el-select>
                  <p style="display:flex;align-items: center;position: absolute;left: 0;top: 2px" @click.stop="">
                    <span>更多</span>
                    <!-- 自定义图标 -->
                    <el-icon style="margin-left: 5px;" @click="">
                      <RefreshRight/>
                    </el-icon>
                  </p>
                </div>
              </div>
              <div class="timeFlagBox">
                <span style="margin-left: 20px">时间： </span>
                <el-radio-group v-model="indexDataObj.timeFlag" @change="timeFlagSelect">
                  <el-radio value="1H">1小时</el-radio>
                  <el-radio value="1D">日</el-radio>
                  <el-radio value="1W">周</el-radio>
                </el-radio-group>
                <div class="timeFlagSelectBox">
                  <el-select v-model="indexDataObj.timeFlag" :teleported="false" clearable
                             @change="timeFlagSelect()">
                    <el-option label="自定义时间段" value="-1"></el-option>
                    <el-option :disabled="indexDataObj.chatType==4?false:true" label="瞬时" value="now"></el-option>
                    <el-option label="近1小时" value="1H"></el-option>
                    <el-option label="近12小时" value="12H"></el-option>
                    <el-option label="近一天" value="1D"></el-option>
                    <el-option label="近3天" value="3D"></el-option>
                    <el-option label="近1周" value="1W"></el-option>
                    <el-option label="本周" value="THIS_WEEK"></el-option>
                    <el-option label="本月" value="1M"></el-option>
                    <el-option label="本季" value="1Q"></el-option>
                  </el-select>
                  <p style="display:flex;align-items: center;position: absolute;left: 0;top: 2px">
                    <span>更多</span>
                    <!-- 自定义图标 -->
                    <el-icon style="margin-left: 5px;" @click="">
                      <RefreshRight/>
                    </el-icon>
                  </p>
                </div>
              </div>
              <el-date-picker v-if="indexDataObj.timeFlag=='-1'" v-model="indexDataObj.dateTime"
                              :disabled="indexDataObj.timeFlag==now?true:false"
                              :teleported="false"
                              end-placeholder="结束时间"
                              popper-class="date-style"
                              range-separator="→" start-placeholder="开始时间"
                              style="margin-left: 15px" type="datetimerange"
                              value-format="YYYY-MM-DD HH:mm:ss"
                              @change="timeUnitChange()">
              </el-date-picker>
            </div>
            <div class="rightSearch">
              <div class="refreshTimeBox">
                <!-- el-select 组件 -->
                <!--                <el-select v-model="refreshTime" :teleported="false" placeholder="请选择刷新时间"-->
                <!--                           @change="refreshTimeChange">-->
                <!--                  <el-option :value="60" label="一分钟"/>-->
                <!--                  <el-option :value="3600" label="一小时"/>-->
                <!--                  <el-option :value="86400" label="一天"/>-->
                <!--                  <el-option :value="25920000" label="一月"/>-->
                <!--                  <el-option :value="311040000" label="一年"/>-->
                <!--                </el-select>-->
                <!-- 自定义图标 -->
                <!--                <el-icon>-->
                <!--                  <RefreshRight/>-->
                <!--                </el-icon>-->

                <el-tooltip content="是否刷新">
                  <el-switch v-model="isRefresh" @change="isRefreshChange"/>
                </el-tooltip>
              </div>
              <!--              <el-button type="primary" @click="exportFun()">导出</el-button>-->
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/exportIcon.png"
                   style="margin-left: 16px;"
                   @click="exportFun()">
              <!--              <el-icon style="margin:0 16px" @click="screenClick">-->
              <!--                <FullScreen/>-->
              <!--              </el-icon>-->
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/screenIcon.png"
                   style="margin:0 16px 0 10px"
                   @click="screenClick">
            </div>
          </div>
          <div class="chart chartOne"
               style="height:calc(100% - 64px);width:calc(100% - 40px);margin-top:10px;padding:0 20px"></div>
        </div>
        <!--单独查询-->
        <div v-show="router.currentRoute.value.query.queryType==1"
             style="height: calc(100% - 56px);width: 100%;overflow: hidden">
          <div class="head" style="border-bottom: 1px solid rgba(0,0,0,0.08)">
            <div class="tab">
              <p :class="showFlag?'active':''" @click="showClick(1)">图表</p>
              <p :class="!showFlag?'active':''" @click="showClick(2)">表格</p>
            </div>
          </div>
          <div v-show="showFlag" class="searchScreen">
            <div class="leftSearch">
              <div class="timeUnitBox">
                <span style="margin-left: 20px">数据间隔： </span>
                <el-radio-group v-model="indexDataObj.timeUnit" @change="timeUnitChange">
                  <el-radio value="5m">5分钟</el-radio>
                  <el-radio value="15m">15分钟</el-radio>
                  <el-radio value="1d">日</el-radio>
                </el-radio-group>
                <div class="timeUnitSelectBox" style="width: 70px;">
                  <el-select v-model="indexDataObj.timeUnit" :teleported="false" placeholder="请选择数据间隔"
                             @change="timeUnitChange()">
                    <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value" :value="item.key"/>
                  </el-select>
                  <p style="display:flex;align-items: center;position: absolute;left: 0;top: 2px" @click.stop="">
                    <span>更多</span>
                    <!-- 自定义图标 -->
                    <el-icon style="margin-left: 5px;" @click="">
                      <RefreshRight/>
                    </el-icon>
                  </p>
                </div>
              </div>
              <div class="timeFlagBox">
                <span style="margin-left: 20px">时间： </span>
                <el-radio-group v-model="indexDataObj.timeFlag" @change="timeFlagSelect">
                  <el-radio value="1H">1小时</el-radio>
                  <el-radio value="1D">日</el-radio>
                  <el-radio value="1W">周</el-radio>
                </el-radio-group>
                <div class="timeFlagSelectBox">
                  <el-select v-model="indexDataObj.timeFlag" :teleported="false" clearable
                             @change="timeFlagSelect()">
                    <el-option label="自定义时间段" value="-1"></el-option>
                    <el-option :disabled="indexDataObj.chatType==4?false:true" label="瞬时" value="now"></el-option>
                    <el-option label="近1小时" value="1H"></el-option>
                    <el-option label="近12小时" value="12H"></el-option>
                    <el-option label="近一天" value="1D"></el-option>
                    <el-option label="近3天" value="3D"></el-option>
                    <el-option label="近1周" value="1W"></el-option>
                    <el-option label="本周" value="THIS_WEEK"></el-option>
                    <el-option label="本月" value="1M"></el-option>
                    <el-option label="本季" value="1Q"></el-option>
                  </el-select>
                  <p style="display:flex;align-items: center;position: absolute;left: 0;top: 2px">
                    <span>更多</span>
                    <!-- 自定义图标 -->
                    <el-icon style="margin-left: 5px;" @click="">
                      <RefreshRight/>
                    </el-icon>
                  </p>
                </div>
              </div>
              <el-date-picker v-if="indexDataObj.timeFlag=='-1'" v-model="indexDataObj.dateTime"
                              :disabled="indexDataObj.timeFlag==now?true:false"
                              :teleported="false"
                              end-placeholder="结束时间"
                              popper-class="date-style"
                              range-separator="→" start-placeholder="开始时间"
                              style="margin-left: 15px" type="datetimerange"
                              value-format="YYYY-MM-DD HH:mm:ss"
                              @change="timeUnitChange()">
              </el-date-picker>
            </div>
            <div class="rightSearch">
              <div class="refreshTimeBox">
                <!-- el-select 组件 -->
                <!--                <el-select v-model="refreshTime" :teleported="false" placeholder="请选择刷新时间"-->
                <!--                           @change="refreshTimeChange">-->
                <!--                  <el-option :value="60" label="一分钟"/>-->
                <!--                  <el-option :value="3600" label="一小时"/>-->
                <!--                  <el-option :value="86400" label="一天"/>-->
                <!--                  <el-option :value="25920000" label="一月"/>-->
                <!--                  <el-option :value="311040000" label="一年"/>-->
                <!--                </el-select>-->
                <!-- 自定义图标 -->
                <!--                <el-icon @click="">-->
                <!--                  <RefreshRight/>-->
                <!--                </el-icon>-->
                <el-tooltip content="是否刷新">
                  <el-switch v-model="isRefresh" @change="isRefreshChange"/>
                </el-tooltip>
              </div>
              <!--              <el-button type="primary" @click="exportFun()">导出</el-button>-->
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/exportIcon.png"
                   style="margin-left: 16px;"
                   @click="exportFun()">
              <!--              <el-icon style="margin:0 16px" @click="screenClick">-->
              <!--                <FullScreen/>-->
              <!--              </el-icon>-->
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/screenIcon.png"
                   style="margin:0 16px 0 10px"
                   @click="screenClick">
            </div>
          </div>
          <div v-show="showFlag" class="chart chartOne"
               style="height:calc(100% - 151px);width:calc(100% - 40px);margin-top:10px;padding:0 20px"></div>
          <!--表格-->
          <div v-show="!showFlag&&tableData.length!==0" class="tableCont">
            <div v-for="(item,index) in tableData" :key="index" class="tableItem">
              <div class="tit">{{ item.deviceName }}</div>
              <el-table :data="item.dataMetricsVO" style="width: calc(100% - 48px);" table-layout="auto">
                <el-table-column label="上报时间" prop="ts"></el-table-column>
                <el-table-column v-for="(item_a,index_a) in item.labelArr" :key="index_a" :label="item_a"
                                 :prop="item_a"></el-table-column>
              </el-table>
              <el-button v-if="item.dataMetricsVO.length>0" @click="moreClick(index)">加载更多</el-button>
            </div>
          </div>
          <el-empty v-if="tableData.length===0&&echartsData.length===0"
                    class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"/>
        </div>
      </div>
      <!--图表分组的大列表-->
      <el-carousel v-if="indexDataObj.queryGroupName!==''" ref="carouselRef"
                   :autoplay="false" :loop="false"
                   arrow="always" class="carouselList"
                   style="calc(100% - 140px)" @change="carouselChange">
        <el-carousel-item v-for="(item_c,index_c) in carouselList" :key="index_c">
          <div class="largeScreenContent2">
            <div class="fullScreenHead fullScreenHead2" style="display:flex">
              <p>{{ item_c.title ? item_c.title : '--' }}</p>
              <!--              <el-button @click="exitFullscreen">-->
              <!--                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/back.png"-->
              <!--                     style="width: 16px;height: 16px;margin-right: 7px">返回-->
              <!--              </el-button>-->
            </div>
            <div class="searchScreen searchScreen2" style="display:flex">
              <div class="leftSearch">
                <div class="timeUnitBox">
                  <span style="margin-left: 20px">数据间隔： </span>
                  <el-radio-group v-model="item_c.timeUnit" @change="timeUnitChange2(index_c,item_c)">
                    <el-radio value="5m">5分钟</el-radio>
                    <el-radio value="15m">15分钟</el-radio>
                    <el-radio value="1d">日</el-radio>
                  </el-radio-group>
                  <div class="timeUnitSelectBox" style="width: 70px;">
                    <el-select v-model="item_c.timeUnit" :teleported="false" placeholder="请选择数据间隔"
                               @change="timeUnitChange2(index_c,item_c)">
                      <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value"
                                 :value="item.key"/>
                    </el-select>
                    <p style="display:flex;align-items: center;position: absolute;left: 0;top: 2px" @click.stop="">
                      <span>更多</span>
                      <!-- 自定义图标 -->
                      <el-icon style="margin-left: 5px;" @click="">
                        <RefreshRight/>
                      </el-icon>
                    </p>
                  </div>
                </div>
                <div class="timeFlagBox">
                  <span style="margin-left: 20px">时间： </span>
                  <el-radio-group v-model="item_c.timeFlag" @change="timeFlagSelect2(index_c,item_c)">
                    <el-radio value="1H">1小时</el-radio>
                    <el-radio value="1D">日</el-radio>
                    <el-radio value="1W">周</el-radio>
                  </el-radio-group>
                  <div class="timeFlagSelectBox">
                    <el-select v-model="item_c.timeFlag" :teleported="false" clearable
                               @change="timeFlagSelect2(index_c,item_c)">
                      <el-option label="自定义时间段" value="-1"></el-option>
                      <el-option :disabled="item_c.chatType==4?false:true" label="瞬时" value="now"></el-option>
                      <el-option label="近1小时" value="1H"></el-option>
                      <el-option label="近12小时" value="12H"></el-option>
                      <el-option label="近一天" value="1D"></el-option>
                      <el-option label="近3天" value="3D"></el-option>
                      <el-option label="近1周" value="1W"></el-option>
                      <el-option label="本周" value="THIS_WEEK"></el-option>
                      <el-option label="本月" value="1M"></el-option>
                      <el-option label="本季" value="1Q"></el-option>
                    </el-select>
                    <p style="display:flex;align-items: center;position: absolute;left: 0;top: 2px">
                      <span>更多</span>
                      <!-- 自定义图标 -->
                      <el-icon style="margin-left: 5px;" @click="">
                        <RefreshRight/>
                      </el-icon>
                    </p>
                  </div>
                </div>
                <el-date-picker v-if="item_c.timeFlag=='-1'" v-model="item_c.dateTime"
                                :disabled="item_c.timeFlag==now?true:false"
                                :teleported="false"
                                end-placeholder="结束时间"
                                popper-class="date-style"
                                range-separator="→" start-placeholder="开始时间"
                                style="margin-left: 15px" type="datetimerange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                @change="timeUnitChange2(index_c,item_c)">
                </el-date-picker>
              </div>
              <div class="rightSearch">
                <div class="refreshTimeBox">
                  <!-- el-select 组件 -->
                  <!--                  <el-select v-model="refreshTime" :teleported="false" placeholder="请选择刷新时间"-->
                  <!--                             @change="refreshTimeChange">-->
                  <!--                    <el-option :value="60" label="一分钟"/>-->
                  <!--                    <el-option :value="3600" label="一小时"/>-->
                  <!--                    <el-option :value="86400" label="一天"/>-->
                  <!--                    <el-option :value="25920000" label="一月"/>-->
                  <!--                    <el-option :value="311040000" label="一年"/>-->
                  <!--                  </el-select>-->
                  <!-- 自定义图标 -->
                  <!--                  <el-icon>-->
                  <!--                    <RefreshRight/>-->
                  <!--                  </el-icon>-->
                  <el-tooltip content="是否刷新">
                    <el-switch v-model="isRefresh" @change="isRefreshChange"/>
                  </el-tooltip>
                </div>
                <!--                <el-button type="primary" @click="exportFun(index_c)">导出</el-button>-->
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/exportIcon.png"
                     style="margin-left: 16px;"
                     @click="exportFun(index_c)">
                <!--                <el-icon style="margin:0 16px" @click="screenClick">-->
                <!--                  <FullScreen/>-->
                <!--                </el-icon>-->
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/screenIcon.png"
                     style="margin:0 16px 0 10px"
                     @click="screenClick">
              </div>
            </div>
            <!--            <p style="font-weight: 600;font-size: 16px;color: #333333;text-align: center;margin: 10px 0">-->
            <!--              {{ item_c.queryName }}</p>-->
            <div
                :class="'chartc'+index_c"
                class="chart"
                style="height:calc(100% - 110px);width:calc(100% - 140px);padding:0 70px"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!--图表分组的小列表-下面-->
      <el-carousel v-if="indexDataObj.queryGroupName!==''" ref="carouselItemRef"
                   :autoplay="false" :loop="false" arrow="never"
                   class="carouselItemList" height="120px">
        <el-carousel-item v-for="(item_v,index_v) in carouselItemList" :key="index_v">
          <div v-for="(item_c,index_c) in item_v" :key="index_c"
               :class="carouselIdx===index_v*6+index_c?'itemBoxActive':''"
               class="itemBox"
               @click="groupItemClick(index_v,index_c)">
            <p class="itemPlanName">{{ item_c.title ? item_c.title : '--' }}</p>
            <div class="chartItemBox">
              <div :class="'chartItem'+index_v+'_'+index_c" class="chartItem"></div>
            </div>
          </div>
        </el-carousel-item>
        <p class="prevBtn" @click="carouselItemPrevClick">
          <el-icon>
            <ArrowLeft/>
          </el-icon>
        </p>
        <p class="nextBtn" @click="carouselItemNextClick">
          <el-icon>
            <ArrowRight/>
          </el-icon>
        </p>
      </el-carousel>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref, watch} from "vue"
import {
  addQueryGroupListApi, delQueryGroupListApi,
  getProjectOverviewApi,
  getQueryGroupDataApi,
  getQueryGroupListApi
} from "@/api/modules/project";
import qs from "qs";
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {FullScreen, RefreshRight} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {delHomeQueryApi, indexData} from "@/api/modules/homePage";
import * as echarts from "echarts";
import moment from "moment";
import {
  exportDataApi, exportUseDataApi,
  getChartDataByQueryIdApi,
  getEchartsDataApi,
  getIntervalListApi,
  getSearchListApi, getTableDataApi
} from "@/api/modules/dataSearch";
import letterList from "@/assets/js/letterList";
import {chatSearchApi, exportComDataApi} from "@/api/modules/compoundQuery";

let router = useRouter()

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}


// 获取图表数据------------------------------------------------------------------------------
// 获取首页图表数据
const getIndexData = async function () {
  openFullScreen()
  await indexData().then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      indexDataObj.value = res.data.result.dataSearchList[router.currentRoute.value.query.idx]
      // 处理加载数据查询图表
      if (indexDataObj.value.queryGroupName !== '') {
        enterGroupScreenFun(indexDataObj.value)
      } else {
        queryChartLoad()
      }
      document.title = indexDataObj.value.queryName
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取项目图表数据
const getIndexData2 = async function () {
  openFullScreen()
  await getProjectOverviewApi(qs.stringify({
    projectId: router.currentRoute.value.query.projectId
  })).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      indexDataObj.value = res.data.result.dataSearchList[router.currentRoute.value.query.idx]
      // 处理加载数据查询图表
      if (indexDataObj.value.queryGroupName !== '') {
        enterGroupScreenFun(indexDataObj.value)
      } else {
        queryChartLoad()
      }
      document.title = indexDataObj.value.queryName
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 处理图表数据------------------------------------------------------------------------------
let indexDataObj = ref({})  //不分组数据
// 处理图表数据
const queryChartLoad = function () {
  indexDataObj.value.dateTime = [indexDataObj.value.startTime, indexDataObj.value.endTime]
  // 判断是否为饼图
  if (indexDataObj.value.chatType == 4) {
    echartsDataX.value = null
    let list2 = indexDataObj.value.pieList
    for (let i = 0; i < list2.length; i++) {
      list2[i].name = list2[i].key
      list2[i].value = Number(list2[i].value)
      list2[i].itemStyle = {color: colorArr[i]}
      list2[i].label = {formatter: `{b}: {d}%\n${list2[i].time}`}
      list2[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[i].time}`}
    }
    echartsData.value = [{
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      itemStyle: {
        borderRadius: 10,
        borderColor: '#fff',
        borderWidth: 2,
      },
      label: {
        show: true,
        lineHeight: 18
      },
      data: list2
    }]
  } else {
    echartsDataX.value = indexDataObj.value.xAxis
    echartsData.value = indexDataObj.value.yAxis
    let isThan = false
    for (let i = 0; i < echartsData.value.length; i++) {
      // 把每条数据名称改为设备+指标
      echartsData.value[i].name = echartsData.value[i].deviceName + '-' + echartsData.value[i].titleName
      echartsData.value[i].sampling = 'lttb'

      if (echartsData.value[i].name.includes('同比') || echartsData.value[i].name.includes('环比')) {
        isThan = true
      }

      // 数据处理为小数点两位
      for (let k = 0; k < echartsData.value[i].data.length; k++) {
        echartsData.value[i].data[k] = Number(echartsData.value[i].data[k]).toFixed(2)
      }

      // 处理最大值最小值
      if (echartsData.value[i].valueType == 0) {
        echartsData.value[i].markPoint = {
          data: [{type: 'max', name: 'Max'}]
        }
      }
      if (echartsData.value[i].valueType == 1) {
        echartsData.value[i].markPoint = {
          data: [{type: 'min', name: 'Min'}]
        }
      }
    }
    // 图表类型样式
    // 如果是属于折线图
    if (indexDataObj.value.chatType == 1) {
      for (let i = 0; i < echartsData.value.length; i++) {
        echartsData.value[i].type = 'line'
        // 处理同环比的不同样式
        judgmentRatio(echartsData.value[i], i, isThan)
      }
    }
    // 如果是属于柱状图
    if (indexDataObj.value.chatType == 0) {
      for (let i = 0; i < echartsData.value.length; i++) {
        echartsData.value[i].type = 'bar'
        // 处理同环比的不同样式
        judgmentRatio(echartsData.value[i], i, isThan)
      }
    }
  }

  nextTick(() => {
    chartLoad()
  })
}
// 判断同环比方法
const judgmentRatio = function (item, i, isThan) {
  // 如果是带同环比--------------------------------------------
  if (isThan) {
    if (item.name.includes('同比') || item.name.includes('环比')) {
      // 判断当前是同环比********************
      item.color = colorArr3[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
      }
      item.symbol = 'circle'
      item.symbolSize = 7
    } else {
      // 判断当前不是同环比********************
      item.color = colorArr[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
        shadowColor: colorArr2[Math.floor(i / 2)],
        shadowBlur: 4
      }
      item.symbolSize = 10
    }
  } else {
    // 如果不带同环比--------------------------------------------
    item.color = colorArr[i]
    item.lineStyle = {
      width: 3,
      shadowColor: colorArr2[i],
      shadowBlur: 4
    }
    item.symbolSize = 7
  }

}


let isRefresh = ref(true)  //是否刷新
const isRefreshChange = function () {
  if (isRefresh.value) {
    refreshTimeChange(60)
  } else {
    clearInterval(timer)
  }
}
let refreshTime = ref(60)  //刷新时间
let timeUnit = ref()  //时间间隔
let timeUnit2 = ref([])  //时间间隔
// 刷新时间选择事件
var timer = setInterval(() => {
  if (indexDataObj.value.queryGroupName || router.currentRoute.value.query.type) {
    // enterGroupScreenFun(indexDataObj.value)
    let data
    if (indexDataObj.value.queryGroupName) {
      if (carouselList.value[carouselIdx.value].yom == 1) {
        carouselList.value[carouselIdx.value].lastStartTime = moment(carouselList.value[carouselIdx.value].startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
        carouselList.value[carouselIdx.value].lastEndTime = moment(carouselList.value[carouselIdx.value].endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      } else if (carouselList.value[carouselIdx.value].yom == 2) {
        if (indexDataObj.value.timeFlag === '1M') {
          carouselList.value[carouselIdx.value].lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
          carouselList.value[carouselIdx.value].lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
        } else if (indexDataObj.value.timeFlag === '1Q') {
          carouselList.value[carouselIdx.value].lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
          carouselList.value[carouselIdx.value].lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        } else {
          let timeLength = moment(carouselList.value[carouselIdx.value].endTime).diff(carouselList.value[carouselIdx.value].startTime, 'second')
          carouselList.value[carouselIdx.value].lastStartTime = moment(carouselList.value[carouselIdx.value].startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
          carouselList.value[carouselIdx.value].lastEndTime = moment(carouselList.value[carouselIdx.value].endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      data = {
        exportId: carouselList.value[carouselIdx.value].exportId,
        groupType: carouselList.value[carouselIdx.value].groupType,
        timeUnit: carouselList.value[carouselIdx.value].timeUnit,
        startTime: carouselList.value[carouselIdx.value].dateTime[0],
        endTime: carouselList.value[carouselIdx.value].dateTime[1],
        lastStartTime: carouselList.value[carouselIdx.value].lastStartTime,
        lastEndTime: carouselList.value[carouselIdx.value].lastEndTime,
      }
    } else {
      if (indexDataObj.value.yom == 1) {
        indexDataObj.value.lastStartTime = moment(indexDataObj.value.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
        indexDataObj.value.lastEndTime = moment(indexDataObj.value.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      } else if (indexDataObj.value.yom == 2) {
        if (indexDataObj.value.timeFlag === '1M') {
          indexDataObj.value.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
          indexDataObj.value.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
        } else if (indexDataObj.value.timeFlag === '1Q') {
          indexDataObj.value.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
          indexDataObj.value.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        } else {
          let timeLength = moment(indexDataObj.value.endTime).diff(indexDataObj.value.startTime, 'second')
          indexDataObj.value.lastStartTime = moment(indexDataObj.value.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
          indexDataObj.value.lastEndTime = moment(indexDataObj.value.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      data = {
        exportId: indexDataObj.value.exportId,
        groupType: indexDataObj.value.groupType,
        timeUnit: indexDataObj.value.timeUnit,
        startTime: indexDataObj.value.dateTime[0],
        endTime: indexDataObj.value.dateTime[1],
        lastStartTime: indexDataObj.value.lastStartTime,
        lastEndTime: indexDataObj.value.lastEndTime,
      }
    }
    // // 刷新当前选中的图表
    getChartDataByQueryIdApi(qs.stringify(data)).then(res => {
      if (res.data.code === 0) {
        echartsDataX.value[carouselIdx.value] = res.data.result.xAxis
        if (res.data.result.chatType == 4) {
          echartsData.value[carouselcarouselIdx.value.value].data = res.data.result.pieList
          for (let i = 0; i < res.data.result.pieList.length; i++) {
            echartsData.value[carouselIdx.value][i].data.name = res.data.result.pieList[i].key
            echartsData.value[carouselIdx.value][i].data.value = Number(res.data.result.pieList[i].value)
            echartsData.value[carouselIdx.value][i].data.itemStyle = {color: colorArr[i]}
            echartsData.value[carouselIdx.value][i].data.label = {formatter: `{b}:{d}%\n${res.data.result.pieList[i].time}`}
            echartsData.value[carouselIdx.value][i].data.tooltip = {formatter: `{b}:{c}%<br />占比: {d}%<br />${res.data.result.pieList[i].time}`}
          }
        } else {
          if (res.data.result.yAxis.length === 0) {
            for (let i = 0; i < echartsData.value[carouselIdx.value].length; i++) {
              echartsData.value[carouselIdx.value][i].data = []
            }
          }
          for (let i = 0; i < res.data.result.yAxis.length; i++) {
            for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
              echartsData.value[carouselIdx.value][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
            }
          }
        }
        nextTick(() => {
          chartLoad(carouselIdx.value)
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    if (router.currentRoute.value.query.uid) {
      // 取出查询的条件
      if (router.currentRoute.value.query.queryType == 1) {
        getEchartsDataFun()
        getTableDataFun()
      } else {
        getEchartsDataFun2()
      }
    } else {
      if (router.currentRoute.value.query.type == 1) {
        // 判断如果是首页数据
        getIndexData()
      } else {
        // 判断如果是项目数据
        getIndexData2()
      }
    }
  }
}, refreshTime.value * 1000)  // 定时器
const refreshTimeChange = function (e) {
  clearInterval(timer)
  setTimeout(() => {
    if (isRefresh.value) {
      clearInterval(timer)
      timer = setInterval(() => {
        if (indexDataObj.value.queryGroupName || router.currentRoute.value.query.type) {
          // enterGroupScreenFun(indexDataObj.value)
          let data
          if (indexDataObj.value.queryGroupName) {
            data = {
              exportId: carouselList.value[carouselIdx.value].exportId,
              groupType: carouselList.value[carouselIdx.value].groupType,
              timeUnit: carouselList.value[carouselIdx.value].timeUnit,
              startTime: carouselList.value[carouselIdx.value].dateTime[0],
              endTime: carouselList.value[carouselIdx.value].dateTime[1],
              lastStartTime: carouselList.value[carouselIdx.value].lastStartTime,
              lastEndTime: carouselList.value[carouselIdx.value].lastEndTime,
            }
          } else {
            data = {
              exportId: indexDataObj.value.exportId,
              groupType: indexDataObj.value.groupType,
              timeUnit: indexDataObj.value.timeUnit,
              startTime: indexDataObj.value.dateTime[0],
              endTime: indexDataObj.value.dateTime[1],
              lastStartTime: indexDataObj.value.lastStartTime,
              lastEndTime: indexDataObj.value.lastEndTime,
            }
          }

          // // 刷新当前选中的图表
          getChartDataByQueryIdApi(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              echartsDataX.value[carouselIdx.value] = res.data.result.xAxis
              if (res.data.result.chatType == 4) {
                echartsData.value[carouselIdx.value].data = res.data.result.pieList
                for (let i = 0; i < res.data.result.pieList.length; i++) {
                  echartsData.value[carouselIdx.value][i].data.name = res.data.result.pieList[i].key
                  echartsData.value[carouselIdx.value][i].data.value = Number(res.data.result.pieList[i].value)
                  echartsData.value[carouselIdx.value][i].data.itemStyle = {color: colorArr[i]}
                  echartsData.value[carouselIdx.value][i].data.label = {formatter: `{b}:{d}%\n${res.data.result.pieList[i].time}`}
                  echartsData.value[carouselIdx.value][i].data.tooltip = {formatter: `{b}:{c}%<br />占比: {d}%<br />${res.data.result.pieList[i].time}`}
                }
              } else {
                if (res.data.result.yAxis.length === 0) {
                  for (let i = 0; i < echartsData.value[carouselIdx.value].length; i++) {
                    echartsData.value[carouselIdx.value][i].data = []
                  }
                }
                for (let i = 0; i < res.data.result.yAxis.length; i++) {
                  for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
                    echartsData.value[carouselIdx.value][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
                  }
                }
              }
              nextTick(() => {
                chartLoad(carouselIdx.value)
              })
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          if (router.currentRoute.value.query.uid) {
            // 取出查询的条件
            if (router.currentRoute.value.query.queryType == 1) {
              getEchartsDataFun()
              getTableDataFun()
            } else {
              getEchartsDataFun2()
            }
          } else {
            if (router.currentRoute.value.query.type == 1) {
              // 判断如果是首页数据
              getIndexData()
            } else {
              // 判断如果是项目数据
              getIndexData2()
            }
          }
        }
      }, e * 1000)
    }
  }, e * 1000)
}
// 时间间隔选择事件
const timeUnitChange = function () {
  // 计算同环比时间
  if (indexDataObj.value.yom == 1) {
    indexDataObj.value.lastStartTime = moment(indexDataObj.value.dateTime[0]).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    indexDataObj.value.lastEndTime = moment(indexDataObj.value.dateTime[1]).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (indexDataObj.value.yom == 2) {
    if (indexDataObj.value.timeFlag === '1M') {
      indexDataObj.value.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      indexDataObj.value.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (indexDataObj.value.timeFlag === '1Q') {
      indexDataObj.value.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      indexDataObj.value.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(indexDataObj.value.dateTime[1]).diff(indexDataObj.value.dateTime[0], 'second')
      indexDataObj.value.lastStartTime = moment(indexDataObj.value.dateTime[0]).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      indexDataObj.value.lastEndTime = moment(indexDataObj.value.dateTime[1]).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  if (router.currentRoute.value.query.uid) {
    let data = searchCondition.value.echartSearchData
    data.timeUnit = indexDataObj.value.timeUnit
    data.startTime = indexDataObj.value.dateTime[0]
    data.endTime = indexDataObj.value.dateTime[1]
    data.lastStartTime = indexDataObj.value.lastStartTime
    data.lastEndTime = indexDataObj.value.lastEndTime
    data.timeFlag = indexDataObj.value.timeFlag
    if (router.currentRoute.value.query.queryType == 1) {
      getEchartsDataApi(data).then(res => {
        if (res.data.code === 0) {
          indexDataObj.value = res.data.result
          queryChartLoad()
          document.title = '简单查询'
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
      tableData.value = []
      let dataArr = JSON.parse(JSON.stringify(searchCondition.value.tableSearchData))
      for (let i = 0; i < dataArr.length; i++) {
        dataArr[i].timeUnit = indexDataObj.value.timeUnit
        dataArr[i].timeFlag = indexDataObj.value.timeFlag
        dataArr[i].startTime = indexDataObj.value.dateTime[0]
        dataArr[i].endTime = indexDataObj.value.dateTime[1]
        dataArr[i].lastStartTime = indexDataObj.value.lastStartTime
        dataArr[i].lastEndTime = indexDataObj.value.lastEndTime
        getTableDataApi(dataArr[i]).then(res => {
          if (res.data.code === 0) {
            let arr = res.data.result.list
            for (let i = 0; i < arr.length; i++) {
              arr[i].labelArr = []
              for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
                arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
                for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
                  arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
                  arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
                  let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
                  arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
                }
              }
            }
            tableData.value.push(...arr)
          } else {
            ElMessage({
              type: 'error',
              message: res.data.message
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    } else {
      chatSearchApi(data).then(res => {
        if (res.data.code === 0) {
          indexDataObj.value = res.data.result
          queryChartLoad()
          document.title = '复合查询'
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }
  } else {
    getChartDataByQueryIdApi(qs.stringify({
      // queryId: indexDataObj.value.queryId,
      exportId: indexDataObj.value.exportId,
      groupType: indexDataObj.value.groupType,
      timeUnit: indexDataObj.value.timeUnit,
      startTime: indexDataObj.value.dateTime[0],
      endTime: indexDataObj.value.dateTime[1],
      lastStartTime: indexDataObj.value.lastStartTime,
      lastEndTime: indexDataObj.value.lastEndTime,
    })).then(res => {
      if (res.data.code === 0) {
        echartsDataX.value = res.data.result.xAxis
        if (res.data.result.chatType == 4) {
          echartsData.value.data = res.data.result.pieList
          for (let i = 0; i < res.data.result.pieList.length; i++) {
            echartsData.value[i].data.name = res.data.result.pieList[i].key
            echartsData.value[i].data.value = Number(res.data.result.pieList[i].value)
            echartsData.value[i].data.itemStyle = {color: colorArr[i]}
            echartsData.value[i].data.label = {formatter: `{b}:{d}%\n${res.data.result.pieList[i].time}`}
            echartsData.value[i].data.tooltip = {formatter: `{b}:{c}%<br />占比: {d}%<br />${res.data.result.pieList[i].time}`}
          }
        } else {
          // if (res.data.result.yAxis.length === 0) {
          for (let i = 0; i < echartsData.value.length; i++) {
            echartsData.value[i].data = []
            console.log(echartsData.value[i].data, 'aaa')
          }
          // }
          console.log(echartsData.value)
          console.log(res.data.result.yAxis)
          for (let i = 0; i < res.data.result.yAxis.length; i++) {
            for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
              echartsData.value[i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
            }
          }
        }
        nextTick(() => {
          chartLoad()
        })
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  refreshTimeChange(refreshTime.value)
}
// 分组图表时间间隔选择事件
const timeUnitChange2 = function (idx, item) {
  console.log(item.dateTime)
  // 计算同环比时间
  if (item.yom == 1) {
    item.lastStartTime = moment(item.dateTime[0]).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.dateTime[1]).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom == 2) {
    if (indexDataObj.value.timeFlag === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (indexDataObj.value.timeFlag === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.dateTime[1]).diff(item.dateTime[0], 'second')
      item.lastStartTime = moment(item.dateTime[0]).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.dateTime[1]).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    // queryId: item.queryId,
    exportId: item.exportId,
    groupType: item.groupType,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.chatType == 4) {
        echartsData.value[idx].data = res.data.result.pieList
        for (let i = 0; i < res.data.result.pieList.length; i++) {
          echartsData.value[idx][i].data.name = res.data.result.pieList[i].key
          echartsData.value[idx][i].data.value = Number(res.data.result.pieList[i].value)
          echartsData.value[idx][i].data.itemStyle = {color: colorArr[i]}
          echartsData.value[idx][i].data.label = {formatter: `{b}:{d}%\n${res.data.result.pieList[i].time}`}
          echartsData.value[idx][i].data.tooltip = {formatter: `{b}:{c}%<br />占比: {d}%<br />${res.data.result.pieList[i].time}`}
        }
      } else {
        // if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
        // }
        for (let i = 0; i < res.data.result.yAxis.length; i++) {
          for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
            echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
          }
        }
      }
      nextTick(() => {
        chartLoad(idx)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 日期类型选择事件
const timeFlagSelect = function () {
  if (indexDataObj.value.timeFlag !== undefined) {
    if (indexDataObj.value.timeFlag === '-1') {
      indexDataObj.value.dateTime = []
    } else if (indexDataObj.value.timeFlag === '1H') {
      indexDataObj.value.dateTime = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (indexDataObj.value.timeFlag === '12H') {
      indexDataObj.value.dateTime = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (indexDataObj.value.timeFlag === '1D' || indexDataObj.value.timeFlag === 'now') {
      indexDataObj.value.dateTime = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (indexDataObj.value.timeFlag === '3D') {
      indexDataObj.value.dateTime = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (indexDataObj.value.timeFlag === '1W') {
      indexDataObj.value.dateTime = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (indexDataObj.value.timeFlag === 'THIS_WEEK') {
      indexDataObj.value.dateTime = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (indexDataObj.value.timeFlag === '1M') {
      indexDataObj.value.dateTime = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (indexDataObj.value.timeFlag === '1Q') {
      indexDataObj.value.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
      // indexDataObj.value.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
    }
    if (indexDataObj.value.timeFlag !== '-1') {
      // 计算同环比时间
      timeUnitChange()
    }
  }
}
// 分组图表日期类型选择事件
const timeFlagSelect2 = function (idx, item) {
  if (item.timeFlag !== undefined) {
    if (item.timeFlag === '-1') {
      item.dateTime = []
    } else if (item.timeFlag === '1H') {
      item.dateTime = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (item.timeFlag === '12H') {
      item.dateTime = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (item.timeFlag === '1D' || item.timeFlag === 'now') {
      item.dateTime = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (item.timeFlag === '3D') {
      item.dateTime = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (item.timeFlag === '1W') {
      item.dateTime = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (item.timeFlag === 'THIS_WEEK') {
      item.dateTime = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (item.timeFlag === '1M') {
      item.dateTime = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
    } else if (item.timeFlag === '1Q') {
      item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
      // item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
    }
    if (item.timeFlag !== '-1') {
      // 计算同环比时间
      timeUnitChange2(idx, item)
    }
  }
}


// 加载图表------------------------------------------------------------------------------
// 加载图表
let colorArr = ['rgba(69,125,252,1)', 'rgba(189,82,244,1)', 'rgba(0,229,166,1)', 'rgba(8,205,255,1)', 'rgba(249,74,171,1)', 'rgba(254, 44, 44, 1)', 'rgba(136, 228, 49, 1)', 'rgba(255, 144, 71, 1)', 'rgba(255, 187, 0, 1)', 'rgba(33, 120, 86, 1)', 'rgba(68, 68, 93, 1)', 'rgba(29, 33, 212, 1)', 'rgba(220, 122, 30, 1)']
let colorArr2 = ['rgba(69,125,252,0.2)', 'rgba(189,82,244,0.2)', 'rgba(0,229,166,0.2)', 'rgba(8,205,255,0.2)', 'rgba(249,74,171,0.2)', 'rgba(254, 44, 44, 0.2)', 'rgba(136, 228, 49, 0.2)', 'rgba(255, 144, 71, 0.2)', 'rgba(255, 187, 0, 0.2)', 'rgba(33, 120, 86, 0.2)', 'rgba(68, 68, 93, 0.2)', 'rgba(29, 33, 212, 0.2)', 'rgba(220, 122, 30, 0.2)']
// let colorArr3 = ['rgba(69,125,252,0.4)', 'rgba(189,82,244,0.4)', 'rgba(0,229,166,0.4)', 'rgba(8,205,255,0.4)', 'rgba(249,74,171,0.4)', 'rgba(254, 44, 44, 0.4)', 'rgba(136, 228, 49, 0.4)', 'rgba(255, 144, 71, 0.4)', 'rgba(255, 187, 0, 0.4)', 'rgba(33, 120, 86, 0.4)', 'rgba(68, 68, 93, 0.4)', 'rgba(29, 33, 212, 0.4)', 'rgba(220, 122, 30, 0.4)']
let colorArr3 = ['rgba(181, 203, 254, 1)', 'rgba(229, 186, 251, 1)', 'rgba(153, 244, 219, 1)', 'rgba(156, 235, 255, 1)', 'rgba(253, 182, 222, 1)', 'rgba(255, 171, 171, 1)', 'rgba(208, 244, 173, 1)', 'rgba(255, 209, 180, 1)', 'rgba(255, 228, 153, 1)', 'rgba(166, 201, 187, 1)', 'rgba(180, 180, 190, 1)', 'rgba(164, 166, 238, 1)', 'rgba(241, 202, 165, 1)']
const chartLoad = function (idx) {
  let chartDom
  if (router.currentRoute.value.query.idx !== undefined && carouselList.value.length > 0) {
    chartDom = document.querySelectorAll(`.chartc${idx}`);
  } else {
    chartDom = document.querySelectorAll('.chart.chartOne');
  }
  for (let i = 0; i < chartDom.length; i++) {
    var myChart = echarts.init(chartDom[i]);
    var option;
    option = {
      // toolbox: {
      //   feature: {
      //     dataZoom: {
      //       yAxisIndex: 'none'
      //     },
      //     restore: {},
      //     saveAsImage: {}
      //   }
      // },
      grid: {
        left: 50,
        right: 10,
        top: 30,
        bottom: 80,
      },
      color: colorArr,
      legend: {
        show: true,
        type: 'scroll'
      },
      tooltip: {
        trigger: router.currentRoute.value.query.idx === undefined || carouselList.value.length === 0 ? (echartsData.value[0] && echartsData.value[0].type === 'pie' ? 'item' : 'axis') : (echartsData.value[idx][0].type === 'pie' ? 'item' : 'axis'), // axis   item   none三个值
        show: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: router.currentRoute.value.query.idx === undefined || carouselList.value.length === 0 ? echartsDataX.value : echartsDataX.value[idx],
        show: router.currentRoute.value.query.idx === undefined || carouselList.value.length === 0 ? echartsDataX.value : echartsDataX.value[idx]
      },
      yAxis: {
        type: 'value',
      },
      dataZoom: router.currentRoute.value.query.idx === undefined || carouselList.value.length === 0 ? (echartsData.value[0] && echartsData.value[0].type !== 'pie' ? [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100
        }
      ] : null) : (echartsData.value[idx][0].type !== 'pie' ? [
        {
          type: 'inside',
          start: 0,
          end: 100,
        },
        {
          start: 0,
          end: 100
        }
      ] : null),
      series: router.currentRoute.value.query.idx === undefined || carouselList.value.length === 0 ? echartsData.value : echartsData.value[idx ? idx : 0]
    };
    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize();

    window.addEventListener('resize', () => {
      myChart.resize()
    }, true)

    // 创建一个ResizeObserver实例
    const observer = new ResizeObserver(entries => {
      myChart.resize();
    });

    // 监听目标div元素的宽度变化
    let ele = document.querySelectorAll('.largeScreenContent')
    for (let i = 0; i < ele.length; i++) {
      observer.observe(ele[i]);
    }

    // 监听目标div元素的宽度变化
    let ele2 = document.querySelectorAll('.carouselList')
    for (let i = 0; i < ele2.length; i++) {
      observer.observe(ele2[i]);
    }
  }
}


// 如果是分组
let echartsItemDataX = ref([])
let echartsItemData = ref([])
let pageNumber = ref(1)
const enterGroupScreenFun = async function (item) {
  openFullScreen()
  await getQueryGroupDataApi(qs.stringify({
    type: router.currentRoute.value.query.type,
    projectId: router.currentRoute.value.query.type == 2 ? router.currentRoute.value.query.projectId : null,
    groupName: item.queryGroupName,
    pageNumber: pageNumber.value,
    pageSize: 6,
  })).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      if (pageNumber.value !== 1 && res.data.result.length === 0) {
        ElMessage({
          type: 'info',
          message: '没有更多数据了！'
        })
      } else {
        // carouselList.value = res.data.result
        carouselList.value.push(...res.data.result)  //将新请求的加到原来的数组中
        for (let i = 0; i < carouselList.value.length; i++) {
          carouselList.value[i].dateTime = [carouselList.value[i].startTime, carouselList.value[i].endTime]
        }
        // 处理二维数组
        carouselItemList.value = []
        let index = 0;
        while (index < carouselList.value.length) {
          carouselItemList.value.push(carouselList.value.slice(index, index += 6));
        }
        // 处理大列表的图表数据
        // let list = res.data.result
        let list = carouselList.value
        for (let j = 0; j < list.length; j++) {

          // 判断是否为饼图
          if (list[j].chatType == 4) {
            echartsDataX.value[j] = null
            let list2 = list[j].pieList
            for (let i = 0; i < list2.length; i++) {
              list2[i].name = list2[i].key
              list2[i].value = Number(list2[i].value)
              list2[i].itemStyle = {color: colorArr[i]}
              list2[i].label = {formatter: `{b}: {d}%\n${list2[i].time}`}
              list2[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[i].time}`}
            }
            echartsData.value[j] = [{
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: true,
                lineHeight: 18
              },
              data: list2
            }]
          } else {
            echartsDataX.value[j] = list[j].xAxis
            echartsData.value[j] = list[j].yAxis
            let isThan = false
            for (let i = 0; i < echartsData.value[j].length; i++) {
              // 把每条数据名称改为设备+指标
              echartsData.value[j][i].name = echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName
              echartsData.value[j][i].sampling = 'lttb'

              if (echartsData.value[j][i].name.includes('同比') || echartsData.value[j][i].name.includes('环比')) {
                isThan = true
              }

              // 数据处理为小数点两位
              for (let k = 0; k < echartsData.value[j][i].data.length; k++) {
                echartsData.value[j][i].data[k] = Number(echartsData.value[j][i].data[k]).toFixed(2)
              }
            }
            // 图表类型样式
            // 如果是属于折线图
            if (list[j].chatType == 1) {
              for (let i = 0; i < echartsData.value[j].length; i++) {
                echartsData.value[j][i].type = 'line'
                // 处理同环比的不同样式
                judgmentRatio(echartsData.value[j][i], i, isThan)
              }
            }
            // 如果是属于柱状图
            if (list[j].chatType == 0) {
              for (let i = 0; i < echartsData.value[j].length; i++) {
                echartsData.value[j][i].type = 'type'
                // 处理同环比的不同样式
                judgmentRatio(echartsData.value[j][i], i, isThan)
              }
            }
          }

          nextTick(() => {
            chartLoad(j)
          })
        }


        // 处理小列表的图表数据
        let listItem = carouselItemList.value
        for (let i = 0; i < listItem.length; i++) {
          echartsItemDataX.value.push([])
          echartsItemData.value.push([])
          for (let j = 0; j < listItem[i].length; j++) {
            // 判断是否为饼图
            if (listItem[i][j].chatType == 4) {
              echartsItemDataX.value[i][j] = null
              let list2 = listItem[i][j].pieList
              for (let k = 0; k < list2.length; k++) {
                list2[k].name = list2[k].key
                list2[k].value = Number(list2[k].value)
                list2[k].itemStyle = {color: colorArr[k]}
                list2[k].label = {formatter: `{b}: {d}%\n${list2[k].time}`}
                list2[k].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[k].time}`}
              }
              echartsItemData.value[i][j] = [{
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                itemStyle: {
                  borderRadius: 10,
                  borderColor: '#fff',
                  borderWidth: 2,
                },
                label: {
                  show: true,
                  lineHeight: 18
                },
                data: list2
              }]
            } else {
              echartsItemDataX.value[i][j] = listItem[i][j].xAxis
              echartsItemData.value[i][j] = listItem[i][j].yAxis
              let isThan = false
              for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
                // 把每条数据名称改为设备+指标
                echartsItemData.value[i][j][k].name = echartsItemData.value[i][j][k].deviceName + '-' + echartsItemData.value[i][j][k].titleName
                echartsItemData.value[i][j][k].sampling = 'lttb'

                if (echartsItemData.value[i][j][k].name.includes('同比') || echartsItemData.value[i][j][k].name.includes('环比')) {
                  isThan = true
                }

                // 数据处理为小数点两位
                for (let h = 0; h < echartsItemData.value[i][j][k].data.length; h++) {
                  echartsItemData.value[i][j][k].data[h] = Number(echartsItemData.value[i][j][k].data[h]).toFixed(2)
                }
              }

              // 图表类型样式
              // 如果是属于折线图
              if (listItem[i][j].chatType == 1) {
                for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
                  echartsItemData.value[i][j][k].type = 'line'
                  // 处理同环比的不同样式
                  judgmentRatio(echartsItemData.value[i][j][k], k, isThan)
                }
              }
              // 如果是属于柱状图
              if (listItem[i][j].chatType == 0) {
                for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
                  echartsItemData.value[i][j][k].type = 'bar'
                  // 处理同环比的不同样式
                  judgmentRatio(echartsItemData.value[i][j][k], k, isThan)
                }
              }
            }


            nextTick(() => {
              chartListLoad(i, j)
            })
          }
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 如果不是分组
let echartsDataX = ref([])
let echartsData = ref([])


// 分组操作--------------------------------------------------------------------------------
// 获取分组列表
let queryGroup = ref('')
let queryGroupList = ref([])
const getQueryGroupListFun = function () {
  getQueryGroupListApi(qs.stringify({
    type: router.currentRoute.value.query.type,
  })).then(res => {
    if (res.data.code === 0) {
      queryGroupList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取分组的数据列表
let carouselList = ref([])  //大列表
let carouselItemList = ref([])   //小列表
// 加载图表 小列表图表
const chartListLoad = function (idx, idx2) {
  let dom = document.querySelectorAll(`.chartItem${idx}_${idx2}`)
  for (let i = 0; i < dom.length; i++) {
    let myChart = echarts.init(dom[i]);
    myChart.clear(); //切换折线图前要先清除
    myChart.setOption({
      tooltip: {
        show: false,
      },
      /*图表位置*/
      grid: {
        x: 0, //左留白
        y: 10, //上留白
        x2: 10, //右留白
        y2: 0 //下留白
      },
      //x轴
      xAxis: {
        data: echartsItemDataX.value[idx][idx2],
        axisLine: {
          lineStyle: {
            color: '#ccc',
            width: 0 //x轴线
          }
        },
        //x轴线中的小竖的分割线
        axisTick: {
          show: false
        },
        show: false
      },
      //y轴
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#999',
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: echartsItemData.value[idx][idx2]
    });
    myChart.resize();
    window.onresize = () => {
      myChart.resize();
    }
  }
}

// 分组的全屏操作 ---------------------------
let carouselIdx = ref(0)  //当前选中的大列表的索引
// 大列表的切换操作
const carouselChange = function (newVal) {
  carouselIdx.value = newVal
  nextTick(() => {
    carouselItemRef.value.setActiveItem(Math.floor(newVal / 6))
  })
}
// 小列表的点击
let carouselRef = ref()
let carouselItemRef = ref()
const groupItemClick = function (idx1, idx2) {
  carouselIdx.value = idx1 * 6 + idx2
  nextTick(() => {
    carouselRef.value.setActiveItem(carouselIdx.value)
  })
}
// 小列表切换到上一页
const carouselItemPrevClick = function () {
  if (carouselItemRef.value.activeIndex !== 0) {
    carouselItemRef.value.prev()
  }
}
// 小列表切换到下一页
const carouselItemNextClick = async function () {
  if (carouselItemList.value.length <= carouselItemRef.value.activeIndex + 1) {
    pageNumber.value = pageNumber.value + 1
    await enterGroupScreenFun(indexDataObj.value)
    nextTick(() => {
      // carouselItemRef.value.next()
      carouselItemRef.value.setActiveItem(carouselItemList.value.length - 1)
    })
  } else {
    carouselItemRef.value.next()
  }
}


// 数据查询的图表和表格的展示操作-------------------------------------------------------------------------------
// 根据条件获取图表
const getEchartsDataFun = async function () {
  openFullScreen()
  await getEchartsDataApi(searchCondition.value.echartSearchData).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      indexDataObj.value = res.data.result
      queryChartLoad()
      document.title = '简单查询'
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const getEchartsDataFun2 = async function () {
  openFullScreen()
  await chatSearchApi(searchCondition.value.echartSearchData).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      indexDataObj.value = res.data.result
      queryChartLoad()
      document.title = '复合查询'
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 根据条件获取表格
// 获取表格数据
let tableData = ref([])
const getTableDataFun = async function () {
  tableData.value = []
  for (let i = 0; i < searchCondition.value.tableSearchData.length; i++) {
    await getTableDataApi(searchCondition.value.tableSearchData[i]).then(res => {
      if (res.data.code === 0) {
        let arr = res.data.result.list
        for (let i = 0; i < arr.length; i++) {
          arr[i].labelArr = []
          for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
            arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
            for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
              arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
              arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
              let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
              arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
            }
          }
        }
        tableData.value.push(...arr)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
// 表格加载更多
const moreClick = async function (index) {
  searchCondition.value.tableSearchData[index].pageNum = searchCondition.value.tableSearchData[index].pageNum + 1
  let data = searchCondition.value.tableSearchData[index]
  data.pageNum = searchCondition.value.tableSearchData[index].pageNum + 1
  data.timeUnit = indexDataObj.value.timeUnit
  data.timeFlag = indexDataObj.value.timeFlag
  data.startTime = indexDataObj.value.dateTime[0]
  data.endTime = indexDataObj.value.dateTime[1]
  data.lastStartTime = indexDataObj.value.lastStartTime
  data.lastEndTime = indexDataObj.value.lastEndTime

  await getTableDataApi(data).then(res => {
    if (res.data.code === 0) {
      let arr = res.data.result.list
      if (arr.length > 0) {
        for (let i = 0; i < arr.length; i++) {
          arr[i].labelArr = []
          for (let j = 0; j < arr[i].dataMetricsVO.length; j++) {
            arr[i].dataMetricsVO[j].ts = arr[i].dataMetricsVO[j].ts.slice(0, 10) + ' ' + arr[i].dataMetricsVO[j].ts.slice(11, 19)
            for (let k = 0; k < arr[i].dataMetricsVO[j].metricDataList.length; k++) {
              arr[i].labelArr.push(arr[i].dataMetricsVO[j].metricDataList[k].titleName)
              arr[i].labelArr = Array.from(new Set(arr[i].labelArr))
              let name = arr[i].dataMetricsVO[j].metricDataList[k].titleName
              arr[i].dataMetricsVO[j][name] = Number(arr[i].dataMetricsVO[j].metricDataList[k].val).toFixed(2)
            }
          }
        }
        tableData.value[index].dataMetricsVO.push(...arr[0].dataMetricsVO)
        tableData.value[index].labelArr.push(...arr[0].labelArr)
        tableData.value[index].labelArr = Array.from(new Set(tableData.value[index].labelArr))
      } else {
        ElMessage({
          type: 'info',
          message: '没有更多数据了！'
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 控制展示图表还是表格
let showFlag = ref(true)
const showClick = async function (flag) {
  // showFlag.value = flag === 1;
  if (flag === 1) {
    showFlag.value = true
  } else {
    showFlag.value = false
  }
}


// 点击导出
const exportFun = function (idx) {
  console.log(idx)
  const downloadZip = (data, fileName = '数据查询') => {
    var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    if ('download' in document.createElement('a')) {
      var downloadElement = document.createElement('a')
      var href = window.URL.createObjectURL(blob) //创建下载的链接
      downloadElement.href = href
      downloadElement.download = fileName //下载后文件名
      document.body.appendChild(downloadElement)
      downloadElement.click() //点击下载
      document.body.removeChild(downloadElement) //下载完成移除元素
      window.URL.revokeObjectURL(href) //释放掉blob对象
    }
  }
  // 判断是数据查询
  if (router.currentRoute.value.query.uid) {
    openFullScreen()
    let data = searchCondition.value.echartSearchData
    // 数据间隔和查询时间用当前页面的
    data.yom = indexDataObj.value.yom
    data.startTime = indexDataObj.value.startTime
    data.endTime = indexDataObj.value.endTime
    data.lastStartTime = indexDataObj.value.lastStartTime
    data.lastEndTime = indexDataObj.value.lastEndTime
    data.timeFlag = indexDataObj.value.timeFlag
    data.timeUnit = indexDataObj.value.timeUnit

    // 简单数据查询
    if (router.currentRoute.value.query.queryType == 1) {
      exportDataApi(data).then(res => {
        loadingAdd.value.close()
        downloadZip(res.data)
      }).catch(err => {
        console.log(err);
      })
    } else {
      // 复杂数据查询
      exportComDataApi(data).then(res => {
        loadingAdd.value.close()
        downloadZip(res.data)
      }).catch(err => {
        console.log(err);
      })
    }
  } else {
    // 首页或项目的数据方案啊
    let data
    if (carouselList.value.length > 0) {
      data = {
        exportId: carouselList.value[idx].exportId,
        groupType: carouselList.value[idx].groupType,
        timeUnit: carouselList.value[idx].timeUnit,
        timeFlag: carouselList.value[idx].timeFlag,
        startTime: carouselList.value[idx].startTime,
        endTime: carouselList.value[idx].endTime,
        lastStartTime: carouselList.value[idx].lastStartTime,
        lastEndTime: carouselList.value[idx].lastEndTime
      }
    } else {
      data = {
        exportId: indexDataObj.value.exportId,
        groupType: indexDataObj.value.groupType,
        timeUnit: indexDataObj.value.timeUnit,
        timeFlag: indexDataObj.value.timeFlag,
        startTime: indexDataObj.value.startTime,
        endTime: indexDataObj.value.endTime,
        lastStartTime: indexDataObj.value.lastStartTime,
        lastEndTime: indexDataObj.value.lastEndTime
      }
    }
    exportUseDataApi(qs.stringify(data)).then(res => {
      loadingAdd.value.close()
      downloadZip(res.data)
    }).catch(err => {
      console.log(err);
    })
  }
}


// 进入全屏
const screenClick = function () {
  let ele = document.documentElement
  if (ele.requestFullscreen) {
    ele.requestFullscreen();
  } else if (ele.webkitRequestFullScreen) {
    ele.webkitRequestFullScreen();
  } else if (ele.mozRequestFullScreen) {
    ele.mozRequestFullScreen();
  } else if (ele.msRequestFullscreen) {
    // IE11
    ele.msRequestFullscreen();
  }
}


// 退出全屏
const exitFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
};


let intervalList = ref([])  //数据间隔列表
let searchCondition = ref()   //查询条件
onMounted(() => {
  if (router.currentRoute.value.query.uid) {
    // 取出查询的条件
    searchCondition.value = JSON.parse(window.localStorage.getItem(router.currentRoute.value.query.uid));
    if (router.currentRoute.value.query.queryType == 1) {
      getEchartsDataFun()
      getTableDataFun()
    } else {
      getEchartsDataFun2()
    }
  } else {
    if (router.currentRoute.value.query.type == 1) {
      // 判断如果是首页数据
      getIndexData()
    } else {
      // 判断如果是项目数据
      getIndexData2()
    }
    getQueryGroupListFun()
  }


  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })

})
onUnmounted(() => {
  clearInterval(timer)
})


</script>

<style lang="less">
.homeSearchScreen {
  width: 100vw;
  height: 100vh;
  background-color: #fff;

  //大屏样式
  .largeScreenContent {
    background-color: #fff;
    height: 100%;

    .fullScreenHead {
      width: 100vw;
      height: 56px;
      background-color: #E5ECF9;
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 56px;


      p {
        font-weight: 600;
        font-size: 20px;
        color: #333333;
        position: relative;
        //margin-left: 35px;
        text-indent: 35px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p::before {
        content: '';
        position: absolute;
        left: 20px;
        top: 19px;
        width: 4px;
        height: 20px;
        background: #165DFF;
      }

      .el-button {
        width: 80px;
        height: 32px;
        background: #165DFF;
        border-radius: 16px 0 0 16px;
        color: #FFFFFF;
      }
    }

    .searchScreen {
      height: 34px;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .leftSearch {
        display: flex;

        .el-select {
          width: 220px;
        }

        .el-popper {
          inset: 44px auto auto 0 !important;

          .el-popper__arrow {
            left: 48% !important;
          }
        }

        .el-date-editor + .el-popper {
          inset: 160px auto auto 200px !important;
        }


        .timeUnitBox, .timeFlagBox {
          display: flex;
          align-items: center;
          line-height: 29px;

          .timeUnitSelectBox, .timeFlagSelectBox {
            display: inline-block;
            position: relative;
          }

          .el-select {
            width: 48px;
            position: relative;
            z-index: 99;
          }

          .el-select .el-select__wrapper {
            opacity: 0;
          }

          .el-radio {
            margin-right: 16px;
          }
        }
      }

      .rightSearch {
        display: flex;
        /* 隐藏输入框 */

        .el-select .el-select__wrapper {
          opacity: 0;
        }

        /* 显示自定义图标 */

        .refreshTimeBox {
          height: 32px;

          .el-icon {
            transform: translate(40px, -40px);
            pointer-events: none; /* 使图标不接收鼠标事件 */
          }
        }

        .el-icon {
          width: 32px;
          height: 32px;
          background: rgba(22, 93, 255, 0.1);
          border-radius: 3px;
          font-size: 20px;
          color: #165DFF;
        }

        .el-button {
          margin-left: 20px;
        }

        .el-button:last-child {
          margin-right: 20px;
        }

        img {
          width: 34px;
          height: 34px;
        }
      }
    }


    .largeScreenContent2 {
      height: 100%;
    }

    .carouselList {
      height: calc(100% - 160px);

      .el-carousel__indicators.el-carousel__indicators--horizontal {
        display: none;
      }

      .el-carousel__container {
        height: 100%;
      }

      .el-carousel__arrow {
        width: 32px;
        height: 32px;
        background-color: #165DFF;
        border-radius: 4px;
        transform: translateY(40px);
        font-size: 16px;
      }
    }

    .carouselItemList {
      width: calc(100% - 40px);
      height: 120px;
      margin-left: 20px;
      margin-top: 10px;
      position: relative;

      .el-carousel__indicators.el-carousel__indicators--horizontal {
        display: none;
      }

      .el-carousel__item {
        display: flex;
        //justify-content: space-between;
      }

      .itemBox:nth-child(6n) {
        margin-right: 0;
      }

      .itemBox {
        width: calc(16% - 20px);
        height: 118px;
        padding: 0 10px;
        background-color: #F5F7FA;
        margin-right: 10px;
        cursor: pointer;

        .itemPlanName {
          text-align: center;
          line-height: 30px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .chartItemBox {
          width: 100%;
          height: calc(100% - 40px);
          background-color: #fff;

          .chartItem {
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, rgba(246, 246, 246, 1) 10%, transparent 0), linear-gradient(rgba(246, 246, 246, 1) 10%, transparent 0);
            background-size: 10px 10px;
          }
        }
      }

      .itemBoxActive {
        border: 1px solid #165DFF;

        .itemPlanName {
          color: #165DFF;
        }
      }

      .prevBtn, .nextBtn {
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-20px);
        text-align: center;
        line-height: 48px;
        color: #fff;
        cursor: pointer;

        .el-icon {
          font-size: 20px;
        }
      }

      .prevBtn {
        left: 30px;
      }

      .nextBtn {
        right: 30px;
      }
    }


    //简单查询分图表和表格
    .head {
      width: 100%;
      height: 46px;
      display: flex;
      justify-content: space-between;

      .tab {
        width: 88px;
        height: 46px;
        line-height: 46px;
        display: flex;
        justify-content: space-between;
        margin-left: 24px;

        p {
          cursor: pointer;
        }

        .active {
          color: #165DFF;
          border-bottom: 2px solid #165DFF;
        }
      }
    }
  }

  .chart {
    width: 100%;
    height: 100%;
  }


  //表格样式
  .tableCont {
    padding: 0 24px;
    height: calc(100% - 50px);
    overflow: auto;

    .tableItem {
      border: 1px solid rgba(0, 0, 0, 0.06);
      margin-top: 16px;

      .tit {
        padding: 0 24px;
        line-height: 58px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        font-size: 16px;
        color: #000;
      }

      .el-table {
        color: rgba(3, 10, 26, 0.85);
        border: 1px solid #ebeef5;
        margin: 24px;

        .el-table__body-header, .el-table__header, .el-table__body {
          th {
            background-color: rgb(250, 250, 250);
            font-weight: 500;
          }

          //th:nth-child(2)::before, th:nth-child(3)::before, th:nth-child(4)::before, th:nth-child(5)::before, th:nth-child(6)::before, th:nth-child(7)::before {
          //  content: '';
          //  width: 1px;
          //  height: 1.6em;
          //  position: absolute;
          //  left: 0;
          //  top: 50%;
          //  transform: translateY(-50%);
          //  background-color: rgba(0, 0, 0, 0.06);
          //}
        }
      }

      .el-button {
        margin-bottom: 24px;
        margin-left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  //弹窗
  .el-dialog {
    //min-width: 1000px;

    .el-dialog__header {
      border-bottom: 1px solid #efefef;
      margin: 0;
    }

    .el-dialog__footer {
      border-top: 1px solid #efefef;
      margin: 0;
    }

    .liPox {
      display: flex;
      margin-bottom: 16px;

      span {
        display: inline-block;
        line-height: 32px;
        width: 85px;
      }

      .el-input, .el-select {
        width: 100%;
      }
    }
  }

  .delFilter {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .editFilter {
    position: absolute;
    top: 10px;
    right: 34px;
  }
}
</style>
