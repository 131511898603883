import { api } from '@/api'

// 查询用电箱管理系统-角色管理列表
export function listBoxRole(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/page',
    method: 'get',
    params: query,
  })
}

// 查询用电箱管理系统-角色管理详细
export function getBoxRole(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/detail/' + id,
    method: 'get',
  })
}

// 新增用电箱管理系统-角色管理
export function addBoxRole(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/save',
    method: 'post',
    data: data,
  })
}

// 修改用电箱管理系统-角色管理
export function updateBoxRole(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/update',
    method: 'post',
    data: data,
  })
}

// 删除用电箱管理系统-角色管理
export function delBoxRole(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/remove/' + id,
    method: 'post',
  })
}

// 查询用电箱管理系统-角色管理项目列表
export function listBoxRoleProject(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/project',
    method: 'get',
    params: query,
  })
}

// 查询用电箱管理系统-角色管理设备列表
export function listBoxRoleEquipment(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/equipment',
    method: 'get',
    params: query,
  })
}

// 绑定设备
export function bindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/bind',
    method: 'post',
    data: data,
  })
}

// 解绑设备
export function unbindEquipment(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/unbind',
    method: 'post',
    data: data,
  })
}

// 查询用电箱管理系统-角色管理列表
export function getBoxRoleList(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/role/list',
    method: 'get',
    params: query,
  })
}
