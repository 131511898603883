import { api } from '@/api'

export function syncData(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/sync/data',
    method: 'post',
    data: data,
  })
}

export function syncMeter(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/sync/meter',
    method: 'post',
    data: data,
  })
}
