<template>
  <div class="alarmQuery">
    <div class="title">
      <p>
        <span>告警查询</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div class="liBox">
          <p>发生时间</p>
          <el-date-picker v-model="happenTime" end-placeholder="结束时间" popper-class="date-style"
                          range-separator="→" start-placeholder="开始时间" type="datetimerange"
                          value-format="YYYY-MM-DD HH:mm:ss">
          </el-date-picker>
        </div>
        <div class="liBox">
          <p>告警等级</p>
          <el-select v-model="warnLevel" placeholder="请选择告警级别">
            <el-option :value="1" label="紧急告警"></el-option>
            <el-option :value="2" label="主要告警"></el-option>
            <el-option :value="3" label="次要告警"></el-option>
            <el-option :value="4" label="警告告警"></el-option>
            <el-option :value="5" label="告警清除"></el-option>
          </el-select>
        </div>
        <div class="liBox" style="margin-left: calc(100% - 957px);">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
        </div>
        <div class="liBox">
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--告警列表-->
        <el-table v-loading="loading" :data="tableData" stripe style="width: 100%" table-layout="auto">
          <template #empty>
            <el-empty image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/noData.png">
            </el-empty>
          </template>
          <el-table-column label="告警设备" prop="equipmentName"></el-table-column>
          <el-table-column :filter-method="filterHandler" :filters="[
        { text: '紧急', value: '紧急' },
        { text: '主要', value: '主要' },
        { text: '次要', value: '次要' },
        { text: '警告', value: '警告' },
        { text: '告警清除', value: '告警清除' },
      ]" label="告警等级" prop="levelStr">
            <template #default="scope">
              <p v-if="scope.row.level===1" class="level level1">{{ scope.row.levelStr }}</p>
              <p v-if="scope.row.level===2" class="level level2">{{ scope.row.levelStr }}</p>
              <p v-if="scope.row.level===3" class="level level3">{{ scope.row.levelStr }}</p>
              <p v-if="scope.row.level===4" class="level level4">{{ scope.row.levelStr }}</p>
              <p v-if="scope.row.level===5" class="level level5">{{ scope.row.levelStr }}</p>
            </template>
          </el-table-column>
          <el-table-column label="发生时间" prop="time"></el-table-column>
          <el-table-column label="触发规则" prop="triggerRule"></el-table-column>
          <el-table-column label="触发值" prop="nowValue"></el-table-column>
          <el-table-column label="通知人" prop="memberList">
            <template #default="scope">
              <p>{{ scope.row.memberList && scope.row.memberList.join(',') }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="220px">
            <template #default="scope">
              <span class="editClass btn" @click.prevent="workOrderClick(scope.row.id,scope.row)">转工单</span>
              <span class="editClass btn" @click.prevent="closeWarnClick(scope.row.id,scope.row)">结束警告</span>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableData.length>0" class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,prev, pager, next,sizes" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './alarmQuery.less'


import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {endAlarmAPi, getAlarmListAPi2} from "@/api/modules/alarm";

let router = useRouter()

// 告警列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref(0)
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let happenTime = ref([])
let warnLevel = ref()
const filterHandler = (value, row, column) => {
  const property = column['property']
  return row[property] === value
}
// 参数表格数据
let tableData = ref([])
// 获取告警列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getAlarmListAPi2(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    startTime: happenTime.value[0],
    endTime: happenTime.value[1],
    level: warnLevel.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 查询
const searchFun = function () {
  getListFun()
}
// 重置
const resetFun = function () {
  happenTime.value = []
  warnLevel.value = ''
  pageNumber.value = 1
  pageSize.value = 20
  getListFun()
}


// 转工单-----------------------------------------------------------------
const workOrderClick = function (id, scope) {
  router.push({
    path: '/admin/workOrder',
    query: {
      equipmentName: scope.equipmentName,
      levelStr: scope.levelStr,
      triggerRule: scope.triggerRule,
      nowValue: scope.nowValue
    }
  })
}


// 结束警告-----------------------------------------------------------------
const closeWarnClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要结束此告警', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    endAlarmAPi(qs.stringify({id: id})).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '结束告警成功'
        });
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        });
      }
    }).catch(err => {
      console.log(err)
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消结束告警'
    });
  });
}


onMounted(() => {
  getListFun()
})

</script>

<style lang="less" scoped>
</style>
