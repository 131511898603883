<template>
  <div class="overview">
    <div class="topContain">
      <div class="itemCont">
        <p class="tit">总设备数</p>
        <div class="center">
          <div class="leftBox">
            <div class="dataCont">
              <p>
                <span class="val">{{ indexDataObj.totalEquipmentNumber }}</span>
                <span class="unit">台</span>
              </p>
            </div>
          </div>
          <!--          <div class="rightBox">-->
          <!--            <el-progress :indeterminate="true"-->
          <!--                         :percentage="(indexDataObj.onlineEquipmentNumber/indexDataObj.totalEquipmentNumber)*100"-->
          <!--                         :show-text="false" :stroke-width="14" color="#165DFF" type="circle"/>-->
          <!--          </div>-->
          <div class="onlineClass">
            目前在线 <span> &nbsp;{{ indexDataObj.onlineEquipmentNumber }}&nbsp; </span> 台
          </div>
        </div>
      </div>
      <div class="itemCont">
        <p class="tit">总网关数</p>
        <div class="center">
          <div class="leftBox">
            <div class="dataCont">
              <p>
                <span class="val">{{ indexDataObj.totalGatewayNumber }}</span>
                <span class="unit">台</span>
              </p>
            </div>
          </div>
          <!--          <div class="rightBox">-->
          <!--            <el-progress :indeterminate="true"-->
          <!--                         :percentage="(indexDataObj.onlineGatewayNumber/indexDataObj.totalGatewayNumber)*100"-->
          <!--                         :show-text="false" :stroke-width="14" color="#8D4EDA" type="circle"/>-->
          <!--          </div>-->
          <div class="onlineClass">
            目前在线 &nbsp;<span>{{ indexDataObj.onlineGatewayNumber }}</span>&nbsp; 台
          </div>
        </div>
      </div>
      <div class="itemCont">
        <p class="tit">告警数量</p>
        <div class="center">
          <div class="leftBox">
            <div class="dataCont">
              <p>
                <span class="val">{{ indexDataObj.warnNumber }}</span>
                <span class="unit">单</span>
              </p>
            </div>
          </div>
          <!--          <div class="rightBox">-->
          <!--            <el-progress :indeterminate="true"-->
          <!--                         :percentage="(indexDataObj.warnNotCleanNumber/indexDataObj.warnNumber)*100"-->
          <!--                         :show-text="false" :stroke-width="14" color="#FF9216" type="circle"/>-->
          <!--          </div>-->
          <div class="onlineClass">
            <p>本周
              <span>{{ indexDataObj.weekWarnNumber }}</span>
              <img v-show="indexDataObj.weekWarnUpFlag==0" alt=""
                   src=" https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectDown.png">
              <img v-show="indexDataObj.weekWarnUpFlag==1" alt=""
                   src=" https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectUp.png">
            </p>
            <p style="margin-left: 8px">本月
              <span>{{ indexDataObj.monthWarnNumber }}</span>
              <img v-show="indexDataObj.monthWarnUpFlag==0" alt=""
                   src=" https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectDown.png">
              <img v-show="indexDataObj.monthWarnUpFlag==1" alt=""
                   src=" https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectUp.png">
            </p>
          </div>
        </div>
      </div>
      <!--      <div class="itemCont">-->
      <!--        <div class="tit">总记录数</div>-->
      <!--        <div class="center">-->
      <!--          <div class="leftBox">-->
      <!--            <div class="dataCont">-->
      <!--              <p>-->
      <!--                    <span class="val">{{-->
      <!--                        Math.round(Number(String(indexDataObj.totalRecordNumber).slice(0, -1))) ? Math.round(Number(String(indexDataObj.totalRecordNumber).slice(0, -1))) : ''-->
      <!--                      }}</span>-->
      <!--                <span class="unit">万</span>-->
      <!--              </p>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="rightBox">-->
      <!--            <div class="kChart"></div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="onlineClass">-->
      <!--          <p>本周-->
      <!--            <span>{{ indexDataObj.weekRecordNumber }}</span>-->
      <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/projectUp.png">-->
      <!--          </p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="title3" style="display: flex;justify-content: space-between;align-items: center;margin-right: 20px;">
      <p style="display:flex;align-items: center">
        <span>数据图表</span>
        <el-button :icon="Plus" style="margin-left: 16px" type="primary" @click="addClick">添加图表</el-button>
      </p>
      <div>
        <el-button @click="router.push('/admin/searchPlan')">添加简单方案</el-button>
        <el-button @click="router.push('/admin/compoundQueryPlan')">添加复合方案</el-button>
      </div>
    </div>
    <div class="largeScreenBox largeScreenBoxCopy">
      <div v-for="(item,index) in indexDataObj.dataSearchList" class="largeScreenUl">
        <div class="largeScreenTitle">
          <p>
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/homeItem2.png"
                 style="width: 24px;height: 24px;"/>
            <span>
							{{ item.title }}
						</span>
          </p>
          <el-icon @click="deleteClick(item,item.queryId)">
            <CloseBold/>
          </el-icon>
        </div>
        <div class="largeScreenContent">
          <!--单个查询-->
          <div v-if="screenGroup.queryGroupName === ''||!isScreen" class="largeScreenContent2">
            <div :style="{display:isScreen?'flex':'none'}" class="fullScreenHead">
              <p>{{ item.queryName ? item.queryName : '--' }}</p>
              <el-button @click="exitFullscreen">
                <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/back.png"
                     style="width: 16px;height: 16px;margin-right: 7px">返回
              </el-button>
            </div>
            <div :style="{display:isScreen?'flex':'none'}" class="searchScreen">
              <div class="leftSearch">
                <span style="margin-left: 20px">数据间隔： </span>
                <el-select v-model="item.timeUnit" :teleported="false" placeholder="请选择数据间隔"
                           @change="timeUnitChange(index,item)">
                  <el-option v-for="(item,index) in intervalList" :key="index" :label="item.value" :value="item.key"/>
                </el-select>
                <span style="margin-left: 20px;">强调展示：</span>
                <el-select v-model="strongShow" :teleported="false" multiple placeholder="请选择强调展示的数据"
                           style="width: 500px"
                           @change="strongShowChange(index)">
                  <el-option v-for="(item_a,index_a) in strongShowArr[index]" :key="index_a" :label="item_a.name"
                             :value="item_a.name">
                    <span :style="{backgroundColor:item_a.color}"
                          style="float: left;display: inline-block;width: 12px;height: 12px;border-radius: 50%;transform: translate(-7px, 11px);"></span>
                    <span>{{ item_a.name }}</span>
                  </el-option>
                </el-select>
                <p style="width: 100%; height: 10px;"></p>
                <span style="margin-left: 20px">日期： </span>
                <el-select v-model="item.timeFlag" :teleported="false" clearable @change="timeFlagSelect(index,item)">
                  <el-option label="自定义时间段" value="-1"></el-option>
                  <el-option :disabled="item.chatType==4?false:true" label="瞬时" value="now"></el-option>
                  <el-option label="近1小时" value="1H"></el-option>
                  <el-option label="近12小时" value="12H"></el-option>
                  <el-option label="近一天" value="1D"></el-option>
                  <el-option label="近3天" value="3D"></el-option>
                  <el-option label="近1周" value="1W"></el-option>
                  <el-option label="本周" value="THIS_WEEK"></el-option>
                  <el-option label="本月" value="1M"></el-option>
                  <el-option label="本季" value="1Q"></el-option>
                </el-select>
                <el-date-picker v-model="item.dateTime" :disabled="item.timeFlag==now?true:false" :teleported="false"
                                end-placeholder="结束时间"
                                popper-class="date-style"
                                range-separator="→" start-placeholder="开始时间"
                                style="margin-left: 15px" type="datetimerange"
                                value-format="YYYY-MM-DD HH:mm:ss"
                                @change="dateTimeChange(index,item)">
                </el-date-picker>
              </div>
              <div class="rightSearch">
                <div class="refreshTimeBox">
                  <!-- el-select 组件 -->
                  <el-select v-model="refreshTime" :teleported="false" placeholder="请选择刷新时间"
                             @change="refreshTimeChange">
                    <el-option :value="60" label="一分钟"/>
                    <el-option :value="3600" label="一小时"/>
                    <el-option :value="86400" label="一天"/>
                    <el-option :value="25920000" label="一月"/>
                    <el-option :value="311040000" label="一年"/>
                  </el-select>

                  <!-- 自定义图标 -->
                  <el-icon @click="">
                    <RefreshRight/>
                  </el-icon>
                </div>
                <el-button type="primary" @click="addClick(item)">查询分组</el-button>
                <!--                <el-button type="success" @click="exportFun(item.queryId)">导出</el-button>-->
              </div>
            </div>
            <div :class="'chart'+index"
                 :style="{height:isScreen?'calc(100% - 182px)':'100%',width:isScreen?'calc(100% - 40px)':'100%',marginTop:isScreen?'10px':0,padding:isScreen?'0 20px':0}"
                 class="chart"></div>
          </div>
        </div>
        <el-button class="detailButton" style="width:100%" type="primary"
                   @click="enterFullScreen(index,item.queryId,item)">
          查看详情
        </el-button>
      </div>
      <!--创建图表弹窗-->
      <el-drawer v-model="drawerChart" :show-close="false" direction="rtl" size="580">
        <template #header>
          <span class="tltleClass">添加图表</span>
          <span class="closeClass">
					<el-icon :size="20" style="cursor: pointer;" @click="drawerChart=false">
						<CloseBold/>
					</el-icon>
				</span>
        </template>
        <template #footer>
          <div class="operBox">
            <el-button class="resetButton" @click="drawerChart=false">取消</el-button>
            <el-button type="primary" @click="saveProjectSubmit">确认</el-button>
          </div>
        </template>
        <template #default>
          <div style="padding: 20px;height: calc(100% - 40px)">
            <el-form ref="chartFormRef" class="demo-ruleForm"
                     status-icon>
              <el-form-item label="分组:">
                <el-select v-model="queryGroup" :teleported="false" allow-create class="planBox"
                           clearable filterable placeholder="请选择已有分组或新增分组">
                  <el-option v-for="(item,index) in queryGroupList" :key="index" :label="item"
                             :value="item"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="查询类型:">
                <el-radio-group v-model="planType" @change="planTypeSelect">
                  <el-radio :value="0">简单查询</el-radio>
                  <el-radio :value="1">复合查询</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="查询方案:">
                <el-select v-model="plan" :teleported="false" class="planBox" clearable
                           filterable placeholder="请选择方案" @change="getUseEquipmentFun">
                  <el-option v-for="(item,index) in planList" :key="index" :label="item.queryName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div v-if="checkList.length>0" class="transferRightBox">
              <div class="head">
                <p>已应用的设备</p>
              </div>
              <!--多设备操作-->
              <div class="transferContain">
                <div class="transferContainHead">
                  <p>
                    <el-checkbox v-model="checkAll" @change="checkAllFun"></el-checkbox>
                  </p>
                  <p>序号</p>
                  <p v-for="(item,index) in checkList[0].arr" :key="index">设备{{ index + 1 }}</p>
                </div>
                <div class="transferContainCont">
                  <div v-for="(item,index) in checkList" :key="index" class="transferContainItem">
                    <p style="width: 40px">
                      <el-checkbox v-model="item.check"></el-checkbox>
                    </p>
                    <p style="width: 60px;">
                      <span>{{ index + 1 }}</span>
                    </p>
                    <p v-for="(item_t,index_t) in item.arr" :key="index_t"
                       :style="{width: checkListWidth[index_t+2]+'px'}">
                      <el-tooltip :content="item_t.value">
                        <span style="color: #165DFF">{{ item_t.value }}</span>
                      </el-tooltip>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-drawer>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, nextTick, onMounted, onUnmounted, reactive, ref, watch} from "vue"
import './overview.less'
import {RefreshRight, FullScreen, Plus, CloseBold} from '@element-plus/icons-vue'
import * as echarts from "echarts";
import {
  exportDataApi,
  getEchartsDataApi,
  getSearchDetailApi,
  getSearchListApi,
  getChartDataByQueryIdApi, getIntervalListApi, getUseEquipmentApi
} from "@/api/modules/dataSearch";
import {ElLoading, ElMessage, ElMessageBox} from "element-plus";
import {
  getProjectOverviewApi,
  addProjectQueryApi,
  delProjectQueryApi,
  getQueryGroupListApi,
  addQueryGroupListApi,
  delQueryGroupListApi,
  getQueryGroupDataApi
} from '@/api/modules/project'
import qs from "qs";
import moment from "moment";
import letterList from "@/assets/js/letterList";
import {exportComDataApi} from "@/api/modules/compoundQuery";
import {useRouter} from "vue-router/dist/vue-router";


let router = useRouter()
let route = useRouter()
const props = defineProps(['custerObj']);


// 项目概览****************************************************************************
let indexDataObj = ref({
  totalEquipmentNumber: '',
})
//获取项目概览数据
const getIndexData2 = async function () {
  openFullScreen()
  await getProjectOverviewApi(qs.stringify({
    projectId: custerObj.value.id
  })).then(res => {
    loadingAdd.value.close()
    if (res.data.code === 0) {
      indexDataObj.value = res.data.result
      // 处理加载数据查询图表
      queryChartLoad()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


const chatLoad = function () {
  var chartDom = document.querySelector('.kChart')
  var myChart = echarts.init(chartDom);
  var option;
  option = {
    tooltip: {
      trigger: 'axis', // axis   item   none三个值
      show: true
    },
    grid: {
      x: 0, //左留白
      y: 0, //上留白
      x2: 0, //右留白
      y2: 0 //下留白
    },
    xAxis: {
      type: 'category',
      data: indexDataObj.value.storeWeekTimeList,
      axisLabel: { //坐标轴刻度标签
        show: false
      },
      axisLine: { //坐标轴轴线
        show: false
      },
      axisTick: { //坐标轴刻度
        show: false
      }
    },
    yAxis: {
      splitLine: { //坐标轴在grid区域中的分割线
        show: false
      },
      axisLabel: { //坐标轴刻度标签
        show: false
      },
      min: function (value) {
        return value.min;
      }
      //logBase: 10, // 底数
    },
    series: [{
      data: indexDataObj.value.storeWeekDataList,
      type: 'bar',
      itemStyle: {
        color: function (params) {
          // 定义换色数组
          var colorList = ['#2CAB40', '#86DF6C']
          if (params.dataIndex % 2 == 0) {
            return colorList[0]
          } else {
            return colorList[1]
          }
        },
        borderRadius: [4, 4, 4, 4]

      },
      //barMinHeight:30
    }]
  };

  myChart.clear()
  option && myChart.setOption(option);

  myChart.resize();
  window.onresize = () => {
    myChart.resize();
  }
}


// 数据查询操作-------------------------------------------------------------------------------
let planType = ref()
// 获取查询方案列表------------------------
let plan = ref()
let planList = ref([])
// 获取方案列表
const planTypeSelect = async function (e) {
  plan.value = ''
  checkList.value = []
  await getSearchListApi({type: e}).then(res => {
    if (res.data.code === 200) {
      planList.value = res.data.data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 新增数据查询------------------------
let drawerChart = ref(false)  //创建图表弹窗
// 点击新增
const addClick = function () {
  drawerChart.value = true
  planType.value = ''
  plan.value = ''
  queryGroup.value = ''
  checkList.value = []
}

// 创建图表提交
const saveProjectSubmit = function () {
  let list = []
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkList.value[i].check) {
      list[i] = []
      for (let j = 0; j < checkList.value[i].arr.length; j++) {
        list[i].push(checkList.value[i].arr[j].key)
      }
    }
  }
  let list1 = list.filter(element => element != null && element !== '' && element !== undefined)
  if (list1.length > 0 && plan.value !== '' && custerObj.value.id !== '') {
    addQueryGroupListApi(qs.stringify({
      type: 2,
      groupName: queryGroup.value,
      queryId: plan.value,
      projectId: custerObj.value.id,
      equipmentIdJson: JSON.stringify(list1)
    })).then(res => {
      if (res.data.code === 0) {
        drawerChart.value = false
        getIndexData2()
        getQueryGroupListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      type: 'info',
      message: '请输入必填项'
    })
  }
}

// 点击删除
const deleteClick = function (item, id) {
  ElMessageBox.confirm('请确认是否要删除此查询，删除后数据不可恢复', 'Warning', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
    title: ''
  }).then(() => {
    delQueryGroupListApi(qs.stringify({id: item.groupId})).then(res => {
      if (res.data.ok) {
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
        getIndexData2()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 创建图表设备操作-------------------------------------------------------------------------
let checkList = ref([])  //设备方案已选设备
let checkListWidth = ref([])
// 获取应用设备初始化列表
const getUseEquipmentFun = function (id) {
  getUseEquipmentApi(qs.stringify({
    id: id,
  })).then(res => {
    if (res.data.code === 0) {
      checkList.value = []
      let list = JSON.parse(JSON.stringify(res.data.result))
      for (let i = 0; i < list.length; i++) {
        checkList.value.push({
          check: false,
          arr: []
        })
        for (let j = 0; j < list[i].length; j++) {
          checkList.value[i].arr.push(list[i][j])
        }
      }
      nextTick(() => {
        let arr1 = document.querySelectorAll('.transferContainHead p')
        checkListWidth.value = []
        for (let i = 0; i < arr1.length; i++) {
          checkListWidth.value.push(Number(arr1[i].offsetWidth) - 2)
        }
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 全选
let checkAll = ref(false)
let checkAllFun = function () {
  for (let i = 0; i < checkList.value.length; i++) {
    if (checkAll.value) {
      checkList.value[i].check = true
    } else {
      checkList.value[i].check = false
    }
  }
}


// 处理图表数据--------------------------------------------------------------------------
// 加载图表数据
let echartsData = ref([])
let echartsDataX = ref([])
let strongShowArr = ref([])
let strongShow = ref([])  //强调展示
let strongShow2 = ref([])  //强调展示
let refreshTime = ref(60)  //刷新时间
const queryChartLoad = function () {
  let list = indexDataObj.value.dataSearchList
  for (let j = 0; j < list.length; j++) {
    list[j].dateTime = [list[j].startTime, list[j].endTime]
    strongShowArr.value[j] = []
    // 判断是否为饼图
    if (list[j].chatType == 4) {
      echartsDataX.value[j] = null
      let list2 = list[j].pieList
      for (let i = 0; i < list2.length; i++) {
        list2[i].name = list2[i].key
        list2[i].value = Number(list2[i].value)
        list2[i].itemStyle = {color: colorArr[i]}
        list2[i].label = {formatter: `{b}: {d}%\n${list2[i].time}`}
        list2[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[i].time}`}
        strongShowArr.value[j].push({
          name: list2[i].key,
          color: colorArr[i]
        })
      }
      echartsData.value[j] = [{
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: true,
          lineHeight: 18
        },
        data: list2
      }]
    } else {
      echartsDataX.value[j] = list[j].xAxis
      echartsData.value[j] = list[j].yAxis
      let isThan = false
      for (let i = 0; i < echartsData.value[j].length; i++) {
        // 把每条数据名称改为设备+指标
        echartsData.value[j][i].name = echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName
        echartsData.value[j][i].sampling = 'lttb'

        if (echartsData.value[j][i].name.includes('同比') || echartsData.value[j][i].name.includes('环比')) {
          isThan = true
        }

        // 数据处理为小数点两位
        for (let k = 0; k < echartsData.value[j][i].data.length; k++) {
          echartsData.value[j][i].data[k] = Number(echartsData.value[j][i].data[k]).toFixed(2)
        }
      }
      // 图表类型样式
      // 如果是属于折线图
      if (list[j].chatType == 1) {
        for (let i = 0; i < echartsData.value[j].length; i++) {
          echartsData.value[j][i].type = 'line'
          // 处理同环比的不同样式
          judgmentRatio(echartsData.value[j][i], i, isThan)
        }
      }
      // 如果是属于柱状图
      if (list[j].chatType == 0) {
        for (let i = 0; i < echartsData.value[j].length; i++) {
          echartsData.value[j][i].type = 'bar'
          // 处理同环比的不同样式
          judgmentRatio(echartsData.value[j][i], i, isThan)
        }
      }

      for (let i = 0; i < echartsData.value[j].length; i++) {
        strongShowArr.value[j].push({
          name: echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName,
          color: echartsData.value[j][i].color
        })
      }
    }
    nextTick(() => {
      chartLoad(j)
    })
  }
}
// 判断同环比方法
const judgmentRatio = function (item, i, isThan) {
  // 如果是带同环比--------------------------------------------
  if (isThan) {
    if (item.name.includes('同比') || item.name.includes('环比')) {
      // 判断当前是同环比********************
      item.color = colorArr3[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
      }
      item.symbol = 'circle'
      item.symbolSize = 7
    } else {
      // 判断当前不是同环比********************
      item.color = colorArr[Math.floor(i / 2)]
      item.lineStyle = {
        width: 3,
        shadowColor: colorArr2[Math.floor(i / 2)],
        shadowBlur: 4
      }
      item.symbolSize = 10
    }
  } else {
    // 如果不带同环比--------------------------------------------
    item.color = colorArr[i]
    item.lineStyle = {
      width: 3,
      shadowColor: colorArr2[i],
      shadowBlur: 4
    }
    item.symbolSize = 7
  }

}
// 强调展示选择事件
let strongShowChange = function (idx) {
  // 判断是否为饼图
  if (echartsData.value[idx][0].type === 'pie') {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: '#DDDDDD'}
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[idx][0].data[i].name === strongShow.value[j]) {
            echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
          }
        }
      }
    }
  } else {
    if (strongShow.value && strongShow.value.length === 0) {
      // 强调展示为空时
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        judgmentRatio(echartsData.value[idx][i], i, isThan)
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        echartsData.value[idx][i].color = '#DDDDDD'
        echartsData.value[idx][i].lineStyle = {
          width: 3,
        }
        for (let j = 0; j < strongShow.value.length; j++) {
          if (echartsData.value[idx][i].name === strongShow.value[j]) {
            judgmentRatio(echartsData.value[idx][i], i, isThan)
          }
        }
      }
    }
  }

  nextTick(() => {
    chartLoad(idx)
  })
}
// 分组图表强调展示选择事件
let strongShowChange2 = function (id, idx) {
  // 判断是否为饼图
  if (echartsData.value[idx][0].type === 'pie') {
    console.log(echartsData.value[idx])
    if (strongShow2.value[idx] && strongShow2.value[idx].length === 0) {
      // 强调展示为空时
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      for (let i = 0; i < echartsData.value[idx][0].data.length; i++) {
        echartsData.value[idx][0].data[i].itemStyle = {color: '#DDDDDD'}
        for (let j = 0; j < strongShow2.value[idx].length; j++) {
          if (echartsData.value[idx][0].data[i].name === strongShow2.value[idx][j]) {
            echartsData.value[idx][0].data[i].itemStyle = {color: colorArr[i]}
          }
        }
      }
    }
  } else {
    if (strongShow2.value[idx] && strongShow2.value[idx].length === 0) {
      // 强调展示为空时
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        judgmentRatio(echartsData.value[idx][i], i, isThan)
      }
    } else {
      // 强调展示不为空时 处理强调展示的数据
      let isThan = false
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        if (echartsData.value[idx][i].name.includes('同比') || echartsData.value[idx][i].name.includes('环比')) {
          isThan = true
        }
      }
      for (let i = 0; i < echartsData.value[idx].length; i++) {
        echartsData.value[idx][i].color = '#DDDDDD'
        echartsData.value[idx][i].lineStyle = {
          width: 3,
        }
        for (let j = 0; j < strongShow2.value[idx].length; j++) {
          if (echartsData.value[idx][i].name === strongShow2.value[idx][j]) {
            judgmentRatio(echartsData.value[idx][i], i, isThan)
          }
        }
      }
    }
  }

  nextTick(() => {
    chartLoad(idx, `c${idx}`)
  })
}
// 刷新时间选择事件
const refreshTimeChange = function (e) {
  clearInterval(timer)
  timer = setInterval(() => {
    if (screenGroup.value.queryGroupName && isScreen) {
      enterGroupScreenFun(screenGroup.value)
    } else {
      getIndexData2()
    }
  }, e * 1000)
}
// 时间间隔选择事件
const timeUnitChange = function (idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(idx)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 分组图表时间间隔选择事件
const timeUnitChange2 = function (id, idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(id, `c${id}`)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 日期类型选择事件
const timeFlagSelect = function (idx, item) {
  if (item.timeFlag === '-1') {
    item.dateTime = []
  } else if (item.timeFlag === '1H') {
    item.dateTime = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '12H') {
    item.dateTime = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1D' || item.timeFlag === 'now') {
    item.dateTime = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '3D') {
    item.dateTime = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1W') {
    item.dateTime = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === 'THIS_WEEK') {
    item.dateTime = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1M') {
    item.dateTime = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1Q') {
    item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(idx)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 分组图表日期类型选择事件
const timeFlagSelect2 = function (id, idx, item) {
  if (item.timeFlag === '-1') {
    item.dateTime = []
  } else if (item.timeFlag === '1H') {
    item.dateTime = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '12H') {
    item.dateTime = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1D' || item.timeFlag === 'now') {
    item.dateTime = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '3D') {
    item.dateTime = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1W') {
    item.dateTime = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === 'THIS_WEEK') {
    item.dateTime = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1M') {
    item.dateTime = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (item.timeFlag === '1Q') {
    item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // item.dateTime = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(id, `c${id}`)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 日期时间选择事件
const dateTimeChange = function (idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(idx)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}
// 分组图表日期时间选择事件
const dateTimeChange2 = function (id, idx, item) {
  // 计算同环比时间
  if (item.yom && item.yom[0] == 1) {
    item.lastStartTime = moment(item.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
    item.lastEndTime = moment(item.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
  } else if (item.yom && item.yom[0] == 2) {
    if (timeFlag.value === '1M') {
      item.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
    } else if (timeFlag.value === '1Q') {
      item.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
      item.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
    } else {
      let timeLength = moment(item.endTime).diff(item.startTime, 'second')
      item.lastStartTime = moment(item.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
      item.lastEndTime = moment(item.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
    }
  }
  getChartDataByQueryIdApi(qs.stringify({
    queryId: item.queryId,
    timeUnit: item.timeUnit,
    startTime: item.dateTime[0],
    endTime: item.dateTime[1],
    lastStartTime: item.lastStartTime,
    lastEndTime: item.lastEndTime,
  })).then(res => {
    if (res.data.code === 0) {
      echartsDataX.value[idx] = res.data.result.xAxis
      if (res.data.result.yAxis.length === 0) {
        for (let i = 0; i < echartsData.value[idx].length; i++) {
          echartsData.value[idx][i].data = []
        }
      }
      for (let i = 0; i < res.data.result.yAxis.length; i++) {
        for (let j = 0; j < res.data.result.yAxis[i].data.length; j++) {
          echartsData.value[idx][i].data.push(Number(res.data.result.yAxis[i].data[j]).toFixed(2))
        }
      }
      nextTick(() => {
        chartLoad(id, `c${id}`)
      })
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
  refreshTimeChange(refreshTime.value)
}


// 加载图表
let colorArr = ['rgba(69,125,252,1)', 'rgba(189,82,244,1)', 'rgba(0,229,166,1)', 'rgba(8,205,255,1)', 'rgba(249,74,171,1)', 'rgba(254, 44, 44, 1)', 'rgba(136, 228, 49, 1)', 'rgba(255, 144, 71, 1)', 'rgba(255, 187, 0, 1)', 'rgba(33, 120, 86, 1)', 'rgba(68, 68, 93, 1)', 'rgba(29, 33, 212, 1)', 'rgba(220, 122, 30, 1)']
let colorArr2 = ['rgba(69,125,252,0.2)', 'rgba(189,82,244,0.2)', 'rgba(0,229,166,0.2)', 'rgba(8,205,255,0.2)', 'rgba(249,74,171,0.2)', 'rgba(254, 44, 44, 0.2)', 'rgba(136, 228, 49, 0.2)', 'rgba(255, 144, 71, 0.2)', 'rgba(255, 187, 0, 0.2)', 'rgba(33, 120, 86, 0.2)', 'rgba(68, 68, 93, 0.2)', 'rgba(29, 33, 212, 0.2)', 'rgba(220, 122, 30, 0.2)']
// let colorArr3 = ['rgba(69,125,252,0.4)', 'rgba(189,82,244,0.4)', 'rgba(0,229,166,0.4)', 'rgba(8,205,255,0.4)', 'rgba(249,74,171,0.4)', 'rgba(254, 44, 44, 0.4)', 'rgba(136, 228, 49, 0.4)', 'rgba(255, 144, 71, 0.4)', 'rgba(255, 187, 0, 0.4)', 'rgba(33, 120, 86, 0.4)', 'rgba(68, 68, 93, 0.4)', 'rgba(29, 33, 212, 0.4)', 'rgba(220, 122, 30, 0.4)']
let colorArr3 = ['rgba(181, 203, 254, 1)', 'rgba(229, 186, 251, 1)', 'rgba(153, 244, 219, 1)', 'rgba(156, 235, 255, 1)', 'rgba(253, 182, 222, 1)', 'rgba(255, 171, 171, 1)', 'rgba(208, 244, 173, 1)', 'rgba(255, 209, 180, 1)', 'rgba(255, 228, 153, 1)', 'rgba(166, 201, 187, 1)', 'rgba(180, 180, 190, 1)', 'rgba(164, 166, 238, 1)', 'rgba(241, 202, 165, 1)']
const chartLoad = function (idx, cIdx) {
  var chartDom
  if (cIdx) {
    chartDom = document.querySelectorAll(`.chart${screenIdx.value}${cIdx}`);
  } else {
    chartDom = document.querySelectorAll(`.chart${idx}`);
  }
  for (let i = 0; i < chartDom.length; i++) {
    var myChart = echarts.init(chartDom[i]);
    var option;
    option = {
      // toolbox: {
      //   feature: {
      //     dataZoom: {
      //       yAxisIndex: 'none'
      //     },
      //     restore: {},
      //     saveAsImage: {}
      //   }
      // },
      grid: {
        left: 50,
        right: 10,
        top: 30,
        bottom: 30,
      },
      color: colorArr,
      legend: {
        show: true,
        type: 'scroll'
      },
      tooltip: {
        trigger: echartsData.value[idx].type === 'pie' ? 'item' : 'axis', // axis   item   none三个值
        show: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: true,
        data: echartsDataX.value[idx],
        show: echartsDataX.value[idx]
      },
      yAxis: {
        type: 'value',
      },
      // dataZoom: [
      //   {
      //     type: 'inside',
      //     start: 0,
      //     end: 100,
      //     disabled: false
      //   },
      //   {
      //     start: 0,
      //     end: 100
      //   }
      // ],
      series: echartsData.value[idx]
    };
    myChart.clear()
    option && myChart.setOption(option);

    myChart.resize();
    window.onresize = () => {
      myChart.resize();
    }

    // 创建一个ResizeObserver实例
    const observer = new ResizeObserver(entries => {
      myChart.resize();
    });

// 监听目标div元素的宽度变化
    let ele = document.querySelectorAll('.largeScreenContent')
    for (let i = 0; i < ele.length; i++) {
      observer.observe(ele[i]);
    }

    // 监听目标div元素的宽度变化
    let ele2 = document.querySelectorAll('.carouselList')
    for (let i = 0; i < ele2.length; i++) {
      observer.observe(ele2[i]);
    }
  }
}

// 分组操作--------------------------------------------------------------------------------
// 获取分组列表
let queryGroup = ref('')
let queryGroupList = ref([])
const getQueryGroupListFun = function () {
  getQueryGroupListApi(qs.stringify({
    type: 2,
    projectId: custerObj.value.id
  })).then(res => {
    if (res.data.code === 0) {
      queryGroupList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 获取分组的数据列表
let carouselList = ref([])  //大列表
let carouselItemList = ref([])   //小列表
// 加载图表 小列表图表
const chartListLoad = function (idx, idx2) {
  let dom = document.querySelectorAll(`.chartItem${idx}_${idx2}`)
  for (let i = 0; i < dom.length; i++) {
    let myChart = echarts.init(dom[i]);
    myChart.clear(); //切换折线图前要先清除
    myChart.setOption({
      tooltip: {
        show: false,
      },
      /*图表位置*/
      grid: {
        x: 0, //左留白
        y: 10, //上留白
        x2: 10, //右留白
        y2: 0 //下留白
      },
      //x轴
      xAxis: {
        data: echartsItemDataX.value[idx][idx2],
        axisLine: {
          lineStyle: {
            color: '#ccc',
            width: 0 //x轴线
          }
        },
        //x轴线中的小竖的分割线
        axisTick: {
          show: false
        },
        show: false
      },
      //y轴
      yAxis: {
        axisLine: {
          lineStyle: {
            color: '#999',
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          show: false
        }
      },
      series: echartsItemData.value[idx][idx2]
    });
    myChart.resize();
    window.onresize = () => {
      myChart.resize();
    }
  }
}

// 分组的全屏操作 ---------------------------
let isBigScreen = ref(false)
let carouselIdx = ref(0)  //当前选中的大列表的索引
// 大列表的切换操作
const carouselChange = function (newVal) {
  carouselIdx.value = newVal
  nextTick(() => {
    for (let i = 0; i < carouselItemRef.value.length; i++) {
      carouselItemRef.value[i].setActiveItem(Math.floor(newVal / 6))
    }
  })
}
// 小列表的点击
let carouselRef = ref()
let carouselItemRef = ref()
const groupItemClick = function (idx1, idx2) {
  carouselIdx.value = idx1 * 6 + idx2
  nextTick(() => {
    for (let i = 0; i < carouselRef.value.length; i++) {
      carouselRef.value[i].setActiveItem(carouselIdx.value)
    }
  })
}


// 导出--------------------------------------------------------------------------------
// 加载的动画
let loadingAdd = ref(null)
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
const exportFun = async function (id) {
  await getSearchDetailApi(id).then(res => {
    if (res.data.code === 200) {
      // 处理指标
      for (let i = 0; i < res.data.data.deviceConditionList.length; i++) {
        res.data.data.deviceConditionList[i].dictKeys = []
        for (let j = 0; j < res.data.data.deviceConditionList[i].dictList.length; j++) {
          res.data.data.deviceConditionList[i].dictKeys[j] = res.data.data.deviceConditionList[i].dictList[j].key
        }
      }
      let data = {
        startTime: res.data.data.startTime,
        endTime: res.data.data.endTime,
        frequency: res.data.data.frequency,
        timeUnit: res.data.data.timeUnit,
        params: res.data.data.deviceConditionList,
        yom: res.data.data.yom
      }
      // 计算同环比时间
      if (res.data.data.yom && res.data.data.yom[0] == 1) {
        data.lastStartTime = moment(data.startTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
        data.lastEndTime = moment(data.endTime).subtract(1, 'years').format("YYYY-MM-DD HH:mm:ss")
      } else if (res.data.data.yom && res.data.data.yom[0] == 2) {
        if (timeFlag.value === '1M') {
          data.lastStartTime = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss')
        } else if (timeFlag.value === '1Q') {
          data.lastStartTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').startOf('quarter').format("YYYY-MM-DD HH:mm:ss")
          data.lastEndTime = moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).subtract(1, 'quarter').endOf('quarter').format("YYYY-MM-DD HH:mm:ss")
        } else {
          let timeLength = moment(data.endTime).diff(data.startTime, 'second')
          data.lastStartTime = moment(data.startTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
          data.lastEndTime = moment(data.endTime).subtract(timeLength, 'second').format('YYYY-MM-DD HH:mm:ss')
        }
      }
      let flag = false
      for (let i = 0; i < data.params.length; i++) {
        if (!data.params[i].deviceId || (data.params[i].dictKeys.length === 0)) {
          flag = true
        }
        delete data.params[i].dictList
        delete data.params[i].dictIds
      }
      if (data.frequency && data.timeUnit && !flag) {
        const downloadZip = (data, fileName = '数据查询') => {
          var blob = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
          if ('download' in document.createElement('a')) {
            var downloadElement = document.createElement('a')
            var href = window.URL.createObjectURL(blob) //创建下载的链接
            downloadElement.href = href
            downloadElement.download = fileName //下载后文件名
            document.body.appendChild(downloadElement)
            downloadElement.click() //点击下载
            document.body.removeChild(downloadElement) //下载完成移除元素
            window.URL.revokeObjectURL(href) //释放掉blob对象
          }
        }
        openFullScreen()
        exportDataApi(data).then(res => {
          loadingAdd.value.close()
          downloadZip(res.data)
        }).catch(err => {
          console.log(err);
        })
      } else {
        ElMessage({
          type: 'info',
          message: '请选择筛选条件'
        })
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 进入全屏
let screenIdx = ref(0)
let screenVal = ref()
let screenGroup = ref({})
let isScreen = ref(false)
let echartsItemDataX = ref([])
let echartsItemData = ref([])
const enterGroupScreenFun = function (item) {
  getQueryGroupDataApi(qs.stringify({
    type: 2,
    projectId: custerObj.value.id,
    groupName: item.queryGroupName
  })).then(res => {
    if (res.data.code === 0) {
      carouselList.value = res.data.result
      for (let i = 0; i < carouselList.value.length; i++) {
        carouselList.value[i].dateTime = [carouselList.value[i].startTime, carouselList.value[i].endTime]
      }

      // 处理二维数组
      carouselItemList.value = []
      let index = 0;
      while (index < carouselList.value.length) {
        carouselItemList.value.push(carouselList.value.slice(index, index += 6));
      }
      // 处理大列表的图表数据
      let list = res.data.result
      for (let j = 0; j < list.length; j++) {
        strongShowArr.value[j] = []
        // 判断是否为饼图
        if (list[j].chatType == 4) {
          echartsDataX.value[j] = null
          let list2 = list[j].pieList
          for (let i = 0; i < list2.length; i++) {
            list2[i].name = list2[i].key
            list2[i].value = Number(list2[i].value)
            list2[i].itemStyle = {color: colorArr[i]}
            list2[i].label = {formatter: `{b}: {d}%\n${list2[i].time}`}
            list2[i].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[i].time}`}
            strongShowArr.value[j].push({
              name: list2[i].key,
              color: colorArr[i]
            })
          }
          echartsData.value[j] = [{
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            label: {
              show: true,
              lineHeight: 18
            },
            data: list2
          }]
        } else {
          echartsDataX.value[j] = list[j].xAxis
          echartsData.value[j] = list[j].yAxis
          let isThan = false
          for (let i = 0; i < echartsData.value[j].length; i++) {
            // 把每条数据名称改为设备+指标
            echartsData.value[j][i].name = echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName
            echartsData.value[j][i].sampling = 'lttb'

            if (echartsData.value[j][i].name.includes('同比') || echartsData.value[j][i].name.includes('环比')) {
              isThan = true
            }

            // 数据处理为小数点两位
            for (let k = 0; k < echartsData.value[j][i].data.length; k++) {
              echartsData.value[j][i].data[k] = Number(echartsData.value[j][i].data[k]).toFixed(2)
            }
          }
          // 图表类型样式
          // 如果是属于折线图
          if (list[j].chatType == 1) {
            for (let i = 0; i < echartsData.value[j].length; i++) {
              echartsData.value[j][i].type = 'line'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[j][i], i, isThan)
            }
          }
          // 如果是属于柱状图
          if (list[j].chatType == 0) {
            for (let i = 0; i < echartsData.value[j].length; i++) {
              echartsData.value[j][i].type = 'bar'
              // 处理同环比的不同样式
              judgmentRatio(echartsData.value[j][i], i, isThan)
            }
          }

          for (let i = 0; i < echartsData.value[j].length; i++) {
            strongShowArr.value[j].push({
              name: echartsData.value[j][i].deviceName + '-' + echartsData.value[j][i].titleName,
              color: echartsData.value[j][i].color
            })
          }
        }

        nextTick(() => {
          chartLoad(j, `c${j}`)
        })
      }

      // 处理小列表的图表数据
      let listItem = carouselItemList.value
      for (let i = 0; i < listItem.length; i++) {
        echartsItemDataX.value.push([])
        echartsItemData.value.push([])
        for (let j = 0; j < listItem[i].length; j++) {
          // 判断是否为饼图
          if (listItem[i][j].chatType == 4) {
            echartsItemDataX.value[i][j] = null
            let list2 = listItem[i][j].pieList
            for (let k = 0; k < list2.length; k++) {
              list2[k].name = list2[k].key
              list2[k].value = Number(list2[k].value)
              list2[k].itemStyle = {color: colorArr[k]}
              list2[k].label = {formatter: `{b}: {d}%\n${list2[k].time}`}
              list2[k].tooltip = {formatter: `{b}: {c}<br />占比: {d}%<br />${list2[k].time}`}
            }
            echartsItemData.value[i][j] = [{
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2,
              },
              label: {
                show: true,
                lineHeight: 18
              },
              data: list2
            }]
          } else {
            echartsItemDataX.value[i][j] = listItem[i][j].xAxis
            echartsItemData.value[i][j] = listItem[i][j].yAxis
            let isThan = false
            for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
              // 把每条数据名称改为设备+指标
              echartsItemData.value[i][j][k].name = echartsItemData.value[i][j][k].deviceName + '-' + echartsItemData.value[i][j][k].titleName
              echartsItemData.value[i][j][k].sampling = 'lttb'

              if (echartsItemData.value[i][j][k].name.includes('同比') || echartsItemData.value[i][j][k].name.includes('环比')) {
                isThan = true
              }

              // 数据处理为小数点两位
              for (let h = 0; h < echartsItemData.value[i][j][k].data.length; h++) {
                echartsItemData.value[i][j][k].data[h] = Number(echartsItemData.value[i][j][k].data[h]).toFixed(2)
              }
            }

            // 图表类型样式
            // 如果是属于折线图
            if (listItem[i][j].chatType == 1) {
              for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
                echartsItemData.value[i][j][k].type = 'line'
                // 处理同环比的不同样式
                judgmentRatio(echartsItemData.value[i][j][k], k, isThan)
              }
            }
            // 如果是属于柱状图
            if (listItem[i][j].chatType == 0) {
              for (let k = 0; k < echartsItemData.value[i][j].length; k++) {
                echartsItemData.value[i][j][k].type = 'bar'
                // 处理同环比的不同样式
                judgmentRatio(echartsItemData.value[i][j][k], k, isThan)
              }
            }
          }
          nextTick(() => {
            chartListLoad(i, j)
          })
        }
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const enterFullScreen = (idx, id, item) => {
  // screenGroup.value = item
  // screenIdx.value = idx
  // screenVal.value = id
  // isScreen.value = true
  // // 判断进入全屏的是分组还是单个查询
  // if (item.queryGroupName) {
  //   enterGroupScreenFun(item)
  // }
  // // 获取layout的dom元素
  // const parentNode = document.querySelectorAll('.largeScreenUl')[idx].querySelector('.largeScreenContent'); // 获取需要全屏的元素;
  // // document.querySelector('.largeScreenContent .chart').style.height = 'calc(100% - 182px)'  //进入全屏后变大图片
  // // 进入全屏
  // if (parentNode.requestFullscreen) {
  //   parentNode.requestFullscreen()
  // } else if (parentNode.webkitRequestFullScreen) {
  //   parentNode.webkitRequestFullScreen();
  // } else if (parentNode.mozRequestFullScreen) {
  //   parentNode.mozRequestFullScreen();
  // } else if (parentNode.msRequestFullscreen) {
  //   // IE11
  //   parentNode.msRequestFullscreen();
  // }
  //
  //
  // nextTick(() => {
  //   chartLoad(idx)
  // })


  // 打开新窗口
  window.open(`#/homeSearchScreen?type=2&idx=${idx}&projectId=${custerObj.value.id}`, '_blank')
};
// 退出全屏
const exitFullscreen = () => {
  isScreen.value = false
  carouselIdx.value = 0
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if ((document).webkitCancelFullScreen) {
    (document).webkitCancelFullScreen();
  } else if ((document).mozCancelFullScreen) {
    (document).mozCancelFullScreen();
  } else if ((document).msExitFullscreen) {
    (document).msExitFullscreen();
  }
  getIndexData2()
};

// 定时器
let timer = setInterval(() => {
  // getIndexData2()
}, refreshTime.value * 1000)


let intervalList = ref([])  //数据间隔列表
onMounted(async () => {
  getQueryGroupListFun()
  document.addEventListener('fullscreenchange', function () {
    if (!document.fullscreenElement) {
      isScreen.value = false
      carouselIdx.value = 0
      getIndexData2()
    } else {
      // 执行进入全屏后的操作
    }
  });

  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})


onUnmounted(() => {
  clearInterval(timer)
})


let custerObj = ref()
// 监听props.data.list中每个元素的变化，当它变化时，启动动画
watch(() => props.custerObj, async (newValues, oldValues) => {
  custerObj.value = newValues
  if (custerObj.value.id) {
    await getIndexData2()
    queryChartLoad()
    getQueryGroupListFun()
  }
}, {immediate: true, deep: true});
</script>

<style lang="less" scoped>
</style>
