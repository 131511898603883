<template>
  <div class="comOffline">
    <!--搜索-->
    <div v-if="!props.value" class="searchBox">
      <div class="liBox">
        <span style="margin-right: 8px">设备通行地址</span>
        <el-input v-model="address" clearable placeholder="请输入通讯地址"></el-input>
      </div>
      <div class="liBox">
        <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
      </div>
    </div>
    <!--数据列表内容-->
    <div class="contain">
      <!--报警列表-->
      <el-table v-loading="loading" :data="tableData" style="width: 100%" table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="发生时间" prop="sendTime" sortable/>
        <el-table-column label="重新上线时间" prop="onTime" sortable/>
        <el-table-column label="通讯地址" prop="address">
          <template #default="scope">
            <span>{{ scope.row.address }}</span>
            <img alt="" class="adressClass" src="@/assets/img/编组@2x.png"/>
          </template>
        </el-table-column>
      </el-table>
      <div class="pag">
        <el-pagination v-show="tableData.length > 0" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                       :total="total" background layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                       @current-change="pageChange"/>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import {Back, Plus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {getOfflineListApi} from '@/api/modules/warn'
import qs from "qs";

const props = defineProps({
  value: String
})
let router = useRouter()

// 报警-----------------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 报警表格数据
let tableData = ref([])
// 获取报警列表
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getOfflineListApi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    gatewayId: props.value,
    address: address.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 查询
let address = ref() //通讯地址
const searchFun = function () {
  getListFun()
}

onMounted(() => {
  getListFun()
})
</script>

<style lang="less">
.comOffline {
  //搜索
  .searchBox {
    margin-bottom: 16px;
    padding: 16px 0px 0px 24px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .liBox {
      margin-bottom: 16px;
      margin-right: 16px;

      > p {
        margin-bottom: 8px;
      }

      .el-select,
      .el-input {
        width: 220px;
      }

      //.el-button {
      //  margin-top: -1px;
      //}
    }
  }

  //列表内容
  .contain {
    width: calc(100% - 48px);
    background-color: #fff;
    padding: 24px;
    //新增
    .addBtn {
      font-weight: 400;
      margin-bottom: 16px;

      .el-icon {
        margin-right: 5px;
      }
    }

    //表格
    .el-table {
      color: rgba(3, 10, 26, 0.85);

      .el-table__header,
      .el-table__body {
        th {
          background-color: rgb(250, 250, 250);
          font-weight: 500;
        }

        //th:nth-child(2)::before, th:nth-child(3)::before, th:nth-child(4)::before, th:nth-child(5)::before {
        //  content: '';
        //  width: 1px;
        //  height: 1.6em;
        //  position: absolute;
        //  left: 0;
        //  top: 50%;
        //  transform: translateY(-50%);
        //  background-color: rgba(0, 0, 0, 0.06);
        //}
      }

      th,
      td {
        padding: 0;
      }

      .cell {
        padding: 14px 16px;

        .btn {
          display: inline-block;
          color: #307FE2;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }

    //分页
    .pag {
      width: 100%;
      height: 32px;
      padding: 16px 0 0 0;

      .el-pagination {
        float: right;
        margin-right: 12px;
      }
    }
  }

  .adressClass {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-left: 4px;
  }
}
</style>
