<template>
  <div>
    <div class="title" style="display: flex; align-items: center">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/admin/box' }"
        >用电箱管理
        </el-breadcrumb-item
        >
        <el-breadcrumb-item>用电箱详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <el-card header="用电箱信息" shadow="never" style="margin-bottom: 16px">
        <el-form :inline="true" disabled label-width="auto">
          <el-form-item label="用电箱名称" prop="">
            <el-input style="width: 240px"/>
          </el-form-item>
          <el-form-item label="用电箱编号" prop="boxNumber">
            <el-input v-model="form.boxNumber" style="width: 240px"/>
          </el-form-item>
          <el-form-item label="蓝牙编号" prop="">
            <el-input style="width: 240px"/>
          </el-form-item>
          <el-form-item label="二维码" prop="boxQrCode">
            <ImagePreview :height="100" :src="form.boxQrCode" :width="100"/>
          </el-form-item>
        </el-form>
      </el-card>

      <el-card header="操作记录" shadow="never" style="margin-bottom: 16px">
        <el-form ref="queryRef" :inline="true" label-width="auto">
          <el-form-item label="操作类型" prop="">
            <el-select clearable placeholder="请选择操作类型"></el-select>
          </el-form-item>
          <el-form-item label="操作时间" style="width: 480px">
            <el-date-picker
                v-model="dateTimeRange"
                end-placeholder="结束时间"
                range-separator="-"
                start-placeholder="开始时间"
                type="datetimerange"
                value-format="YYYY-MM-DD HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="操作人" prop="">
            <el-input
                clearable
                placeholder="请输入操作人"
                style="width: 240px"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="Search" type="primary">搜索</el-button>
            <el-button icon="Refresh">重置</el-button>
          </el-form-item>
        </el-form>

        <el-row :gutter="10" style="margin-bottom: 8px">
          <el-col :span="1.5">
            <el-button icon="Download" plain type="warning">导出</el-button>
          </el-col>
        </el-row>

        <el-table :data="[]">
          <el-table-column align="center" label="分路" prop=""/>
          <el-table-column align="center" label="操作类型" prop=""/>
          <el-table-column align="center" label="操作时间" prop=""/>
          <el-table-column align="center" label="操作人" prop=""/>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import {getBox} from '@/api/modules/box'
import ImagePreview from '@/components/ImagePreview.vue'
import {ref} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()
const form = ref({})
const dateTimeRange = ref([])

getBox(route.query.id).then((res) => {
  form.value = res.data.result
})
</script>

<style>
/*.title {*/
/*  height: 48px;*/
/*  padding-left: 36px;*/
/*  line-height: 48px;*/
/*}*/

/*.content {*/
/*  padding: 0 28px 24px;*/
/*}*/
</style>
