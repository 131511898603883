<template>
  <div class="powerStationDetail">
    <div class="title">
      <p>
        <span @click="goBack">储能电站</span>
        <span> / </span>
        <span>电站信息</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        电站信息
      </p>
    </div>
    <div class="content">
      <div class="contTitle">详情</div>
      <!--搜索-->
      <div class="searchBox">
        <div style="display: flex; width: calc(100% - 136px);">
          <div class="liBox">
            <span>电站名称:</span>
            <el-input v-model="name" clearable placeholder="请输入电站名称"></el-input>
          </div>
          <div class="liBox">
            <span>电站地址:</span>
            <el-input v-model="address" clearable placeholder="请输入电站地址"></el-input>
          </div>
        </div>
        <div class="liBox" style="width: 136px;margin-right: 24px">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--电站列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%;" table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建设备信息</p>
            </el-empty>
          </template>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="累计发电量" prop="memberName">
            <template #default="scope">
              <span>{{}}</span>
            </template>
          </el-table-column>
          <el-table-column label="总装机容量" prop="customerName"></el-table-column>
          <el-table-column label="当前总功率" prop="createTime"></el-table-column>
          <el-table-column label="累计收益" prop="beforeContent">
            <template #default="scope">
              <p class="cont">{{ scope.row.beforeContent }}</p>
            </template>
          </el-table-column>
          <el-table-column label="地址" prop="currentContent">
            <template #default="scope">
              <p class="cont">{{ scope.row.currentContent }}</p>
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/positionIcon.png"
                   style="width: 14px;height: 14px;">
            </template>
          </el-table-column>
          <el-table-column label="报警数量" prop="remark">
            <template #default="scope"><p class="cont">{{ scope.row.remark }}</p></template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="192px">
            <template #default="scope">
              <div class="opacity">
                <el-tooltip content="报警详情">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon2.png"
                       @click="warnClick(scope.row.name)">
                </el-tooltip>
                <el-tooltip content="电站数据">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon3.png"
                       @click="lookData(scope.row.id)">
                </el-tooltip>
                <el-tooltip content="新建设备">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon4.png"
                       @click="addEquipmentClick(scope.row.id)">
                </el-tooltip>
                <el-tooltip content="删除电站">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon5.png"
                       @click="delClick(scope.row.id)">
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-show="tableData.length > 0" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                         :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--新增设备弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" title="新增设备" width="460">
      <div class="liPox">
        <span>设备名称：</span>
        <el-input v-model="formData.equipmentName"></el-input>
      </div>
      <div class="liPox">
        <span>关联设备：</span>
        <el-select v-model="formData.associationEquipment" :teleported="false" class="planBox">
          <el-option v-for="(item,index) in equipmentList" :key="index" :label="item.name"
                     :value="item.value"></el-option>
        </el-select>
      </div>
      <template #footer>
				<span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
          <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './powerStationDetail.less'
import {Back} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {ElMessage, ElMessageBox} from "element-plus";

let router = useRouter()

const goBack = function () {
  router.push('/admin/energyStation')
}


// 电站列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let name = ref()
let address = ref()
// 参数表格数据
let tableData = ref([{}])
// 获取告警列表
let loading = ref(false)
const getListFun = function () {
  // loading.value = true
  // getLogApi(qs.stringify({
  //   pageNumber: pageNumber.value,
  //   pageSize: pageSize.value,
  //   name: name.value,
  //   address: address.value,
  // })).then(res => {
  //   loading.value = false
  //   if (res.data.code === 0) {
  //     tableData.value = res.data.result.list
  //     total.value = Number(res.data.result.total)
  //   } else {
  //     ElMessage({
  //       message: res.data.message,
  //       type: 'error'
  //     })
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })
}
// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}
// 重置
const resetFun = function () {
  pageNumber.value = 1
  pageSize.value = 20
  name.value = ''
  address.value = ''
  getListFun()
}


// 新增设备-----------------------------------------------------------------
let dialogFormVisible = ref(false) //新增设备弹窗
let equipmentList = ref([]) //关联设备列表
// 新增设备表单数据
let formData = ref({
  equipmentName: '',
  associationEquipment: '',
})
// 点击新增设备
const addEquipmentClick = function (id) {
  dialogFormVisible.value = true
}
// 点击保存
const save = function () {

}


// 报警详情-----------------------------------------------------------------------------------------
const warnClick = function (name) {
  router.push({
    path: '/admin/energyStationWarn',
    query: {
      name: name
    }
  })
}

// 点击查看电站数据-----------------------------------------------------------------------------------------
const lookData = function (id) {
  router.push({
    path: '/admin/energyStationData',
    query: {
      id: id
    }
  })
}

// 点击删除-----------------------------------------------------------------------------------------
const delClick = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除电站',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此电站，删除后数据不可恢复'),
    ])
  }).then(() => {
    // delSearchListApi(id).then(res => {
    //   if (res.data.code === 200) {
    //     getSearchListFun()
    //     ElMessage({
    //       type: 'success',
    //       message: '删除成功'
    //     });
    //   } else {
    //     ElMessage({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    // }).catch(err => {
    //   console.log(err);
    // })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


onMounted(() => {
  getListFun()
})


</script>

<style lang="less" scoped>
</style>
