import { api } from '@/api'

// 查询用电箱管理系统-用电作业许可证列表
export function listPermit(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/permit/page',
    method: 'get',
    params: query,
  })
}

// 查询用电箱管理系统-用电作业许可证详细
export function getPermit(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/permit/detail/' + id,
    method: 'get',
  })
}

// 新增用电箱管理系统-用电作业许可证
export function addPermit(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/permit/save',
    method: 'post',
    data: data,
  })
}

// 修改用电箱管理系统-用电作业许可证
export function updatePermit(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/permit/update',
    method: 'post',
    data: data,
  })
}

// 删除用电箱管理系统-用电作业许可证
export function delPermit(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/permit/remove/' + id,
    method: 'post',
  })
}

export function getUserInfo(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/web/permit/user/info/' + id,
    method: 'get',
  })
}

export function getPermitRecord(id) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/box/app/permit/record/' + id,
    method: 'get',
  })
}
