<template>
  <!--10kV中心配电室高压配电系统图一-->
  <div class="whGOnceImg1">
    <div class="onceImgBgc">
      <div class="bgcImg">
        <img alt="" class="bgc"
             src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/onceImg/wuhan/whDOnceImg5-t3B.png">
        <div v-for="(item,index) in data" :key="index" :class="'tableItem'+(index+1)" class="tableItem">
          <p v-if="!(item.subList&&item.subList.length>0)">Ua (V) : {{ item.ua }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ub (V) : {{ item.ub }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Uc (V) : {{ item.uc }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ia (A) : {{ item.ia }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ib (A) : {{ item.ib }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ic (A) : {{ item.ic }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">P (kw) : {{ item.p }}</p>

          <div v-for="(item_c,index_c) in item.subList" v-if="item.subList&&item.subList.length>0" :key="index_c"
               :class="'dataItemList'+(index_c+1)" class="dataItemList">
            <!--            <div style="width: 85px">-->
            <p>Ia (A) : {{ item_c.ia }}</p>
            <p>Ib (A) : {{ item_c.ib }}</p>
            <!--            </div>-->

            <!--            <br>-->
            <!--            <div>-->
            <p>Ic (A) : {{ item_c.ic }}</p>
            <p>P (kw) : {{ item_c.p }}</p>
            <!--            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, ref} from "vue";
import {getWuhanOnePicDataApi, getEveryOnceImgDataApi} from "@/api/modules/onceImg";
import {ElMessage} from "element-plus";
import qs from "qs";

// 一次图数据
let data = ref({
  dataList: [
    {
      subList: [
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        }
      ]
    },
    {
      subList: [
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
      ]
    },
    {
      subList: [
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
      ]
    },
    {
      subList: [
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }
      ]
    },
    {
      subList: [
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        },
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }
      ]
    },
    {
      subList: [
        {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.001,
          ub: 0.001,
          uc: 0.001,
          ia: 0.001,
          ib: 0.001,
          ic: 0.001,
          p: 0.001,
        }, {
          ua: 0.000,
          ub: 0.000,
          uc: 0.000,
          ia: 0.000,
          ib: 0.000,
          ic: 0.000,
          p: 0.000,
        }
      ]
    }
  ]
})

const resizeFun = function () {
  nextTick(() => {
    let ele = document.querySelector('.onceImgBgc')
    let scaleX = (ele.clientWidth) / 840
    let scaleY = (ele.clientHeight) / 630
    // document.querySelector('.bgcImg').style.transform = `scale(${scaleX},${scaleY})`
    for (let i = 0; i < document.querySelectorAll('.bgcImg').length; i++) {
      document.querySelectorAll('.onceImgBgc .bgcImg')[i].style.transform = `scale(${scaleX},${scaleY})`
    }
  })
}
onMounted(() => {
  nextTick(() => {
    resizeFun()
  })
  window.onresize = () => {
    resizeFun()
  }

  getWuhanOnePicDataApi(qs.stringify({
    type: 41
  })).then(res => {
    if (res.data.code === 0) {
      // data.value = res.data.result
      if (res.data.result.dataList.length > 0) {
        let list = res.data.result
        let newList = list.dataList.slice(9, 10)
        newList.unshift({subList: list.dataList.slice(0, 9)})
        newList.push(
            {subList: list.dataList.slice(10, 20)},
            {subList: list.dataList.slice(20, 28)},
            {subList: list.dataList.slice(28, 37)},
            {subList: list.dataList.slice(37, 46)},
            {subList: list.dataList.slice(46, 49)})
        data.value = newList
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less" scoped>
.whGOnceImg1, .whGOnceImg1 .onceImgBgc {
  width: 100%;
  height: 100% !important;

  .bgcImg {
    width: 840px;
    height: 630px;
    transform-origin: 0% 0%;
    position: relative;

    .bgc {
      width: 100%;
      height: 100%;
    }

    .tableItem {
      font-size: 8px;
      display: none;

      p {
        color: #EAFA42;
        font-size: 8px;
        margin-bottom: 2px;
        transform: scale(0.8);
      }

      p:nth-child(2n) {
        color: #2EEF28;
      }

      p:nth-child(3n) {
        color: #FF0000;
      }

      p:nth-child(7) {
        color: #00F6F4;
      }
    }

    .tableItem1 {
      display: block;
      position: absolute;
      width: 115px;
      top: 125px;
      left: 16px;
    }

    .tableItem2 {
      display: block;
      position: absolute;
      top: 471px;
      left: 136px;
    }

    .tableItem3 {
      display: block;
      position: absolute;
      width: 115px;
      top: 125px;
      left: 197px;
    }

    .tableItem4 {
      display: block;
      position: absolute;
      width: 115px;
      top: 125px;
      left: 318px;
    }

    .tableItem5 {
      display: block;
      position: absolute;
      width: 115px;
      top: 125px;
      left: 440px;
    }

    .tableItem6 {
      display: block;
      position: absolute;
      width: 115px;
      top: 125px;
      left: 562px;
    }

    .tableItem7 {
      display: block;
      position: absolute;
      width: 115px;
      top: 125px;
      left: 696px;
    }


    .dataItemList {
      font-size: 8px;
      display: none;

      p {
        font-size: 8px;
        margin-bottom: 0 !important;
        transform: scale(0.7);
        transform-origin: 0% 0%;
        display: inline-block;
        white-space: nowrap;
        width: 44px;
      }

      p:nth-of-type(1) {
        color: #EAFA42 !important;
      }

      p:nth-of-type(2) {
        color: #2EEF28 !important;
      }

      p:nth-of-type(3) {
        color: #FF0000 !important;
      }

      p:nth-of-type(4) {
        color: #00F6F4 !important;
      }

      p:nth-of-type(3), p:nth-of-type(4) {
        transform: scale(0.7) translateY(-7px) !important;
      }
    }

    .dataItemList1 {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    .dataItemList2 {
      display: block;
      position: absolute;
      top: 53px;
      left: 0;
    }

    .dataItemList3 {
      display: block;
      position: absolute;
      top: 105px;
      left: 0;
    }

    .dataItemList4 {
      display: block;
      position: absolute;
      top: 158px;
      left: 0;
    }

    .dataItemList5 {
      display: block;
      position: absolute;
      top: 211px;
      left: 0;
    }

    .dataItemList6 {
      display: block;
      position: absolute;
      top: 264px;
      left: 0;
    }

    .dataItemList7 {
      display: block;
      position: absolute;
      top: 317px;
      left: 0;
    }

    .dataItemList8 {
      display: block;
      position: absolute;
      top: 370px;
      left: 0;
    }

    .dataItemList9 {
      display: block;
      position: absolute;
      top: 423px;
      left: 0;
    }

    .dataItemList10 {
      display: block;
      position: absolute;
      top: 476px;
      left: 0;
    }

  }
}
</style>
