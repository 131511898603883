import {api} from "@/api";

// 首页数据

export const indexData = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/getIndexData',
        method: 'POST',
        data: data
    })
}
// 获取微应用列表

export const microApplicationList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/microApplication/list',
        method: 'POST',
        data: data
    })
}
// 微应用保存

export const saveMicroApplication = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/microApplication/save',
        method: 'POST',
        data: data
    })
}
//微应用详情

export const detailMicroApplication = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/microApplication/getDetail',
        method: 'POST',
        data: data
    })
}
//微应用删除
export const deleteMicroApplication = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/microApplication/delete',
        method: 'POST',
        data: data
    })
}

// 新增查询数据
export const addHomeQueryApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/addQuery',
        method: 'POST',
        data: data
    })
}
// 删除查询数据
export const delHomeQueryApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/deleteQuery',
        method: 'POST',
        data: data
    })
}
