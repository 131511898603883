import { api } from '@/api'

export function getLandlordStatistics(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/statistics/landlord',
    method: 'post',
    data: data,
  })
}

export function getTenantStatistics(data) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/statistics/tenant',
    method: 'post',
    data: data,
  })
}
