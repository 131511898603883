<template>
  <!--抄读项页面-->
  <div class="parserItem">
    <div class="title">
      <p>
        <span @click="goBack">抄读管理</span>
        <span> / </span>
        <span>抄读项</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        抄读项
      </p>
    </div>
    <div class="content">
      <div class="tableBox" style="height: 90px;padding: 16px;">
        <el-table ref="multipleTableRef" :data="parserDetail" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="抄读名称" min-width="2%" property="name">
          </el-table-column>
          <el-table-column label="备注" min-width="2%" property="remark">
          </el-table-column>
        </el-table>
      </div>
      <div class="itemBox tableBox" style="overflow: auto;">
        <el-button :icon="Plus" style="margin-bottom: 14px;" type="primary" @click="addClick()">新建抄读项</el-button>
        <el-tabs v-model="parserItemId" class="demo-tabs" closable type="card" @tab-remove="removeTab"
                 @tab-change="changeTab">
          <el-tab-pane v-for="item in parserItemList" :key="item.id" :label="item.name" :name="item.id">
          </el-tab-pane>
        </el-tabs>
        <div style="display: flex;justify-content: flex-end;">
          <el-button v-show="parserItemObjArr.length>0" style="margin-bottom: 14px;" type="primary"
                     @click="addClick(1)">编辑抄读项
          </el-button>
        </div>

        <el-table ref="multipleTableRef" :data="parserItemObjArr" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建抄读项信息</p>
            </el-empty>
          </template>
          <el-table-column label="抄读子集" min-width="2%" property="name">
          </el-table-column>
          <el-table-column label="数据标识" min-width="2%" property="dataCode">
          </el-table-column>
          <el-table-column label="包含数据项" min-width="2%" property="number">
          </el-table-column>
        </el-table>
        <el-table ref="multipleTableRef" :data="parserItemDetailList" :row-class-name="getRowClass"
                  style="width: 100%;margin-top: 30px;">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="" min-width="1%" prop="" type="expand">
            <template #default="scope">
              <div class="childTable2">
                <!--子表格-->
                <el-table v-show="scope.row.subList.length>0" :data="scope.row.subList" :row-class-name="getRowClass3"
                          style="width: 100%">
                  <el-table-column label="" min-width="6%" prop=""></el-table-column>
                  <el-table-column label="" min-width="4%" prop="" type="expand">
                    <template #default="scope">
                      <div class="childTable2">
                        <!--子子表格-->
                        <el-table v-show="scope.row.enumList.length>0" :data="scope.row.enumList" style="width: 100%">
                          <el-table-column label="" min-width="3%" prop=""></el-table-column>
                          <el-table-column label="值" min-width="1%" prop="">
                            <template #default="scope">{{ scope.row.val }}</template>
                          </el-table-column>
                          <el-table-column label="对应含义" min-width="3%" prop="">
                            <template #default="scope">{{ scope.row.name }}</template>
                          </el-table-column>
                          <!--<el-table-column v-if="scope.row.isevent==1" label="事件" min-width="1%" prop="">
                            <template #default="scope">{{ scope.row.isevent ? '是' : '否' }}</template>
                          </el-table-column>
                          <el-table-column v-if="scope.row.isevent==1" label="详情OAD" min-width="1%" prop="">
                            <template #default="scope">{{ scope.row.oad }}</template>
                          </el-table-column>-->
                        </el-table>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="配置类型" min-width="9%" prop="">
                    <template #default="scope">{{ scope.row.typeStr }}</template>
                  </el-table-column>
                  <el-table-column label="抄读项" min-width="9%" prop="">
                    <template #default="scope">{{ scope.row.dictName }}</template>
                  </el-table-column>
                  <el-table-column label="起始位" min-width="9%" prop="">
                    <template #default="scope">{{ scope.row.startIdx }}</template>
                  </el-table-column>
                  <el-table-column label="结束位" min-width="9%" prop="">
                    <template #default="scope">{{ scope.row.endIdx }}</template>
                  </el-table-column>
                  <!--<el-table-column prop="" label="是否倒数" min-width="9%">-->
                  <el-table-column label="" min-width="9%" prop="">
                    <!--<template #default="scope">{{scope.row.sortTypeStr}}</template>-->
                  </el-table-column>
                  <!--<el-table-column prop="" label="数据类型" min-width="9%">-->
                  <el-table-column label="" min-width="9%" prop="">
                    <!--<template #default="scope">{{scope.row.dataType}}</template>-->
                  </el-table-column>
                  <el-table-column label="是否有值含义" min-width="9%" prop="">
                    <template #default="scope">{{ scope.row.isenumStr }}</template>
                  </el-table-column>
                  <!--<el-table-column prop="" label="单位" min-width="9%">-->
                  <el-table-column label="" min-width="9%" prop="">
                    <!--<template #default="scope">{{scope.row.unitName}}</template>-->
                  </el-table-column>
                  <el-table-column label="" min-width="9%" prop="">
                    <!--<el-table-column prop="" label="数据精度" min-width="9%">-->
                    <!--<template #default="scope">{{scope.row.precisionStr}}</template>-->
                  </el-table-column>
                  <el-table-column label="是否事件" min-width="9%" prop="">
                    <template #default="scope">{{ scope.row.iseventStr }}</template>
                  </el-table-column>
                  <!--<el-table-column prop="" label="变比" min-width="9%">-->
                  <el-table-column label="" min-width="9%" prop="">
                    <!--<template #default="scope">{{scope.rowchangeStr}}
                    </template>-->
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="配置类型" min-width="2%" property="typeStr">
            <template #default="scope">
              {{ scope.row.typeStr }}
            </template>
          </el-table-column>
          <el-table-column label="抄读项" min-width="2%" property="dictName">
          </el-table-column>
          <el-table-column label="起始字节" min-width="2%" property="startIdx">
          </el-table-column>
          <el-table-column label="结束字节" min-width="2%" property="endIdx">
          </el-table-column>
          <el-table-column label="是否倒序" min-width="2%" property="sortTypeStr">
          </el-table-column>
          <el-table-column label="数据类型" min-width="2%" property="dataType">
          </el-table-column>
          <el-table-column label="是否有值含义" min-width="2%" property="isenumStr">
          </el-table-column>
          <el-table-column label="单位" min-width="2%" property="unitName">
          </el-table-column>
          <el-table-column label="数据精度" min-width="2%" property="precisionStr">
          </el-table-column>
          <el-table-column label="是否事件" min-width="2%" property="iseventStr">
          </el-table-column>
          <el-table-column label="变比" min-width="2%" property="changeStr">
          </el-table-column>

        </el-table>
      </div>
    </div>
    <!--新增、编辑抄读方式弹窗-->
    <el-dialog v-model="parserItemDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <el-form ref="parserItemFormRef" :model="parserItemForm" :rules="parserItemRules" class="parserItemFormRef"
               label-width="auto" style="width:100%">
        <div style="display: flex;justify-content: space-around;">
          <el-form-item label="数据标识" prop="dataCode">
            <el-input v-model="parserItemForm.dataCode" placeholder="请输入数据标识"/>
          </el-form-item>
          <el-form-item label="抄读子集" prop="name">
            <el-input v-model="parserItemForm.name" placeholder="请输入抄读子集"/>
          </el-form-item>
          <el-form-item label="数据数量" prop="number">
            <el-input v-model="parserItemForm.number" :disabled="firstDisabled" placeholder="请输入数量">
              <template #append>
                <button :disabled="firstDisabled"
                        style="color: #1890ff;cursor: pointer;width: 100%;display: inline-block;padding: 0 20px;background-color: transparent;border: none;"
                        @click="createClickF()">生成
                </button>
              </template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <div class="tableBox childTable1" style="padding: 0;">
        <!--父表格------------------------------------>
        <el-table ref="parentTableRef" :data="parserItemDetail" :row-class-name="getRowClass" row-key="sign"
                  style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="" min-width="2%" property="name" type="expand">
            <template #default="scope">
              <!--子①表格--------------------------->
              <div class="childTable" @click="expandChange(scope.$index)" @mouseover="mouseOver(scope.$index)">
                <el-table v-show="scope.row.type==1" ref="childTableRef1" :data="scope.row.subList"
                          :expand-row-keys="expands" :row-class-name="getRowClass3" row-key='sign' style="width: 100%"
                          type='index' @expand-change="expandSelect">
                  <template #empty>
                    <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                    </el-empty>
                  </template>
                  <el-table-column label="" min-width="2%" prop=""></el-table-column>
                  <el-table-column label="" min-width="3%" prop="" type="expand">
                    <template #default="scope">
                      <div class="childTable3" @click="expandChangeT(scope.$index)">
                        <!--子②表格------------------>
                        <el-table :data="scope.row.enumList" style="width: 100%">
                          <template #empty>
                            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                            </el-empty>
                          </template>
                          <el-table-column label="" min-width="9%" prop=""></el-table-column>
                          <el-table-column label="值" min-width="3%" prop="">
                            <template #default="scope">
                              <el-input v-model="scope.row.val" type="number"></el-input>
                            </template>
                          </el-table-column>
                          <el-table-column label="对应含义" min-width="6%" prop="">
                            <template #default="scope">
                              <el-input v-model="scope.row.name"></el-input>
                            </template>
                          </el-table-column>

                          <!--<el-table-column v-if="scope.row.isevent" label="" min-width="2%" prop="">
                            <template #default="scope">
                              <div class="checkBoxClass">
                                <el-checkbox v-model="scope.row.isevent">事件</el-checkbox>
                              </div>
                            </template>
                          </el-table-column>
                          <el-table-column v-else label="" min-width="15%" prop="">
                          </el-table-column>
                          <el-table-column v-if="scope.row.isevent" label="详情OAD" min-width="15%" prop="">
                            <template #default="scope">
                              <el-input v-model="scope.row.oad"
                                        @input="scope.row.oad = scope.row.oad.replace(/[\u4e00-\u9fa5d]/g, '');"></el-input>
                            </template>
                          </el-table-column>-->
                        </el-table>
                      </div>
                    </template>
                  </el-table-column>
                  <!--子①抄读项-->
                  <el-table-column label="抄读项" min-width="4%" prop="">
                    <template #default="scope">
                      <el-tree-select v-model="scope.row.dictId" :data="dictList" :load="load" :props="props" lazy
                                      @node-click="(data)=>clickUnitTreeNodeChild2(data,scope.$index)"/>
                      <!--<el-select v-model="scope.row.dictId" class="select-unitName select-option-father" popper-class="select_report_and_type" :popper-append-to-body="false" ref="elSelectTable">
                        <el-option :value="dictList">
                          <el-tree class="unit-tree" node-key="label" :props="defaultProps" :default-expanded-keys="unitTreeExpandedId" ref="tableDom" highlight-current :expand-on-click-node='false' @node-click="clickUnitTreeNodeChild" lazy :load="load" @current-change="rowClick(scope.$index)">
                          </el-tree>
                        </el-option>
                      </el-select>-->
                    </template>
                  </el-table-column>
                  <!--子①起始字节-->
                  <el-table-column label="起始位" min-width="3%" prop="">
                    <template #default="scope">
                      <el-input v-model="scope.row.startIdx"
                                @change="startIdxChange(scope.row.isenum,scope.$index,scope.row)"></el-input>
                    </template>
                  </el-table-column>
                  <!--子①结束字节-->
                  <el-table-column label="结束位" min-width="3%" prop="">
                    <template #default="scope">
                      <el-input v-model="scope.row.endIdx"
                                @change="startIdxChange(scope.row.isenum,scope.$index,scope.row)"></el-input>
                    </template>
                  </el-table-column>
                  <el-table-column label="" min-width="2%" prop="">
                  </el-table-column>
                  <!--子①是否有值含义-->
                  <el-table-column label="是否有值含义" min-width="5%" prop="">
                    <template #default="scope">
                      <!--子②表格------------------>
                      <el-radio v-model="scope.row.isenum" label="1"
                                @change="radioChange(scope.row.isenum,scope.$index,scope.row)">是
                      </el-radio>
                      <el-radio v-model="scope.row.isenum" label="0"
                                @change="radioChange(scope.row.isenum,scope.$index,scope.row)">否
                      </el-radio>
                    </template>
                  </el-table-column>
                  <!--子①单位-->
                  <!--<el-table-column label="单位" min-width="2%" prop="">
                    <template #default="scope">
                      <el-input v-if="scope.row.isenum=='0'" v-model="scope.row.unitName"></el-input>
                    </template>
                  </el-table-column>-->
                  <!--子①精度-->
                  <!--<el-table-column label="数据精度" min-width="3%" prop="">
                    <template #default="scope">
                      <el-input v-if="scope.row.isenum=='0'" v-model="scope.row.precisionStr" max="9" min="0" type="number"></el-input>
                    </template>
                  </el-table-column>-->
                  <!--子①事件-->
                  <el-table-column label="事件" min-width="11%" prop="">
                    <template #default="scope">
                      <div class="checkBoxClass">
                        <el-checkbox v-model="scope.row.isevent">事件</el-checkbox>
                      </div>
                    </template>
                  </el-table-column>
                  <!--子①变比-->
                  <!--<el-table-column label="变比" min-width="4%" prop="">
                    <template #default="scope">
                      <el-checkbox v-if="scope.row.isenum=='0'" v-model="scope.row.isa">电流</el-checkbox>
                      <el-checkbox v-if="scope.row.isenum=='0'" v-model="scope.row.isv">电压</el-checkbox>
                    </template>
                  </el-table-column>-->
                  <el-table-column label="操作" min-width="2%" prop="">
                    <template #default="scope">
                      <a class="deleteClass" @click="deleteClickS(scope.$index)">
                        删除
                      </a>
                    </template>
                  </el-table-column>
                </el-table>
                <el-button v-if="scope.row.type==1" :icon="Plus" circle class="addButtonClassF" type="primary"
                           @click="addCreateS(scope.$index)"></el-button>
              </div>
            </template>
          </el-table-column>
          <!--父表格内容----------------------------->
          <el-table-column label="配置类型" min-width="3%" prop="">
            <template #default="scope">
              <select v-model="scope.row.type" placeholder="请选择" style="width:100%">
                <option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id">
                </option>
              </select>
            </template>
          </el-table-column>
          <el-table-column label="抄读项" min-width="4%" prop="">
            <!--filterable(TODO)-->
            <template #default="scope">
              <el-tree-select v-show="scope.row.type==0" v-model="scope.row.dictId" :data="dictList" :load="load"
                              :props="props" lazy @node-click="(data)=>clickUnitTreeNodeChild(data,scope.$index)"/>
              <!--<el-select v-model="scope.row.dictId" class="select-unitName select-option-father" popper-class="select_report_and_type" :popper-append-to-body="false" ref="elSelectTable" v-show="scope.row.type==0">
                <el-option :value="dictList">
                  <el-tree class="unit-tree" node-key="label" :props="defaultProps" :default-expanded-keys="unitTreeExpandedId" ref="tableDom" highlight-current :expand-on-click-node='false' @node-click="clickUnitTreeNode" lazy :load="load" @current-change="rowClick(scope.$index)">
                  </el-tree>
                </el-option>
              </el-select>-->
            </template>
          </el-table-column>
          <el-table-column label="起始字节" min-width="3%" prop="">
            <template #default="scope">
              <el-input v-model="scope.row.startIdx"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="结束字节" min-width="3%" prop="">
            <template #default="scope">
              <el-input v-model="scope.row.endIdx"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="" min-width="2%" prop="">
            <template #default="scope">
              <div v-if="scope.row.type==0" class="checkBoxClass">
                <el-checkbox v-show="scope.row.type==0" v-model="scope.row.sortType">倒序</el-checkbox>
              </div>
              <!--子①表格-->
              <span v-if="scope.row.type==1" class="numText">子项数量</span>
            </template>
          </el-table-column>
          <el-table-column label="数据类型" min-width="5%" prop="">
            <template #default="scope">
              <select v-show="scope.row.type==0" v-model="scope.row.dataType" placeholder="请选择" style="width:100%">
                <option v-for="item in dataTypeList" :key="item" :label="item" :value="item">
                </option>
              </select>
              <!--子①表格-->
              <div v-if="scope.row.type==1">
                <input v-model="scope.row.codeNum2" :disabled="scope.row.secondDisabled" class="inputClass3"
                       type="text"/>
                <el-button :disabled="scope.row.secondDisabled" class="clickButtonClass2" plain type="primary"
                           @click="createClickS(scope.$index,scope.row.codeNum2,scope.row)">生成
                </el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="单位" min-width="2%" prop="">
            <template #default="scope">
              <el-input v-show="scope.row.type==0" v-model="scope.row.unitName"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="数据精度" min-width="3%" prop="">
            <template #default="scope">
              <el-input v-show="scope.row.type==0" v-model="scope.row.precisionStr" max="9" min="0"
                        type="number"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="" min-width="2%" prop="">
            <template #default="scope">
              <div v-if="scope.row.type==0" class="checkBoxClass">
                <el-checkbox v-show="scope.row.type==0" v-model="scope.row.isevent">事件</el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="变比" min-width="4%" prop="">
            <template #default="scope">
              <el-checkbox v-show="scope.row.type==0" v-model="scope.row.isa">电流</el-checkbox>
              <el-checkbox v-show="scope.row.type==0" v-model="scope.row.isv">电压</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="2%" prop="">
            <template #default="scope">
              <a class="deleteClass" @click.prevent="deleteClickF(scope.$index)">删除</a>
            </template>
          </el-table-column>
        </el-table>
        <el-button :icon="Plus" circle class="addButtonClassF" type="primary" @click="addCreateF()"></el-button>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="parserItemDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="parserItemSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, nextTick, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus, Back} from '@element-plus/icons-vue'
import {v4 as uuidv4} from "uuid"
import {
  getDetail,
  getList,
  getParserObj,
  saveList,
  deleteList,
  getParserItemSelect,
  getDataType,
  getDictCategorySelect,
  getDictSelect,
  getDictIdList,
  getParserItemDictList
} from '../../api/modules/parserItem'
import './parserItem.less'
import qs from 'qs';

let router = useRouter()
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

// 返回
const goBack = function () {
  router.push('/admin/parser')
}

/*生命周期*/
onMounted(() => {
  getListFun()
  getDetailFun()
  getDictCategorySelectFun()
  getDataTypeFun()
  getdictAllFun()
})
//抄读************************************
let parserDetail = ref([]) //当前抄读列表
//获取当前抄读列表
const getDetailFun = function () {
  parserDetail.value = []
  getDetail(qs.stringify({
    id: router.currentRoute.value.query.id
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      parserDetail.value.push(res.data.result)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//抄读项************************************
let parserItemId = ref() //抄读项id
let parserItemList = ref() //抄读项列表
//获取所有抄读项列表
const getListFun = function () {
  getList(qs.stringify({
    parserId: router.currentRoute.value.query.id
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      parserItemList.value = res.data.result
      if (!parserItemId.value && parserItemList.value && parserItemList.value.length > 0) {
        parserItemId.value = parserItemList.value[0].id
      }
      changeTab()
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let dictAllList = ref([]) //所有字典列表
const getdictAllFun = function () {
  getDictSelect(qs.stringify({
    categoryId: 0
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      dictAllList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let parserItemObjArr = ref([]) //抄读项信息
let parserItemDetailList = ref([]) //抄读项信息列表
//抄读项tab改变
const changeTab = async function () {
  parserItemObjArr.value = []
  if (parserItemId.value) {
    openFullScreen()
    await getParserObj(qs.stringify({
      id: parserItemId.value
    })).then(res => {
      //console.log(res);
      loadingAdd.value.close();
      if (res.data.ok) {
        var arr = []
        arr.push(res.data.result)
        parserItemObjArr.value = arr
        parserItemDetailList.value = res.data.result.list
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
}
//抄读项删除
const removeTab = function (e) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除抄读项',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此抄读项，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: e
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        parserItemId.value = ''
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
let title = ref() //弹窗标题
let dialogWidth = ref('99%') //新增抄读项弹窗宽度
let parserItemDialogVisible = ref(false) //新增抄读项弹窗开关
let parserItemDetail = ref([])
let typeList = ref([]) //配置列表
let dataTypeList = ref() //数据类型列表
//获取配置、数据类型列表
const getDataTypeFun = function () {
  getDataType(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      for (var i = 0; i < res.data.result.dataTypeList.length; i++) {
        res.data.result.dataTypeList[i] = res.data.result.dataTypeList[i].replace(/\s*/g, "")
      }
      dataTypeList.value = res.data.result.dataTypeList
      typeList.value = res.data.result.typeList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//新增、编辑抄读项的点击
const addClick = async function (val, row) {
  parserItemForm.value = {}
  parserItemDetail.value = []
  if (val) {
    firstDisabled.value = true
    title.value = '编辑抄读项'
    parserItemForm.value.name = parserItemObjArr.value[0].name
    parserItemForm.value.dataCode = parserItemObjArr.value[0].dataCode
    parserItemForm.value.number = parserItemObjArr.value[0].number
    await changeTab()
    parserItemDetail.value = parserItemDetailList.value
    parserItemDetail.value.forEach(item => {
      item.sign = uuidv4()
      if (item.subList.length > 0) {
        item.secondDisabled = true
      }
      item.codeNum2 = item.subList.length
      if (item.sortType == 0) {
        item.sortType = false
      } else if (item.sortType == 1) {
        item.sortType = true
      }
      if (item.isevent == 0) {
        item.isevent = false
      } else if (item.isevent == 1) {
        item.isevent = true
      }
      if (item.isa == 0) {
        item.isa = false
      } else if (item.isa == 1) {
        item.isa = true
      }
      if (item.isv == 0) {
        item.isv = false
      } else if (item.isv == 1) {
        item.isv = true
      }
      if (item.type == 1) {
        item.titleName = ''
      }
      item.dictId = Number(item.dictId)
      item.subList.forEach((item_z, i_z) => {
        item_z.sign = uuidv4()
        if (item_z.isevent == 0) {
          item_z.isevent = false
        } else if (item_z.isevent == 1) {
          item_z.isevent = true
        }
        if (item_z.isv == 0) {
          item_z.isv = false
        } else if (item_z.isv == 1) {
          item_z.isv = true
        }
        if (item_z.isa == 0) {
          item_z.isa = false
        } else if (item_z.isa == 1) {
          item_z.isa = true
        }
        if (item_z.isenum == 0) {
          item_z.isenum = '0'
        } else if (item_z.isenum == 1) {
          item_z.isenum = '1'
        }
        item_z.enumList.forEach((item_t, i_t) => {
          if (item_t.isevent == 0) {
            item_t.isevent = false
          } else if (item_t.isevent == 1) {
            item_t.isevent = true
          }
        })
      })
    })
    parserItemDetail.value.forEach((item, index) => {
      item.index == index
      dictAllList.value.forEach(items => {
        if (item.dictId == items.id) {
          item.dictIdCopy = item.dictId
          item.dictId = items.name
        }
        item.subList.forEach(itemc => {
          if (itemc.dictId == items.id) {
            item.dictIdCopy = item.dictId
            itemc.dictId = items.name
          }
        })
      })
    })
    //this.codeNum = this.tableData.length
    //this.totalCode = this.tableData.length
  } else {
    title.value = '新增抄读项'
    firstDisabled.value = false
  }
  getDictIdListFun()
  parserItemDialogVisible.value = true
}
let parserItemFormRef = ref() //抄读项
let firstDisabled = ref(false) //第一级新增按钮禁用
//抄读项表单
let parserItemForm = ref({
  name: '',
  dataCode: '',
  number: ''
})
// 抄读项表单验证规则
let parserItemRules = ref({
  name: [{
    required: true,
    message: '请输入抄读子集',
    trigger: 'blur'
  }],
  dataCode: [{
    required: true,
    message: '请输入数据标识',
    trigger: 'blur'
  }]
})
/*表单******************************************/

//第一层每行
const shellFun = function () {
  parserItemDetail.value.push({
    "type": "0",
    "dictId": '',
    "startIdx": '',
    "endIdx": '',
    "sortType": false,
    "dataType": '',
    "unitName": "",
    "precisionStr": "",
    "isevent": false,
    "isa": false,
    "isv": false,
    "subList": [],
    "sign": uuidv4()
  })
}
//第一层生成
const createClickF = function () {
  firstDisabled.value = true
  for (var i = 1; i <= parserItemForm.value.number; i++) {
    shellFun()
    parserItemDetail.value.forEach((item, index) => {
      item.index = index + 1
    })
  }
}
//第一层新增+1
const addCreateF = function () {
  var open = false;
  parserItemDetail.value.forEach((item, index) => {
    if (item.type == 0) {
      if (item.dictId || item.dictId == '0') {
        open = true;
      } else {
        open = false;
        ElMessage({
          message: '请输入抄读项',
          type: 'info',
        })
      }
    } else {
      open = true;
    }
  })
  if (open == true) {
    shellFun();
    parserItemDetail.value.forEach((item, index) => {
      item.index = index + 1
    })
    parserItemForm.value.number = parserItemDetail.value.length
  }
}
//第一层删除
const deleteClickF = function (index) {
  parserItemDetail.value.forEach((item, i) => {
    if (i == index) {
      parserItemDetail.value.splice(index, 1);
    }
  })
  console.log(parserItemDetail.value)
  parserItemForm.value.number = parserItemDetail.value.length
}
const parentTableRef = ref() //父表格
let expands = ref([])
const expandSelect = function (row, expandedRows) {
  if (expandedRows.length) {
    expands.value = []
    if (row) {
      expands.value.push(row.sign)
    }
  } else {
    expands = []
  }
}
//第二层生成
const createClickS = function (index, num, row) {
  parserItemDetail.value.forEach((item, i) => {
    if (item.type == 1) {
      if (i == index) {
        for (var i = 0; i < num; i++) {
          item.subList.push({
            "dictId": '',
            "startIdx": '',
            "endIdx": '',
            "isenum": '0',
            "unitName": '',
            "precisionStr": '',
            "isevent": false,
            "isa": false,
            "isv": false,
            "sign": uuidv4()
          })
        }
        item.subList.forEach((item_z, i_z) => {
          item.secondDisabled = true
        })
      }
    }
  })
  parentTableRef.value.toggleRowExpansion(row);
}
//第二层新增+1
const addCreateS = function (index) {
  parserItemDetail.value.forEach((item, i) => {
    if (item.type == 1) {
      if (i == index) {
        item.subList.push({
          "dictId": '',
          "startIdx": '',
          "endIdx": '',
          "isenum": '0',
          "unitName": '',
          "precisionStr": '',
          "isevent": false,
          "isa": false,
          "isv": false,
          "sign": uuidv4()

        })
      }
      item.subList.forEach((item_z, i_z) => {
        item.codeNum2 = item.subList.length
      })
    }
  })
}
let childrenId = ref()
let parentIndex = ref()
//第二层删除
const deleteClickS = function (index) {
  childrenId.value = ''
  window.localStorage.setItem('childrenId', index)
}
//第二层表格的点击（删除）
const expandChange = function (parentIndex2) {
  parentIndex.value = parentIndex2
  childrenId.value = window.localStorage.getItem('childrenId')
  if (childrenId.value) {
    parserItemDetail.value.forEach((item, i) => {
      if (item.type == 1) {
        if (i == parentIndex.value) {
          item.subList.forEach((item_z, i_z) => {
            if (i_z == childrenId.value) {
              item.subList.splice(childrenId.value, 1);
              window.localStorage.setItem('childrenId', '') //处理删除行的点击触发删除
              item.codeNum2 = item.subList.length
            }
          })
        }
      }
    })
  }
}
/*
 * 第三层
 * */
let childTableRef1 = ref() //子①表格
//起始字节、结束字节改变&&值的含义为是
const startIdxChange = function (val, index, row) {
  if (val == 1) {
    parserItemDetail.value.forEach((item, index_z) => {
      if (index_z == parentIndex.value) {
        item.subList.forEach((item_s, index_s) => {
          if (index == index_s) {
            item_s.enumList = []
          }
        })
      }
    })
    radioChange(val, index, row)
    childTableRef1.value.toggleRowExpansion(row);
  }
}
//单选框改变
const radioChange = function (val, index, row) {
  if (row.endIdx >= 0 && row.startIdx >= 0) {
    var byteNum = row.endIdx - row.startIdx + 1 //字节
    var binary = '' //二进制数
    for (var i = 0; i < byteNum; i++) {
      binary = binary + '1'
    }
    /*二进制转十进制*/
    var decimalism; //十进制数
    decimalism = parseInt(binary, 2)
    if (val == 0) { //否 删除所有
      parserItemDetail.value.forEach((item, index_z) => {
        if (index_z == parentIndex.value) {
          item.subList.forEach((item_s, index_s) => {
            if (index == index_s) {
              item_s.enumList = []
            }
          })
        }
      })
    } else if (val == 1) { //是 增加
      parserItemDetail.value.forEach((item, index_z) => {
        if (index_z == parentIndex.value) {
          item.subList.forEach((item_s, index_s) => {
            if (index == index_s) {
              var arr = []
              for (var i = 0; i <= decimalism; i++) {
                arr.push({
                  val: i,
                  name: '',
                  isevent: false,
                  oad: '',
                })
                item_s.enumList = arr
              }
            }
          })
        }
      })
    }
    childTableRef1.value.toggleRowExpansion(row);
  }
}
let parentIndexT = ref()
let childrenIdT = ref()
//第三层表格的点击（删除）
const expandChangeT = function (parentIndex) {
  parentIndexT.value = parentIndex
  childrenIdT.value = window.localStorage.getItem('childrenIdT')
  if (childrenIdT.value) {
    parserItemDetail.value.forEach((item, i) => {
      if (i == parentIndex.value) {
        item.subList.forEach((item_z, i_z) => {
          if (i_z == parentIndexT.value) {
            item_z.enumList.forEach((item_s, index_s) => {
              if (index_s == childrenIdT.value) {
                window.localStorage.setItem('childrenIdT', '') //处理删除行的点击触发删除
                item_z.enumList.splice(childrenIdT.value, 1);
                item_z.codeNum3 = item_z.enumList.length
              }
            })
          }
        })
      }
    })
  }
}
let parent_index = ref()
const mouseOver = function (parent_index2) {
  parent_index.value = parent_index2
}
/*树级select*/
const props = {
  label: 'name',
  children: 'subList',
  isLeaf: 'isLeaf'
}
let dictList = ref([]) //字典列表
const load = (node, resolve) => {
  /*懒加载缓存刷新处理*/
  if (node.level == 0) {
    resolve(dictList.value)
  }
  if (node.level == 1) {
    dictList.value.forEach(item => {
      if (node.data.id == item.id) {
        resolve(item.subList)
      }
    })
  }
  if (node.level == 2) {
    getDictSelectFun(node, resolve);
  }
  if (node.level == 3) {
    resolve([])
  }

}
//子表格数据字典改变事件
const clickUnitTreeNodeChild = function (e, index) {
  if (title.value == '新增抄读项') {
    var falg = true
    nextTick(() => {
      dictIdList.value.forEach((item, i) => {
        if (e.id == item) { //第一层筛选
          ElMessage({
            message: '字典重复！',
            type: 'info',
          })
          parserItemDetail.value[index].dictId = ''
          falg = false
        } else {
          falg = true
        }
      })
      if (falg) {
        let flag2 = true
        parserItemDetail.value.forEach((item, i) => { //第二层筛选
          if (item.dictId == e.id && index != i) {
            ElMessage({
              message: '字典重复！',
              type: 'info',
            })
            flag2 = false
            parserItemDetail.value[index].dictId = ''
          }
          item.subList.forEach((items, i_s) => { //第三层筛选
            if (items.dictId == e.id) {
              ElMessage({
                message: '字典重复！',
                type: 'info',
              })
              flag2 = false
              parserItemDetail.value[index].dictId = ''
            }
          })
        })
        if (flag2) {
          parserItemDetail.value[index].dictId = e.id
        }
      }
    })
  } else if (title.value == '编辑抄读项') {
    parserItemDetail.value[index].dictIdCopy = e.id
    var falg = true
    nextTick(() => {
      dictIdList.value.forEach((item, i) => {
        if (e.id == item) { //第一层筛选
          ElMessage({
            message: '字典重复！',
            type: 'info',
          })
          parserItemDetail.value[index].dictId = ''
          parserItemDetail.value[index].dictIdCopy = ''
          falg = false
        } else {
          falg = true
        }
      })
      if (falg) {
        parserItemDetail.value.forEach((item, i) => { //第二层筛选
          if (item.dictIdCopy == e.id && index != i) {
            ElMessage({
              message: '字典重复！',
              type: 'info',
            })
            parserItemDetail.value[index].dictId = ''
            parserItemDetail.value[index].dictIdCopy = ''
          }
          item.subList.forEach((items, i_s) => { //第三层筛选
            if (items.dictIdCopy == e.id) {
              ElMessage({
                message: '字典重复！',
                type: 'info',
              })
              parserItemDetail.value[index].dictId = ''
              parserItemDetail.value[index].dictIdCopy = ''
            }
          })
        })
      }
    })
  }
  /*if(!e.isLeaf) {
    ElMessage({
      message: '请选择数据字典',
      type: 'info',
    })
  }*/
}
//父表格数据字典改变事件
const clickUnitTreeNodeChild2 = function (e, index) {
  //console.log(dictIdList.value)
  if (title.value == '新增抄读项') {
    var falg = true
    nextTick(() => {
      dictIdList.value.forEach((item, i) => {
        if (e.id == item) { //第一层筛选
          ElMessage({
            message: '字典重复！',
            type: 'info',
          })
          parserItemDetail.value[parent_index.value].subList[index].dictId = ''
          falg = false
        } else {
          falg = true
        }
      })
      if (falg) {
        parserItemDetail.value.forEach((item, i) => { //第二层筛选
          if (item.dictId == e.id) {
            ElMessage({
              message: '字典重复！',
              type: 'info',
            })
            parserItemDetail.value[parent_index.value].subList[index].dictId = ''
          }
          item.subList.forEach((items, i_s) => { //第三层筛选
            if (items.dictId == e.id && index != i_s) {
              ElMessage({
                message: '字典重复！',
                type: 'info',
              })
              parserItemDetail.value[parent_index.value].subList[index].dictId = ''
            }
          })
        })
      }
    })
  } else if (title.value == '编辑抄读项') {
    parserItemDetail.value[parent_index.value].subList[index].dictIdCopy = e.id
    var falg = true
    nextTick(() => {
      dictIdList.value.forEach((item, i) => {
        if (e.id == item) { //第一层筛选
          ElMessage({
            message: '字典重复！',
            type: 'info',
          })
          parserItemDetail.value[parent_index.value].subList[index].dictId = ''
          parserItemDetail.value[parent_index.value].subList[index].dictIdCopy = ''
          falg = false
        } else {
          falg = true
        }
      })
      if (falg) {
        parserItemDetail.value.forEach((item, i) => { //第二层筛选
          if (item.dictIdCopy == e.id) {
            ElMessage({
              message: '字典重复！',
              type: 'info',
            })
            parserItemDetail.value[parent_index.value].subList[index].dictId = ''
            parserItemDetail.value[parent_index.value].subList[index].dictIdCopy = ''
          }
          item.subList.forEach((items, i_s) => { //第三层筛选
            if (items.dictIdCopy == e.id && index != i_s) {
              ElMessage({
                message: '字典重复！',
                type: 'info',
              })
              parserItemDetail.value[parent_index.value].subList[index].dictId = ''
              parserItemDetail.value[parent_index.value].subList[index].dictIdCopy = ''
            }
          })
        })
      }
    })
  }
}

//获取字典分类列表
const getDictCategorySelectFun = function () {
  getDictCategorySelect(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      dictList.value = res.data.result
      dictList.value.forEach(item => {
        item.value = item.id
        item.disabled = true
        item.subList.forEach(items => {
          items.value = items.id
          items.disabled = true
        })
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//获取字典列表
const getDictSelectFun = function (node, resolve) {
  getDictSelect(qs.stringify({
    categoryId: node.data.id
  })).then(res => {
    if (res.data.ok) {
      let arr = res.data.result
      if (arr && arr.length > 0) {
        // arr[0].isLeaf = true
        // arr[0].value = arr[0].id
        for (let i = 0; i < arr.length; i++) {
          arr[i].isLeaf = true
          arr[i].value = arr[i].id
        }
        return resolve(arr);
      } else {
        return resolve([]);
      }

    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let dictIdList = ref([])
//获取获取抄读项使用的字典id列表
const getDictIdListFun = function () {
  var parserItemIdVal;
  if (title.value == '新增抄读项') {
    parserItemIdVal = ''
  } else {
    parserItemIdVal = parserItemId.value
  }
  getDictIdList(qs.stringify({
    id: router.currentRoute.value.query.id
  })).then(res => {
    //console.log(res,'抄读');
    if (res.data.ok) {
      dictIdList.value = res.data.result
      if (parserItemIdVal) {
        getParserItemDictList(qs.stringify({
          id: parserItemIdVal
        })).then(res => {
          //console.log(res,'抄读项');
          if (res.data.ok) {
            const arr = dictIdList.value.filter(item1 => !res.data.result.some(item2 => item2 === item1))
            dictIdList.value = arr
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
//控制按钮的展示
const getRowClass = function (row, rowIndex) {
  let data = row.row;
  let res = [];
  if (data.subList && data.subList.length > 0) {
    res.push('row-expand-has')
    return res;
  } else {
    res.push('row-expand-unhas')
    return res;
  }
}
//控制按钮的展示2
const getRowClass3 = function (row, rowIndex) {
  let data = row.row;
  let res = [];
  if (data.enumList && data.enumList.length > 0) {
    res.push('row-expand-has')
    return res;
  } else {
    res.push('row-expand-unhas')
    return res;
  }
}
//抄读项的保存
const parserItemSave = function () {
  let data = JSON.parse(JSON.stringify(parserItemDetail.value))
  data.forEach(item => {
    if (!item.sortType) {
      item.sortType = 0
    } else {
      item.sortType = 1
    }
    if (!item.isevent) {
      item.isevent = 0
    } else {
      item.isevent = 1
    }
    if (!item.isa) {
      item.isa = 0
    } else {
      item.isa = 1
    }
    if (!item.isv) {
      item.isv = 0
    } else {
      item.isv = 1
    }
    if (item.subList.length > 0) {
      item.subList.forEach((item_z, i_z) => {
        if (!item_z.isevent) {
          item_z.isevent = 0
        } else {
          item_z.isevent = 1
        }
        if (!item_z.isa) {
          item_z.isa = 0
        } else {
          item_z.isa = 1
        }
        if (!item_z.isv) {
          item_z.isv = 0
        } else {
          item_z.isv = 1
        }
        if (item_z.enumList && item_z.enumList.length > 0) {
          item_z.enumList.forEach(item_d => {
            if (!item_d.isevent) {
              item_d.isevent = 0
            } else {
              item_d.isevent = 1
            }
          })
        }
      })
    }
  })
  data.forEach(item => {
    if (item.type == '1') {
      item.dictId = ''
      item.sortType = 0
      item.dataType = ''
      item.unitName = ''
      item.precisionStr = ''
      item.isevent = 0
      item.isa = 0
      item.isv = 0
    }
    item.subList.forEach(item_s => {
      if (item_s.isenum == '1') {
        item_s.unitName = ''
        item_s.precisionStr = ''
        //item_s.isevent = 0
        item_s.isa = 0
        item_s.isv = 0
      }
    })
  })
  data.forEach((item, i) => {
    dictAllList.value.forEach(items => {
      if (item.dictId == items.name) {
        item.dictId = items.id
      }
      item.subList.forEach(itemc => {
        if (itemc.dictId == items.name) {
          itemc.dictId = items.id
        }
      })
    })
  })
  let params = {
    parserId: router.currentRoute.value.query.id,
    name: parserItemForm.value.name,
    dataCode: parserItemForm.value.dataCode,
    detailJsonStr: JSON.stringify(data)
  }
  //console.log(parserItemDetail.value)
  var falg = false;
  data.forEach(item => {
    if (item.subList && item.subList.length > 0) {
      item.subList.forEach(items => {
        if (items.isenum == '1') {
          items.enumList.forEach(item_c => {
            if (item_c.name === '' || item_c.val === '') {
              falg = true
            }
          })
        }
      })
    }
  })
  //console.log(params,parserItemId.value)
  if (parserItemFormRef) {
    parserItemFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        if (title.value == '新增抄读项') {
          params['id'] = 0
        } else if (title.value == '编辑抄读项') {
          params['id'] = parserItemId.value
        }
        if (falg == false) {
          saveList(
              qs.stringify(params)
          ).then(async res => {
            //console.log(res);
            if (res.data.ok) {
              parserItemDialogVisible.value = false
              getListFun()
            } else {
              ElMessage({
                message: res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            message: '请输入必填项！',
            type: 'info'
          })
        }
      }
    })
  }
}
</script>
