<template>
  <div class="powerStationData">
    <div class="title">
      <p>
        <span @click="goBack">储能电站</span>
        <span> / </span>
        <span @click="goBack2">电站信息</span>
        <span> / </span>
        <span>电站数据</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack2">
          <Back/>
        </el-icon>
        电站数据
      </p>
    </div>
    <div class="content">
      <div class="contentBox">
        <div v-for="(item,index) in dataList" :key="index"
             :style="{width:itemNum===4?'calc(25% - 39.5px)':'calc(20% - 42.5px)',marginRight:(index+1)%itemNum===0?'0':'10px'}"
             class="contItem">
          <div class="head">
            <p class="name">{{ item.name }}</p>
            <el-icon style="cursor: pointer" @click="lookEquipmentDetail(item.id)">
              <ArrowRight/>
            </el-icon>
          </div>
          <div class="centerBox">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/dataItemCopy.png">
            <div class="statusBox">
              <p>状态</p>
              <p :class="item.status==1?'online':'offline'">{{ item.status == 1 ? '在线' : '离线' }}</p>
            </div>
            <div class="warnBox">
              <p>告警</p>
              <p>{{ item.warnNum }}</p>
            </div>
          </div>
          <div class="mainBox">
            <div class="mainItem">
              <p>
                <span class="val">{{ item.val1 }}</span>
                <span class="unit">A</span>
              </p>
              <p>电流</p>
            </div>
            <div class="mainItem">
              <p>
                <span class="val">{{ item.val2 }}</span>
                <span class="unit">V</span>
              </p>
              <p>电压(V)</p>
            </div>
            <div class="mainItem">
              <p>
                <span class="val">{{ item.val3 }}</span>
                <span class="unit">kwh</span>
              </p>
              <p>交流侧总有功率</p>
            </div>
            <div class="mainItem">
              <p>
                <span class="val">{{ item.val4 }}</span>
                <span class="unit">kwh</span>
              </p>
              <p>直流输出功率</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import './powerStationData.less'
import {Back, ArrowRight} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";

let router = useRouter()

const goBack = function () {
  router.push('/admin/energyStation')
}

const goBack2 = function () {
  router.push('/admin/energyStationDetail')
}


// 数据列表
let dataList = ref([
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 0,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  },
  {
    name: '逆变器1',
    status: 1,
    warnNum: 48,
    val1: 12,
    val2: 12,
    val3: 12,
    val4: 12,
  }
])


// 点击查看设备详情
const lookEquipmentDetail = function (id) {
  router.push({
    path: '/admin/energyStationEquipment',
    query: {
      id: id
    }
  })
}


// 控制一排四个还是五个
let itemNum = ref(4)
onMounted(() => {
  if (window.innerWidth > 1800) {
    itemNum.value = 5
  } else {
    itemNum.value = 4
  }
  window.addEventListener('resize', () => {
    if (window.innerWidth > 1800) {
      itemNum.value = 5
    } else {
      itemNum.value = 4
    }
  });
});
</script>

<style lang="less" scoped>
</style>
