import {api} from "@/api";


// 获取配置列表
export const getConfigListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/config/page',
        method: 'POST',
        data: data
    })
}

// 修改保存配置列表
export const saveConfigListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/config/save',
        method: 'POST',
        data: data
    })
}

// 删除配置列表
export const delConfigListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/config/delete',
        method: 'POST',
        data: data
    })
}
