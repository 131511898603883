<template>
  <div class="deliOnceImg6">
    <div class="onceImgBgc">
      <div class="bgcImg">
        <img alt="" class="bgc"
             src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/onceImg/deli/onceImgDeliC2.png">
        <!--        <div v-for="(item,index) in data.dataList" :key="index" :class="'tableItem'+(index+1)" class="tableItem">-->
        <!--          <p>Ua (V) : {{ item.ua }}</p>-->
        <!--          <p>Ub (V) : {{ item.ub }}</p>-->
        <!--          <p>Uc (V) : {{ item.uc }}</p>-->
        <!--          <p>Ia (A) : {{ item.ia }}</p>-->
        <!--          <p>Ib (A) : {{ item.ib }}</p>-->
        <!--          <p>Ic (A) : {{ item.ic }}</p>-->
        <!--          <p>P (kw) : {{ item.p }}</p>-->
        <!--        </div>-->
        <div v-for="(item,index) in data" :key="index" :class="'tableItem'+(index+1)" class="tableItem">
          <p v-if="!(item.subList&&item.subList.length>0)">Ua (V) : {{ item.ua }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ub (V) : {{ item.ub }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Uc (V) : {{ item.uc }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ia (A) : {{ item.ia }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ib (A) : {{ item.ib }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">Ic (A) : {{ item.ic }}</p>
          <p v-if="!(item.subList&&item.subList.length>0)">P (kw) : {{ item.p }}</p>

          <div v-for="(item_c,index_c) in item.subList" v-if="item.subList&&item.subList.length>0" :key="index_c"
               :class="'dataItemList'+(index_c+1)" class="dataItemList">
            <!--            <div style="width: 85px">-->
            <p>Ia (A) : {{ item_c.ia }}</p>
            <p>Ib (A) : {{ item_c.ib }}</p>
            <!--            </div>-->

            <!--            <br>-->
            <!--            <div>-->
            <p>Ic (A) : {{ item_c.ic }}</p>
            <p>P (kw) : {{ item_c.p }}</p>
            <!--            </div>-->

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, ref} from "vue";
import {getDeliOnePicDataApi, getEveryOnceImgDataApi} from "@/api/modules/onceImg";
import {ElMessage} from "element-plus";
import qs from "qs";

// 一次图数据
let data = ref({})

const resizeFun = function () {
  nextTick(() => {
    let ele = document.querySelector('.onceImgBgc')
    let scaleX = (ele.clientWidth) / 840
    let scaleY = (ele.clientHeight) / 630
    // document.querySelector('.bgcImg').style.transform = `scale(${scaleX},${scaleY})`
    for (let i = 0; i < document.querySelectorAll('.bgcImg').length; i++) {
      document.querySelectorAll('.onceImgBgc .bgcImg')[i].style.transform = `scale(${scaleX},${scaleY})`
    }
  })
}
onMounted(() => {
  nextTick(() => {
    resizeFun()
  })
  window.onresize = () => {
    resizeFun()
  }

  getDeliOnePicDataApi(qs.stringify({
    type: 8
  })).then(res => {
    if (res.data.code === 0) {
      // data.value = res.data.result
      if (res.data.result.dataList.length > 0) {
        let list = res.data.result
        let newList = list.dataList.slice(27, 30)
        newList.unshift({subList: list.dataList.slice(0, 3)}, {subList: list.dataList.slice(3, 6)}, {subList: list.dataList.slice(6, 9)}, {subList: list.dataList.slice(9, 18)}, {subList: list.dataList.slice(18, 27)})
        data.value = newList
      }
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less">
.deliOnceImg6, .deliOnceImg6 .onceImgBgc {
  width: 100%;
  height: 100% !important;

  .bgcImg {
    width: 840px;
    height: 630px;
    transform-origin: 0% 0%;
    position: relative;

    .bgc {
      width: 100%;
      height: 100%;
    }

    .tableItem {
      font-size: 8px;
      display: none;

      p {
        color: #EAFA42;
        font-size: 8px;
        margin-bottom: 2px;
        transform: scale(0.8);
      }

      p:nth-child(2n) {
        color: #2EEF28;
      }

      p:nth-child(3n) {
        color: #FF0000;
      }

      p:nth-child(7) {
        color: #00F6F4;
      }
    }

    .tableItem1 {
      display: block;
      position: absolute;
      width: 130px;
      top: 211px;
      left: 29px;

      .dataItemList1 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      .dataItemList2 {
        display: block;
        position: absolute;
        top: 115px;
        left: 0;
      }

      .dataItemList3 {
        display: block;
        position: absolute;
        top: 247px;
        left: 0;
      }
    }

    .tableItem2 {
      display: block;
      position: absolute;
      width: 130px;
      top: 211px;
      left: 118px;

      .dataItemList1 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      .dataItemList2 {
        display: block;
        position: absolute;
        top: 115px;
        left: 0;
      }

      .dataItemList3 {
        display: block;
        position: absolute;
        top: 247px;
        left: 0;
      }
    }

    .tableItem3 {
      display: block;
      position: absolute;
      width: 130px;
      top: 211px;
      left: 207px;

      .dataItemList1 {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }

      .dataItemList2 {
        display: block;
        position: absolute;
        top: 115px;
        left: 0;
      }

      .dataItemList3 {
        display: block;
        position: absolute;
        top: 247px;
        left: 0;
      }
    }

    .tableItem4 {
      display: block;
      position: absolute;
      width: 130px;
      top: 138px;
      left: 301px;
    }

    .tableItem5 {
      display: block;
      position: absolute;
      width: 130px;
      top: 138px;
      left: 429px;
    }

    .tableItem6 {
      display: block;
      position: absolute;
      top: 415px;
      left: 521px;
    }

    .tableItem7 {
      display: block;
      position: absolute;
      top: 415px;
      left: 594px;
    }

    .tableItem8 {
      display: block;
      position: absolute;
      top: 415px;
      left: 683px;
    }


    .dataItemList {
      font-size: 8px;
      display: none;

      p {
        font-size: 8px;
        margin-bottom: 0 !important;
        transform: scale(0.7);
        transform-origin: 0% 0%;
        display: inline-block;
        white-space: nowrap;
        width: 44px;
      }

      p:nth-of-type(1) {
        color: #EAFA42 !important;
      }

      p:nth-of-type(2) {
        color: #2EEF28 !important;
      }

      p:nth-of-type(3) {
        color: #FF0000 !important;
      }

      p:nth-of-type(4) {
        color: #00F6F4 !important;
      }

      p:nth-of-type(3), p:nth-of-type(4) {
        transform: scale(0.7) translateY(-7px) !important;
      }
    }

    .dataItemList1 {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }

    .dataItemList2 {
      display: block;
      position: absolute;
      top: 49px;
      left: 0;
    }

    .dataItemList3 {
      display: block;
      position: absolute;
      top: 98px;
      left: 0;
    }

    .dataItemList4 {
      display: block;
      position: absolute;
      top: 147px;
      left: 0;
    }

    .dataItemList5 {
      display: block;
      position: absolute;
      top: 196px;
      left: 0;
    }

    .dataItemList6 {
      display: block;
      position: absolute;
      top: 245px;
      left: 0;
    }

    .dataItemList7 {
      display: block;
      position: absolute;
      top: 294px;
      left: 0;
    }

    .dataItemList8 {
      display: block;
      position: absolute;
      top: 343px;
      left: 0;
    }

    .dataItemList9 {
      display: block;
      position: absolute;
      top: 392px;
      left: 0;
    }

    .dataItemList10 {
      display: block;
      position: absolute;
      top: 441px;
      left: 0;
    }
  }
}
</style>
