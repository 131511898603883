<template>
  <div class="order">
    <div class="title">
      <p>
        <span>工单管理</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">工单标题</label>
          <el-input
            v-model="queryParams.title"
            prefix-icon="Search"
            clearable
            placeholder="请输入工单标题"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">工单类型</label>
          <el-select
            v-model="queryParams.type"
            clearable
            placeholder="请选择工单类型"
          >
          </el-select>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建工单
        </el-button>
        <el-table v-loading="loading" :data="orderList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建工单</p>
            </el-empty>
          </template>
          <el-table-column label="工单标题" align="center" prop="title" />
          <el-table-column label="工单类型" align="center" prop="type" />
          <el-table-column label="状态" align="center" prop="status">
            <template #default="scope">
              <el-tag
                :type="
                  sys_status.find((item) => item.value === scope.row.status)
                    ?.type
                "
                size="small"
              >
                {{
                  sys_status.find((item) => item.value === scope.row.status)
                    ?.label
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="处理人" align="center" prop="" />
          <el-table-column label="处理时间" align="center" prop="" />
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p
                  class="btn"
                  @click="handleUpdate(scope.row, true, '工单详情')"
                >
                  详情
                </p>
                <p
                  v-if="scope.row.status === 0"
                  class="btn"
                  @click="handleProcess(scope.row)"
                >
                  处理
                </p>
                <!-- <p class="btn" @click="handleUpdate(scope.row)">修改</p> -->
                <!-- <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form
        ref="orderRef"
        :model="form"
        :rules="rules"
        label-width="auto"
        :disabled="disabled"
      >
        <el-form-item label="工单标题" prop="title">
          <el-input
            v-model="form.title"
            placeholder="请输入工单标题"
            clearable
          />
        </el-form-item>
        <el-form-item label="问题描述" prop="description">
          <el-input
            v-model="form.description"
            :autosize="{ minRows: 4 }"
            type="textarea"
            placeholder="请输入问题描述"
            clearable
          />
        </el-form-item>
        <el-form-item label="补充图片" prop="imageUrl">
          <ImageUpload v-model="form.imageUrl" />
          <el-checkbox v-model="form.imageUrl" v-show="false" />
        </el-form-item>
      </el-form>
      <template v-if="!disabled" #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm">
            保存
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog
      :title="processTitle"
      v-model="processOpen"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form
        ref="processRef"
        :model="processForm"
        :rules="processRules"
        label-width="auto"
      >
        <el-form-item label="工单标题" prop="title">
          <el-input
            v-model="processForm.title"
            placeholder="请输入工单标题"
            disabled
          />
        </el-form-item>
        <el-form-item label="问题描述" prop="description">
          <el-input
            v-model="processForm.description"
            :autosize="{ minRows: 4 }"
            type="textarea"
            placeholder="请输入问题描述"
            disabled
          />
        </el-form-item>
        <el-form-item label="补充图片" prop="imageUrl">
          <ImageUpload v-model="processForm.imageUrl" :disabled="true" />
          <el-checkbox v-model="processForm.imageUrl" v-show="false" />
        </el-form-item>
        <el-form-item label="处理意见" prop="processComment">
          <el-input
            v-model="processForm.processComment"
            :autosize="{ minRows: 4 }"
            type="textarea"
            placeholder="请输入处理意见"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="processCancel"
            >取消</el-button
          >
          <el-button class="confrim" type="primary" @click="submitProcessForm">
            保存
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './order.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listOrder,
  getOrder,
  delOrder,
  addOrder,
  updateOrder,
  handleOrder,
} from '@/api/modules/order'
import ImageUpload from '@/components/ImageUpload.vue'

const orderList = ref([])
const open = ref(false)
const processOpen = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const processTitle = ref('')
const orderRef = ref(null)
const processRef = ref(null)
const disabled = ref(false)

const sys_status = ref([
  { label: '未处理', value: 0, type: 'info' },
  { label: '处理中', value: 1, type: 'warning' },
  { label: '已处理', value: 2, type: 'success' },
])

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    title: null,
    type: null,
  },
  rules: {
    title: [{ required: true, message: '请输入工单标题', trigger: 'blur' }],
    description: [
      { required: true, message: '请输入问题描述', trigger: 'blur' },
    ],
    imageUrl: [{ required: true, message: '请上传图片', trigger: 'change' }],
  },
})

const { queryParams, form, rules } = toRefs(data)

const processForm = ref({})
const processRules = ref({
  processComment: [
    { required: true, message: '请输入处理意见', trigger: 'blur' },
  ],
})

/** 查询工单列表 */
function getList() {
  loading.value = true
  listOrder(queryParams.value).then((response) => {
    orderList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

function processCancel() {
  processOpen.value = false
  processReset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    title: null,
    description: null,
    imageUrl: null,
  }
  orderRef.value?.resetFields()
}

function processReset() {
  processForm.value = {
    id: null,
    title: null,
    description: null,
    imageUrl: null,
    processComment: null,
  }
  processRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    title: null,
    type: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  disabled.value = false
  reset()
  open.value = true
  title.value = '新建工单'
}

/** 修改按钮操作 */
function handleUpdate(row, disabledValue = false, titleValue = '修改工单') {
  disabled.value = disabledValue
  reset()
  getOrder(row.id).then((response) => {
    form.value = response.data.result
    open.value = true
    title.value = titleValue
  })
}

/** 处理按钮操作 */
function handleProcess(row) {
  processReset()
  getOrder(row.id).then((response) => {
    processForm.value = response.data.result
    processOpen.value = true
    processTitle.value = '处理工单'
  })
}

/** 提交按钮 */
function submitForm() {
  orderRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updateOrder(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addOrder(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

function submitProcessForm() {
  processRef.value.validate((valid) => {
    if (valid) {
      handleOrder(processForm.value).then((response) => {
        if (response.data.code === 0) {
          ElMessage.success('处理成功')
          processOpen.value = false
          getList()
        } else {
          ElMessage({
            type: 'error',
            message: response.data.message,
          })
        }
      })
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除工单',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此工单，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delOrder(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}
</script>
