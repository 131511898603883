<template>
  <div class="powerStationSetting">
    <div class="title">
      <p>
        <span @click="goBack">光伏电站</span>
        <span> / </span>
        <span>配置</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        配置
      </p>
    </div>
    <div class="content">
      <p class="tit">累计发电量</p>
      <div class="contentItem">
        <div class="liItemBox">
          <div class="bgc">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem1.png">
            <p>累计发电量 kwh</p>
          </div>
          <p style="margin-left: 30px">=</p>
        </div>
        <div v-for="(item,index) in settingData.powerGeneration.data" :key="index"
             class="contItemBox">
          <div v-if="index!==0" class="symbol">
            <p
                :class="settingData.powerGeneration.formula.substring(2*index-1,2*index)==='+'?'activeSymbol':''"
                @click="symbolClick('powerGeneration',index,'+')">+</p>
            <p
                :class="settingData.powerGeneration.formula.substring(2*index-1,2*index)==='-'?'activeSymbol':''"
                @click="symbolClick('powerGeneration',index,'-')">-</p>
            <p
                :class="settingData.powerGeneration.formula.substring(2*index-1,2*index)==='×'||settingData.powerGeneration.formula.substring(2*index-1,2*index)==='*'?'activeSymbol':''"
                @click="symbolClick('powerGeneration',index,'*')">×</p>
            <p
                :class="settingData.powerGeneration.formula.substring(2*index-1,2*index)==='÷'||settingData.powerGeneration.formula.substring(2*index-1,2*index)==='/'?'activeSymbol':''"
                @click="symbolClick('powerGeneration',index,'/')">÷</p>
          </div>
          <div class="contItem">
            <el-select v-model="item.type" clearable placeholder="请选择参数类型">
              <el-option :value="1" label="指标"/>
              <el-option :value="0" label="数值"/>
            </el-select>
            <el-icon v-if="settingData.powerGeneration.data.length>1" class="delIcon"
                     @click="delControl('powerGeneration',index)">
              <CircleClose/>
            </el-icon>
            <div v-if="item.type!==''" class="contBox">
              <!--              <el-select v-show="item.type==1" ref="elSelectTable" v-model="item.typeName"-->
              <!--                         :filter-method="filterMethod" :popper-append-to-body="false"-->
              <!--                         clearable filterable-->
              <!--                         popper-class="select_report_and_type">-->
              <!--                <el-option :value="dataItemList">-->
              <!--                  <el-tree-->
              <!--                      ref="treeRef"-->
              <!--                      :expand-on-click-node=true-->
              <!--                      :filter-node-method="filterNode"-->
              <!--                      :load="treeList"-->
              <!--                      :props="props"-->
              <!--                      highlight-current-->
              <!--                      lazy-->
              <!--                      @node-click="clickUnitTreeNodeChild($event,'powerGeneration',index)"-->
              <!--                  />-->
              <!--                </el-option>-->
              <!--              </el-select>-->
              <el-input v-show="item.type==1" v-model="item.typeName" readonly
                        style="margin-top: 20px;cursor: pointer" @click="selectTimeFun(index,1)"></el-input>
              <div class="center">
                <el-input v-if="item.type==0" v-model="item.val" class="val" placeholder="请输入"
                          type="number"
                />
                <span v-if="item.type==1&& item.deviceName">{{ item.deviceName }}</span>
                <!--                    <p v-if="item.type==1">{{ item.typeName }}</p>-->
              </div>
              <span v-if="item.type==1" class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div>
          <el-icon class="addIcon" @click="addControl('powerGeneration')">
            <CirclePlus/>
          </el-icon>
        </div>
      </div>
      <p class="tit">电价组合包</p>
      <div class="contentItem" style="display: flex;align-items: center">
        <span style="margin-right: 10px">电价组合包</span>
        <el-select v-model="settingData.powerPriceGroupId" class="bagClass" clearable placeholder="请选择电价组合包"
                   style="width: 220px;">
          <el-option v-for="(item,index) in electrovalenceBagList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
      </div>
      <!--      <p class="tit">总装机容量</p>-->
      <!--      <div class="contentItem">-->
      <!--        <div class="liItemBox">-->
      <!--          <div class="bgc">-->
      <!--            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem3.png">-->
      <!--            <p>累计发电量 kwh</p>-->
      <!--          </div>-->
      <!--          <p style="margin-left: 30px">=</p>-->
      <!--        </div>-->
      <!--        <div v-for="(item,index) in settingData.capacity.data" :key="index"-->
      <!--             class="contItemBox">-->
      <!--          <div v-if="index!==0" class="symbol">-->
      <!--            <p-->
      <!--                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='+'?'activeSymbol':''"-->
      <!--                @click="symbolClick('capacity',index,'+')">+</p>-->
      <!--            <p-->
      <!--                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='-'?'activeSymbol':''"-->
      <!--                @click="symbolClick('capacity',index,'-')">-</p>-->
      <!--            <p-->
      <!--                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='×'||settingData.capacity.formula.substring(2*index-1,2*index)==='*'?'activeSymbol':''"-->
      <!--                @click="symbolClick('capacity',index,'*')">×</p>-->
      <!--            <p-->
      <!--                :class="settingData.capacity.formula.substring(2*index-1,2*index)==='÷'||settingData.capacity.formula.substring(2*index-1,2*index)==='/'?'activeSymbol':''"-->
      <!--                @click="symbolClick('capacity',index,'/')">÷</p>-->
      <!--          </div>-->
      <!--          <div class="contItem">-->
      <!--            <el-select v-model="item.type" clearable placeholder="请选择参数类型">-->
      <!--              <el-option :value="1" label="指标"/>-->
      <!--              <el-option :value="0" label="数值"/>-->
      <!--            </el-select>-->
      <!--            <el-icon v-if="settingData.capacity.data.length>1" class="delIcon"-->
      <!--                     @click="delControl('capacity',index)">-->
      <!--              <CircleClose/>-->
      <!--            </el-icon>-->
      <!--            <div v-if="item.type!==''" class="contBox">-->
      <!--              <el-select v-show="item.type==1" ref="elSelectTable" v-model="item.typeName"-->
      <!--                         :filter-method="filterMethod" :popper-append-to-body="false"-->
      <!--                         class="select-unitName select-option-father" clearable filterable-->
      <!--                         popper-class="select_report_and_type">-->
      <!--                <el-option :value="dataItemList">-->
      <!--                  <el-tree-->
      <!--                      ref="treeRef"-->
      <!--                      :expand-on-click-node=true-->
      <!--                      :filter-node-method="filterNode"-->
      <!--                      :load="treeList"-->
      <!--                      :props="props"-->
      <!--                      highlight-current-->
      <!--                      lazy-->
      <!--                      @node-click="clickUnitTreeNodeChild($event,'capacity',index)"-->
      <!--                  />-->
      <!--                </el-option>-->
      <!--              </el-select>-->
      <!--              <div class="center">-->
      <!--                <el-input v-if="item.type==0" v-model="item.val" class="val" placeholder="请输入"-->
      <!--                          type="number"-->
      <!--                />-->
      <!--                <span v-if="item.type==1&& item.deviceName">{{ item.deviceName }}</span>-->
      <!--                &lt;!&ndash;                    <p v-if="item.type==1">{{ item.typeName }}</p>&ndash;&gt;-->
      <!--              </div>-->
      <!--              <span v-if="item.type==1" class="unit">{{ item.unit }}</span>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <el-icon class="addIcon" @click="addControl('capacity')">-->
      <!--            <CirclePlus/>-->
      <!--          </el-icon>-->
      <!--        </div>-->
      <!--      </div>-->
      <p class="tit">当前总功率</p>
      <div class="contentItem">
        <div class="liItemBox">
          <div class="bgc">
            <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/homeItem4.png">
            <p>当前总功率 kw</p>
          </div>
          <p style="margin-left: 30px">=</p>
        </div>
        <div v-for="(item,index) in settingData.totalRate.data" :key="index"
             class="contItemBox">
          <div v-if="index!==0" class="symbol">
            <p
                :class="settingData.totalRate.formula.substring(2*index-1,2*index)==='+'?'activeSymbol':''"
                @click="symbolClick('totalRate',index,'+')">+</p>
            <p
                :class="settingData.totalRate.formula.substring(2*index-1,2*index)==='-'?'activeSymbol':''"
                @click="symbolClick('totalRate',index,'-')">-</p>
            <p
                :class="settingData.totalRate.formula.substring(2*index-1,2*index)==='×'||settingData.totalRate.formula.substring(2*index-1,2*index)==='*'?'activeSymbol':''"
                @click="symbolClick('totalRate',index,'*')">×</p>
            <p
                :class="settingData.totalRate.formula.substring(2*index-1,2*index)==='÷'||settingData.totalRate.formula.substring(2*index-1,2*index)==='/'?'activeSymbol':''"
                @click="symbolClick('totalRate',index,'/')">÷</p>
          </div>
          <div class="contItem">
            <el-select v-model="item.type" clearable placeholder="请选择参数类型">
              <el-option :value="1" label="指标"/>
              <el-option :value="0" label="数值"/>
            </el-select>
            <el-icon v-if="settingData.totalRate.data.length>1" class="delIcon"
                     @click="delControl('totalRate',index)">
              <CircleClose/>
            </el-icon>
            <div v-if="item.type!==''" class="contBox">
              <!--              <el-select v-show="item.type==1" ref="elSelectTable" v-model="item.typeName"-->
              <!--                         :filter-method="filterMethod" :popper-append-to-body="false"-->
              <!--                         class="select-unitName select-option-father" clearable filterable-->
              <!--                         popper-class="select_report_and_type">-->
              <!--                <el-option :value="dataItemList">-->
              <!--                  <el-tree-->
              <!--                      ref="treeRef"-->
              <!--                      :expand-on-click-node=true-->
              <!--                      :filter-node-method="filterNode"-->
              <!--                      :load="treeList"-->
              <!--                      :props="props"-->
              <!--                      highlight-current-->
              <!--                      lazy-->
              <!--                      @node-click="clickUnitTreeNodeChild($event,'totalRate',index)"-->
              <!--                  />-->
              <!--                </el-option>-->
              <!--              </el-select>-->
              <el-input v-show="item.type==1" v-model="item.typeName" readonly
                        style="margin-top: 20px;cursor: pointer" @click="selectTimeFun(index,2)"></el-input>
              <div class="center">
                <el-input v-if="item.type==0" v-model="item.val" class="val" placeholder="请输入"
                          type="number"
                />
                <span v-if="item.type==1&& item.deviceName">{{ item.deviceName }}</span>
                <!--                    <p v-if="item.type==1">{{ item.typeName }}</p>-->
              </div>
              <span v-if="item.type==1" class="unit">{{ item.unit }}</span>
            </div>
          </div>
        </div>
        <div>
          <el-icon class="addIcon" @click="addControl('totalRate')">
            <CirclePlus/>
          </el-icon>
        </div>
      </div>
      <div class="footer">
        <el-button @click="router.push('/admin/powerStation')">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </div>

    <DeviceSelect2 :deviceVisible="deviceVisible"
                   @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref, watch} from "vue"
import './powerStationSetting.less'
import {Back, Delete, CirclePlus, CircleClose} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {getTreeListApi} from "@/api/modules/control";
import qs from "qs";
import {ElMessage, ElMessageBox} from "element-plus";
import DeviceSelect2 from '@/components/deviceSelect2'
import {getPowerPriceGroupListApi} from "@/api/modules/rate";
import {getPowerStationSettingDetailApi, savePowerStationSettingDetailApi} from "@/api/modules/powerStation";
import letterList from "@/assets/js/letterList";

let router = useRouter()

const goBack = function () {
  router.push('/admin/powerStation')
}

// 设备选择-----------------------------------------------------------------
let deviceVisible = ref(false)
let selectIdx = ref()
let selectType = ref()
const selectTimeFun = function (idx, type) {
  selectIdx.value = idx
  selectType.value = type
  deviceVisible.value = true
}
const deviceVisibleFun = function (data) {
  deviceVisible.value = data
}
const checkDeviceListFun = function (data) {
  if (selectType.value == 1) {
    settingData.value.powerGeneration.data[selectIdx.value].typeName = data.dictName
    settingData.value.powerGeneration.data[selectIdx.value].typeId = data.dictId
    settingData.value.powerGeneration.data[selectIdx.value].unit = data.unit
    settingData.value.powerGeneration.data[selectIdx.value].dictKey = data.dictKey
    settingData.value.powerGeneration.data[selectIdx.value].deviceId = data.deviceId
    settingData.value.powerGeneration.data[selectIdx.value].deviceName = data.deviceName
  } else {
    settingData.value.totalRate.data[selectIdx.value].typeName = data.dictName
    settingData.value.totalRate.data[selectIdx.value].typeId = data.dictId
    settingData.value.totalRate.data[selectIdx.value].unit = data.unit
    settingData.value.totalRate.data[selectIdx.value].dictKey = data.dictKey
    settingData.value.totalRate.data[selectIdx.value].deviceId = data.deviceId
    settingData.value.totalRate.data[selectIdx.value].deviceName = data.deviceName
  }
}


// 配置-------------------------------------------
let settingData = ref({
  powerGeneration: {
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    }]
  },
  powerPriceGroupId: '',
  totalRate: {
    formula: 'A',
    name: '',
    valueType: [],
    data: [{
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    }]
  }
}) //配置列表
// 获取电站配置
const getPowerStationSettingDetailFun = function () {
  getPowerStationSettingDetailApi(qs.stringify({
    powerStationId: router.currentRoute.value.query.id
  })).then(res => {
    if (res.data.code === 0) {
      settingData.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 保存配置
const save = function () {
  let data = JSON.parse(JSON.stringify(settingData.value))
  // 处理符号和字母
  for (let i = 0; i < data.powerGeneration.data.length; i++) {
    data.powerGeneration.data[i].replaceStr = letterList()[i].name
  }
  for (let i = 0; i < data.powerGeneration.formula.length; i++) {
    if (i % 2 == 0 && i !== 0) {
      data.powerGeneration.formula = data.powerGeneration.formula.substr(0, i) + letterList()[i / 2].name + data.powerGeneration.formula.substr(i + 1);
    }
  }
  for (let i = 0; i < data.totalRate.data.length; i++) {
    data.totalRate.data[i].replaceStr = letterList()[i].name
  }
  for (let i = 0; i < data.totalRate.formula.length; i++) {
    if (i % 2 == 0 && i !== 0) {
      data.totalRate.formula = data.totalRate.formula.substr(0, i) + letterList()[i / 2].name + data.totalRate.formula.substr(i + 1);
    }
  }
  // data.powerGeneration = JSON.stringify(data.powerGeneration)
  // data.totalRate = JSON.stringify(data.totalRate)
  data.powerStationId = router.currentRoute.value.query.id
  savePowerStationSettingDetailApi(data).then(res => {
    if (res.data.code === 0) {
      router.push('/admin/powerStation')
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 获取树形结构列表----------------------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  await getTreeListApi(qs.stringify({
    parentId: node.data.id ? node.data.id : 0,
    type: node.data.type ? node.data.type : 1
  })).then(res => {
    if (res.data.code === 0) {
      return resolve(res.data.result)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data.type === 3) {
      return true
    }
  }
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}


// 参数操作------------------------------------
// 符号点击
const symbolClick = function (index, index_child, e) {
  let str = settingData.value[index].formula
  settingData.value[index].formula = str.substr(0, 2 * index_child - 1) + e + str.substr(2 * index_child);
}
// 添加参数值
const addControl = function (type) {
  if (type === 'powerGeneration') {
    if (settingData.value.powerGeneration.data.length === 0) {
      settingData.value.powerGeneration.formula = 'A'
    } else {
      settingData.value.powerGeneration.formula = settingData.value.powerGeneration.formula.concat('+D')
    }
    settingData.value.powerGeneration.data.push({
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    })
  } else {
    if (settingData.value.totalRate.data.length === 0) {
      settingData.value.totalRate.formula = 'A'
    } else {
      settingData.value.totalRate.formula = settingData.value.totalRate.formula.concat('+D')
    }
    settingData.value.totalRate.data.push({
      val: "",
      type: '',
      unit: "",
      typeId: '',
      typeName: "",
      replaceStr: "A",
      deviceId: '',
      deviceName: '',
    })
  }
}
// 删除参数值
const delControl = function (type, index_child) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除参数',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此参数，删除后数据不可恢复'),
    ])
  }).then(() => {
    if (type === 'powerGeneration') {
      let str = settingData.value.powerGeneration.formula
      let strA
      let strB
      if (index_child !== 0) {
        strA = str.slice(0, 2 * index_child - 1)
        strB = str.slice(2 * index_child + 1, str.length)
      } else {
        strA = ''
        strB = str.slice(2, str.length)
      }
      settingData.value.powerGeneration.formula = strA.concat(strB)
      settingData.value.powerGeneration.data.splice(index_child, 1)
    } else {
      let str = settingData.value.totalRate.formula
      let strA
      let strB
      if (index_child !== 0) {
        strA = str.slice(0, 2 * index_child - 1)
        strB = str.slice(2 * index_child + 1, str.length)
      } else {
        strA = ''
        strB = str.slice(2, str.length)
      }
      settingData.value.totalRate.formula = strA.concat(strB)
      settingData.value.totalRate.data.splice(index_child, 1)
    }

    ElMessage({
      type: 'success',
      message: '删除成功'
    });
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}
//node的点击
let elSelectTable = ref()
const clickUnitTreeNodeChild = function (data, index, index_child) {
  if (data.type == 3) {
    settingData.value[index].data[index_child].typeName = data.name
    settingData.value[index].data[index_child].typeId = data.id
    settingData.value[index].data[index_child].deviceName = data.equipmentName
    settingData.value[index].data[index_child].unit = data.unitName
    settingData.value[index].data[index_child].deviceId = data.equipmentId
    settingData.value[index].data[index_child].dictKey = data.dictKey
    elSelectTable.value[index_child].blur();
  } else {
    // ElMessage({
    //   type: 'info',
    //   message: '当前选择不是指标选项'
    // });
  }
}


// 下拉搜索
const filterMethod = function (val) {
  treeRef.value[0].filter(val)
}
const filterNode = (value, data) => {
  if (!value) return true
  return data.name.includes(value)
}

let electrovalenceBagList = ref([])
onMounted(() => {
  getPowerStationSettingDetailFun()
  // 获取电价组合包列表
  getPowerPriceGroupListApi(qs.stringify({
    pageNumber: 1,
    pageSize: 99999,
  })).then(res => {
    if (res.data.code === 0) {
      electrovalenceBagList.value = res.data.result.list
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

</script>

<style lang="less" scoped>
</style>
