<template>
  <div class="template">
    <!-- <div class="title">
      <p>
        <span>计费模板管理</span>
      </p>
    </div> -->
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">模板名称</label>
          <el-input
            v-model="queryParams.name"
            prefix-icon="Search"
            clearable
            placeholder="请输入模板名称"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建计费模板
        </el-button>
        <el-table v-loading="loading" :data="templateList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建计费模板</p>
            </el-empty>
          </template>
          <el-table-column
            label="计费项模板名称"
            align="center"
            prop="templateName"
          />
          <el-table-column
            label="备注"
            align="center"
            prop="remark"
            show-overflow-tooltip
          />
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="1000px"
      append-to-body
    >
      <el-form
        ref="templateRef"
        :model="form"
        :rules="rules"
        label-width="auto"
      >
        <el-form-item v-if="isShow" label="项目" prop="projectId">
          <el-select
            v-model="form.projectId"
            placeholder="请选择项目"
            clearable
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in formLists.projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="计费模版名称" prop="templateName">
          <el-input
            v-model="form.templateName"
            placeholder="请输入计费模版名称"
            clearable
          />
        </el-form-item>
        <el-card style="margin-bottom: 8px" shadow="never">
          <div>
            <el-button
              style="margin-bottom: 12px"
              type="primary"
              v-for="item in formLists.templateList"
              :key="item.id"
              @click="handleAddChargeItem(item)"
              >{{ item.chargeItemName }}</el-button
            >
          </div>
        </el-card>
        <el-card style="margin-bottom: 8px" header="公式区域" shadow="never">
          <el-form-item prop="formula">
            <div
              ref="editor"
              contenteditable
              style="border: 1px solid #ddd; width: 100%; padding: 8px"
            ></div>
          </el-form-item>
        </el-card>
        <el-card shadow="never" header="试算区域">
          <el-form inline>
            <el-form-item
              v-for="(item, index) in filterChargeItemList"
              :key="index"
              :label="item.chargeItemName"
            >
              <el-input-number
                v-model="item.value"
                :controls="false"
                :value-on-clear="0"
                @focus="handleChargeItemNameFocus"
              />
            </el-form-item>
            <el-divider />
            <el-row justify="end">
              <el-form-item label="试算结果">
                <div>{{ result }}</div>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="getChargeTemplateResult"
                  >试算</el-button
                >
                <el-button @click="resetChargeTemplate">重置</el-button>
              </el-form-item>
            </el-row>
          </el-form>
        </el-card>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, computed, watch, nextTick, h } from 'vue'
import './template.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listTemplate,
  getTemplate,
  delTemplate,
  addTemplate,
  updateTemplate,
} from '@/api/modules/template'
import { evaluate } from 'mathjs'
import { getCustomList } from '@/api/modules/custom'
import { listLandlordProject } from '@/api/modules/landlord'

const templateList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const templateRef = ref(null)
const editor = ref(null)
let observer
const result = ref('')
const isShow = ref(false)

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    templateName: null,
    mobile: null,
  },
  rules: {
    projectId: [{ required: true, message: '项目不能为空', trigger: 'change' }],
    templateName: [
      { required: true, message: '计费模板姓名不能为空', trigger: 'blur' },
    ],
    userName: [{ required: true, message: '户名不能为空', trigger: 'blur' }],
    mobile: [{ required: true, message: '手机号码不能为空', trigger: 'blur' }],
    address: [{ required: true, message: '详细地址不能为空', trigger: 'blur' }],
  },
})

const { queryParams, form, rules } = toRefs(data)

const formLists = reactive({
  projectList: [],
  templateList: [],
})

/** 查询计费模板列表 */
function getList() {
  loading.value = true
  listTemplate(queryParams.value).then((response) => {
    templateList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    projectId: null,
    templateName: null,
    userName: null,
    mobile: null,
    address: null,
  }
  templateRef.value?.resetFields()
  observer?.disconnect()
  if (editor.value) {
    editor.value.innerHTML = ''
  }
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    templateName: null,
    mobile: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listLandlordProject().then((response) => {
    formLists.projectList = response.data.result
    if (formLists.projectList.length === 1) {
      form.value.projectId = formLists.projectList[0].projectId
      isShow.value = false
    } else {
      isShow.value = true
    }
    getCustomList().then((response) => {
      formLists.templateList = response.data.result
      open.value = true
      title.value = '新建计费模板'

      nextTick(() => {
        observer = new MutationObserver((mutations) => {
          mutations.forEach(() => {
            form.value.formula = editor.value.innerText
          })
        })

        observer.observe(editor.value, {
          childList: true,
          subtree: true,
          characterData: true,
        })
      })
    })
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listLandlordProject().then((response) => {
    formLists.projectList = response.data.result
    getCustomList().then((response) => {
      formLists.templateList = response.data.result.map((item) => ({
        ...item,
        isUse: false,
        value: 0,
      }))

      getTemplate(row.id).then((response) => {
        let formula = response.data.result.formula
        const parts = formula.split(/([+\-*/()])/)

        parts.forEach((part, index) => {
          formLists.templateList.forEach((item) => {
            if (part === item.chargeItemValue) {
              parts[index] = /* HTML */ `<span
                contenteditable="false"
                class="el-tag el-tag--primary el-tag--light"
                >${item.chargeItemName}</span
              >`
            }
          })
        })

        formula = parts.join('')

        form.value = response.data.result

        if (
          formLists.projectList.length === 1 &&
          form.value.projectId === formLists.projectList[0].projectId
        ) {
          isShow.value = false
        } else {
          isShow.value = true
        }

        open.value = true
        title.value = '修改计费模板'

        nextTick(() => {
          editor.value.innerHTML = formula

          observer = new MutationObserver((mutations) => {
            mutations.forEach(() => {
              form.value.formula = editor.value.innerText
            })
          })

          observer.observe(editor.value, {
            childList: true,
            subtree: true,
            characterData: true,
          })

          form.value.formula = editor.value.innerText
        })
      })
    })
  })
}

/** 提交按钮 */
function submitForm() {
  templateRef.value.validate((valid) => {
    if (valid) {
      let formula = editor.value.innerText
      const parts = formula.split(/([+\-*/()])/)

      parts.forEach((part, index) => {
        formLists.templateList.forEach((item) => {
          if (part === item.chargeItemName) {
            parts[index] = item.chargeItemValue
          }
        })
      })

      formula = parts.join('')

      if (form.value.id != null) {
        updateTemplate({ ...form.value, formula }).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addTemplate({ ...form.value, formula }).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除计费模板',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此计费模板，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delTemplate(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}

function handleAddChargeItem(item) {
  const selection = window.getSelection()
  if (selection && selection.rangeCount > 0) {
    const range = selection.getRangeAt(0)

    // Check if the current selection is within the editor and not inside a span
    if (editor.value && editor.value.contains(range.commonAncestorContainer)) {
      let currentNode = range.commonAncestorContainer
      let insideSpan = false

      // Traverse up the DOM tree to check if any parent is a span
      while (currentNode && currentNode !== editor.value) {
        if (currentNode.nodeName === 'SPAN') {
          insideSpan = true
          break
        }
        currentNode = currentNode.parentNode
      }

      // Only insert the span if the cursor is not inside another span
      if (!insideSpan) {
        const button = document.createElement('span')
        button.className = 'el-tag el-tag--primary el-tag--light'
        button.contentEditable = 'false'
        button.innerText = item.chargeItemName

        range.deleteContents()
        range.insertNode(button)

        // Set the cursor after the inserted button
        range.setStartAfter(button)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    }
  }
}

const filterChargeItemList = computed(() => {
  return formLists.templateList.filter((item) => item.isUse)
})

function handleChargeItemNameFocus(event) {
  event.target.select()
}

function getChargeTemplateResult() {
  const parts = editor.value.innerText.split(/([+\-*/()])/)

  parts.forEach((part, index) => {
    filterChargeItemList.value.forEach((item) => {
      if (part === item.chargeItemName) {
        parts[index] = item.value
      }
    })
  })

  const formula = parts.join('')

  try {
    result.value = evaluate(formula)
  } catch (error) {
    console.log(error)

    ElMessage.error('公式错误，请检查')
  }
}

function resetChargeTemplate() {
  filterChargeItemList.value.forEach((item) => {
    item.value = 0
  })
  result.value = ''
}

watch(
  () => form.value.formula,
  (val) => {
    if (!val) {
      formLists.templateList.forEach((item) => {
        item.isUse = false
        item.value = 0
      })
      return
    }

    let formula = val
    const parts = formula.split(/([+\-*/()])/)

    formLists.templateList.forEach((item) => {
      item.isUse = parts.includes(item.chargeItemName)

      if (item.value === undefined) {
        item.value = 0
      }
    })
  }
)
</script>
