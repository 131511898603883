<script setup>
import { syncData, syncMeter } from '@/api/modules/sync'
import { ElMessage } from 'element-plus'

const syncDataHandler = async () => {
  const response = await syncData()

  if (response.data.code === 0) {
    ElMessage.success('同步电表数据成功')
  } else {
    ElMessage.error('同步电表数据失败')
  }
}

const syncMeterHandler = async () => {
  const response = await syncMeter()

  if (response.data.code === 0) {
    ElMessage.success('同步电表成功')
  } else {
    ElMessage.error('同步电表失败')
  }
}
</script>

<template>
  <div style="padding: 20px">
    <el-row :gutter="10" style="margin-bottom: 8px">
      <el-col :span="1.5">
        <el-button type="primary" icon="Refresh" @click="syncMeterHandler"
          >同步电表</el-button
        >
      </el-col>
      <el-col :span="1.5">
        <el-button type="primary" icon="Refresh" @click="syncDataHandler"
          >同步电表数据</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
