import {api} from "@/api";

// 登陆
export const loginApi = (data) => {
    return api({
        headers: {},
        url: '/zouk/auth/login',   //登陆获取token
        method: 'POST',
        data: data
    })
}

// 登陆
export const loginApi2 = (data) => {
    return api({
        headers: {
            // Authorization: window.localStorage.getItem('Authorization')
        },
        url: '/zouk/rest/member/login',  //登陆
        method: 'POST',
        data: data
    })
}

// 忘记密码
export const forgetApi = (data) => {
    return api({
        headers: {},
        url: '/zouk/rest/member/forgetPassword',
        method: 'POST',
        data: data
    })
}

// 发送验证码
export const sendCodeApi = (data) => {
    return api({
        headers: {},
        url: '/zouk/rest/validateCode/sendForgetPassword',
        method: 'POST',
        data: data
    })
}

// 获取登陆用户详情
export const getLoginDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/member/getDetail',
        method: 'POST',
        data: data
    })
}


// 获取配置的登陆图和logo图
export const getImageConfigApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/config/getImageConfig',
        method: 'POST',
        data: data
    })
}
