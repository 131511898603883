<script setup>
import { ref } from 'vue'
import Landlord from '../landlord/landlord.vue'
import Tenant from '../tenant/tenant.vue'
import Sync from '../sync/sync.vue'
import Price from '../price/price.vue'

const activeName = ref('landlord')
const landlordId = ref('')

const handleTabChange = (tab) => {
  activeName.value = tab.activeName
  landlordId.value = tab.landlordId
}
</script>

<template>
  <div class="config">
    <el-tabs v-model="activeName">
      <el-tab-pane label="房东信息管理" name="landlord"></el-tab-pane>
      <el-tab-pane label="租户信息管理" name="tenant"> </el-tab-pane>
      <el-tab-pane label="三方数据同步" name="sync"> </el-tab-pane>
      <el-tab-pane label="电费模板管理" name="price"> </el-tab-pane>
    </el-tabs>

    <Transition appear mode="out-in" name="el-fade-in-linear">
      <Landlord
        v-if="activeName === 'landlord'"
        @tab-change="handleTabChange"
      />
      <Tenant v-else-if="activeName === 'tenant'" :landlordId="landlordId" />
      <Sync v-else-if="activeName === 'sync'" />
      <Price v-else-if="activeName === 'price'" />
    </Transition>
  </div>
</template>

<style>
.config .el-tabs__header {
  padding-left: 36px;
  padding-right: 36px;
  height: 70px;
  display: flex;
  align-items: center;
  margin: 0;
}
</style>
