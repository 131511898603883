<template>
  <div class="readSearch">
    <div class="title">
      <p>
        <span>读数查询</span>
      </p>
    </div>
    <div class="content">
      <!--搜索-->
      <div class="searchBox">
        <div style="height: 100%;overflow: auto">
          <div class="liBox liBox2" style="display: flex">
            <span>数据间隔： </span>
            <el-radio-group v-model="timeUnit">
              <el-radio v-for="(item,index) in intervalList" :key="index" :value="item.key">{{ item.value }}</el-radio>
            </el-radio-group>
          </div>
          <div class="liBox liBox2">
            <span>日期： </span>
            <el-radio-group v-model="timeFlag" style="margin-bottom: 10px;" @change="timeFlagSelect">
              <el-radio value="now">瞬时</el-radio>
              <el-radio value="1H">近1小时</el-radio>
              <el-radio value="12H">近12小时</el-radio>
              <el-radio value="1D">近一天</el-radio>
              <el-radio value="3D">近3天</el-radio>
              <el-radio value="1W">近1周</el-radio>
              <el-radio value="THIS_WEEK">本周</el-radio>
              <el-radio value="1M">本月</el-radio>
              <el-radio value="1Q">本季</el-radio>
              <el-radio value="-1">自定义时间段</el-radio>
            </el-radio-group>
            <el-date-picker v-model="dateTime" end-placeholder="结束时间" popper-class="date-style"
                            range-separator="→"
                            start-placeholder="开始时间" style="margin-left: 40px"
                            type="datetimerange"
                            value-format="YYYY-MM-DD HH:mm:ss">
            </el-date-picker>
          </div>
          <div class="liBox liCopy">
            <span>设备： </span>
            <el-tree-select
                ref="treeRef"
                v-model="deviceIds"
                :check-strictly="true"
                :load="treeList"
                :props="props"
                :render-after-expand="false"
                :render-content="renderContent"
                highlight-current
                lazy
                multiple
                node-key="id"
                popper-class="devicePopper"
                style="height: auto!important;"
                @change="equipmentChange()"
            />
            <el-select v-model="productId" class="item" clearable filterable
                       placeholder="请选择产品类型" @change="productChange">
              <el-option v-for="(item_d,index_d) in productList" :key="index_d" :label="item_d.name"
                         :value="item_d.id"></el-option>
            </el-select>
            <el-select v-model="dictKeys" class="item" clearable filterable multiple
                       placeholder="请选择指标" @change="dataChange">
              <el-option v-for="(item_d,index_d) in dataList" :key="index_d" :label="item_d.name"
                         :value="item_d.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="liBox" style="position:absolute;top: 16px;right: 0">
          <el-button type="success" @click="exportFun">导出</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {onMounted, reactive, ref} from "vue"
import moment from "moment";
import {getIntervalListApi, getTreePrefix} from "@/api/modules/dataSearch";
import {ElLoading, ElMessage} from "element-plus";
import {getTreeListApi} from "@/api/modules/control";
import qs from "qs";
import {http} from "@/api";
import {getDictSelectApi} from "@/api/modules/alarm";
import {getIndexSelectAPi, getProductSelectApi} from "@/api/modules/alarm";
import {getList} from "@/api/modules/productCategory";


let timeFlag = ref()   //日期快捷选择
let dateTime = ref([])   //搜索日期
let timeUnit = ref('1m')   //频率单位
let deviceIds = ref()
let dictKeys = ref()

// 日期快捷选择事件
const timeFlagSelect = function () {
  if (timeFlag.value === '-1') {
    dateTime.value = []
  } else if (timeFlag.value === '1H') {
    dateTime.value = [moment().subtract(1, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '12H') {
    dateTime.value = [moment().subtract(12, "hours").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1D') {
    dateTime.value = [moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '3D') {
    dateTime.value = [moment().subtract(2, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1W') {
    dateTime.value = [moment().subtract(6, "days").startOf('day').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === 'THIS_WEEK') {
    dateTime.value = [moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1M') {
    dateTime.value = [moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'), moment().format("YYYY-MM-DD HH:mm:ss")]
  } else if (timeFlag.value === '1Q') {
    dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().format("YYYY-MM-DD HH:mm:ss")]
    // dateTime.value = [moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).startOf('quarter').format("YYYY-MM-DD HH:mm:ss"), moment().quarter(moment(moment(), "YYYY-MM-DD").quarter()).endOf('quarter').format("YYYY-MM-DD HH:mm:ss")]
  }
}

// 设备选择事件
let equipmentChange = function () {
  // console.log(deviceIds.value)
}
// 指标选择事件
let dataChange = function () {

}
// 产品列表
let productList = ref([])
let productId = ref()
// 获取产品列表
const getProductListFun = function () {
  getProductSelectApi().then(res => {
    if (res.data.code === 0) {
      productList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const productChange = function () {
  getDataSelectFun()
  dictKeys.value = []
}

// 树形结构处理-----------------------------------------------------------
// 左侧项目树元素
let treeRef = ref()
//获取项目树
const getProjectFun = async function (node, resolve) {
  if (node.level === 0) {
    await getTreePrefix(qs.stringify({
      id: 0,
    })).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else if (node.data && node.data.subList && node.data.subList.length > 0) {
    return resolve(node.data.subList)
  } else {
    await getTreePrefix(qs.stringify({
      id: node.data.id,
    })).then(res => {
      if (res.data.code === 0) {
        return resolve(res.data.result)
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }
  // await getTreePrefix(qs.stringify({
  //   id: node.data.id ? node.data.id : 0,
  // })).then(res => {
  //   if (res.data.code === 0) {
  //     return resolve(res.data.result)
  //   } else {
  //     ElMessage({
  //       type: 'error',
  //       message: res.data.message
  //     })
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })

}
// 项目树配置
let props = {
  label: 'name',
  children: 'subList',
  isLeaf: (data, node) => {
    if (node.data && node.data.id && node.data.id.includes('e')) {
      return true
    }
  },
}
// 树级列表
const treeList = (node, resolve) => {
  getProjectFun(node, resolve)
}
// 自定义树形结构的前面图标
const renderContent = (h, {node, data}) => {
  return h('div', [
    h('img', {
      src: data.id.includes('p') ? 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon5.png' : 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/energyIcon4.png',
      style: {
        width: '16px',
        height: '16px',
        transform: 'translateY(3px)',
        marginRight: '5px'
      }
    }, ''),
    h('span', null, node.label)
  ])
}


// 获取指标列表
let dataList = ref([])
const getDataSelectFun = function () {
  getDictSelectApi(qs.stringify({
    productId: productId.value
  })).then(res => {
    if (res.data.code === 0) {
      dataList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 点击导出
const exportFun = function () {
  if (timeFlag.value !== '' && timeUnit.value !== '' && deviceIds.value && deviceIds.value.length > 0 && dictKeys.value && dictKeys.value.length > 0) {
    openFullScreen();
    setTimeout(() => {
      loadingAdd.value.close();
      ElMessage({
        type: 'success',
        message: '导出成功'
      });
    }, 2000);
    // console.log('timeFlag=' + timeFlag.value + '&memberId=' + window.localStorage.getItem('memberId') + '&timeUnit=' + timeUnit.value + '&startTime=' + dateTime.value[0] + '&endTime=' + dateTime.value[1] + '&equipmentIdList=' + deviceIds.value.join(',') + '&dictIdList=' + dictKeys.value.join(','))
    if (dateTime.value && dateTime.value.length > 0) {
      window.location.href = http + 'zouk/rest/dataMetricsSearch/exportWithRead?timeFlag=' + timeFlag.value + '&memberId=' + window.localStorage.getItem('memberId') + '&timeUnit=' + timeUnit.value + '&startTime=' + dateTime.value[0] + '&endTime=' + dateTime.value[1] + '&equipmentIdList=' + deviceIds.value.join(',') + '&dictIdList=' + dictKeys.value.join(',')
    } else {
      window.location.href = http + 'zouk/rest/dataMetricsSearch/exportWithRead?timeFlag=' + timeFlag.value + '&memberId=' + window.localStorage.getItem('memberId') + '&timeUnit=' + timeUnit.value + '&equipmentIdList=' + deviceIds.value.join(',') + '&dictIdList=' + dictKeys.value.join(',')

    }
  } else {
    ElMessage({
      type: 'info',
      message: '请选择查询条件'
    })
  }
}


let intervalList = ref([])  //数据间隔列表
let productCategoryList = ref([])  //数据间隔列表
onMounted(() => {
  // getDataSelectFun()
  getProductListFun()
  // 获取数据间隔列表
  getIntervalListApi().then(async res => {
    if (res.data.code === 0) {
      intervalList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
  // 获取产品分类
  getList(null).then(res => {
    if (res.data.ok) {
      productCategoryList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
})
</script>

<style lang="less">
.readSearch {
  width: 100%;
  height: 100%;

  //标题
  .title {
    width: calc(100% - 72px);
    height: 48px;
    padding: 0 36px;
    line-height: 48px;
    //background-color: #EDF0F6;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .content {
    width: calc(100% - 56px);
    height: calc(100% - 72px);
    padding: 0 28px 24px;
    overflow: auto;

    //搜索
    .searchBox {
      height: calc(100% - 32px);
      padding: 16px 24px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);
      position: relative;

      .liBox {
        margin-bottom: 30px;
        margin-right: 16px;
        line-height: 32px;

        > p {
          margin-bottom: 8px;
        }

        .el-select, .el-input {
          width: 300px;
        }

        .el-date-editor {
          max-width: 340px;
        }

        .el-input-group__append {
          .el-input.el-input--suffix {
            width: 90px !important;

            .el-input__wrapper {
              background-color: #fff !important;
            }
          }
        }

        .el-button {
          margin-bottom: 20px;
        }


      }

      .liBox2 {
        display: flex;
        flex-wrap: wrap;

        .timeUnitSelectBox, .timeFlagSelectBox {
          display: inline-block;
          position: relative;
        }

        .el-select {
          width: 48px;
          position: relative;
          z-index: 99;
        }

        .el-select .el-select__wrapper {
          opacity: 0;
        }

        .el-radio {
          margin-right: 16px;
        }
      }


      .liCopy {
        .el-select, .el-input {
          margin-right: 16px;
        }

        .item {
          width: 300px;

          .el-input.el-input--suffix {
            width: 300px;
          }
        }
      }

      .addIcon, .delIcon {
        font-size: 18px;
        transform: translateY(5px);
        margin-right: 5px;
        cursor: pointer;
      }


      //应用设备
      .applicationDevice {
        width: calc(100% - 2px);
        border-radius: 3px;
        border: 1px solid #DDDDDD;
        margin-bottom: 16px;

        .head {
          width: calc(100% - 16px);
          height: 20px;
          padding: 14px 8px;
          background-color: #F5F7FA;
          display: flex;
          align-items: center;

          .el-input {
            width: 265px;
            height: 32px;
            margin-left: 18px;
          }
        }

        .deviceList {
          padding: 16px 16px 6px;
          display: flex;
          flex-wrap: wrap;


          p {
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 10px;
            background: #F5F5F5;
            border-radius: 3px;
            cursor: pointer;
          }

          .active {
            background: #E8EFFF;
            color: #165DFF;
          }
        }
      }
    }
  }

}

</style>
