<script setup>
import { ref } from 'vue'
import statisticsAnalysisLandlord from '../statisticsAnalysisLandlord/statisticsAnalysisLandlord.vue'
import statisticsAnalysisTenant from '../statisticsAnalysisTenant/statisticsAnalysisTenant.vue'

const activeName = ref('statisticsAnalysisLandlord')
</script>

<template>
  <div class="statistics">
    <el-tabs v-model="activeName">
      <el-tab-pane
        label="房东统计分析"
        name="statisticsAnalysisLandlord"
      ></el-tab-pane>
      <el-tab-pane label="租户统计分析" name="statisticsAnalysisTenant">
      </el-tab-pane>
    </el-tabs>

    <Transition appear mode="out-in" name="el-fade-in-linear">
      <component
        :is="
          activeName === 'statisticsAnalysisLandlord'
            ? statisticsAnalysisLandlord
            : statisticsAnalysisTenant
        "
      />
    </Transition>
  </div>
</template>

<style>
.statistics .el-tabs__header {
  padding-left: 36px;
  padding-right: 36px;
  height: 70px;
  display: flex;
  align-items: center;
  margin: 0;
}
</style>
