<template>
  <div class="alarmRule">
    <div class="title">
      <p>
        <span>告警规则</span>
      </p>
    </div>
    <!--列表-->
    <div class="content">
      <div class="contain">
        <!--新增规则-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增规则
        </el-button>
        <!--规则列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
            </el-empty>
          </template>
          <el-table-column label="告警名称" prop="name"></el-table-column>
          <el-table-column label="设备" prop="equipmentNameList"></el-table-column>
          <el-table-column label="创建时间" prop="createTime"></el-table-column>
          <el-table-column label="告警规则" prop="targetList">
            <template #default="scope">
              <p v-for="(item,index) in scope.row.targetList" :key="index">
                <span v-if="item.level===1" class="level level1">{{ item.levelStr }}</span>
                <span v-if="item.level===2" class="level level2">{{ item.levelStr }}</span>
                <span v-if="item.level===3" class="level level3">{{ item.levelStr }}</span>
                <span v-if="item.level===4" class="level level4">{{ item.levelStr }}</span>
                <span v-if="item.level===5" class="level level5">{{ item.levelStr }}</span>
                {{ item.dictName }}{{ item.symbol }}{{ item.value }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="120px">
            <template #default="scope">
              <div class="opacity">
                <span class="editClass btn" @click.prevent="editClick(scope.row.id,scope.row)">编辑</span>
                <span class="editClass delBtn" @click.prevent="delClick(scope.row.id,scope.row)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableData.length>0" class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
    <!--弹窗-->
    <el-dialog v-model="dialogFormVisible" :close-on-click-modal="false" :title="formData.id?'编辑规则':'新建规则'"
               style="min-width: 1008px" width="70%">
      <el-form ref="fromRef" :inline="true" :model="formData" :rules="formRules">
        <el-form-item label="规则名称:" prop="name">
          <el-input v-model="formData.name" placeholder="请输入规则名称"></el-input>
        </el-form-item>
        <!--        <el-form-item label="设备" prop="equipmentIdList" style="width: calc(60% - 64px);">-->
        <!--          <el-tree-select-->
        <!--              ref="treeRef1"-->
        <!--              v-model="formData.equipmentIdList"-->
        <!--              :data="allTree"-->
        <!--              :props="props"-->
        <!--              :render-after-expand="false"-->
        <!--              class="treeSelect"-->
        <!--              filterable-->
        <!--              multiple-->
        <!--              node-key="id"-->
        <!--              placeholder="请选择设备"-->
        <!--              style="height: auto!important;"-->
        <!--          />-->
        <!--        </el-form-item>-->
        <el-form-item class="topFormItem topFormItem2" label="设备" prop="equipmentIdList" style="width: 100%">
          <el-select ref="elSelectTable" v-model="formData.equipmentIdList1"
                     :disabled="true" :popper-append-to-body="false" :suffix-icon="Plus"
                     class="timeSelect" multiple popper-class="timeSelectPopper"
                     @click="selectTimeFun">
            <el-option v-for="(item,index) in formData.equipmentIdList" :key="index"
                       :label="item.value"
                       :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <!--新增-->
        <p class="line" style="width: 100%;height: 1px;background-color: #eee;margin-bottom: 25px"></p>
        <el-form-item label="自动派单:" prop="autoFlag" style="width: calc(100% - 32px)">
          <el-switch v-model="formData.autoFlag"></el-switch>
        </el-form-item>
        <!--        <el-form-item label="派单人手机号:" prop="mobiles" style="width: calc(40% - 32px)">-->
        <!--          <el-input v-model="formData.mobiles" placeholder="请输入手机号"></el-input>-->
        <!--        </el-form-item>-->
        <!--        <el-form-item label="验证码:" style="width: calc(40% - 32px)">-->
        <!--          <el-input v-model="formData.mobilesCode" placeholder="请输入验证码" @change="checkCodeFun1">-->
        <!--            <template #suffix>-->
        <!--              <p style="color: #165DFF;cursor: pointer;" @click="sendCode1">发送验证码</p>-->
        <!--            </template>-->
        <!--          </el-input>-->
        <!--        </el-form-item>-->
        <div v-for="(item,index) in formData.mobiles" :key="index" class="notificationBox" style="width: 100%">
          <el-form-item label="通知人手机号:" prop="item.mobile">
            <el-input v-model="item.mobile" :disabled="item.disabled" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item v-if="!item.disabled" label="验证码:" prop="item.code">
            <el-input v-model="item.code" placeholder="请输入验证码" @change="checkCodeFun2(index,item)">
              <template #suffix>
                <p style="color: #165DFF;cursor: pointer;" @click="sendCode2(index,item)">发送验证码</p>
              </template>
            </el-input>
          </el-form-item>
          <el-icon v-if="formData.mobiles.length>1" class="delIcon"
                   style="font-size: 18px;margin-right: 5px;cursor: pointer;transform: translateY(-2px)"
                   @click="notificationDelClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===formData.mobiles.length-1"
                   class="addIcon" style="font-size: 18px;margin-right: 5px;cursor: pointer;transform: translateY(-2px)"
                   @click="notificationAddClick">
            <CirclePlus/>
          </el-icon>
        </div>
        <p class="line" style="width: 100%;height: 1px;background-color: #eee;margin-bottom: 25px"></p>


        <el-form-item v-show="formData.equipmentIdList.length>0" label="告警模版:" prop="template">
          <el-select v-model="formData.template" class="item" clearable placeholder="请选择告警模版" @change="templateChange">
            <el-option v-for="(item,index) in selectList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <!--        <el-form-item label="通知人:" prop="memberIdList" style="width: calc(60% - 64px);">-->
        <!--          <el-select v-model="formData.memberIdList" class="item treeSelect" clearable multiple placeholder="请选择通知人">-->
        <!--            <el-option v-for="(item,index) in memberList" :key="index" :label="item.name" :value="item.id"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <div v-for="(item,index) in formData.targetJsonStr" v-show="formData.equipmentIdList.length>0" :key="index"
             class="rowItem">
          <el-form-item name="item.level">
            <el-select v-model="item.level" class="item" clearable placeholder="请选择告警级别">
              <el-option :value="1" label="紧急告警"></el-option>
              <el-option :value="2" label="主要告警"></el-option>
              <el-option :value="3" label="次要告警"></el-option>
              <el-option :value="4" label="警告告警"></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item>-->
          <!--            <el-select v-model="item.productId" class="item" clearable filterable-->
          <!--                       placeholder="请选择产品类型" @change="productChange(index)">-->
          <!--              <el-option v-for="(item_d,index_d) in productList" :key="index_d" :label="item_d.name"-->
          <!--                         :value="item_d.id"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->
          <el-form-item class="data" name="item.dictId">
            <el-select v-model="item.dictId" class="item" filterable placeholder="请选择监控指标"
                       @change="handleChange($event,index)">
              <el-option v-for="(item_d,index_d) in dataList" :key="index_d" :label="item_d.name"
                         :value="item_d.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="data" name="item.value">
            <el-input v-model="item.value" class="item" placeholder="请输入告警值">
              <template #prepend>
                <el-select v-model="item.symbol" class="item" placeholder="请选择">
                  <el-option label=">" value=">"></el-option>
                  <el-option label="<" value="<"></el-option>
                  <el-option label="=" value="="></el-option>
                  <el-option label=">=" value=">="></el-option>
                  <el-option label="<=" value="<="></el-option>
                </el-select>
              </template>
            </el-input>
          </el-form-item>

          <el-icon v-if="formData.targetJsonStr.length>1" class="delIcon" @click="delItemClick(index)">
            <CircleClose/>
          </el-icon>
          <el-icon v-if="index===formData.targetJsonStr.length-1" class="addIcon" @click="addItemClick">
            <CirclePlus/>
          </el-icon>
        </div>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button class="cancle" @click="dialogFormVisible=false">取消</el-button>
        <el-button class="confrim" type="primary" @click="save()">保存</el-button>
      </span>
      </template>
    </el-dialog>
    <DeviceSelect :checkDeviceList="formData.equipmentIdList" :deviceVisible="deviceVisible"
                  @checkDeviceListFun="checkDeviceListFun" @deviceVisibleFun="deviceVisibleFun"/>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref, watch} from "vue"
import './alarmRule.less'
import DeviceSelect from '@/components/deviceSelect'

import {CloseBold, ZoomIn, Plus, Back, CircleClose, CirclePlus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import qs from "qs";
import {getAllTreeApi} from "@/api/modules/strategy";
import {
  checkCodeApi,
  delAlarmRuleAPi,
  getAlarmRuleDetailAPi,
  getAlarmRuleListAPi, getAlarmSelectListAPi,
  getIndexSelectAPi, getUserSelectListAPi,
  saveAlarmRuleAPi, sendCodeApi, getProductSelectApi, listSelectByPrefixEquipmentIdApi
} from "@/api/modules/alarm";

let router = useRouter()


// 规则列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref(0)
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 表格数据
let tableData = ref([])
// 获取表格数据
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getAlarmRuleListAPi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 新增编辑-----------------------------------------------------------------
// 新增编辑弹窗
let dialogFormVisible = ref(false)
// 产品列表
let productList = ref([])
// 获取产品列表
const getProductListFun = function () {
  getProductSelectApi().then(res => {
    if (res.data.code === 0) {
      productList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
const productChange = function (idx) {
  formData.value.targetJsonStr[idx].dictId = ''
  getDataListFun(idx)
}
// 指标列表
let dataList = ref([])
// 获取指标列表
const getDataListFun = function () {
  listSelectByPrefixEquipmentIdApi(qs.stringify({
    equipmentIdList: formData.value.equipmentIdList1.join(',')
  })).then(res => {
    if (res.data.code === 0) {
      dataList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 指标改变事件
const handleChange = function (value, index) {
  const optionExists = dataList.value.some(option => option.id === value);
  if (!optionExists) {
    formData.value.targetJsonStr[index].dictId = '';
  }
}
// 告警模版选择
const templateChange = function () {
  for (let i = 0; i < selectList.value.length; i++) {
    if (formData.value.template === selectList.value[i].id) {
      formData.value.targetJsonStr = selectList.value[i].targetList
    }
  }
  for (let i = 0; i < formData.value.targetJsonStr.length; i++) {
    handleChange(formData.value.targetJsonStr[i].dictId, i)
  }
  // for (let i = 0; i < formData.value.targetJsonStr.length; i++) {
  //   getDataListFun(i)
  // }
}
// 新增编辑表单数据
let formData = ref({
  id: 0,
  name: '',
  equipmentIdList: [],
  memberIdList: [],
  template: '',
  autoFlag: true,
  mobiles: [{
    mobile: '',
    code: ''
  }],
  targetJsonStr: [
    {
      level: '',
      dictId: '',
      symbol: '',
      value: '',
      // productId: ''
    }
  ]
})
// 表单规则
let formRules = ref({
  name: [
    {required: true, message: '请输入规则名称', trigger: 'blur'},
  ],
  equipmentIdList: [
    {required: true, message: '请选择设备', trigger: 'blur'},
  ],
  memberIdList: [
    {required: true, message: '请选择通知人', trigger: 'blur'},
  ],
  level: [
    {required: true, message: '请选择告警级别', trigger: 'blur'},
  ],
})
// 点击新增
const addClick = function () {
  dialogFormVisible.value = true
  formData.value = {
    id: 0,
    name: '',
    equipmentIdList: [],
    memberIdList: [],
    template: '',
    autoFlag: true,
    mobiles: [{
      mobile: '',
      code: ''
    }],
    targetJsonStr: [
      {
        level: '',
        dictId: '',
        symbol: '',
        value: '',
        // productId: ''
      }
    ]
  }
}
// 点击编辑
const editClick = function (id, row) {
  dialogFormVisible.value = true
  getAlarmRuleDetailAPi(qs.stringify({id: id})).then(res => {
    if (res.data.code === 0) {
      formData.value = {
        id: res.data.result.id,
        name: res.data.result.name,
        equipmentIdList: res.data.result.equipmentList,
        memberIdList: res.data.result.memberIdList,
        autoFlag: res.data.result.autoFlag == 1 ? true : false,
        mobiles: res.data.result.mobileList,
        level: res.data.result.level,
        targetJsonStr: res.data.result.targetList
      }
      formData.value.equipmentIdList1 = []
      for (let i = 0; i < formData.value.equipmentIdList.length; i++) {
        formData.value.equipmentIdList[i].name = formData.value.equipmentIdList[i].value
        formData.value.equipmentIdList[i].id = formData.value.equipmentIdList[i].key
        formData.value.equipmentIdList1.push(formData.value.equipmentIdList[i].key)
      }
      for (let i = 0; i < res.data.result.mobileList.length; i++) {
        formData.value.mobiles[i] = {mobile: res.data.result.mobileList[i], disabled: true}
      }
      // getDataListFun()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击增加设备
const addItemClick = function () {
  formData.value.targetJsonStr.push({
    level: '',
    dictId: '',
    symbol: '',
    value: '',
    // productId: ''
  })
}
// 点击删除设备
const delItemClick = function (index) {
  formData.value.targetJsonStr.splice(index, 1)
  // dataList.value.splice(index, 1)
}
// 点击新增通知人
const notificationAddClick = function () {
  console.log(formData.value.mobiles[formData.value.mobiles.length - 1].disabled)
  if (!formData.value.mobiles[formData.value.mobiles.length - 1].disabled) {
    if (formData.value.mobiles[formData.value.mobiles.length - 1].mobile && formData.value.mobiles[formData.value.mobiles.length - 1].code) {
      formData.value.mobiles.push({
        mobile: '',
        code: ''
      })
    } else {
      ElMessage({
        type: 'info',
        message: '请输入通知人手机号与验证码'
      })
    }
  } else {
    formData.value.mobiles.push({
      mobile: '',
      code: ''
    })
  }

}
// 点击删除通知人
const notificationDelClick = function (index) {
  formData.value.mobiles.splice(index, 1)
}
// 点击发送验证码
const sendCode2 = function (index, item) {
  sendCodeApi(qs.stringify({
    mobile: item.mobile,
    type: 4
  })).then(res => {
    if (res.data.code === 0) {
      ElMessage({
        message: '已发送验证码',
        type: 'success',
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 检验验证码
const checkCodeFun2 = function (index, item) {
  checkCodeApi(qs.stringify({
    mobile: item.mobile,
    type: 4,
    code: item.code
  })).then(res => {
    if (res.data.code === 0) {

    } else {
      ElMessage({
        message: res.data.message,
        type: 'error',
      })
      formData.value.mobiles[index].code = ''
    }
  }).catch(err => {
    console.log(err);
  })
}
// 提交
let fromRef = ref()
const save = function () {
  // 提交表单
  if (fromRef) {
    fromRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        let flag = true
        for (let i = 0; i < formData.value.mobiles.length; i++) {
          if (formData.value.mobiles[i].code === '') {
            flag = false
          }
        }
        if (flag) {
          let data = JSON.parse(JSON.stringify(formData.value))
          data.targetJsonStr = JSON.stringify(data.targetJsonStr)
          // data.equipmentIdList = data.equipmentIdList.join(',')
          data.equipmentIdList = data.equipmentIdList1.join(',')
          data.memberIdList = data.memberIdList.join(',')
          data.autoFlag = data.autoFlag ? 1 : 0
          for (let i = 0; i < data.mobiles.length; i++) {
            data.mobiles[i] = data.mobiles[i].mobile
          }
          data.mobiles = data.mobiles.join(',')
          for (let i = 0; i < data.targetJsonStr.length; i++) {
            delete data.targetJsonStr[i].dictName
          }
          delete data.equipmentIdList1
          delete data.memberIdList


          saveAlarmRuleAPi(qs.stringify(data)).then(res => {
            if (res.data.code === 0) {
              getListFun()
              dialogFormVisible.value = false
            } else {
              ElMessage({
                type: 'error',
                message: res.data.message
              })
            }
          }).catch(err => {
            console.log(err);
          })
        } else {
          ElMessage({
            type: 'info',
            message: '请输入验证码'
          })
        }
      }
    })
  }
}


// 设备选择-----------------------------------------------------------------
let deviceVisible = ref(false)
const selectTimeFun = function () {
  deviceVisible.value = true
}
const deviceVisibleFun = function (data) {
  deviceVisible.value = data
}
const checkDeviceListFun = function (data) {
  formData.value.equipmentIdList = data
}
watch(() => formData.value.equipmentIdList, (newVal) => {
  formData.value.equipmentIdList1 = []
  for (let i = 0; i < newVal.length; i++) {
    formData.value.equipmentIdList1.push(newVal[i].key)
  }
}, {deep: true, immediate: true})
watch(() => formData.value.equipmentIdList1, () => {
  getDataListFun()
})


// 点击删除-----------------------------------------------------------------
const delClick = function (id, row) {
  ElMessageBox.confirm('请确认是否要删除此告警规则，删除后数据不可恢复', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除规则',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此告警规则，删除后数据不可恢复'),
    ])
  }).then(() => {
    delAlarmRuleAPi(qs.stringify({id: id})).then(res => {
      if (res.data.code === 0) {
        getListFun()
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消删除'
    });
  });
}


// 树形结构-----------------------------------------------------------------
let treeRef1 = ref()
// 筛选树形结构唯一值
const onlySelect = function (data) {
  for (let i = 0; i < data.length; i++) {
    if (data[i].type == 1) {
      data[i].id = data[i].id + '_' + data[i].type
    }
    // data[i].onlyValue = data[i].id + '_' + data[i].type
    if (data[i].subList.length > 0) {
      onlySelect(data[i].subList)
    }
  }
}
// 项目树配置
let props = {
  label: 'name',
  value: 'id',
  children: 'subList',
  disabled: 'isProject',
  isLeaf: (data, node) => {
    if (node.data.type === 2) {
      return true
    }
  }
}
// 树形结构数据
let allTree = ref()
let selectList = ref([])
let memberList = ref([])

onMounted(() => {
  getListFun()
  getProductListFun()

  // 获取所有设备树
  getAllTreeApi().then(async res => {
    if (res.data.code === 0) {
      let data = res.data.result
      await onlySelect(data)
      allTree.value = data
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })

  // 获取告警模版选项
  getAlarmSelectListAPi().then(async res => {
    if (res.data.code === 0) {
      selectList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
  // 获取登陆组织的用户选项
  getUserSelectListAPi().then(async res => {
    if (res.data.code === 0) {
      memberList.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message,
      })
    }
  }).catch(err => {
    console.log(err);
  })
})


</script>

<style lang="less">
.topFormItem2 {
  .el-select .el-select__wrapper.is-disabled, .el-select .el-icon.el-select__caret.el-select__icon {
    cursor: pointer !important;
  }
}
</style>
