<template>
  <div class="deviceDetail">
    <!--标题-->
    <div class="title">
      <p>
        <span @click="goBack">设备管理</span>
        <span> / </span>
        <span>设备详情</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        设备详情
      </p>
    </div>
    <div class="content">
      <el-tabs v-model="activeName" class="demo-tabs">
        <el-tab-pane label="数据统计" name=1>
          <div class="cont">
            <div class="detailBox">
              <div class="topBox">
                <p>
                  <span>节点类型: </span>
                  <span>{{ metricsData.node_type === '2' ? '应用' : '驱动' }}</span>
                </p>
                <p>
                  <span>连接状态: </span>
                  <span>{{ metricsData.link_state === '1' ? '连接' : '断开' }}</span>
                </p>
                <p>
                  <span>采集组总数: </span>
                  <span>{{ tableData.length }}</span>
                  <!--<span>{{ metricsData.group_tags_total}}</span>-->
                </p>
                <p>
                  <span>工作状态: </span>
                  <span :class="metricsData.running_state==='3'  ?'start':'stop'">{{
                      metricsData.running_state === '3' ? '运行中' : '停止'
                    }}</span>
                </p>
              </div>
              <div class="bottomBox">
                <div class="item">
                  <p>{{ metricsData.last_rtt_ms }}</p>
                  <p>当前采集延迟</p>
                </div>
                <div class="item">
                  <p>{{ metricsData.send_bytes }}</p>
                  <p>发送字节数</p>
                </div>
                <div class="item">
                  <p>{{ metricsData.recv_bytes }}</p>
                  <p>接收字节数</p>
                </div>
                <div class="item">
                  <p>{{ metricsData.tags_total }}</p>
                  <p>点位总数</p>
                </div>
                <div class="item">
                  <p>{{ metricsData.tag_reads_total }}</p>
                  <p>点位读取数总计</p>
                </div>
                <div class="item">
                  <p>{{ metricsData.tag_read_errors_total }}</p>
                  <p>点位读取错误数总计</p>
                </div>
              </div>
            </div>
            <div class="searchBox">
              <div class="liBox">
                <label style="margin-right: 10px;">组名称</label>
                <el-input v-model="name" :prefix-icon="Search" clearable placeholder="请输入关键字搜索"></el-input>
              </div>
            </div>
            <div class="tableBox">
              <el-table v-loading="loading" :data="tableDataFiter" style="width: 100%">
                <template #empty>
                  <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
                  </el-empty>
                </template>
                <el-table-column label="组名称" property="name">
                  <template #default="scope">
                    <p>
                      {{ scope.row.name.slice(7, scope.row.name.length - 1) }}</p>
                  </template>
                </el-table-column>
                <el-table-column label="点位数" property="group_tags_total"></el-table-column>
                <el-table-column label="最近一次发送消息数" property="group_last_send_msgs"></el-table-column>
                <el-table-column label="最近一次执行消耗时间(ms)" property="group_last_timer_ms"></el-table-column>
                <el-table-column label="上一次采集错误信息" property="group_last_error_code"></el-table-column>
                <el-table-column label="上一次采集错误信息时间" property="group_last_error_timestamp_ms">
                  <template #default="scope">
                    <p>
                      {{
                        scope.row.group_last_error_timestamp_ms != 0 ? moment(Number(scope.row.group_last_error_timestamp_ms)).format("YYYY-MM-DD HH:mm:ss") : '-'
                      }}</p>
                  </template>
                </el-table-column>
              </el-table>
              <div class="footerBox">
                <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                               layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                               @current-change="pageChange"/>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="设备配置" name=2>
          <div class="cont2">
            <el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
              <el-form-item label="应用名称" prop="name" style="margin-bottom: 20px">
                <el-input v-model="formData.name" disabled placeholder="请输入应用名称"></el-input>
              </el-form-item>
              <!--              <el-form-item label="插件" prop="plugin">-->
              <!--                <el-select v-model="formData.plugin" clearable filterable placeholder="请选择插件" @change="selectChange">-->
              <!--                  <el-option v-for="(item,index) in selectList" :key="index"-->
              <!--                             :label="item.name==='eKuiper'?'ZKData':item.name"-->
              <!--                             :value="item.name"></el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
            </el-form>
            <el-form :inline="true" :model="formData.params" label-width="auto" style="transform: translateY(-16px)">
              <!--接口请求循环出来的表单数据-->
              <el-form-item v-for="(value, key, index) in formObj" :key="index" :prop="key" :rules="[{
                        required: value.attribute==='required'&&(!value.condition||formData.params[value.condition.field]===value.condition.value),
                        message: `${value.type==='string'||value.type==='int'?'请输入':'请选择'}${value.name_zh}`,
                        trigger: 'blur',
                      }]" class="formItem">
                <div v-if="!value.condition||formData.params[value.condition.field]===value.condition.value"
                     slot="label" class="labelBox" style="margin-top: 20px">
                  <i v-if="value.attribute==='required'" style="color: red;">*</i>
                  <p style="margin-left: 5px">{{ value.name_zh }}</p>
                  <el-tooltip :content="value.description_zh" effect="dark" placement="top"
                              popper-class="el_tooltip_item">
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <div class="widthBox">
                  <!--输入框-->
                  <!--<el-input
                      v-if="value.type==='string'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]" :maxlength="value.valid.length"></el-input>-->
                  <el-input
                      v-if="value.type==='string'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]"></el-input>
                  <!--下拉框-->
                  <el-select
                      v-if="value.type==='map'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]" clearable>
                    <el-option v-for="(item,index) in value.valid.map" :key="index" :label="item.key"
                               :value="item.value"></el-option>
                  </el-select>
                  <!--单选框-->
                  <el-radio-group
                      v-if="value.type==='bool'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]">
                    <el-radio :label="true" border>True</el-radio>
                    <el-radio :label="false" border>False</el-radio>
                  </el-radio-group>
                  <!--数字输入框-->
                  <el-input-number
                      v-if="value.type==='int'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model="formData.params[key]" :controls="false" :max="value.valid.max" :min="value.valid.min"/>
                  <!--上传文件-->
                  <el-upload
                      v-if="value.type==='file'&&(!value.condition||formData.params[value.condition.field]===value.condition.value)"
                      v-model:file-list="formData.params[key+'-fileList']" :auto-upload="false"
                      :on-remove="handleRemove($event,key)" action="#" class="upload-demo" limit="1"
                      @change="uploadFile($event,key)">
                    <div class="uploadBox">
                      <div
                          :class="formData.params[key+'-fileList']&&formData.params[key+'-fileList'].length>0?'none':''"
                          class="upBtnBox">
                        <el-icon>
                          <Document/>
                        </el-icon>
                        <span>点击上传</span>
                      </div>
                    </div>
                  </el-upload>
                </div>
              </el-form-item>
            </el-form>
            <!--<el-form ref="userForm" :inline="true" :model="formData" :rules="formRules" label-width="auto">
              <el-form-item label="设备名称" prop="name">
                <el-input v-model="formData.name" placeholder="请输入设备名称"></el-input>
              </el-form-item>

              <el-form-item prop="ip">
                <div slot="label" class="labelBox">
                  <i style="color: red;">*</i>
                  <p style="margin-left: 5px">指令发送间隔</p>
                  <el-tooltip class="box-item" content="绑定本地IP地址，等待eKuiper连接" effect="dark" placement="top">
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <el-input v-model="formData.ip" placeholder="请输入指令发送间隔"></el-input>
              </el-form-item>
              <el-form-item prop="ip">
                <div slot="label" class="labelBox">
                  <i style="color: red;">*</i>
                  <p style="margin-left: 5px">IP地址</p>
                  <el-tooltip class="box-item" content="绑定本地IP地址，等待eKuiper连接" effect="dark" placement="top">
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <el-input v-model="formData.ip" placeholder="请输入IP地址"></el-input>
              </el-form-item>
              <el-form-item prop="port">
                <div slot="label" class="labelBox">
                  <i style="color: red;">*</i>
                  <p style="margin-left: 5px">端口号</p>
                  <el-tooltip class="box-item" content="绑定本地端口，等待eKuiper连接" effect="dark" placement="top">
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <el-input v-model="formData.port" placeholder="请输入端口号"></el-input>
              </el-form-item>
              <el-form-item prop="port">
                <div slot="label" class="labelBox">
                  <i style="color: red;">*</i>
                  <p style="margin-left: 5px">连接超时时间</p>
                  <el-tooltip class="box-item" content="绑定本地端口，等待eKuiper连接" effect="dark" placement="top">
                    <el-icon>
                      <QuestionFilled/>
                    </el-icon>
                  </el-tooltip>
                </div>
                <el-input v-model="formData.port" placeholder="请输入连接超时时间"></el-input>
              </el-form-item>
            </el-form>-->
            <div class="footer">
              <el-button class="cancle resetButton" @click="goBack">取消</el-button>
              <el-button class="confrim" type="primary" @click="save()">保存</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h, onUnmounted} from 'vue';
import './deviceDetail.less'
import {useRouter} from "vue-router/dist/vue-router";
import {Back, QuestionFilled} from '@element-plus/icons-vue'
import {metricsList, saveSouthEquipmentApi} from "@/api/modules/southDriver";
import {
  getFormDataApi,
  getSelectListApi,
  getSettingApi,
  saveSettingApi
} from "@/api/modules/northDriver";
import moment from "moment";
import {ElMessage} from "element-plus";
import qs from "qs";

let router = useRouter()
/*生命周期*/
onMounted(async () => {
  getMetricsListFun()
  // 获取下拉列表数据
  getSelectListApi().then(res => {
    if (res.status === 200) {
      for (let i = 0; i < res.data.plugins.length; i++) {
        if (res.data.plugins[i].node_type === 2) {
          selectList.value.push(res.data.plugins[i])
        }
      }
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
  await selectChange()
  getSettingFun()
})
let metricsData = ref({}) //数据详情
let tableData = ref([]) //表格数据
let name = ref('') //名称搜索
const tableDataFiter = computed(() => {
  if (name.value) {
    return tableData.value.filter(user =>
        user.name.slice(7, user.name.length - 1).toLowerCase() == name.value.toLowerCase()
    );
  } else {
    return tableData.value
  }
});
//设备详情
const getMetricsListFun = function () {
  var params = {
    category: 'driver',
    node: router.currentRoute.value.query.node
  }
  metricsList(params).then(res => {
    if (res.status == 200) {
      //console.log(res.data)
      const regex = /\r\n|\n|\r/g;
      let arr = res.data.split(regex)
      let newArr = {}
      let newArr2 = []
      let newData2 = []
      for (let i = 0; i < arr.length; i++) {
        if (arr[i][0] !== '#') {
          let valIndex = arr[i].indexOf('}')
          let keyIndex = arr[i].indexOf('{')
          newArr[arr[i].slice(0, keyIndex)] = arr[i].slice(valIndex + 2)
        }
        if (arr[i].indexOf('group=') != -1) {
          let valIndex = arr[i].indexOf('"}')
          let keyIndex = arr[i].indexOf('group="')
          newArr2.push({
            name: arr[i].slice(keyIndex, valIndex + 1)
          })
          newData2.push(arr[i])
        }
      }
      const uniqueArray = Array.from(new Set(newArr2.map(JSON.stringify))).map(JSON.parse);
      metricsData.value = newArr
      for (var i = 0; i < uniqueArray.length; i++) {
        for (var j = 0; j < newData2.length; j++) {
          let valIndex = newData2[j].indexOf('}')
          let keyIndex = newData2[j].indexOf('{')
          if (newData2[j].indexOf(uniqueArray[i].name) != -1) {
            uniqueArray[i][newData2[j].slice(0, keyIndex)] = newData2[j].slice(valIndex + 2)
          }
        }
      }
      tableData.value = uniqueArray
      //console.log(metricsData.value)
    } else {
      metricsData.value = {}
      tableData.value = []
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// tab栏选中
let activeName = ref(router.currentRoute.value.query.type)
// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}
// 返回
const goBack = function () {
  router.push({
    path: '/admin/project',
    query: {
      type: 3
    }
  })
}
// 添加编辑应用*****************************************************************************
// 获取应用配置
const getSettingFun = function () {
  getSettingApi({
    node: router.currentRoute.value.query.node
  }).then(res => {
    if (res.status === 200) {
      formData.value.params = res.data.params
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 应用表单数据
let formData = ref({
  name: router.currentRoute.value.query.node,
  plugin: router.currentRoute.value.query.plugin === 'ZKData' ? 'eKuiper' : router.currentRoute.value.query.plugin,
  params: {},
})
let formRules = ref({
  name: [{
    required: true,
    message: '请输入应用名称',
    trigger: 'change',
  }],
  plugin: [{
    required: true,
    message: '请选择插件',
    trigger: 'blur'
  }]
})
// 插件选择事件
let formObj = ref({})
const selectChange = async function () {
  await getFormDataApi({
    schema_name: formData.value.plugin
  }).then(res => {
    if (res.status === 200) {
      formData.value = {
        name: formData.value.name,
        plugin: formData.value.plugin,
        params: {}
      }
      formObj.value = res.data
      let num = 0
      for (const key in formObj.value) {
        formData.value.params[key] = formObj.value[key].default
        if (formObj.value[key].type === 'file') {
          let fileList = key + '-fileList'
          formData.value.params[fileList] = []
          num++
        }
      }
      delete formObj.value.group_interval;
      delete formObj.value.tag_regex;
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 文件上传
const uploadFile = function (file, key) {
  const reader = new FileReader();
  reader.readAsDataURL(file.raw);
  reader.onload = (e) => {
    formData.value.params[key] = reader.result.split(",")[1]
  };
}
// 文件删除
const handleRemove = function (file, key) {
  formData.value.params[key] = ''
}

// 提交应用表单
let userForm = ref()
const save = function () {
  userForm.value.validate((valid) => {
    //提交成功
    if (valid) {
      formData.value.node = formData.value.name
      saveSettingApi({...formData.value, projectId: router.currentRoute.value.query.projectId}).then(res => {
        if (res.status === 200) {
          router.push({
            path: '/admin/project',
            query: {
              type: 3
            }
          })
        } else {
          ElMessage({
            type: 'error',
            message: res.data.message
          });
        }
      }).catch(err => {
        console.log(err)
      })


      // saveSouthEquipmentApi(qs.stringify(formData.value)).then(res => {
      //   if (res.data.code === 0) {
      //      router.push({
      //   path: '/admin/project',
      //       query: {
      //     type: 3
      //   }
      // })
      //   } else {
      //     ElMessage({
      //       type: 'error',
      //       message: res.data.message
      //     });
      //   }
      // }).catch(err => {
      //   console.log(err)
      // })
    }
  })
}
// 下拉列表数据
let selectList = ref([])
// 定时器
//const intervalId = setInterval(getMetricsListFun, 5000)
onUnmounted(() => {
  //clearInterval(intervalId)
})
</script>

<style lang="less" scoped>

</style>
