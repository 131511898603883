<template>
  <div class="powerStationWarn">
    <div class="title">
      <p>
        <span @click="goBack">储能电站</span>
        <span> / </span>
        <span>告警</span>
      </p>
      <p class="tit">
        <el-icon @click="goBack">
          <Back/>
        </el-icon>
        告警
      </p>
    </div>
    <div class="content">
      <div class="contTitle">告警</div>
      <!--搜索-->
      <div class="searchBox">
        <div style="display: flex; width: calc(100% - 136px);flex-wrap: wrap">
          <div class="liBox">
            <span>电站名称:</span>
            <el-input v-model="name" clearable placeholder="请输入电站名称"></el-input>
          </div>
          <div class="liBox">
            <span>发生时间:</span>
            <el-date-picker
                v-model="happenTime"
                type="datetime"
                value-format="YYYY-MM-DD HH:mm:ss"
            ></el-date-picker>
          </div>
          <div class="liBox">
            <span>发生位置:</span>
            <el-select v-model="happenLocal">
              <el-option>全部</el-option>
            </el-select>
          </div>
          <div class="liBox">
            <span>电站地址:</span>
            <el-input v-model="address" clearable placeholder="请输入电站地址"></el-input>
          </div>
          <div class="liBox">
            <span>处理进度:</span>
            <el-select v-model="progress">
              <el-option>已处理</el-option>
              <el-option>未处理</el-option>
            </el-select>
          </div>
        </div>
        <div class="liBox" style="width: 136px;margin-right: 24px;align-items: start">
          <el-button class="searchButton" type="primary" @click="searchFun">查询</el-button>
          <el-button class="searchButton" @click="resetFun">重置</el-button>
        </div>
      </div>
      <!--数据列表内容-->
      <div class="contain">
        <!--告警列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%;" table-layout="fixed">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建设备信息</p>
            </el-empty>
          </template>
          <el-table-column label="名称" prop="name"></el-table-column>
          <el-table-column label="发生时间" prop="memberName">
            <template #default="scope">
              <span>{{}}</span>
            </template>
          </el-table-column>
          <el-table-column label="发生位置" prop="customerName"></el-table-column>
          <el-table-column label="报警数量" prop="createTime"></el-table-column>
          <el-table-column label="地址" prop="beforeContent">
            <template #default="scope">
              <p class="cont">{{ scope.row.beforeContent }}</p>
              <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/positionIcon.png"
                   style="width: 14px;height: 14px;">
            </template>
          </el-table-column>
          <el-table-column label="处理进度" prop="progress">
            <template #default="scope">
              <p class="cont">{{ scope.row.progress }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="192px">
            <template #default="scope">
              <div class="opacity">
                <el-tooltip content="结束报警">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon6.png"
                       @click="endClick(scope.row.id)">
                </el-tooltip>
                <el-tooltip content="转工单">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon7.png"
                       @click="wordClick(scope.row.id)">
                </el-tooltip>
                <el-tooltip content="报警详情">
                  <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon2.png"
                       @click="warnClick(scope.row.id)">
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-show="tableData.length > 0" v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]"
                         :total="total" background
                         layout="total,sizes,prev, pager, next" @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>

    <!--告警设备弹窗-->
    <el-dialog v-model="warnDialog" :close-on-click-modal="false" class="warnDialog" title="查看详情" width="50%">
      <el-table v-loading="warnLoading" :data="warnTableData" class="tableBox" style="width: 100%"
                table-layout="auto">
        <template #empty>
          <el-empty class="emptyClass" description="暂无数据信息"
                    image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
          </el-empty>
        </template>
        <el-table-column label="发生时间" prop="happenTime"/>
        <el-table-column label="类别" prop="type"/>
        <el-table-column class-name="level" label="级别" prop="level">
          <template #default="scope">
            <p v-if="scope.row.level===3" style="background: #FF1111">高风险</p>
            <p v-if="scope.row.level===2" style="background: #FF7811">中风险</p>
            <p v-if="scope.row.level===1" style="background: #FFBB11">低风险</p>
            <p v-if="scope.row.level===0" style="background: #00B42A">正常</p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template #default="scope">
            <el-tooltip content="结束报警">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon6.png"
                   style="margin-right: 10px" @click="endClick(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="转工单">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon7.png"
                   style="margin-right: 10px" @click="wordClick(scope.row.id)">
            </el-tooltip>
            <el-tooltip content="查看当时值">
              <img alt="" class="operator"
                   src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/operaIcon1.png"
                   @click="lookValue(scope.row.id)">
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './powerStationWarn.less'
import {Back} from '@element-plus/icons-vue'
import {useRouter} from "vue-router/dist/vue-router";
import {ElMessage, ElMessageBox} from "element-plus";


let router = useRouter()
let route = useRouter()

const goBack = function () {
  router.push('/admin/energyStation')
}


// 电站列表-----------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 搜索条件
let name = ref()
let happenTime = ref()
let happenLocal = ref()
let address = ref()
let progress = ref()
// 参数表格数据
let tableData = ref([{}, {}, {}, {}, {}])
// 获取告警列表
let loading = ref(false)
const getListFun = function () {
  // loading.value = true
  // getLogApi(qs.stringify({
  //   pageNumber: pageNumber.value,
  //   pageSize: pageSize.value,
  //   name: name.value,
  //   happenTime: happenTime.value,
  //   happenLocal: happenLocal.value,
  //   address: address.value,
  //   progress: progress.value,
  // })).then(res => {
  //   loading.value = false
  //   if (res.data.code === 0) {
  //     tableData.value = res.data.result.list
  //     total.value = Number(res.data.result.total)
  //   } else {
  //     ElMessage({
  //       message: res.data.message,
  //       type: 'error'
  //     })
  //   }
  // }).catch(err => {
  //   console.log(err);
  // })
}
// 查询
const searchFun = function () {
  pageNumber.value = 1
  getListFun()
}
// 重置
const resetFun = function () {
  pageNumber.value = 1
  pageSize.value = 20
  name.value = ''
  happenTime.value = null
  happenLocal.value = ''
  address.value = ''
  progress.value = ''
  getListFun()
}


// 告警详情
let warnDialog = ref(false)
let warnLoading = ref(false)
let warnTableData = ref([
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 0
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 1
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 2
  },
  {
    id: 0,
    happenTime: '2024-09-10 12:45:04',
    type: 'C相断相',
    level: 3
  }
])
// 点击查看告警详情
const warnClick = function () {
  warnDialog.value = true
}

// 转工单
const wordClick = function () {

}


// 结束报警
const endClick = function () {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '结束报警',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要结束此报警，结束后数据不可恢复'),
    ])
  }).then(() => {
    // delSearchListApi(id).then(res => {
    //   if (res.data.code === 200) {
    //     getSearchListFun()
    //     ElMessage({
    //       type: 'success',
    //       message: '结束报警成功'
    //     });
    //   } else {
    //     ElMessage({
    //       type: 'error',
    //       message: res.data.message
    //     })
    //   }
    // }).catch(err => {
    //   console.log(err);
    // })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '已取消结束报警'
    });
  });
}


onMounted(() => {
  if (router.currentRoute.value.query.name) {
    name.value = router.currentRoute.value.query.name
  }
  const query = {...route.query};
  // 使用新的查询参数替换当前路由
  router.replace({query});
})

</script>

<style lang="less" scoped>
</style>
