<template>
  <div class="role">
    <div class="title">角色管理</div>
    <div class="content">
      <!--左侧角色列表-->
      <div class="leftRoleBox">
        <div class="title">角色列表</div>
        <div class="cont">
          <div v-for="(item,index) in roleList" :key="index"
               :class="item.id===activeId?'activeList listItem':'listItem'"
               @click="selectRoleClick(index,item.id)">
            <p>{{ item.name }}</p>
            <el-button class="edit" @click="addRoleClick(item.id,item.name)">
              <el-icon>
                <Edit/>
              </el-icon>
            </el-button>
            <el-button class="del" @click="delRoleClick(item.id)">
              <el-icon>
                <Delete/>
              </el-icon>
            </el-button>
          </div>
        </div>
        <div class="footer">
          <el-button @click="addRoleClick(0)"><i class="iconfont icon-jiaoseguanli"></i>新建角色</el-button>
        </div>
      </div>
      <!--右侧权限管理-->
      <div class="rightBox">
        <div class="cont">
          <el-tabs v-model="tabName" class="demo-tabs" @tab-click="tabClick">
            <el-tab-pane :name="1" label="桌面端">
              <!--tab里面的内容-->
              <el-alert :closable="false" show-icon title="配置Web管理平台的功能权限" type="info"/>
              <div v-show="existAllFlag" class="allBtn">
                <p class="title">所有菜单标识</p>
                <el-switch v-model="allFlag" @change="allFlagClick"/>
              </div>
              <el-collapse>
                <el-collapse-item v-for="(item,index) in menuList" :key="index" :name="index">
                  <!--折叠面板头部-->
                  <template #title>
                    <p class="title">{{ item.name }}</p>
                    <el-switch v-model="item.checkFlag" @change="checkItemChange(item)"
                               @click="$event.stopPropagation()"/>
                  </template>
                  <!--折叠面板内容-->
                  <div v-if="!item.subMenuFlag">
                    <p class="title">操作权限</p>
                    <el-checkbox-group v-model="item.subList2">
                      <el-checkbox v-for="(item_child,index_child) in item.subList" :key="index_child"
                                   :label="item_child.id"
                                   @change="checkItemChange(item.subList2)">{{
                          item_child.name
                        }}
                      </el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div v-if="item.subMenuFlag">
                    <div v-for="(item_child,index_child) in item.subList" :key="index_child" class="collapseCont">
                      <div class="title">{{ item_child.name }}
                        <el-switch v-model="item_child.checkFlag" style="float: right;width: 40px;"
                                   @change="checkItemChange(item_child)"/>
                      </div>
                      <el-checkbox-group v-model="item_child.subList2">
                        <div v-for="(item_child2,index_child2) in item_child.subList" :key="index_child2">
                          <el-checkbox :label="item_child2.id" @change="checkItemChange(item_child.subList2)">{{
                              item_child2.name
                            }}
                          </el-checkbox>
                        </div>
                      </el-checkbox-group>
                      <div v-if="item_child.subList.length===0" class="empty">
                        <el-icon>
                          <InfoFilled/>
                        </el-icon>
                        <span>该菜单无操作权限</span>
                      </div>
                    </div>
                  </div>


                  <div v-if="item.subList.length===0" class="empty">
                    <el-icon>
                      <InfoFilled/>
                    </el-icon>
                    <span>该菜单无操作权限</span>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </el-tab-pane>
            <!--<el-tab-pane label="移动端" name="mobile">移动端</el-tab-pane>-->
          </el-tabs>
        </div>
        <div class="footer">
          <el-button type="primary" @click="menuSubmit">保存</el-button>
        </div>
      </div>
    </div>
    <!--新建编辑角色弹窗-->
    <el-dialog
        v-model="roleDialog"
        :title="editId?'编辑角色':'新增角色'"
        width="522"
    >
      <el-form
          ref="roleFormRef"
          :model="roleForm"
          :rules="roleRules"
          class="roleFormRef"
          status-icon
      >
        <el-form-item label="角色名称" prop="name">
          <el-input v-model="roleForm.name" placeholder="请输入角色名称"/>
        </el-form-item>
      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="roleDialog = false">取消</el-button>
        <el-button type="primary" @click="roleSubmit">确定</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {h, onMounted, reactive, ref} from "vue"
import './role.less'
import {Edit, Delete, InfoFilled} from '@element-plus/icons-vue'
import {CascaderProps, ElLoading, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {getRoleListApi, getRoleMenuApi, saveRoleApi, saveRoleMenuApi, delRoleApi} from '@/api/modules/role'
import qs from "qs";


let router = useRouter()

// 加载的动画
let loadingAdd = ref()
const openFullScreen = function () {
  loadingAdd.value = ElLoading.service({
    lock: true,
    text: 'Loading',
    background: 'rgba(0, 0, 0, 0.1)'
  })
}

// 角色管理------------------------------------------------------------
// 角色列表
let roleList = ref([])
// 获取角色列表
const getRoleListFun = async function () {
  await getRoleListApi().then(res => {
    if (res.data.code === 0) {
      roleList.value = res.data.result
      if (!activeId.value) {
        activeId.value = roleList.value[0].id
      }
      getRoleMenuFun(activeId.value)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
// 点击角色列表
let activeId = ref(0)
const selectRoleClick = function (index, id) {
  activeId.value = id
  getRoleMenuFun(id)
}
// 角色表单数据
let roleForm = ref({
  name: ''
})
// 角色表单规则
let roleRules = ref({
  name: [
    {required: true, message: '请输入角色名称', trigger: 'blur'},
  ]
})
// 新建角色弹窗
let roleDialog = ref(false)
// 编辑的id
let editId = ref('0')
// 新建角色
const addRoleClick = function (idx, name) {
  if (name) {
    roleForm.value.name = name
  } else {
    roleForm.value.name = ''
  }
  editId.value = idx
  roleDialog.value = true
}
// 新建角色提交
let roleFormRef = ref()
const roleSubmit = function () {
  roleFormRef.value.validate((valid) => {
    //提交成功
    if (valid) {
      saveRoleApi(qs.stringify({
        id: editId.value,
        name: roleForm.value.name
      })).then(async res => {
        if (res.data.code === 0) {
          await getRoleListFun()
          roleDialog.value = false
        } else {
          ElMessage({
            message: res.data.message,
            type: 'error'
          })
        }
      }).catch(err => {
        console.log(err);
      })
    }
  })
}
// 删除角色
const delRoleClick = function (idx) {
  ElMessageBox.confirm('请确认是否要删除此角色，删除后数据不可恢复', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    // type: 'warning',
    title: '删除角色',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此角色，删除后数据不可恢复'),
    ])
  }).then(() => {
    delRoleApi(qs.stringify({
      id: idx
    })).then(res => {
      if (res.data.code === 0) {
        activeId.value = 0
        getRoleListFun()
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}


// 右侧权限管理------------------------------------------------------------
// tab栏选择
let tabName = ref(1)
// tab栏切换事件
const tabClick = function () {

}

// 是否是所有菜单
let allFlag = ref(false)
// 是否展示所有菜单按钮
let existAllFlag = ref(true)
// 点击全选
const allFlagClick = function (e) {
  if (e) {
    for (let i = 0; i < menuList.value.length; i++) {
      menuList.value[i].checkFlag = true
      for (let j = 0; j < menuList.value[i].subList.length; j++) {
        menuList.value[i].subList[j].checkFlag = true
        for (let k = 0; k < menuList.value[i].subList[j].subList.length; k++) {
          menuList.value[i].subList[j].subList[k].checkFlag = true
        }
      }
    }
  } else {
    for (let i = 0; i < menuList.value.length; i++) {
      menuList.value[i].checkFlag = false
      for (let j = 0; j < menuList.value[i].subList.length; j++) {
        menuList.value[i].subList[j].checkFlag = false
        for (let k = 0; k < menuList.value[i].subList[j].subList.length; k++) {
          menuList.value[i].subList[j].subList[k].checkFlag = false
        }
      }
    }
  }

  for (let i = 0; i < menuList.value.length; i++) {
    if (menuList.value[i].subMenuFlag) {
      for (let j = 0; j < menuList.value[i].subList.length; j++) {
        if (!menuList.value[i].subList[j].subMenuFlag) {
          menuList.value[i].subList[j].subList2 = []
          for (let k = 0; k < menuList.value[i].subList[j].subList.length; k++) {
            if (menuList.value[i].subList[j].subList[k].checkFlag) {
              menuList.value[i].subList[j].subList2.push(menuList.value[i].subList[j].subList[k].id)
            }
          }
        }
      }
    } else {
      menuList.value[i].subList2 = []
      for (let j = 0; j < menuList.value[i].subList.length; j++) {
        if (menuList.value[i].subList[j].checkFlag) {
          menuList.value[i].subList2.push(menuList.value[i].subList[j].id)
        }
      }
    }
  }
}
// 点击单个选中
const checkItemChange = function (item) {
  // 联动下面一层
  if (item.subMenuFlag) {
    for (let i = 0; i < item.subList.length; i++) {
      item.subList[i].checkFlag = item.checkFlag
      if (item.checkFlag) {
        item.subList[i].subList2 = []
        for (let j = 0; j < item.subList[i].subList.length; j++) {
          item.subList[i].subList2.push(item.subList[i].subList[j].id)
        }
      } else {
        item.subList[i].subList2 = []
      }
    }
  } else {
    if (item.checkFlag) {
      item.subList2 = []
      for (let j = 0; j < item.subList.length; j++) {
        item.subList2.push(item.subList[j].id)
      }
    } else {
      item.subList2 = []
    }
  }
  // 处理是否全选
  let flag = true
  for (let i = 0; i < menuList.value.length; i++) {
    if (!menuList.value[i].checkFlag) {
      flag = false
    }
    if (menuList.value[i].subMenuFlag) {
      // menuList.value[i].checkFlag = false    //判断上级是否应该关闭
      for (let j = 0; j < menuList.value[i].subList.length; j++) {
        if (menuList.value[i].subList[j].subList.length !== menuList.value[i].subList[j].subList2.length) {
          flag = false
        }
        if (!menuList.value[i].subList[j].checkFlag) {
          flag = false
        }

        // 上级联动
        if (menuList.value[i].subList[j].checkFlag) {
          menuList.value[i].checkFlag = true
        }
        if (menuList.value[i].subList[j].subList2.length !== 0) {
          menuList.value[i].subList[j].checkFlag = true
          menuList.value[i].checkFlag = true
        } else {
          //判断上级是否应该关闭

          // menuList.value[i].subList[j].checkFlag = false
          // menuList.value[i].checkFlag = false
          // for (let k = 0; k < menuList.value[i].subList.length; k++) {
          //   if (menuList.value[i].subList[k].checkFlag) {
          //     menuList.value[i].checkFlag = true
          //   }
          // }
        }
      }
    } else {
      if (menuList.value[i].subList.length !== menuList.value[i].subList2.length) {
        flag = false
      }
      if (menuList.value[i].subList2.length !== 0) {
        menuList.value[i].checkFlag = true
      } else {
        // menuList.value[i].checkFlag = false
      }
    }
  }
  allFlag.value = flag
}
// 菜单列表
let menuList = ref([])
// 获取菜单列表
const getRoleMenuFun = function (id) {
  openFullScreen()
  getRoleMenuApi(qs.stringify({
    id: id,
    type: tabName.value
  })).then(res => {
    loadingAdd.value.close();
    if (res.data.code === 0) {
      allFlag.value = !!res.data.result.allFlag
      existAllFlag.value = res.data.result.existAllFlag
      let list = JSON.parse(JSON.stringify(res.data.result.menuList))
      for (let i = 0; i < list.length; i++) {
        list[i].checkFlag = !!list[i].checkFlag
        if (list[i].subMenuFlag) {
          for (let j = 0; j < list[i].subList.length; j++) {
            list[i].subList[j].checkFlag = !!list[i].subList[j].checkFlag;
            if (!list[i].subList[j].subMenuFlag) {
              list[i].subList[j].subList2 = []
              for (let k = 0; k < list[i].subList[j].subList.length; k++) {
                if (list[i].subList[j].subList[k].checkFlag) {
                  list[i].subList[j].subList2.push(list[i].subList[j].subList[k].id)
                }
              }
            }
          }
        } else {
          list[i].subList2 = []
          for (let j = 0; j < list[i].subList.length; j++) {
            if (list[i].subList[j].checkFlag) {
              list[i].subList2.push(list[i].subList[j].id)
            }
          }
        }
      }
      menuList.value = list
    } else {
      loadingAdd.value.close();
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 更新菜单列表
const menuSubmit = function () {
  let arr = []
  for (let i = 0; i < menuList.value.length; i++) {
    if (menuList.value[i].checkFlag) {
      arr.push(menuList.value[i].id)
    }
    if (menuList.value[i].subMenuFlag) {
      for (let j = 0; j < menuList.value[i].subList.length; j++) {
        arr.push(...menuList.value[i].subList[j].subList2)
        if (menuList.value[i].subList[j].checkFlag) {
          arr.push(menuList.value[i].subList[j].id)
        }
      }
    } else {
      arr.push(...menuList.value[i].subList2)
    }
    // for (let j = 0; j < menuList.value[i].subList.length; j++) {
    //   if (menuList.value[i].subList[j].checkFlag) {
    //     arr.push(menuList.value[i].subList[j].id)
    //   }
    //   for (let k = 0; k < menuList.value[i].subList[j].subList.length; k++) {
    //     if (menuList.value[i].subList[j].subList[k].checkFlag) {
    //       arr.push(menuList.value[i].subList[j].subList[k].id)
    //     }
    //   }
    // }
  }
  if (!existAllFlag.value) {
    allFlag.value = false
  }
  let data = {
    id: activeId.value,
    type: 1,
    allFlag: allFlag.value ? 1 : 0,
    menuJsonStr: JSON.stringify(arr)
  }
  saveRoleMenuApi(qs.stringify(data)).then(res => {
    if (res.data.code === 0) {
      getRoleMenuFun(activeId.value)
      ElMessage({
        message: '保存成功',
        type: 'success'
      })
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// onMounted
onMounted(async () => {
  await getRoleListFun()
  // getRoleMenuFun(roleList.value[0].id)
})
</script>

<style lang="less" scoped>
</style>
