import {api} from "@/api";

// 获取大屏列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataScreen/page',
        method: 'POST',
        data: data
    })
}
//图片上传
export const uploadImg = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
            // 'Content-Type': 'multipart/form-data'
        },
        url: '/zouk/rest/upload/uploadSingle',
        method: 'POST',
        data: data
    })
}
//新增/编辑的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataScreen/save',
        method: 'POST',
        data: data
    })
}

//删除大屏
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/dataScreen/delete',
        method: 'POST',
        data: data
    })
}
