<template>
  <div class="powerStationVideoScreen">
    <el-carousel :autoplay="false" :initial-index="1">
      <el-carousel-item v-for="(item,index) in videoList" :key="index">
        <div class="videoBox">
          <!--          <video :src="item.url" autoplay="autoplay" crossorigin="anonymous" muted></video>-->
          <video :id="'video'+index" autoplay controls crossOrigin="anonymous" muted/>
          <!--          <iframe-->
          <!--              src="http://192.168.3.240:8080/#/play/wasm/ws%3A%2F%2F192.168.3.253%3A9000%2Frtp%2F41010500002000000001_34020000001320000001.live.flv"></iframe>-->
          <p>{{ item.name }}</p>
        </div>
      </el-carousel-item>
    </el-carousel>
    <el-icon class="close" @click="backClick">
      <CloseBold/>
    </el-icon>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue"
import {ArrowLeftBold, ArrowRightBold, CloseBold} from '@element-plus/icons-vue'
import router from "@/router";
import flvjs from 'flv.js'

const backClick = function () {
  router.push('/admin/powerStationVideo')
}

let videoList = ref([
  {
    name: 'xxx视频监控',
    url: '/api4/#/play/wasm/ws%3A%2F%2F192.168.3.253%3A9000%2Frtp%2F41010500002000000001_34020000001320000001.live.flv'
    // url: 'http://192.168.3.240:8080/#/play/wasm/ws%3A%2F%2F192.168.3.253%3A9000%2Frtp%2F41010500002000000001_34020000001320000001.live.flv'
    // url: 'http://vjs.zencdn.net/v/oceans.mp4'
  },
  // {
  //   name: 'xxx视频监控',
  //   url: 'https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv'
  // },
  // {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // }, {
  //   name: 'xxx视频监控',
  //   url: 'https://www.runoob.com/try/demo_source/movie.mp4'
  // },
])

let player = ref([])
const playVideo = function () {
  videoList.value.forEach((item, index) => {
    console.log(flvjs.isSupported())
    if (flvjs.isSupported()) {


      player.value[index] = flvjs.createPlayer({
        type: "auto", //=> 媒体类型 flv 或 mp4
        isLive: true, //=> 是否为直播流
        // hasAudio: false, //=> 是否开启声音
        hasAudio: true,
        hasVideo: true,
        url: item.url, //=> 视频流地址
      }, {
        cors: true, // 是否跨域
        stashInitialSize: 384, // 缓存大小(kb)  默认384kb
        enableStashBuffer: true, // 是否启用缓存
        autoCleanupMaxBackwardDuration: 3 * 60,//自动清除缓存时间
        enableWorker: false, // 是否多线程工作
        autoCleanupSourceBuffer: true, // 是否自动清理缓存
        fixAudioTimestampGap: true,//音视频同步
      })
      nextTick(() => {
        let videoElement = document.querySelector("#video" + index);
        player.value[index].attachMediaElement(videoElement); //=> 绑DOM
        console.log(player.value[index], videoElement, index)
        player.value[index].load();
        setTimeout(() => {
          player.value[index].play();
        }, 1000)

      })

    } else {
      this.$message.error('不支持flv格式视频')
    }
  })
}


let idx = ref(0)


const leftClick = function () {
  if (idx.value !== 0) {
    idx.value = idx.value - 1
  }
}

const rightClick = function () {
  if (idx.value !== (videoList.value.length - 1)) {
    idx.value = idx.value + 1
  }
}


onMounted(() => {
  playVideo()
})

onUnmounted(() => {
  // if (player.value) {
  //   player.value.pause();
  //   player.value.unload();
  //   player.value.detachMediaElement();
  //   player.value.destroy();
  //   player.value = null;
  // }
});

</script>

<style lang="less">
.powerStationVideoScreen {
  width: calc(100vw - 240px);
  height: calc(100vh - 160px);
  //background: #000;
  background: #fff;
  padding: 80px 120px;
  position: relative;

  .el-carousel {
    width: 100%;
    height: 100%;


    .el-carousel__arrow {
      display: block !important;
      width: 48px;
      height: 48px;
      background: #2F2F2F;
      color: #FFFFFF;
      line-height: 48px;
      border-radius: 6px;
      position: absolute;

      .el-icon {
        font-size: 20px;
        font-weight: 800;
        transform: translateY(6px);
      }
    }

    .el-carousel__arrow--left {
      left: 0;
    }

    .el-carousel__arrow--right {
      right: 0;
    }

    .el-carousel__indicators.el-carousel__indicators--horizontal {
      display: none;
    }

    .el-carousel__container {
      height: 100%;
    }

    .videoBox {
      width: calc(100% - 200px);
      height: 100%;
      margin-left: 100px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      video {
        width: 100%;
        max-width: 100%;
        max-height: calc(100% - 30px);
      }

      p {
        width: 100%;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #FFFFFF;
        font-size: 16px;
      }
    }
  }

  .close {
    position: absolute;
    font-size: 18px;
    color: #fff;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
}
</style>
