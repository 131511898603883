<template>
  <div>
    <div class="title" style="display: flex; align-items: center">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/admin/permit' }"
          >用电作业许可证
        </el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="content">
      <el-card header="用户信息" shadow="never" style="margin-bottom: 16px">
        <el-form :inline="true" label-width="auto" disabled>
          <el-form-item label="用户名称" prop="userName">
            <el-input v-model="userInfo.userName" style="width: 240px" />
          </el-form-item>
          <el-form-item label="用户手机号码" prop="phone">
            <el-input v-model="userInfo.phone" style="width: 240px" />
          </el-form-item>
        </el-form>
      </el-card>

      <el-card header="用电箱信息" shadow="never" style="margin-bottom: 16px">
        <el-form :inline="true" label-width="auto" disabled>
          <el-form-item label="用电箱编号" prop="boxNumber">
            <el-input v-model="boxDetail.boxNumber" style="width: 240px" />
          </el-form-item>
          <el-form-item label="用电时间" prop="" style="width: 480px">
            <el-date-picker
              v-model="dateTimeRange"
              value-format="YYYY-MM-DD HH:mm:ss"
              type="datetimerange"
              range-separator="-"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="二维码" prop="boxQrCode">
            <ImagePreview
              :src="boxDetail.boxQrCode"
              :width="100"
              :height="100"
            />
          </el-form-item>
        </el-form>
      </el-card>

      <el-card
        header="用电作业许可证"
        shadow="never"
        style="margin-bottom: 16px"
      >
        <el-timeline style="max-width: 600px">
          <el-timeline-item
            v-for="(activity, index) in permitRecord"
            :key="index"
            :icon="activity.icon"
            :type="activity.type"
            :color="activity.color"
            :size="activity.size"
            :hollow="activity.hollow"
            :timestamp="activity.createTime"
          >
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
      </el-card>
    </div>
  </div>
</template>

<script setup>
import { getBox } from '@/api/modules/box'
import { getPermit, getPermitRecord, getUserInfo } from '@/api/modules/permit'
import ImagePreview from '@/components/ImagePreview.vue'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()
const form = ref({})

const userInfo = ref({})
const boxDetail = ref({})
const permitRecord = ref([])
const dateTimeRange = ref([])

getPermit(route.query.id).then((res) => {
  form.value = res.data.result
  dateTimeRange.value = [form.value.permitStartTime, form.value.permitEndTime]

  getBox(form.value.boxId).then((res) => {
    boxDetail.value = res.data.result
  })
})

getUserInfo(route.query.id).then((res) => {
  userInfo.value = res.data.result
})

getPermitRecord(route.query.id).then((res) => {
  permitRecord.value = res.data.result

  permitRecord.value[0].type = 'primary'
  permitRecord.value[0].hollow = true
})
</script>

<style>
/*.title {*/
/*  height: 48px;*/
/*  padding-left: 36px;*/
/*  line-height: 48px;*/
/*}*/

/*.content {*/
/*  padding: 0 28px 24px;*/
/*}*/
</style>
