<template>
  <div class="component-upload-image">
    <el-upload
      :http-request="uploadToOSS"
      multiple
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :limit="limit"
      :on-error="handleUploadError"
      :on-exceed="handleExceed"
      ref="imageUpload"
      :before-remove="handleDelete"
      :show-file-list="true"
      :file-list="fileList"
      :on-preview="handlePictureCardPreview"
      :class="{ hide: fileList.length >= limit }"
      :disabled="disabled"
    >
      <el-icon class="avatar-uploader-icon"><plus /></el-icon>
    </el-upload>
    <!-- 上传提示 -->
    <div class="el-upload__tip" v-if="showTip">
      请上传
      <template v-if="fileSize">
        大小不超过 <b style="color: #f56c6c">{{ fileSize }}MB</b>
      </template>
      <template v-if="fileType">
        格式为 <b style="color: #f56c6c">{{ fileType.join('/') }}</b>
      </template>
      的文件
    </div>

    <el-dialog
      v-model="dialogVisible"
      title="预览"
      width="800px"
      append-to-body
    >
      <img
        :src="dialogImageUrl"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import { getStsToken } from '@/api/modules/aliyun'
import OSS from 'ali-oss'
import { ElMessage } from 'element-plus'
import { getCurrentInstance, ref, computed, watch } from 'vue'

const props = defineProps({
  modelValue: [String, Object, Array],
  limit: {
    type: Number,
    default: 5,
  },
  fileSize: {
    type: Number,
    default: 5,
  },
  fileType: {
    type: Array,
    default: () => ['png', 'jpg', 'jpeg'],
  },
  isShowTip: {
    type: Boolean,
    default: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const { proxy } = getCurrentInstance()
const emit = defineEmits(['update:modelValue'])
const number = ref(0)
const uploadList = ref([])
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
const fileList = ref([])
const showTip = computed(
  () => props.isShowTip && (props.fileType || props.fileSize)
)

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      const list = Array.isArray(val) ? val : props.modelValue.split(',')
      fileList.value = list.map((item) => {
        if (typeof item === 'string') {
          item = { name: item.split('/').pop(), url: item }
        }
        return item
      })
    } else {
      fileList.value = []
    }
  },
  { deep: true, immediate: true }
)

function handleBeforeUpload(file) {
  let isImg = false
  if (props.fileType.length) {
    let fileExtension = ''
    if (file.name.lastIndexOf('.') > -1) {
      fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
    }
    isImg = props.fileType.some((type) => {
      if (file.type.indexOf(type) > -1) return true
      if (fileExtension && fileExtension.indexOf(type) > -1) return true
      return false
    })
  } else {
    isImg = file.type.indexOf('image') > -1
  }
  if (!isImg) {
    ElMessage.error(
      `文件格式不正确, 请上传${props.fileType.join('/')}图片格式文件!`
    )
    return false
  }
  if (props.fileSize) {
    const isLt = file.size / 1024 / 1024 < props.fileSize
    if (!isLt) {
      ElMessage.error(`上传头像图片大小不能超过 ${props.fileSize} MB!`)
      return false
    }
  }
  number.value++
}

function handleExceed() {
  ElMessage.error(`上传文件数量不能超过 ${props.limit} 个!`)
}

function handleUploadSuccess(res, file) {
  if (res.res.status === 200) {
    uploadList.value.push({ name: res.name, url: res.url })
    uploadedSuccessfully()
  } else {
    number.value--
    ElMessage.error(res.msg)
    proxy.$refs.imageUpload.handleRemove(file)
    uploadedSuccessfully()
  }
}

function handleDelete(file) {
  const findex = fileList.value.map((f) => f.name).indexOf(file.name)
  if (findex > -1 && uploadList.value.length === number.value) {
    fileList.value.splice(findex, 1)
    emit('update:modelValue', listToString(fileList.value))
    return false
  }
}

function uploadedSuccessfully() {
  if (number.value > 0 && uploadList.value.length === number.value) {
    fileList.value = fileList.value
      .filter((f) => f.url !== undefined)
      .concat(uploadList.value)
    uploadList.value = []
    number.value = 0
    emit('update:modelValue', listToString(fileList.value))
  }
}

function handleUploadError() {
  ElMessage.error('上传图片失败')
}

function handlePictureCardPreview(file) {
  dialogImageUrl.value = file.url
  dialogVisible.value = true
}

function listToString(list, separator = ',') {
  return list
    .filter((item) => item.url && !item.url.startsWith('blob:'))
    .map((item) => item.url)
    .join(separator)
}

let credentials = JSON.parse(localStorage.getItem('credentials'))

async function uploadToOSS({ file }) {
  if (isCredentialsExpired(credentials)) {
    const response = await getStsToken()

    credentials = response.data.result
    localStorage.setItem('credentials', JSON.stringify(credentials))
  }

  const client = new OSS({
    // 将<YOUR_BUCKET>设置为OSS Bucket名称。
    bucket: 'power-box-test',
    // 将<YOUR_REGION>设置为OSS Bucket所在地域，例如oss-cn-hangzhou。
    region: 'oss-cn-hangzhou',
    // 如果这里使用的是本地获取的ststoken的参数，请将credentials.xxx手动替换为已获取到参数的具体数值
    accessKeyId: credentials.accessKeyId,
    accessKeySecret: credentials.accessKeySecret,
    stsToken: credentials.securityToken,
  })

  const fileName = `upload/${Date.now()}.${file.name.split('.').pop()}`
  return client.put(fileName, file)
}

function isCredentialsExpired(credentials) {
  if (!credentials) {
    return true
  }
  const expireDate = new Date(credentials.expiration)
  const now = new Date()
  return expireDate.getTime() - now.getTime() <= 60000
}
</script>

<style scoped lang="scss">
:deep(.hide .el-upload--picture-card) {
  display: none;
}
</style>
