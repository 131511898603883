<template>
  <div class="powerStationMap">
    <div id="mapGl"></div>
    <div class="backBox" @click="emit('isShowMapFun', 1);">
      <img alt="" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapBack.png">
      <span>返回上一层</span>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {defineProps, onMounted, reactive, ref, watch} from "vue"
import './powerStationMap.less'
import {getStoreMapDataApi} from "@/api/modules/powerStation";
import {ElMessage} from "element-plus";
import qs from 'qs'

const props = defineProps(['custerObj'])
const emit = defineEmits(['isShowMapFun']);
let custerObj = ref({})


// 获取地图数据
let mapList = ref([])
const getMapData = function () {
  getStoreMapDataApi(qs.stringify({id: custerObj.value.id})).then(res => {
    if (res.data.code === 0) {
      mapList.value = res.data.result
      handelMap()
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
}


// 处理地图数据
const handelMap = function () {
  // // 创建一个定位对象
  // const geolocation = new BMap.Geolocation();
  // // 启用SDK辅助定位
  // geolocation.enableSDKLocation();
  // // 开始定位
  // let lng = ''
  // let lat = ''
  // geolocation.getCurrentPosition(function (r) {
  //   console.log(r)
  //   if (this.getStatus() == BMAP_STATUS_SUCCESS) {
  //     // 获取当前位置的经纬度
  //     console.log(r.point)
  //   } else {
  //     alert('定位失败！');
  //   }
  // }, {enableHighAccuracy: true});

  var map = new BMap.Map('mapGl');
  var point = new BMap.Point(120.2, 30.3);
  map.centerAndZoom(point, 10);
  map.enableScrollWheelZoom();
  // map.setTilt(70);
  // 样式
  map.setMapStyleV2({
    styleId: 'ce50bf9cdb110ff8e9666da356ba4ff8'
  })


  // 给地图加图标
  let list = mapList.value
  var infoWindow = []
  for (let i = 0; i < list.length; i++) {
    var pt = new BMap.Point(list[i].lng, list[i].lat);
    var icon
    let isWarnIcon
    let isWarn
    if (list[i].warnEquipmentNumber == 0) {
      icon = new BMap.Icon('https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon1.png', new BMap.Size(64, 71));
      isWarnIcon = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon3.png'
      isWarn = 'none'
    } else {
      icon = new BMap.Icon('https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon2.png', new BMap.Size(64, 71));
      isWarnIcon = 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/powerStation/mapIcon4.png'
      isWarn = 'block'
    }
    var marker = new BMap.Marker(pt, {
      size: 40,
      icon: icon
    });
    map.addOverlay(marker);
    var opts = {
      width: 100,     // 信息窗口宽度
      height: isWarn === 'none' ? 140 : 160,     // 信息窗口高度
      // title: "故宫博物院", // 信息窗口标题
      message: "这里是故宫",
      enableMessage: false
    }
    var sContent = `<div>
        <div style="display: flex;align-items: center">
          <img  alt="" src="${isWarnIcon}"
               style="width: 24px;height: 24px;">
          <div style="margin-left: 8px">
            <p style="font-weight: bold;">${list[i].name}</p>
            <p style="font-weight: 400;font-size: 12px;color: #969A9F;">总装机容量：${list[i].totalCapacity}kwp</p>
          </div>
        </div>
        <div style="font-size: 12px">
          <p>当前功率：${list[i].totalRate}KW</p>
          <p>当日发电量：${list[i].dailyPowerGeneration}度</p>
          <p>总发电量：${list[i].totalPowerGeneration}KWH</p>
          <p>电站地址：${list[i].address}</p>
          <p style="color: #E63757;display:${isWarn}">告警设备：${list[i].warnEquipmentNumber}</p>
        </div>
      </div>`;
    // var infoWindow = new BMap.InfoWindow(sContent, opts);  // 创建信息窗口对象
    infoWindow.push(new BMap.InfoWindow(sContent, opts))
    marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow[i], new BMap.Point(list[i].lng, list[i].lat)); //开启信息窗口
    });
  }
}


onMounted(() => {
  // handelMap()
  // getMapData()
  // ce50bf9cdb110ff8e9666da356ba4ff8
})


watch(() => props.custerObj, (newVal) => {
  custerObj.value = newVal
  if (newVal && newVal.id) {
    getMapData()
  }
}, {immediate: true, deep: true})

</script>

<style lang="less" scoped>
#mapGl {
  width: 100%;
  height: 100%;
}
</style>
