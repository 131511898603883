<script setup>
import { ref } from 'vue'
import Custom from '../custom/custom.vue'
import Template from '../template/template.vue'

const activeName = ref('custom')
</script>

<template>
  <div class="billing">
    <el-tabs v-model="activeName">
      <el-tab-pane label="计费项管理" name="custom"></el-tab-pane>
      <el-tab-pane label="计费模板管理" name="template"> </el-tab-pane>
    </el-tabs>

    <Transition appear mode="out-in" name="el-fade-in-linear">
      <Custom v-if="activeName === 'custom'" />
      <Template v-else-if="activeName === 'template'" />
    </Transition>
  </div>
</template>

<style>
.billing .el-tabs__header {
  padding-left: 36px;
  padding-right: 36px;
  height: 70px;
  display: flex;
  align-items: center;
  margin: 0;
}
</style>
