<template>
  <div class="app-container">
    <el-form
      :model="queryParams"
      ref="queryRef"
      :inline="true"
      v-show="showSearch"
      label-width="auto"
    >
      <el-form-item label="房东" prop="landlordId">
        <el-select
          v-model="queryParams.landlordId"
          placeholder="请选择房东"
          clearable
          filterable
          style="width: 200px"
        >
          <el-option
            v-for="item in queryLists.landlordList"
            :key="item.landlordId"
            :label="item.landlordName"
            :value="item.landlordId"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="统计目标" prop="statisticalTarget">
        <el-select
          v-model="queryParams.statisticalTarget"
          placeholder="请选择统计目标"
          clearable
          style="width: 200px"
        >
          <el-option
            v-for="dict in statistical_target"
            :key="dict.value"
            :label="dict.label"
            :value="dict.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="时间范围" style="width: 308px">
        <el-date-picker
          v-model="monthrange"
          value-format="YYYY-MM"
          type="monthrange"
          range-separator="-"
          start-placeholder="开始年月"
          end-placeholder="结束年月"
          :shortcuts="shortcuts"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="Search" @click="handleQuery"
          >搜索</el-button
        >
      </el-form-item>
    </el-form>

    <div ref="chartDom" style="height: 400px; width: 50%"></div>
  </div>
</template>

<script setup name="StatisticsAnalysisLandlord">
import * as echarts from 'echarts'
import { getLandlordStatistics } from '@/api/modules/statistics'
import { getCurrentInstance, onMounted, ref, reactive, toRefs } from 'vue'
import dayjs from 'dayjs'
import { getLandlordList } from '@/api/modules/landlord'
import { addDateRange } from '@/utils/hongen'

defineOptions({
  name: 'StatisticsAnalysisLandlord',
})

const { proxy } = getCurrentInstance()
const statistical_target = ref([
  {
    label: '电量',
    value: '0',
  },
  {
    label: '电费',
    value: '1',
  },
])
const shortcuts = [
  {
    text: '近一周',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    },
  },
  {
    text: '近一个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    },
  },
  {
    text: '近三个月',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    },
  },
  {
    text: '近一年',
    value: () => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
      return [start, end]
    },
  },
]
const loading = ref(true)
const showSearch = ref(true)
const end = new Date()
const start = new Date()
start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
const monthrange = ref([
  dayjs(start).format('YYYY-MM'),
  dayjs(end).format('YYYY-MM'),
])
const queryLists = reactive({
  landlordList: [],
})
const data = reactive({
  queryParams: {
    landlordId: '',
    statisticalTarget: '1',
  },
})
const { queryParams } = toRefs(data)

const aAxisDateList = ref([])

const text = ref('月电费统计（单位：元）')
var myChart
var option = {
  title: {
    text: text.value,
    left: 'center',
    textStyle: {
      // 主标题样式
      color: '#87CEEB',
    },
  },
  xAxis: {
    type: 'category',
    data: [],
  },
  yAxis: {
    type: 'value',
  },
  series: [
    {
      data: [],
      type: 'bar',
      label: {
        show: true, // 显示数值
        position: 'top', // 数值显示的位置
      },
    },
  ],
  tooltip: {
    // 触发类型，默认数据触发，可选为：'item' | 'axis'
    trigger: 'axis',
    // 坐标轴触发，仅当 trigger 为 'axis' 时有效
    axisPointer: {
      type: 'shadow',
    },
  },
}

/** 获取房东图表数据 */
function getLandlordChartData() {
  loading.value = true
  getLandlordStatistics(addDateRange(queryParams.value, monthrange.value)).then(
    (response) => {
      aAxisDateList.value = response.data.result.xAxis
      option.xAxis.data = aAxisDateList.value
      option.series[0].data = response.data.result.dataList?.map((item) => {
        return {
          ...item,
          itemStyle: { color: generateRandomColor() },
        }
      })
      if (queryParams.value.statisticalTarget === '0') {
        option.title.text = '月电量统计（单位：KW）'
      } else {
        option.title.text = '月电费统计（单位：元）'
      }
      myChart.setOption(option)
      loading.value = false
    }
  )
}

/** 搜索按钮操作 */
function handleQuery() {
  getLandlordChartData()
}
/** 重置按钮操作 */
function resetQuery() {
  monthrange.value = []
  proxy.resetForm('queryRef')
  queryParams.statisticalTarget = ''
  handleQuery()
}

getLandlordList().then((response) => {
  queryLists.landlordList = [
    {
      landlordId: '',
      landlordName: '全部',
    },
    ...response.data.result,
  ]
})

var chartDom = ref()

function generateRandomColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16)
}

onMounted(() => {
  myChart = echarts.init(chartDom.value)
  getLandlordChartData()
  option && myChart.setOption(option)
})
</script>

<style>
.app-container {
  padding: 20px;
}
</style>
