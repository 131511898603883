<template>
  <!-- 浙江省体育职业技术学院（教工路校区）电气一次图 -->
  <div class="onceItem2">
    <div class="onceImgBgc">
      <div class="bgcImg">
        <img alt="" class="bgc" src="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/onceImg/onceItem2.png">
        <div class="tablePox">
          <div class="tableItem">
            <table>
              <tr>
                <td>Ua</td>
                <td>{{ data.data1.ua }}</td>
                <td>KV</td>
              </tr>
              <tr>
                <td>Ub</td>
                <td>{{ data.data1.ub }}</td>
                <td>KV</td>
              </tr>
              <tr>
                <td>Uc</td>
                <td>{{ data.data1.uc }}</td>
                <td>KV</td>
              </tr>
            </table>
            <table>
              <tr>
                <td>Aa</td>
                <td>{{ data.data1.aa }}</td>
                <td>A</td>
              </tr>
              <tr>
                <td>Ab</td>
                <td>{{ data.data1.ab }}</td>
                <td>A</td>
              </tr>
              <tr>
                <td>Ac</td>
                <td>{{ data.data1.ac }}</td>
                <td>A</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import {nextTick, onMounted, ref} from "vue";
import {getEveryOnceImgDataApi} from "@/api/modules/onceImg";
import qs from "qs";
import {ElMessage} from "element-plus";

let data = ref({
  data1: {
    "aa": "",
    "ab": "",
    "ac": "",
    "ua": "",
    "ub": "",
    "uc": ""
  }
})

const resizeFun = function () {
  nextTick(() => {
    let ele = document.querySelector('.onceImgBgc')
    let scaleX = (ele.clientWidth) / 840
    let scaleY = (ele.clientHeight) / 630
    // document.querySelector('.bgcImg').style.transform = `scale(${scaleX},${scaleY})`
    for (let i = 0; i < document.querySelectorAll('.bgcImg').length; i++) {
      document.querySelectorAll('.onceImgBgc .bgcImg')[i].style.transform = `scale(${scaleX},${scaleY})`
    }
  })
}
onMounted(() => {
  nextTick(() => {
    resizeFun()
  })
  window.onresize = () => {
    resizeFun()
  }


  getEveryOnceImgDataApi(qs.stringify({
    type: 2
  })).then(res => {
    if (res.data.code === 0) {
      data.value = res.data.result
    } else {
      ElMessage({
        type: 'error',
        message: res.data.message
      })
    }
  }).catch(err => {
    console.log(err);
  })
})

</script>

<style lang="less">
.onceItem2, .onceItem2 .onceImgBgc {
  width: 100%;
  height: 100% !important;

  .bgcImg {
    width: 840px;
    height: 630px;
    transform-origin: 0% 0%;
    position: relative;

    .bgc {
      width: 100%;
      height: 100%;
    }

    .tablePox {
      position: absolute;
      left: 299px;
      bottom: 50px;
      width: calc(100% - 598px);
      height: 74px;

      .tableItem {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      table {
        border: 0.5px solid #fff;
        border-collapse: collapse; /* 移除表格内边框间的间隙 */
        font-size: 9px;

        td {
          border: 0.5px solid #fff;
        }
      }

      table {
        width: 104px;
        height: 72px;


        tr {
          display: flex;
          height: 24px;
          align-items: center;

          td {
            height: 100%;
            line-height: 24px;
          }

          td:first-child {
            width: 22px;
            padding-left: 6px;
          }

          td:nth-child(2) {
            flex: 1;
            padding-left: 6px;
          }

          td:nth-child(3) {
            width: 22px;
            padding-left: 6px;
          }
        }

        tr:first-child {
          color: #F0F500;
        }

        tr:nth-child(2) {
          color: #00E834;
          flex: 1;
        }

        tr:nth-child(3) {
          color: #F90C0F;
        }
      }
    }

  }
}
</style>
