<template>
  <!--配置页面-->
  <div class="configPage">
    <div class="title">配置</div>
    <div class="content">
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            新增配置
          </el-button>
        </div>
        <el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增配置信息</p>
            </el-empty>
          </template>
          <el-table-column label="key" property="configKey"></el-table-column>
          <el-table-column label="值" property="configValue"></el-table-column>
          <el-table-column label="备注" property="remark"></el-table-column>
          <el-table-column label="操作" width="120px">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="addClick(scope.row)">编辑</li>
                <li class="deleteClass" @click="deleteClick(scope.row.id)">删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--新增/编辑弹窗-->
    <el-dialog v-model="bigScreenDialogVisible" :close-on-click-modal="false" :title="title" :width="dialogWidth">
      <el-form ref="bigScreenFormRef" :model="bigScreenForm" :rules="bigScreenRules" class="demo-ruleForm"
               label-width="auto" style="width:98%">
        <el-form-item label="key" prop="configKey">
          <el-input v-model="bigScreenForm.configKey" placeholder="请输入key"/>
        </el-form-item>
        <el-form-item label="值" prop="configValue">
          <el-input v-model="bigScreenForm.configValue" placeholder="请输入值"/>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="bigScreenForm.remark" placeholder="请输入备注"/>
        </el-form-item>
      </el-form>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="bigScreenDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="bigScreenSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {
  Delete,
  ZoomIn,
  Plus
} from '@element-plus/icons-vue'
import {getConfigListApi, saveConfigListApi, delConfigListApi} from '@/api/modules/configPage'
import qs from 'qs';

let router = useRouter()
/*生命周期*/
onMounted(() => {
  getListFun()
})

let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置
const resetClick = function () {
  name.value = ''
}
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let name = ref() //配置名称
//获取配置列表
const getListFun = function () {
  loadingVal.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    name: name.value
  }
  getConfigListApi(qs.stringify(params)).then(res => {
    //console.log(res);
    loadingVal.value = false
    if (res.data.ok) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let dialogWidth = ref('40%') //新增配置弹窗宽度
let bigScreenDialogVisible = ref(false) //新增配置弹窗开关
let title = ref('') //弹窗标题
let editId = ref()
//新增/编辑配置的点击
let addClick = function (val) {
  if (val) {
    title.value = '编辑配置'
    bigScreenForm.value = val
  } else {
    title.value = '新建配置'
    bigScreenForm.value = {
      id: 0,
      configKey: '',
      configValue: '',
      remark: ''
    }
  }
  bigScreenDialogVisible.value = true
}
let bigScreenFormRef = ref() //配置
//配置表单
let bigScreenForm = ref({
  id: 0,
  configKey: '',
  configValue: '',
  remark: ''
})
// 配置表单验证规则
let bigScreenRules = ref({
  configKey: [{
    required: true,
    message: '请输入key',
    trigger: 'blur'
  }],
  configValue: [{
    required: true,
    message: '请输入值',
    trigger: 'blur'
  }]
})
//配置的保存
const bigScreenSave = function () {
  if (bigScreenFormRef) {
    bigScreenFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        saveConfigListApi(
            qs.stringify(bigScreenForm.value)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            bigScreenDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除配置',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此配置，删除后数据不可恢复'),
    ])
  }).then(() => {
    delConfigListApi(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
</script>
<style lang="less" scoped>
.configPage {
  width: 100%;
  height: 100%;
  //标题
  > .title {
    width: calc(100% - 36px);
    height: 48px;
    padding-left: 36px;
    line-height: 48px;
  }

  //内容
  .content {
    width: calc(100% - 56px);
    height: calc(100% - 64px);
    margin: 0 28px 16px 28px;
    overflow: auto;

    /*element表格*/

    .tableBox {
      padding: 24px 24px;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.09);

      .operationBox {
        background-color: #fff;
        margin-bottom: 20px;
        display: flex;
        justify-content: flex-start;
      }

      .operationUl {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-between;

        li {
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }

      .editClass {
        cursor: pointer;
        color: #165DFF;
      }

      .deleteClass {
        cursor: pointer;
        color: #EF2121;
      }

      .el-table thead th {
        background: rgb(250, 250, 250);
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        padding: 12px 0;
      }
    }

    //分页
    .footerBox {
      margin: 14px 0 24px 0;
      display: flex;
      justify-content: flex-end;
    }
  }

  .el-form-item {
    display: flex;
  }

  .el-form-item__label {
    font-size: 15px;
  }

  .el-input__validateIcon {
    display: none;
  }

  .el-input__suffix {
    color: #000;
  }

  .el-upload--picture-card {
    display: flex;
    flex-direction: column;
  }
}
</style>
