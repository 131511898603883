import {api} from "@/api";

// 电站-------------------------------------------------------------------------------
// 获取电站树
export const getStationTreeApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getTree',
        method: 'POST',
        data: data
    })
}
// 新增电站
export const addStationTreeList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/save',
        method: 'POST',
        data: data
    })
}
// 删除电站
export const delStationTreeList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/delete',
        method: 'POST',
        data: data
    })
}
// 获取光伏电站年月日图表数据
export const getPhotovoltaicChartDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getPhotovoltaicChartData',
        method: 'POST',
        data: data
    })
}

// 获取光伏电站数据
export const getPhotovoltaicDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getPhotovoltaicData',
        method: 'POST',
        data: data
    })
}


// 获取光伏电站地图数据
export const getPhotovoltaicMapDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getPhotovoltaicMapData',
        method: 'POST',
        data: data
    })
}
// 获取储能电站年月日图表数据
export const getStoreChartDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getStoreChartData',
        method: 'POST',
        data: data
    })
}

// 获取储能电站数据
export const getStoreDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getStoreData',
        method: 'POST',
        data: data
    })
}


// 获取储能电站地图数据
export const getStoreMapDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/getStoreMapData',
        method: 'POST',
        data: data
    })
}


// 电站配置-------------------------------------------------------------------------------
// 获取电站配置详情
export const getPowerStationSettingDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationConfig/getDetail',
        method: 'POST',
        data: data
    })
}
// 修改电站配置
export const savePowerStationSettingDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationConfig/save',
        method: 'POST',
        data: data
    })
}


// 电站设备-------------------------------------------------------------------------------
// 获取电站设备
export const getStationDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationEquipment/page',
        method: 'POST',
        data: data
    })
}

// 获取电站设备详情
export const getStationDeviceDetailApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/equipment/getDetail',
        method: 'POST',
        data: data
    })
}


// 新增电站设备
export const addStationDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationEquipment/add',
        method: 'POST',
        data: data
    })
}
// 删除电站设备
export const delStationDeviceListApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStationEquipment/delete',
        method: 'POST',
        data: data
    })
}

// 关联设备的树形结构
export const getTreePrefixWithPowerStationApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getTreePrefixWithPowerStation',
        method: 'POST',
        data: data
    })
}
// 关联设备的树形结构
export const getTreePrefixWithPowerStationApi2 = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/project/getTreePrefixWithPowerStationConfig',
        method: 'POST',
        data: data
    })
}

// 电站溯源
export const traceApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/powerStation/trace',
        method: 'POST',
        data: data
    })
}


