import {api} from "@/api";

// 获取抄读列表
export const getList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/page',
        method: 'POST',
        data: data
    })
}
//导出抄读
export const exportList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/exportV2',
        method: 'POST',
        data: data
    })
}
//导入抄读
export const importList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/import',
        method: 'POST',
        data: data
    })
}
//新增、编辑的保存
export const saveList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/save',
        method: 'POST',
        data: data
    })
}
//删除抄读
export const deleteList = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/parser/delete',
        method: 'POST',
        data: data
    })
}
