import {api} from "@/api";

//能流图数据
export const getEnergyFlowDataApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/energyFlow/getData',
        method: 'POST',
        data: data
    })
}


//能流图详情
export const getEnergyFlowApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/energyFlow/get',
        method: 'POST',
        data: data
    })
}


//能流图保存
export const saveEnergyFlowApi = (data) => {
    return api({
        headers: {
            memberId: window.localStorage.getItem('memberId'),
            Authorization: window.localStorage.getItem('Authorization'),
        },
        url: '/zouk/rest/energyFlow/save',
        method: 'POST',
        data: data
    })
}










