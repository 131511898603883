<template>
  <div class="boxRole">
    <div class="title">
      <p>
        <span>角色管理</span>
      </p>
    </div>
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">角色名称</label>
          <el-input
            v-model="queryParams.roleName"
            prefix-icon="Search"
            clearable
            placeholder="请输入角色名称"
          ></el-input>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建角色
        </el-button>
        <el-table v-loading="loading" :data="boxRoleList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建角色</p>
            </el-empty>
          </template>
          <el-table-column label="角色名称" align="center" prop="roleName" />
          <el-table-column label="角色值" align="center" prop="roleKey" />
          <el-table-column label="操作" align="center" width="150px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form ref="boxRoleRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="角色名称" prop="roleName">
          <el-input
            v-model="form.roleName"
            placeholder="请输入角色名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="角色值" prop="roleKey">
          <el-input
            v-model="form.roleKey"
            placeholder="请输入角色值"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './boxRole.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  listBoxRole,
  getBoxRole,
  delBoxRole,
  addBoxRole,
  updateBoxRole,
} from '@/api/modules/boxRole'

const boxRoleList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const boxRoleRef = ref(null)

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    roleName: null,
  },
  rules: {
    roleName: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
    roleKey: [{ required: true, message: '请输入角色值', trigger: 'blur' }],
  },
})

const { queryParams, form, rules } = toRefs(data)

/** 查询角色列表 */
function getList() {
  loading.value = true
  listBoxRole(queryParams.value).then((response) => {
    boxRoleList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    roleName: null,
    roleKey: null,
  }
  boxRoleRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    roleName: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  open.value = true
  title.value = '新建角色'
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  getBoxRole(row.id).then((response) => {
    form.value = response.data.result
    open.value = true
    title.value = '修改角色'
  })
}

/** 提交按钮 */
function submitForm() {
  boxRoleRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updateBoxRole(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addBoxRole(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除角色',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此角色，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delBoxRole(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}
</script>
