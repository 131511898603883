<template>
  <!--费率组合-->
  <div class="rateMixPage">
    <div class="title">
      <p>
        <span>费率组合包</span>
      </p>
    </div>
    <div class="content">
      <!--数据列表内容-->
      <div class="contain">
        <!--搜索-->
        <div class="searchBox">
          <div style="display: flex">
            <div class="liBox">
              <span>方案名称：</span>
              <el-input v-model="name" placeholder="请输入方案名称"></el-input>
            </div>
            <!--            <div class="liBox" style="margin-left: 20px">-->
            <!--              <span>生效日期：</span>-->
            <!--              <el-date-picker v-model="date" popper-class="date-style"-->
            <!--                              type="datetime"-->
            <!--                              value-format="YYYY-MM-DD HH:mm:ss">-->
            <!--              </el-date-picker>-->
            <!--            </div>-->
          </div>
          <div class="liBox">
            <el-button type="primary" @click="getListFun">查询</el-button>
          </div>
        </div>
        <!--新增电价配置-->
        <el-button class="addBtn" type="primary" @click="addClick">
          <el-icon>
            <Plus/>
          </el-icon>
          新增电价组合包
        </el-button>
        <!--电价列表-->
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息"
                      image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新增电价</p>
            </el-empty>
          </template>
          <el-table-column label="组合包名称" prop="name"/>
          <el-table-column label="电价组合" prop="priceNameList"/>
          <el-table-column label="创建时间" prop="createTime"/>
          <el-table-column label="创建人" prop="createName"/>
          <!--          <el-table-column label="绑定设备" prop="createTime" sortable/>-->
          <el-table-column fixed="right" label="操作">
            <template #default="scope">
              <div class="opacity">
                <span class="btn" @click="lookClick(scope.row.id)">详情</span>
                <span v-if="scope.row.canEditFlag==1" class="btn" @click="editClick(scope.row.id)">编辑</span>
                <span class="btn" @click="copyClick(scope.row.id)">复制</span>
                <span v-if="scope.row.canDeleteFlag==1" class="btn delClass" @click="delClick(scope.row.id)">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination v-model:page-size="pageSize" :page-sizes="[10, 20, 50, 100]" :total="total" background
                         layout="total,sizes,prev, pager, next" small @size-change="handleSizeChange"
                         @current-change="pageChange"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import 《组件名称》 from '《组件路径》';
import './rateMix.less'

import {h, onMounted, reactive, ref} from "vue"
import {Back, Plus} from '@element-plus/icons-vue'
import {CascaderProps, ElMessage, ElMessageBox} from 'element-plus'
import {useRouter} from 'vue-router';
import {getConfigListApi, delConfigApi} from '@/api/modules/electrovalence'
import qs from "qs";
import {delPowerPriceGroupListApi, getPowerPriceGroupListApi} from "@/api/modules/rate";

let router = useRouter()


// 电价-----------------------------------------------------------------------
// 分页
let pageNumber = ref(1)
let pageSize = ref(20)
let total = ref()
// 更改每页多少
const handleSizeChange = function (e) {
  pageSize.value = e
  getListFun()
}
// 跳转到某页
const pageChange = function (e) {
  pageNumber.value = e
  getListFun()
}
// 电价表格数据
let tableData = ref([])
// 获取电价列表
let date = ref()
let name = ref()
let loading = ref(false)
const getListFun = function () {
  loading.value = true
  getPowerPriceGroupListApi(qs.stringify({
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    name: name.value,
    // date: date.value
  })).then(res => {
    loading.value = false
    if (res.data.code === 0) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}

// 新增电价点击
const addClick = function () {
  router.push('/admin/addRateMixV2')
}
// 编辑点击
const editClick = function (id) {
  router.push({
    path: '/admin/addRateMixV2',
    query: {id: id, type: 'edit'}
  })
}
// 复制点击
const copyClick = function (id) {
  router.push({
    path: '/admin/addRateMixV2',
    query: {id: id, type: 'copy'}
  })
}
// 查看详情
const lookClick = function (id) {
  router.push({
    path: '/admin/addRateMixDetailV2',
    query: {id: id}
  })
}
// 删除电价
const delClick = function (id) {
  ElMessageBox.confirm('', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除电价组合包',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {style: 'color:#595959;'}, '请确认是否要删除此电价组合包，删除后数据不可恢复'),
    ])
  }).then(() => {
    delPowerPriceGroupListApi(qs.stringify({
      id: id
    })).then(res => {
      if (res.data.code === 0) {
        ElMessage({
          type: 'success',
          message: '删除成功',
        })
        getListFun()
      } else {
        ElMessage({
          type: 'error',
          message: res.data.message,
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      type: 'info',
      message: '取消删除',
    })
  })
}

onMounted(() => {
  getListFun()
})
</script>

<style lang="less" scoped>
</style>
