<template>
  <div class="box">
    <div class="title">
      <p>
        <span>用电箱管理</span>
      </p>
    </div>

    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">用电箱编号</label>
          <el-input
            v-model="queryParams.boxNumber"
            prefix-icon="Search"
            clearable
            placeholder="请输入用电箱编号"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">使用状态</label>
          <el-select
            v-model="queryParams.usageStatus"
            placeholder="请选择使用状态"
            clearable
          >
            <el-option
              v-for="dict in sys_usage_status"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-button class="addBtn" type="primary" @click="handleAdd">
          <el-icon>
            <Plus />
          </el-icon>
          新建用电箱
        </el-button>
        <el-table v-loading="loading" :data="boxList">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>您可以在此页面新建用电箱</p>
            </el-empty>
          </template>
          <el-table-column label="电箱编号" align="center" prop="boxNumber" />
          <el-table-column
            label="电箱型号"
            align="center"
            prop="boxModelName"
          />
          <el-table-column label="电箱二维码" align="center" prop="boxQrCode">
            <template #default="scope">
              <ImagePreview
                :src="scope.row.boxQrCode"
                :width="100"
                :height="100"
              />
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" prop="deviceStatus">
            <template #default="scope">
              <el-tag
                :type="
                  sys_device_status.find(
                    (item) => item.value === scope.row.deviceStatus
                  )?.type
                "
                >{{
                  sys_device_status.find(
                    (item) => item.value === scope.row.deviceStatus
                  )?.label
                }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="200px">
            <template #default="scope">
              <div class="opacity">
                <p class="btn" @click="handleDetail(scope.row)">详情</p>
                <p class="btn" @click="handleUpdate(scope.row)">修改</p>
                <p class="btn delClass" @click="handleDelete(scope.row)">
                  删除
                </p>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>

    <!--弹窗-->
    <el-dialog
      :title="title"
      v-model="open"
      :close-on-click-modal="false"
      width="500px"
      append-to-body
    >
      <el-form ref="boxRef" :model="form" :rules="rules" label-width="auto">
        <el-form-item label="电箱编号" prop="boxNumber">
          <el-input
            v-model="form.boxNumber"
            placeholder="请输入电箱编号"
            clearable
          />
        </el-form-item>
        <el-form-item label="电箱型号" prop="boxModelId">
          <el-select
            v-model="form.boxModelId"
            placeholder="请选择电箱型号"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="item in formLists.modelList"
              :key="item.id"
              :label="item.boxModelName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="使用状态" prop="usageStatus">
          <el-select
            v-model="form.usageStatus"
            placeholder="请选择使用状态"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="dict in sys_usage_status"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="设备状态" prop="deviceStatus">
          <el-select
            v-model="form.deviceStatus"
            placeholder="请选择设备状态"
            clearable
            style="width: 100%"
          >
            <el-option
              v-for="dict in sys_device_status"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button class="cancle resetButton" @click="cancel">取消</el-button>
          <el-button class="confrim" type="primary" @click="submitForm"
            >保存</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, h } from 'vue'
import './box.less'
import { ElMessage, ElMessageBox } from 'element-plus'
import { listBox, getBox, delBox, addBox, updateBox } from '@/api/modules/box'
import ImagePreview from '@/components/ImagePreview.vue'
import { useRouter } from 'vue-router'
import { listModel } from '@/api/modules/model'

const router = useRouter()

const boxList = ref([])
const open = ref(false)
const loading = ref(true)
const total = ref(0)
const title = ref('')
const boxRef = ref(null)

const sys_usage_status = ref([
  { label: '未使用', value: 0, type: 'info' },
  { label: '使用中', value: 1, type: 'success' },
])

const sys_device_status = ref([
  { label: '正常', value: 0, type: 'success' },
  { label: '故障', value: 1, type: 'danger' },
  { label: '维修中', value: 2, type: 'warning' },
])

const data = reactive({
  form: {},
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    boxNumber: null,
    usageStatus: null,
  },
  rules: {
    boxNumber: [{ required: true, message: '请输入电箱编号', trigger: 'blur' }],
    boxModelId: [
      { required: true, message: '请输入电箱型号', trigger: 'blur' },
    ],
    usageStatus: [
      { required: true, message: '请选择使用状态', trigger: 'change' },
    ],
    deviceStatus: [
      { required: true, message: '请选择设备状态', trigger: 'change' },
    ],
  },
})

const { queryParams, form, rules } = toRefs(data)

const formLists = reactive({
  modelList: [],
})

/** 查询用电箱列表 */
function getList() {
  loading.value = true
  listBox(queryParams.value).then((response) => {
    boxList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

// 取消按钮
function cancel() {
  open.value = false
  reset()
}

// 表单重置
function reset() {
  form.value = {
    id: null,
    boxNumber: null,
    boxModelId: null,
    usageStatus: null,
    deviceStatus: null,
  }
  boxRef.value?.resetFields()
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    boxNumber: null,
    usageStatus: null,
  }
  handleQuery()
}

/** 新建按钮操作 */
function handleAdd() {
  reset()
  listModel({
    pageNumber: 1,
    pageSize: 1000,
  }).then((response) => {
    formLists.modelList = response.data.result.list
    open.value = true
    title.value = '新建用电箱'
  })
}

function handleDetail(row) {
  router.push({
    path: '/admin/boxDetail',
    query: {
      id: row.id,
    },
  })
}

/** 修改按钮操作 */
function handleUpdate(row) {
  reset()
  listModel({
    pageNumber: 1,
    pageSize: 1000,
  }).then((response) => {
    formLists.modelList = response.data.result.list
    getBox(row.id).then((response) => {
      form.value = response.data.result
      open.value = true
      title.value = '修改用电箱'
    })
  })
}

/** 提交按钮 */
function submitForm() {
  boxRef.value.validate((valid) => {
    if (valid) {
      if (form.value.id != null) {
        updateBox(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('修改成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      } else {
        addBox(form.value).then((response) => {
          if (response.data.code === 0) {
            ElMessage.success('新建成功')
            open.value = false
            getList()
          } else {
            ElMessage({
              type: 'error',
              message: response.data.message,
            })
          }
        })
      }
    }
  })
}

/** 删除按钮操作 */
function handleDelete(row) {
  ElMessageBox.confirm('确认删除吗，删除后不可恢复，请谨慎操作', '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除用电箱',
    message: h('p', null, [
      h(
        'img',
        {
          src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
          style:
            'width:20px;height:20px;transform: translateY(4px);margin-right:8px;',
        },
        ''
      ),
      h(
        'span',
        {
          style: 'color:#595959;',
        },
        '请确认是否要删除此用电箱，删除后数据不可恢复'
      ),
    ]),
  })
    .then(function () {
      return delBox(row.id)
    })
    .then((response) => {
      if (response.data.code === 0) {
        getList()
        ElMessage.success('删除成功')
      } else {
        ElMessage({
          type: 'error',
          message: response.data.message,
        })
      }
    })
    .catch(() => {
      ElMessage({
        message: '已取消删除',
        type: 'info',
      })
    })
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumber = e
  getList()
}
</script>
