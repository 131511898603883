<template>
  <div class="electricityUsageRecord">
    <div class="content">
      <div class="searchBox">
        <div class="liBox">
          <label style="margin-right: 10px">房东名称</label>
          <el-input
            v-model="queryParams.landlordName"
            prefix-icon="Search"
            clearable
            placeholder="请输入房东名称"
          ></el-input>
        </div>
        <div class="liBox">
          <label style="margin-right: 10px">是否欠费</label>
          <el-select
            v-model="queryParams.isOverdue"
            clearable
            placeholder="请选择是否欠费"
          >
            <el-option
              v-for="dict in is_overdue"
              :key="dict.value"
              :label="dict.label"
              :value="dict.value"
            ></el-option>
          </el-select>
        </div>
        <div class="liBox">
          <el-button class="searchButton" type="primary" @click="handleQuery"
            >查询</el-button
          >
          <el-button class="searchButton resetButton" @click="resetQuery"
            >重置</el-button
          >
        </div>
      </div>
      <div class="contain">
        <el-table v-loading="loading" :data="chargeList" row-key="id">
          <template #empty>
            <el-empty
              class="emptyClass"
              description="暂无数据信息"
              image="https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png"
            >
              <p>暂无计费清单</p>
            </el-empty>
          </template>
          <el-table-column type="expand">
            <template #default="props">
              <div style="margin: 1rem">
                <h3>租户计费列表</h3>
                <el-table :data="props.row.tenantElectricityUsageRecordVOList">
                  <el-table-column
                    label="租户姓名"
                    align="center"
                    prop="tenantName"
                  />
                  <el-table-column
                    label="手机号码"
                    align="center"
                    prop="tenantMobile"
                  />
                  <el-table-column
                    label="上次结余"
                    align="center"
                    prop="tenantLastBalance"
                  />
                  <el-table-column
                    label="本次结余"
                    align="center"
                    prop="tenantCurrentBalance"
                  />
                  <el-table-column
                    label="实付电费"
                    align="center"
                    prop="tenantActualCharge"
                  />
                  <el-table-column
                    label="是否欠费"
                    align="center"
                    prop="isOverdue"
                  >
                    <template #default="scope">
                      <el-tag
                        :type="scope.row.isOverdue === 0 ? 'success' : 'danger'"
                        size="small"
                        >{{
                          scope.row.isOverdue === 0 ? '不欠费' : '欠费'
                        }}</el-tag
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="房东名称"
            align="center"
            prop="landlordName"
          />
          <el-table-column
            label="手机号码"
            align="center"
            prop="landlordMobile"
          />
          <el-table-column label="计费日期范围" align="center" width="340px">
            <template #default="scope">
              {{ scope.row.startChargeDate }} ~ {{ scope.row.endChargeDate }}
            </template>
          </el-table-column>
          <el-table-column
            label="结果"
            align="center"
            prop="chargeResult"
            show-overflow-tooltip
          />
        </el-table>
        <div class="pag">
          <el-pagination
            v-model:page-size="queryParams.pageSize"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            background
            layout="total,sizes,prev, pager, next"
            small
            @size-change="handleSizeChange"
            @current-change="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs } from 'vue'
import './electricityUsageRecord.less'
import { listElectricityUsageRecord } from '@/api/modules/electricityUsageRecord'

const chargeList = ref([])

const loading = ref(true)
const total = ref(0)

const is_overdue = ref([
  { label: '不欠费', value: 0, type: 'success' },
  { label: '欠费', value: 1, type: 'danger' },
])

const data = reactive({
  queryParams: {
    pageNumber: 1,
    pageSize: 10,
    landlordName: null,
    isOverdue: null,
  },
})

const { queryParams } = toRefs(data)

/** 查询房东列表 */
function getList() {
  loading.value = true
  listElectricityUsageRecord(queryParams.value).then((response) => {
    chargeList.value = response.data.result.list
    total.value = Number(response.data.result.total)
    loading.value = false
  })
}

/** 搜索按钮操作 */
function handleQuery() {
  queryParams.value.pageNumber = 1
  getList()
}

/** 重置按钮操作 */
function resetQuery() {
  queryParams.value = {
    pageNumber: 1,
    pageSize: 10,
    name: null,
  }
  handleQuery()
}

getList()

function handleSizeChange(e) {
  queryParams.value.pageSize = e
  getList()
}

function pageChange(e) {
  queryParams.value.pageNumberberberber = e
  getList()
}
</script>

<style scoped>
table,
td,
th {
  border: 1px solid #595959;
  border-collapse: collapse;
}
td,
th {
  padding: 3px;
  width: 30px;
  height: 25px;
}
</style>
