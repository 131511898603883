<template>
  <!--产品管理页面-->
  <div class="product">
    <div class="title">产品设置</div>
    <div class="content">
      <el-form>
        <div class="searchBox">
          <div style="display: flex">
            <div class="liBox">
              <el-form-item label="产品型号">
                <el-input v-model="model" autocomplete="off" clearable placeholder='请输入产品型号' type="text"></el-input>
              </el-form-item>
            </div>
            <div class="liBox" style="margin-left: 26px;">
              <el-form-item label="产品分类">
                <el-select v-model="categoryId" clearable placeholder="请选择产品分类" style="width:200px">
                  <el-option v-for="item in productCategoryAllList" :key="item.id" :label="item.name" :value="item.id"
                             style="width:100%">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </div>
          <div class="liBox">
            <el-button class="searchButton" type="primary" @click="getListFun">查询</el-button>
            <el-button class="searchButton resetButton" @click="resetClick()">重置</el-button>
          </div>
        </div>
      </el-form>
      <div class="tableBox">
        <div class="operationBox">
          <el-button :icon="Plus" type="primary" @click="addClick()">
            新建产品
          </el-button>
        </div>
        <el-table ref="multipleTableRef" v-loading="loadingVal" :data="tableData" style="width: 100%"
                  @selection-change="handleSelectionChange">
          <template #empty>
            <el-empty class="emptyClass" description="暂无数据信息" image="
https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/emptyImg.png">
              <p>您可以在此页面新建产品信息</p>
            </el-empty>
          </template>
          <el-table-column label="产品型号" min-width="2%" property="model">
          </el-table-column>
          <el-table-column label="创建人" min-width="2%" property="creatorName">
          </el-table-column>
          <el-table-column label="创建时间" min-width="2%" property="createTime" sortable>
          </el-table-column>
          <el-table-column label="操作" min-width="1%">
            <template #default="scope">
              <ul class="operationUl">
                <li class="editClass" @click="detailClick(scope.row)">查看</li>
                <li class="deleteClass" @click="deleteClick(scope.row.id)">删除
                </li>
              </ul>
            </template>
          </el-table-column>
        </el-table>
        <div class="footerBox">
          <el-pagination :disabled="disabled" :page-size="pageSize" :page-sizes="[20,100, 200, 300, 400]" :small="small"
                         :total="total" background layout="total, sizes, prev, pager, next" small
                         @size-change="handleSizeChange" @current-change="handleCurrentChange"/>
        </div>
      </div>
    </div>
    <!--新增产品弹窗-->
    <el-dialog v-model="productDialogVisible" :close-on-click-modal="false" :width="dialogWidth" title="新增产品">
      <div>
        <el-form ref="productFormRef" :model="productForm" :rules="productRules" class="demo-ruleForm"
                 label-width="auto"
                 style="width:98%">
          <el-form-item label="分类" prop="categoryId">
            <el-select v-model="productForm.categoryId" clearable placeholder="请选择产品分类" style="width:100%"
                       @change="categoryIdChange()">
              <el-option v-for="item in productCategoryAllList" :key="item.id" :label="item.name" :value="item.id"
                         style="width:100%">
              </el-option>
            </el-select>
          </el-form-item>
          <div v-show="categoryType==5">
            <el-form-item label="保留soc" prop="retainSoc">
              <el-input v-model="productForm.retainSoc" placeholder="请输入保留soc"/>
            </el-form-item>
            <el-form-item label="充放电最大功率" prop="maxPower">
              <el-input v-model="productForm.maxPower" placeholder="请输入充放电最大功率"/>
            </el-form-item>
            <el-form-item label="申请容量" prop="applyCapacity">
              <el-input v-model="productForm.applyCapacity" placeholder="请输入申请容量"/>
            </el-form-item>
            <el-form-item label="充电量字典key" prop="rechargeDictKey">
              <el-input v-model="productForm.rechargeDictKey" placeholder="请输入充电量字典key"/>
            </el-form-item>
            <el-form-item label="放电量字典key" prop="generateDictKey">
              <el-input v-model="productForm.generateDictKey" placeholder="请输入放电量字典key"/>
            </el-form-item>
          </div>
          <el-form-item label="型号" prop="model">
            <el-input v-model="productForm.model" placeholder="请输入型号"/>
          </el-form-item>
          <el-form-item v-show="categoryType==11" label="产品id" prop="productCode">
            <el-input v-model="productForm.productCode" placeholder="请输入产品id"/>
          </el-form-item>
          <div v-show="categoryType!=11">
            <el-form-item label="规约类型" prop="rulerType">
              <el-select v-model="productForm.rulerType" placeholder="请选择" style="width:100%">
                <el-option v-for="item in rulerTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="通信密码" prop="password">
              <el-input v-model="productForm.password" placeholder="请输入通信密码"/>
            </el-form-item>
            <el-form-item label="费率个数" prop="feeNum">
              <el-input v-model="productForm.feeNum" placeholder="请输入费率个数" type="number"/>
            </el-form-item>
            <el-form-item label="用户类型" prop="userType">
              <el-input v-model="productForm.userType" placeholder="请输入用户类型"/>
            </el-form-item>
            <el-form-item label="波特率" prop="rateType">
              <el-select v-model="productForm.rateType" placeholder="请选择" style="width:100%">
                <el-option v-for="item in rateTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="接线方式" prop="joinType">
              <el-select v-model="productForm.joinType" placeholder="请选择" style="width:100%">
                <el-option v-for="item in joinTypeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="额定电压" prop="ratedV">
              <el-input v-model="productForm.ratedV" placeholder="请输入额定电压" type="number">
                <template #suffix>
                  V
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="额定电流" prop="ratedA">
              <el-input v-model="productForm.ratedA" placeholder="请输入额定电流" type="number">
                <template #suffix>
                  mA
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="资产号" prop="pCode">
              <el-input v-model="productForm.pCode" placeholder="请输入资产号"/>
            </el-form-item>
            <el-form-item label="抄读方式
" prop="parserId">
              <el-select v-model="productForm.parserId" placeholder="请选择" style="width:100%">
                <el-option v-for="item in parserList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <!--        <el-form-item label="协议类型" prop="protocolType">-->
        <!--          <el-select v-model="productForm.protocolType" placeholder="请选择" style="width:100%">-->
        <!--            <el-option v-for="item in protocolTypeList" :key="item.id" :label="item.name" :value="item.id">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <el-button v-show="categoryType!=11" class="nowdataClass" type="primary" @click="setNowData(parser_id)">配置实时数据
        </el-button>
        <div class="specilCheckBox">
          <div v-for="item in parserItemList" v-show="item.checked==true" class="parserClass">
            <div class="nameClass">{{ item.name }}</div>
            <div class="nameClass">{{ item.dataCode }}</div>
          </div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="productDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="productSave">保存</el-button>
      			</span>
      </template>
    </el-dialog>
    <!--实时数据弹窗-->
    <el-dialog v-model="nowDataDialogVisible" :close-on-click-modal="false" :width="dialogWidth2" title="实时抄读设置">
      <div>
        <span style="display:inline-block;margin-bottom: 20px;">抄读方式：{{ parserName }}</span>
        <div class="checkAllBox">
          <el-checkbox v-model="checkedAll" @change="checkedAllChange()">全选</el-checkbox>
        </div>
        <div v-for="item in parserItemList" :class="[item.name?'parserClass2':'parserClass3']">
          <el-checkbox v-model="item.checked" @change="checkedRadioChange()">{{ item.name }}</el-checkbox>
          <div v-if="item.checkFlag==true" :class="[item.checkFlag?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
          <div v-if="item.checkFlag==false" :class="[item.checked?'nameClass3':'nameClass2']">{{ item.dataCode }}</div>
        </div>
      </div>
      <template #footer>
				<span class="dialog-footer">
		    		<el-button class="resetButton" @click="nowDataDialogVisible = false">取消</el-button>
		    		<el-button type="primary" @click="nowDataDialogVisible = false">确认</el-button>
      			</span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive, onMounted, computed, watch, ref, defineComponent, h} from 'vue';
import {useRouter} from 'vue-router';
import {ElTable, ElMessage, ElMessageBox} from 'element-plus'
import {ArrowUpBold, ArrowDownBold, Plus} from '@element-plus/icons-vue'
import {
  getList,
  getAllCategoryList,
  getAllParserList,
  getDetail,
  getParserItemList,
  saveList,
  deleteList, saveList2
} from '../../api/modules/product'
import './product.less'
import qs from 'qs';
import {getCategoryTypeApi} from "@/api/modules/productCategory";

let router = useRouter()
/*生命周期*/
onMounted(() => {
  getListFun()
  getAllCategoryListFun()
  getAllParserListFun()
  getDetailFun()
})

let pageNumber = ref(1) //页码
let pageSize = ref(20) //页数
let total = ref(0) //总页数
let tableData = ref([]) //列表数据
//重置
const resetClick = function () {
  model.value = ''
  categoryId.value = ''
}
//每页多少
const handleSizeChange = function (val) {
  pageSize.value = val;
  getListFun();
}
//更改页数
const handleCurrentChange = function (val) {
  pageNumber.value = val;
  getListFun();
}
let loadingVal = ref(false) //表格加载
let model = ref() //产品型号
let categoryId = ref() //分类id
//获取产品列表
const getListFun = function () {
  loadingVal.value = true
  let params = {
    pageNumber: pageNumber.value,
    pageSize: pageSize.value,
    model: model.value,
    categoryId: categoryId.value
  }
  getList(qs.stringify(params)).then(res => {
    //console.log(res);
    loadingVal.value = false
    if (res.data.ok) {
      tableData.value = res.data.result.list
      total.value = Number(res.data.result.total)
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let productCategoryAllList = ref([]) //所有分类列表
//所有分类列表
const getAllCategoryListFun = function () {
  getAllCategoryList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      productCategoryAllList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let parserList = ref([]) //所有抄读列表
//所有抄读列表
const getAllParserListFun = function () {
  getAllParserList(null).then(res => {
    //console.log(res);
    if (res.data.ok) {
      parserList.value = res.data.result
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
let rulerTypeList = ref([]) //规约类型
let rateTypeList = ref([]) //波特率
let joinTypeList = ref([]) //接线方式
// let protocolTypeList = ref([]) //协议类型
//获取详情
const getDetailFun = function () {
  getDetail(qs.stringify({
    id: 0
  })).then(res => {
    //console.log(res);
    if (res.data.ok) {
      rulerTypeList.value = res.data.result.rulerTypeList
      rateTypeList.value = res.data.result.rateTypeList
      joinTypeList.value = res.data.result.joinTypeList
      // protocolTypeList.value = res.data.result.protocolTypeList
    } else {
      ElMessage({
        message: res.data.message,
        type: 'error'
      })
    }
  }).catch(err => {
    console.log(err);
  })
}
/*
 * 配置实时数据
 * */
let dialogWidth2 = ref('60%') //实时数据弹窗宽度
let nowDataDialogVisible = ref(false) //实时数据弹窗开关
let parserItemList = ref([]) //抄读项列表
let parserName = ref() //抄读名称
let checkedAll = ref() //全选
//实时数据的点击
const setNowData = function () {
  if (productForm.value.parserId) {
    nowDataDialogVisible.value = true
    parserList.value.forEach(item => {
      if (item.id == productForm.value.parserId) {
        parserName.value = item.name
      }
    })
    getParserItemList(qs.stringify({
      parserId: productForm.value.parserId,
    })).then(res => {
      //console.log(res);
      if (res.data.ok) {
        parserItemList.value = res.data.result
        parserItemList.value.forEach(item => {
          item.checked = false
          item.checkFlag = 0
        })
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  } else {
    ElMessage({
      message: '请先选择抄读方式',
      type: 'info'
    })
  }
}
//全选、全不选
const checkedAllChange = function () {
  if (checkedAll.value == true) {
    parserItemList.value.forEach(item => {
      item.checked = true
    })
  } else {
    parserItemList.value.forEach(item => {
      item.checked = false
    })
  }
}
//单选
const checkedRadioChange = function (val, signVal) {
  var checkedArr = []
  parserItemList.value.forEach(item => {
    if (item.checked == true) {
      checkedArr.push(item)
    }
  })
  if (checkedArr.length == parserItemList.value.length) {
    checkedAll.value = true
  } else {
    checkedAll.value = false
  }
}
let dialogWidth = ref('40%') //新增产品弹窗宽度
let productDialogVisible = ref(false) //新增产品弹窗开关
//新增产品的点击
const addClick = function () {
  parserItemList.value = []
  productForm.value = {}
  productForm.value.password = '123456020000'
  productForm.value.pCode = '000000000000'
  productForm.value.parserId = ''
  // productForm.value.protocolType = ''
  productDialogVisible.value = true
}
let productFormRef = ref() //产品
//产品表单
let productForm = ref({
  categoryId: '',
  retainSoc: '',
  maxPower: '',
  applyCapacity: '',
  rechargeDictKey: '',
  generateDictKey: '',
  model: '',
  rulerType: '',
  productCode: '',
  password: '123456020000',
  feeNum: '',
  userType: '',
  rateType: '',
  joinType: '',
  ratedV: '',
  ratedA: '',
  pCode: '000000000000',
  parserId: '',
  // protocolType: '',
})
// 产品表单验证规则
let productRules = ref({
  categoryId: [{
    required: true,
    message: '请选择分类',
    trigger: 'blur'
  }],
  model: [{
    required: true,
    message: '请输入型号',
    trigger: 'blur'
  }],
  // rulerType: [{
  //   required: true,
  //   message: '请选择规约类型',
  //   trigger: 'blur'
  // }],
  // password: [{
  //   required: true,
  //   message: '请输入通讯密码',
  //   trigger: 'blur'
  // }],
  // feeNum: [{
  //   required: true,
  //   message: '请输入费率个数',
  //   trigger: 'blur'
  // }],
  // userType: [{
  //   required: true,
  //   message: '请输入用户类型',
  //   trigger: 'blur'
  // }],
  // rateType: [{
  //   required: true,
  //   message: '请选择波特率',
  //   trigger: 'blur'
  // }],
  // joinType: [{
  //   required: true,
  //   message: '请选择接线方式',
  //   trigger: 'blur'
  // }],
  // ratedV: [{
  //   required: true,
  //   message: '请输入额定电压',
  //   trigger: 'blur'
  // }],
  // ratedA: [{
  //   required: true,
  //   message: '请输入额定电流',
  //   trigger: 'blur'
  // }],
  // pCode: [{
  //   required: true,
  //   message: '请输入资产号',
  //   trigger: 'blur'
  // }],
  // parserId: [{
  //   required: true,
  //   message: '请选择抄读方式',
  //   trigger: 'blur'
  // }],
  // protocolType: [{
  //   required: true,
  //   message: '请选择协议类型',
  //   trigger: 'blur'
  // }]
})
//产品的保存
const productSave = function () {
  var arr = []
  if (parserItemList.value && parserItemList.value.length > 0) {
    parserItemList.value.forEach(item => {
      if (item.checked) {
        arr.push(item.id)
      }
    })
  }
  if (categoryType.value != 5) {
    productForm.value.retainSoc = ''
    productForm.value.maxPower = ''
    productForm.value.applyCapacity = ''
    productForm.value.rechargeDictKey = ''
    productForm.value.generateDictKey = ''
  }
  let params = {
    id: 0,
    categoryId: productForm.value.categoryId,
    retainSoc: productForm.value.retainSoc,
    maxPower: productForm.value.maxPower,
    applyCapacity: productForm.value.applyCapacity,
    rechargeDictKey: productForm.value.rechargeDictKey,
    generateDictKey: productForm.value.generateDictKey,
    model: productForm.value.model,
    rulerType: productForm.value.rulerType,
    productCode: productForm.value.productCode,
    password: productForm.value.password,
    feeNum: productForm.value.feeNum,
    userType: productForm.value.userType,
    rateType: productForm.value.rateType,
    joinType: productForm.value.joinType,
    ratedV: productForm.value.ratedV,
    ratedA: productForm.value.ratedA,
    pCode: productForm.value.pCode,
    parserId: productForm.value.parserId,
    // protocolType: productForm.value.protocolType,
    parserItemIdList: arr.toString()
  }
  if (productFormRef) {
    productFormRef.value.validate((valid) => {
      //提交成功
      if (valid) {
        categoryType.value == 11 ? saveList2(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            productDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        }) : saveList(
            qs.stringify(params)
        ).then(async res => {
          //console.log(res);
          if (res.data.ok) {
            productDialogVisible.value = false
            getListFun()
          } else {
            ElMessage({
              message: res.data.message,
              type: 'error'
            })
          }
        }).catch(err => {
          console.log(err);
        })
      }
    })
  }
}
//删除
const deleteClick = function (id) {
  ElMessageBox.confirm("确认删除吗，删除后不可恢复，请谨慎操作", '', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    title: '删除产品',
    message: h('p', null, [
      h('img', {
        src: 'https://zkfiles.oss-cn-beijing.aliyuncs.com/zkepC3.0/info.png',
        style: 'width:20px;height:20px;transform: translateY(4px);margin-right:8px;'
      }, ''),
      h('span', {
        style: 'color:#595959;'
      }, '请确认是否要删除此产品，删除后数据不可恢复'),
    ])
  }).then(() => {
    deleteList(
        qs.stringify({
          id: id
        })
    ).then(res => {
      //console.log(res);
      if (res.data.ok) {
        getListFun()
      } else {
        ElMessage({
          message: res.data.message,
          type: 'error'
        })
      }
    }).catch(err => {
      console.log(err);
    })
  }).catch(() => {
    ElMessage({
      message: '已取消删除',
      type: 'info',
    })
  });
}
let categoryType = ref() //分类类型
const categoryIdChange = function () {
  productCategoryAllList.value.forEach(item => {
    if (productForm.value.categoryId == item.id) {
      categoryType.value = item.showType
    }
  })
}
//查看
const detailClick = function (scope) {
  router.push({
    path: '/admin/productDetail',
    query: {
      id: scope.id,
      type: scope.categoryType
    }
  })
}
</script>
