import { api } from '@/api'

// 分页查询房东结算列表
export function listElectricityUsageRecord(query) {
  return api({
    headers: {
      memberId: window.localStorage.getItem('memberId'),
      Authorization: window.localStorage.getItem('Authorization'),
    },
    url: '/zouk/billing/record/page',
    method: 'get',
    params: query,
  })
}
